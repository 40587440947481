import { FunctionComponent } from 'react';
import { Card } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Checkbox, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';

export const DashboardsTab: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 30 }} wrap horizontalAlign='start'>
                <Stack.Item styles={{ root: { width: 'calc(33% - 28px)' } }}>
                    <Checkbox label={formatMessage({ id: 'administrator' })} />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: 'calc(33% - 28px)' } }}>
                    <Checkbox label={formatMessage({ id: 'contravention' })} />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: 'calc(33% - 28px)' } }}>
                    <Checkbox label={formatMessage({ id: 'partner' })} />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: 'calc(33% - 28px)' } }}>
                    <Checkbox label={formatMessage({ id: 'auditor' })} />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: 'calc(33% - 28px)' } }}>
                    <Checkbox label={formatMessage({ id: 'manager' })} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
