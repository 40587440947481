import { Dispatch, FunctionComponent, MouseEventHandler, SetStateAction, useMemo, useState } from 'react';
import { Card, DataTable, Pagination } from 'components';
import { useIntl } from 'react-intl';
import { ActionButtonStyles } from 'constants/tableStylesPeset';
import { FundModal, YearModal, ClientModal } from 'pages/Workflow/components/modals';
import { tableColumnsData, tableItemsData } from 'pages/Workflow/components/data/DashboardData';
import { formatDate } from 'utils/dates';
import { Stack, IColumn, SelectionMode, Text, ActionButton, IStackProps } from '@fluentui/react';

export enum OnClickPopUpNamesEnum {
    JOB_MODAL = 'jobModal',
    FUND_MODAL = 'fundModal',
    CLIENT_MODAL = 'clientModal',
}

export enum DashboardTableTypeEnum {
    Administrator_AuditsAwaitingAuditorAllocation = 1,
    Administrator_AuditsInProgressWithAuditor = 2,
    Administrator_AuditsAwaitingManagerReview = 3,
    Administrator_AuditsAwaitingPartnerReview = 4,
    Administrator_PostArQueriesReadyForDispatch = 5,
    Administrator_ArSentThisWeek = 6,
    Administrator_AuditsReadyForInitialDispatch = 7,
    Administrator_PreArQueriesWaitingToBeReviewed = 8,
    Administrator_PostArQueriesWaitingToBeReviewed = 9,
    Administrator_PreArQueriesWaitingForResponse = 10,
    Administrator_PostArQueriesWaitingForResponse = 11,
    Administrator_Invoicing = 12,
}

export interface ITableFilters {
    pageSize: number;
    page: number;
    searchByName?: string;
}

interface IGeneratedTableProps {
    columnsData: typeof tableColumnsData;
    itemsData: typeof tableItemsData;
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
    containerProps?: IStackProps;
}

export const GeneratedDataTable: FunctionComponent<IGeneratedTableProps> = ({
    columnsData,
    itemsData,
    setFilters,
    containerProps = {},
}) => {
    const { formatMessage } = useIntl();

    const [showClientModal, setShowClientModal] = useState<boolean>(false);
    const [showFundModal, setShowFundModal] = useState<boolean>(false);
    const [showJobModal, setShowJobModal] = useState<boolean>(false);

    const getClickHandler = (onClickPopUpName: string | null): MouseEventHandler<HTMLButtonElement> | null => {
        switch (onClickPopUpName) {
            case OnClickPopUpNamesEnum.JOB_MODAL:
                return () => setShowJobModal(true);
            case OnClickPopUpNamesEnum.FUND_MODAL:
                return () => setShowFundModal(true);
            case OnClickPopUpNamesEnum.CLIENT_MODAL:
                return () => setShowClientModal(true);
            default:
                return null;
        }
    };

    const [columns] = useState<IColumn[]>(
        columnsData.columns.map((column) => ({
            key: column.columnName,
            name: column.displayName,
            minWidth: 150,
            maxWidth: 200,
            fieldName: column.columnName,
            onRender: (items) => {
                const { isClickable, onClickPopUpName, isDateTimeColumn } = column;
                const text = items[column.columnName];

                const procText = isDateTimeColumn ? formatDate(text) : text;
                const onClick = getClickHandler(onClickPopUpName);

                return isClickable && onClick ? (
                    <ActionButton styles={ActionButtonStyles} onClick={onClick} allowDisabledFocus>
                        {procText}
                    </ActionButton>
                ) : (
                    <Text>{procText}</Text>
                );
            },
        }))
    );

    const sortableColumnsKey = useMemo(
        () => columnsData.columns.filter((column) => column.isSortable).map((column) => column.columnName),
        [columnsData]
    );

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters((prev) => ({ ...prev, pageSize, page }));
    };

    return (
        <Stack {...containerProps}>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <Card>
                    <DataTable
                        sortableColumnKeys={sortableColumnsKey}
                        initialColumns={columns}
                        selectionMode={SelectionMode.none}
                        containerHeight='100%'
                        items={itemsData.items}
                    />
                </Card>

                <ClientModal isOpen={showClientModal} onDismiss={() => setShowClientModal(false)} title={formatMessage({ id: 'client' })} />

                <FundModal isOpen={showFundModal} onDismiss={() => setShowFundModal(false)} title={formatMessage({ id: 'fund' })} />

                <YearModal isOpen={showJobModal} onDismiss={() => setShowJobModal(false)} title={formatMessage({ id: 'job' })} />
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Card>
                    <Pagination
                        itemsCount={(itemsData.filter.page - 1) * itemsData.filter.pageSize + itemsData.items.length}
                        total={itemsData.count}
                        page={itemsData.filter.page}
                        pageSize={itemsData.filter.pageSize}
                        onChange={onPaginationChange}
                    />
                </Card>
            </Stack.Item>
        </Stack>
    );
};
