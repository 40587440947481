import { map, Observable, tap, timer } from 'rxjs';
import { chunk, cloneDeep } from 'lodash';
import { IInvoice, IInvoicesFilters, IPaginatedResult } from 'interfaces';
import { mockedInvoices } from 'constants/MockedInvoices';
import { BaseService } from './BaseService';
import { ConfigurationService, configurationService } from './ConfigurationService';

class InvoicesService extends BaseService {
    // TODO: remove after implementing real api
    private allInvoices: Array<IInvoice> = cloneDeep(mockedInvoices);

    constructor(configurationService: ConfigurationService) {
        super('invoiceUrl', configurationService);
    }

    public getAllInvoices(
        clientId: string,
        page: number,
        pageSize: number,
        filters: IInvoicesFilters
    ): Observable<IPaginatedResult<IInvoice>> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(
            map(() => {
                const filteredInvoices = this.allInvoices.filter((i) => (filters.fund ? i.fundGuid === filters.fund : true));
                // .filter((i) => (filters.year ? i.jobYear === filters.year : true));
                const pages = cloneDeep(chunk(filteredInvoices, pageSize));
                if (page > pages.length) {
                    throw new Error('Wrong page');
                }
                return { items: pages[page - 1], totalItemsCount: filteredInvoices.length, shownItemsCount: pages[page - 1].length };
            })
        );
        // FUI: example of api
        // return this.httpClient
        //     .get<Array<IInvoice>>('api/invoices', { params: { page, pageSize, ...filters } })
        //     .pipe(map((response) => response.data));
    }

    public deleteInvoice(invoiceId: number): Observable<any> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(tap(() => (this.allInvoices = this.allInvoices.filter((creditNote) => creditNote.id !== invoiceId))));
        // FUI: example of api
        // return this.httpClient.delete(`api/invoices/${invoiceId}`).pipe(map((response) => response.data));
    }
}

export const invoicesService = new InvoicesService(configurationService);
