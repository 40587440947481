import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
// import { IClient } from './useGetUsersFilter';

export interface IEditUser {
    // !TODO clients interface
    body: any[];
    userId: number;
}

function useEditUserAssignClients() {
    const context = React.useContext(Context);

    const { mutate: editUserAssignClients, isLoading } = useMutation(({ userId, body }: IEditUser) =>
        axios.put(`${context?.config?.userUrl}/api/1.0/User/${userId}/assignClients`, body, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
        }
    );

    return { editUserAssignClients, isLoading };
}

export { useEditUserAssignClients };