import { FunctionComponent } from 'react';
import { Callout, DefaultButton, IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { ControlledTextField } from '../ControlledTextField';
import { ControlledDropdown } from '../ControlledDropdown';

interface Props {
    buttonId: string;
    onDismiss: () => void;
    onSubmit: (url: string, target: '_blank' | '_self') => void;
}

interface ILinkForm {
    url: string;
    target: '_blank' | '_self';
}

export const RichTextLinkEditForm: FunctionComponent<Props> = (props: Props) => {
    const { control, handleSubmit, formState } = useForm<ILinkForm>(DefaultFormSettings);
    const { formatMessage } = useIntl();

    const targetOptions: IDropdownOption[] = [
        { text: formatMessage({ id: 'currentWindow' }), key: '_self' },
        { text: formatMessage({ id: 'newWindow' }), key: '_blank' },
    ];

    return (
        <Callout role='alertdialog' gapSpace={0} target={`#${props.buttonId}`} onDismiss={props.onDismiss} setInitialFocus>
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                <ControlledTextField name='url' control={control} label={formatMessage({ id: 'url' })} />

                <ControlledDropdown name='target' control={control} label={formatMessage({ id: 'openLinkIn' })} options={targetOptions} />

                <Stack tokens={{ childrenGap: 16 }} horizontal>
                    <DefaultButton onClick={props.onDismiss} text={formatMessage({ id: 'cancel' })} />

                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit((data) => props.onSubmit(data.url, data.target))}
                        text={formatMessage({ id: 'save' })}
                    />
                </Stack>
            </Stack>
        </Callout>
    );
};
