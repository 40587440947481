import { Stack } from '@fluentui/react';
import { Card } from 'components';
import { useState } from 'react';
import { DashboardTabsMenu } from 'pages/Workflow/DashboardWorkflow/components';
import { AdministrationDashboard } from 'pages/Workflow/components/dashboards';
import { AuditorDashboard } from '../components/dashboards/AuditorDashboard';
import { ContraventionDashboard } from '../components/dashboards/ContraventionDashboard';

export enum DashboardTabsEnum {
    ADMINISTRATOR = 'administrator',
    AUDITOR = 'auditor',
    CONTRAVENTION = 'contravention',
    MANAGER = 'manager',
    PARTNER = 'partner',
}

export const DashboardWorkflowPage = () => {
    const [selectedKey, setSelectedKey] = useState<DashboardTabsEnum>(DashboardTabsEnum.ADMINISTRATOR);

    const getTabContent = (selectedTab: DashboardTabsEnum): JSX.Element => {
        switch (selectedTab) {
            case DashboardTabsEnum.ADMINISTRATOR:
                return <AdministrationDashboard />;
            // !TODO add your dashboards for other tabs here
            case DashboardTabsEnum.AUDITOR:
                return <AuditorDashboard />;
            case DashboardTabsEnum.CONTRAVENTION:
                return <ContraventionDashboard />;
            case DashboardTabsEnum.MANAGER:
            case DashboardTabsEnum.PARTNER:
            default:
                return <AdministrationDashboard />;
        }
    };

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <Card styles={{ root: { paddingTop: 0, paddingBottom: 0 } }}>
                    <DashboardTabsMenu selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
                </Card>
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <Card styles={{ root: { height: '100%', overflow: 'auto', boxSizing: 'border-box' } }}>{getTabContent(selectedKey)}</Card>
            </Stack.Item>
        </Stack>
    );
};
