import { Stack, IStackTokens, IIconProps, SearchBox, Label } from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react/lib/Button';
import { Card, ControlledDropdown } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { DashboardSearchStyles } from 'constants/tableStylesPeset';
import { useIsDarkTheme } from 'hooks/useIsDarkTheme';
import { JobsAddItemModal } from 'pages/Workflow/components/modals';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

export interface IJobsSearch {
    jobYear: string;
}

const jobYearOptions = [{ key: '2000', text: '2000' }, { key: '2001', text: '2001' }, { key: '2002', text: '2002' }, { key: '2003', text: '2003' }]

export const JobsFilters = () => {
    const stackTokens: IStackTokens = { childrenGap: 12 };
    const isUserPreferDarkTheme = useIsDarkTheme();

    const [activeButActive, setActiveButActive] = useState<boolean>(false);
    const [activeButUnActive, setActiveButUnActive] = useState<boolean>(false);
    const [activeButAll, setActiveButAll] = useState<boolean>(true);

    const exelDoc: IIconProps = { iconName: 'ExcelDocument' };
    const pdf: IIconProps = { iconName: 'PDF' };
    const add: IIconProps = { iconName: 'add' };

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const { formatMessage } = useIntl();
    const { control } = useForm<IJobsSearch>(DefaultFormSettings);
    return (
        <Card>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack horizontal horizontalAlign='space-between'>
                    <Stack horizontal tokens={stackTokens}>
                        {activeButAll ? (
                            <PrimaryButton
                                text={formatMessage({ id: 'all' })}
                                id='3'
                                onClick={() => setActiveButAll(false)}
                                allowDisabledFocus
                            />
                        ) : (
                            <DefaultButton
                                text={formatMessage({ id: 'all' })}
                                id='3'
                                onClick={() => {
                                    setActiveButActive(false);
                                    setActiveButUnActive(false);
                                    setActiveButAll(true);
                                }}
                                allowDisabledFocus
                            />
                        )}
                        {activeButActive ? (
                            <PrimaryButton
                                text={formatMessage({ id: 'active' })}
                                id='1'
                                onClick={() => setActiveButActive(false)}
                                allowDisabledFocus
                            />
                        ) : (
                            <DefaultButton
                                text={formatMessage({ id: 'active' })}
                                id='1'
                                onClick={() => {
                                    setActiveButActive(true);
                                    setActiveButUnActive(false);
                                    setActiveButAll(false);
                                }}
                                allowDisabledFocus
                            />
                        )}
                        {activeButUnActive ? (
                            <PrimaryButton
                                text={formatMessage({ id: 'inactive' })}
                                id='2'
                                onClick={() => setActiveButUnActive(false)}
                                allowDisabledFocus
                            />
                        ) : (
                            <DefaultButton
                                text={formatMessage({ id: 'inactive' })}
                                id='2'
                                onClick={() => {
                                    setActiveButActive(false);
                                    setActiveButUnActive(true);
                                    setActiveButAll(false);
                                }}
                                allowDisabledFocus
                            />
                        )}
                    </Stack>
                    <Stack styles={DashboardSearchStyles}>
                        <SearchBox placeholder={formatMessage({ id: 'searchByName' })} underlined={true} />
                    </Stack>
                    <Stack horizontal>
                        <IconButton iconProps={exelDoc} aria-label='Emoji' />
                        <IconButton iconProps={pdf} aria-label='Emoji' />
                        <IconButton iconProps={add} label='Add' onClick={() => setShowAddModal(true)} />
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='space-between'>
                    <Stack styles={{ root: { minWidth: 80 }}}>
                        <ControlledDropdown
                            name='jobYear'
                            options={jobYearOptions}
                            control={control}
                            placeholder={formatMessage({ id: 'year' })}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 6 }}
                        styles={{
                            root: {
                                paddingRight: '6px',
                                'input::-webkit-calendar-picker-indicator': isUserPreferDarkTheme ? { filter: 'invert(1)' } : {}
                            }
                        }}
                    >
                        <Label>{formatMessage({ id: 'dateARSent' })}:</Label>
                        <SearchBox iconProps={{ style: { display: 'none'} }} showIcon={true} type='date' underlined={true} />
                        <SearchBox iconProps={{ style: { display: 'none'} }} showIcon={true} type='date' underlined={true} />
                    </Stack>
                </Stack>
            </Stack>

            <JobsAddItemModal
                isOpen={showAddModal}
                onDismiss={() => setShowAddModal(false)}
                title={formatMessage({ id: 'addingNewItem' })}
            />
        </Card>
    );
};