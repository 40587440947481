import { observer } from 'mobx-react';
import { stores } from 'stores/MainStore';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const LayoutBreadcrumb = observer(() => {
    const navigate = useNavigate();
    const onBreadcrumbItemClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => navigate(item?.key!);
    const breadcrumbs = cloneDeep(stores.BreadcrumbsStore.breadcrumbs).map((b) => ({
        ...b,
        onClick: b.disabled ? undefined : onBreadcrumbItemClicked,
    }));

    return breadcrumbs.length ? <Breadcrumb items={breadcrumbs} /> : null;
});
