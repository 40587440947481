import { Stack, IStackTokens, IIconProps, SearchBox } from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react/lib/Button';
import { Card } from 'components';
import { DashboardSearchStyles } from 'constants/tableStylesPeset';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FundsAddItemModal } from 'pages/Workflow/components/modals';

export const FundsFilters = () => {
    const stackTokens: IStackTokens = { childrenGap: 12 };

    const [activeButActive, setActiveButActive] = useState<boolean>(false);
    const [activeButUnActive, setActiveButUnActive] = useState<boolean>(false);
    const [activeButAll, setActiveButAll] = useState<boolean>(true);

    const exelDoc: IIconProps = { iconName: 'ExcelDocument' };
    const pdf: IIconProps = { iconName: 'PDF' };
    const add: IIconProps = { iconName: 'add' };

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const { formatMessage } = useIntl();
    return (
        <Card>
            <Stack horizontal horizontalAlign='space-between'>
                <Stack horizontal tokens={stackTokens}>
                    {activeButAll ? (
                        <PrimaryButton
                            text={formatMessage({ id: 'all' })}
                            id='3'
                            onClick={() => setActiveButAll(false)}
                            allowDisabledFocus
                        />
                    ) : (
                        <DefaultButton
                            text={formatMessage({ id: 'all' })}
                            id='3'
                            onClick={() => {
                                setActiveButActive(false);
                                setActiveButUnActive(false);
                                setActiveButAll(true);
                            }}
                            allowDisabledFocus
                        />
                    )}
                    {activeButActive ? (
                        <PrimaryButton
                            text={formatMessage({ id: 'active' })}
                            id='1'
                            onClick={() => setActiveButActive(false)}
                            allowDisabledFocus
                        />
                    ) : (
                        <DefaultButton
                            text={formatMessage({ id: 'active' })}
                            id='1'
                            onClick={() => {
                                setActiveButActive(true);
                                setActiveButUnActive(false);
                                setActiveButAll(false);
                            }}
                            allowDisabledFocus
                        />
                    )}
                    {activeButUnActive ? (
                        <PrimaryButton
                            text={formatMessage({ id: 'inactive' })}
                            id='2'
                            onClick={() => setActiveButUnActive(false)}
                            allowDisabledFocus
                        />
                    ) : (
                        <DefaultButton
                            text={formatMessage({ id: 'inactive' })}
                            id='2'
                            onClick={() => {
                                setActiveButActive(false);
                                setActiveButUnActive(true);
                                setActiveButAll(false);
                            }}
                            allowDisabledFocus
                        />
                    )}
                </Stack>
                <Stack styles={DashboardSearchStyles}>
                    <SearchBox placeholder={formatMessage({ id: 'searchByName' })} underlined={true} />
                </Stack>
                <Stack horizontal>
                    <IconButton iconProps={exelDoc} aria-label='Emoji' />
                    <IconButton iconProps={pdf} aria-label='Emoji' />
                    <IconButton iconProps={add} label='Add' onClick={() => setShowAddModal(true)} />
                </Stack>

                <FundsAddItemModal
                    isOpen={showAddModal}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({ id: 'addingNewItem' })}
                />
            </Stack>
        </Card>
    );
};
