import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useFieldArray, useForm } from 'react-hook-form';
import { IColumn, mergeStyleSets, PrimaryButton, SelectionMode, Stack, Text, useTheme } from '@fluentui/react';
import { Card, ControlledCheckbox, ControlledNumberField, DataTable } from 'components';
import { CardTableContainerStyles } from '../../../../constants/tableStylesPeset';
import { PerfomanceMaterially } from 'pages/PortalSettings/hooks/RiskMatrix/useGetPerfomanceMaterially';
import { PerfomanceMaterialityReq } from 'pages/PortalSettings/hooks/RiskMatrix/useUpdatePerfomanceMaterially';
import { DefaultFormSettings } from 'constants/forms';

interface Props {
    perfomanceMaterially: PerfomanceMaterially;
    updatePerfomanceMateriality: (a: PerfomanceMaterialityReq) => void;
}

export const RiskMatrixSettingsPercentageApplied: FunctionComponent<Props> = ({
    perfomanceMaterially,
    updatePerfomanceMateriality,
}: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, formState } = useForm<PerfomanceMaterialityReq>({
        ...DefaultFormSettings,
        defaultValues: { performanceMaterialitySettings: perfomanceMaterially },
    });
    const theme = useTheme();

    const { fields } = useFieldArray({
        control,
        name: 'performanceMaterialitySettings',
    });

    const updateTable = (form: PerfomanceMaterialityReq) => {
        updatePerfomanceMateriality(form);
    };

    const columns: IColumn[] = [
        {
            key: 'overallAuditJobRiskRating',
            name: formatMessage({ id: 'overallAuditJobRiskRating' }),
            fieldName: 'overallAuditJobRiskRating',
            minWidth: 50,
            maxWidth: 220,
            isMultiline: true,
            onRender: (item) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item ? item.overallAuditJobRiskRating : null}
                </Text>
            ),
        },
        {
            key: 'applyOverallMateriality',
            name: formatMessage({ id: 'applyOverallMateriality' }),
            fieldName: 'applyOverallMateriality',
            minWidth: 200,
            onRender: (item, index) =>
                index !== undefined ? (
                    <ControlledCheckbox control={control} name={`performanceMaterialitySettings.${index}.applyOverallMateriality`} />
                ) : null,
        },
        {
            key: 'percentageApplied',
            name: formatMessage({ id: 'percentageAppliedMaterialityLevelFinancialMisstatement' }),
            minWidth: 250,
            onRender: (item, index) =>
                index !== undefined ? (
                    <ControlledNumberField
                        control={control}
                        name={`performanceMaterialitySettings.${index}.percentageApplied`}
                        suffix='%'
                    />
                ) : (
                    <div style={{ backgroundColor: theme.palette.neutralLighter, width: '100%', height: 30 }} />
                ),
        },
    ];

    const containerStyles = mergeStyleSets(CardTableContainerStyles, { root: { maxHeight: '40%', padding: '16px 16px 50px 16px' } });

    return (
        <>
            <Card styles={containerStyles}>
                <DataTable initialColumns={columns} items={fields} selectionMode={SelectionMode.none} />
                <Stack horizontalAlign='end' styles={{ root: { marginTop: 10 } }}>
                    <PrimaryButton disabled={!formState.isDirty} onClick={handleSubmit(updateTable)} text={formatMessage({ id: 'save' })} />
                </Stack>
            </Card>
        </>
    );
};
