import { IBreadcrumbItem } from '@fluentui/react';
import { Component, ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { BreadcrumbsStore } from 'stores/BreadcrumbsStore';

interface Props {
    breadcrumb: IBreadcrumbItem & { id: string };
    children: ReactNode;
}

interface PropsWithStore {
    BreadcrumbsStore: BreadcrumbsStore;
}

interface State {
    isMount: boolean;
}

@inject('BreadcrumbsStore')
@observer
export class PageWithBreadcrumb extends Component<Props, State> {
    public componentDidMount() {
        this.store.BreadcrumbsStore.addBreadcrumb(this.props.breadcrumb);
        this.setState({ isMount: true });
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        this.store.BreadcrumbsStore.updateBreadcrumb(this.props.breadcrumb.id, this.props.breadcrumb);
    }

    public componentWillUnmount() {
        this.store.BreadcrumbsStore.removeBreadcrumb(this.props.breadcrumb.id);
    }

    public render(): ReactNode {
        if (this.state?.isMount) {
            return this.props.children;
        }
        return null;
    }

    private get store(): PropsWithStore {
        return this.props as any as PropsWithStore;
    }
}
