export const tableColumnsData = {
	tableName: 'Audits Awaiting Auditor Allocation',
	maxQueriesCount: 0,
	searchPlaceholder: 'Search by name',
	columns: [
		{
			displayName: 'Days In',
			columnName: 'daysIn',
			isClickable: false,
			onClickPopUpName: null,
			isSortable: true,
			ambientName: 'JobLocalData.DateReceived',
			displayOrder: 1,
			isDateTimeColumn: false
		},
		{
			displayName: 'Client',
			columnName: 'client',
			isClickable: true,
			onClickPopUpName: 'clientModal',
			isSortable: true,
			ambientName: 'Fund.Client.Name',
			displayOrder: 2,
			isDateTimeColumn: false
		},
		{
			displayName: 'Fund',
			columnName: 'fund',
			isClickable: true,
			onClickPopUpName: 'fundModal',
			isSortable: true,
			ambientName: 'Fund.Name',
			displayOrder: 3,
			isDateTimeColumn: false
		},
		{
			displayName: 'Year',
			columnName: 'year',
			isClickable: true,
			onClickPopUpName: 'jobModal',
			isSortable: true,
			ambientName: 'Year',
			displayOrder: 4,
			isDateTimeColumn: false
		},
		{
			displayName: 'Date Received',
			columnName: 'dateReceived',
			isClickable: false,
			onClickPopUpName: null,
			isSortable: true,
			ambientName: 'JobLocalData.DateReceived',
			displayOrder: 5,
			isDateTimeColumn: true
		},
		{
			displayName: 'Inherent Risk Rating',
			columnName: 'inherentRiskRating',
			isClickable: false,
			onClickPopUpName: null,
			isSortable: true,
			ambientName: 'JobLocalData.InherentRiskRating',
			displayOrder: 6,
			isDateTimeColumn: false
		},
		{
			displayName: 'PY Inherent Risk Rating',
			columnName: 'pyInherentRiskRating',
			isClickable: false,
			onClickPopUpName: null,
			isSortable: true,
			ambientName: 'JobLocalData.PyInherentRiskRating',
			displayOrder: 7,
			isDateTimeColumn: false
		}
	],
	hasAdditionalDateFilter: false
};

export const tableItemsData = {
	items: [
		{
			client: 'Class Super PDF Client',
			fund: '1 3940 test',
			year: 2016,
			dateReceived: '2021-08-23T22:16:23.25',
			inherentRiskRating: null,
			pyInherentRiskRating: '',
			daysIn: 385,
			clientId: 10411,
			fundId: 44677,
			jobId: 149821
		},
		{
			client: 'Class Super PDF Client',
			fund: '1 3940 test',
			year: 2017,
			dateReceived: '2021-08-23T22:18:38.71',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 385,
			clientId: 10411,
			fundId: 44677,
			jobId: 149822
		},
		{
			client: 'Class Super PDF Client',
			fund: '1 3940 test',
			year: 2018,
			dateReceived: '2021-09-29T19:19:56.913',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 348,
			clientId: 10411,
			fundId: 44677,
			jobId: 149873
		},
		{
			client: 'Class Super PDF Client',
			fund: '1 3940 test',
			year: 2019,
			dateReceived: '2021-09-29T19:22:20.417',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 348,
			clientId: 10411,
			fundId: 44677,
			jobId: 149874
		},
		{
			client: 'Class Super PDF Client',
			fund: '1 3940 test',
			year: 2020,
			dateReceived: '2021-09-29T19:27:07.923',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 348,
			clientId: 10411,
			fundId: 44677,
			jobId: 149875
		},
		{
			client: 'Class Super API Client - Client Flow',
			fund: '1 3940 test',
			year: 2017,
			dateReceived: '2021-08-24T00:58:20.127',
			inherentRiskRating: null,
			pyInherentRiskRating: '',
			daysIn: 384,
			clientId: 10345,
			fundId: 44678,
			jobId: 149823
		},
		{
			client: 'Class Super API Client - Client Flow',
			fund: '1 3940 test',
			year: 2018,
			dateReceived: '2021-08-24T00:58:36.847',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 384,
			clientId: 10345,
			fundId: 44678,
			jobId: 149824
		},
		{
			client: 'Class Super API Client - Client Flow',
			fund: '1 3940-7404 test',
			year: 2017,
			dateReceived: '2021-08-31T23:18:19.767',
			inherentRiskRating: null,
			pyInherentRiskRating: '',
			daysIn: 377,
			clientId: 10345,
			fundId: 44681,
			jobId: 149833
		},
		{
			client: 'Class Super API Client - Client Flow',
			fund: '1 3940-7404 test',
			year: 2018,
			dateReceived: '2021-08-31T23:18:38.81',
			inherentRiskRating: null,
			pyInherentRiskRating: null,
			daysIn: 377,
			clientId: 10345,
			fundId: 44681,
			jobId: 149834
		},
		{
			client: 'Class Super API Client - Client Flow',
			fund: '1 3940-7481',
			year: 2017,
			dateReceived: '2021-09-07T00:21:53.133',
			inherentRiskRating: null,
			pyInherentRiskRating: '',
			daysIn: 370,
			clientId: 10345,
			fundId: 44683,
			jobId: 149836
		}
	],
	count: 724,
	filter: {
		clientId: null,
		jobId: null,
		fundId: null,
		filterByYear: null,
		dashboardTableType: 1,
		filterByPeriod: null,
		invoiceStartDate: null,
		invoiceEndDate: null,
		page: 1,
		pageSize: 10,
		orderBy: '',
		orderDirection: 'asc',
		filterBy: null
	},
};

export const widgetData = {
	dashboardTab: 1,
	auditsAwaitingAuditorAllocationCount: 724,
	auditsInProgressWithAuditorCount: 76,
	auditsAwaitingManagerReviewCount: 21,
	auditsAwaitingPartnerReviewCount: 180,
	postArQueriesReadyForDispatchCount: 0,
	arSentThisWeekCount: 0,
	preArQueriesWaitingToBeReviewed: [
		{
			name: "Nico Soon",
			count: 2
		},
		{
			name: "April Employee",
			count: 2
		},
		{
			name: "New Employee",
			count: 1
		},
		{
			name: "Testing Employee",
			count: 1
		},
		{
			name: "second user",
			count: 1
		},
		{
			name: "-",
			count: 2
		}
	],
	postArQueriesWaitingToBeReviewed: [
		{
			name: "Dana Kinghorn",
			count: 1
		},
		{
			name: "Nico Soon",
			count: 1
		},
		{
			name: "Brendan Whittaker",
			count: 1
		},
		{
			name: "second user",
			count: 1
		},
		{
			name: "Employee Phone",
			count: 1
		},
		{
			name: "13 May",
			count: 1
		},
		{
			name: "April Employee",
			count: 1
		},
		{
			name: "-",
			count: 1
		}
	]
}