import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { ITableFilters } from './GeneratedDataTable';
import {
	Stack,
	useTheme,
	SearchBox,
	ISearchBoxProps,
	IconButton,
} from '@fluentui/react';

export interface IGeneratedFiltersProps {
  searchPlaceholder: string;
	setFilters: Dispatch<SetStateAction<ITableFilters>>;
  isWidget?: boolean;
}


export const GeneratedFilters: FunctionComponent<IGeneratedFiltersProps> = ({ searchPlaceholder, setFilters, isWidget }) => {
	const theme = useTheme();

	const onSearchHandle: ISearchBoxProps['onChange'] = (_, searchByName)	=> {
		setFilters((prev) => ({ ...prev, searchByName }));
	};

  const iconButtonsStyles = isWidget ? {
    root: {
      selectors: {
        '.ms-Button-icon': {
          color: theme.palette.white,
        },
        '.ms-Button:hover': {
          backgroundColor: theme.palette.themeSecondary,
        },
      },
    },
  } : {};

	return (
		<>
			<Stack>
				<SearchBox onChange={onSearchHandle} placeholder={searchPlaceholder} underlined />
			</Stack>
			<Stack horizontal styles={iconButtonsStyles}>
				<IconButton iconProps={{ iconName: 'ExcelDocument' }} aria-label='Emoji' />
				<IconButton iconProps={{ iconName: 'PDF' }} aria-label='Emoji' />
			</Stack>
		</>
	);
};