import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, queryClient } from 'utils';

function useApplyStyleTheme(url: string) {
    const { mutate: applyTheme, isLoading } = useMutation(() => axios.put(`${url}/api/1.0/Customisation/applyStyle`, {}, commonHeaders()), {
        onSuccess: () => {
            queryClient.invalidateQueries('getTheme');
            queryClient.invalidateQueries('getCustomizationColors');
        },
    });

    return { applyTheme, isLoading };
}

export { useApplyStyleTheme };
