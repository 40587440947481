import { FunctionComponent, useState } from 'react';
import { mergeStyleSets, Stack, useTheme } from '@fluentui/react';
import { Card } from 'components';
import { DashboardTableTypeEnum } from 'pages/Workflow/components/tables';
import { GeneratedTableModal } from 'pages/Workflow/components/modals';

// !TODO add click handler on widget to open modals with tables
export interface ICountWidget {
	number: number;
	title: string;
	dashboardTableType: DashboardTableTypeEnum;
	isSecondary?: boolean;
	withoutBG?: boolean;
}

export const CountWidget: FunctionComponent<ICountWidget> = ({ number = 0, title, dashboardTableType, isSecondary, withoutBG }) => {
	const theme = useTheme();

	const [isOpen, setOpen] = useState<boolean>(false);

	const cardStyles = {
		root: {
			backgroundColor: (withoutBG && '#fff') || (isSecondary ? theme.palette.themeTertiary : theme.palette.themePrimary),
			height: '100%',
			boxSizing: 'border-box',
			transition: 'all 0.2s linear',
			'&:hover': {
				opacity: 0.8,
				transform: 'scale(1.02)'
			},
		},
	};

	const classNames = mergeStyleSets({
		container: {
			height: '100%',
			textAlign: 'center',
			cursor: 'pointer',
		},
		title: [
			theme.fonts.xLarge,
		],
		count: [
			withoutBG ? theme.fonts.xxLarge : theme.fonts.superLarge,
			{
					paddingTop: withoutBG ? 8 : 32,
			},
		],
	});

	return (
		<Card styles={cardStyles} onClick={() => setOpen(true)}>
			<Stack
				horizontalAlign='center'
				verticalAlign='space-between'
				className={classNames.container}>
				<Stack.Item className={classNames.title}>
					{title}
				</Stack.Item>
				<Stack.Item className={classNames.count}>
					{number}
				</Stack.Item>
			</Stack>
			<GeneratedTableModal
				dashboardTableType={dashboardTableType}
				isOpen={isOpen}
				onDismiss={() => setOpen(false)}/>
		</Card>
	);
};
