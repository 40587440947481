import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

type GetCurrentType = {
    accessToDashboard: boolean;
    b2CObjectId: string;
    clients: [];
    displayName: string;
    email: string | null;
    employeeId: string | null;
    firstName: string | null;
    id: number;
    isDeleted: boolean;
    isSuperUser: boolean;
    lastName: string | null;
    username: string | null;
};

function useGetCurrent() {
    const context = React.useContext(Context);

    const { data, isLoading } = useQuery(
        ['getCurrentClient', context],
        () => axios.get<GetCurrentType>(`${context?.config?.userUrl}/api/1.0/user/me`, commonHeaders()),
        {
            enabled: !!context?.config,
            // onSuccess: () => setInterval(() => queryClient.invalidateQueries('getCurrentClient'), 5000),
        }
    );

    return { data, isLoading };
}

export { useGetCurrent };
