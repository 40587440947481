import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card, ControlledTextField, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { emailValidatorRegexp, passwordRegex } from 'constants/validators';
import { ICreateUser, useCreateUser } from '../hooks/useCreateUser';

interface Props {
    onDismiss: () => void;
}

export const UserManagementAddUserForm: FunctionComponent<Props> = ({ onDismiss }: Props) => {
    const { createUser, isLoading: createUserLoading, isSuccess } = useCreateUser();
    const { control, handleSubmit, getValues, formState } = useForm<ICreateUser>({
        ...DefaultFormSettings,
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
    });
    const { formatMessage } = useIntl();
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };

    useEffect(() => {
        if (isSuccess) {
            onDismiss();
        }
    }, [isSuccess, onDismiss]);

    const onSubmit = (data: ICreateUser) => {
        // !TODO isSuperUser
        const newData: ICreateUser = { ...data, isSuperUser: false }
        createUser(newData);
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='firstName'
                            label={formatMessage({ id: 'firstName' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='lastName'
                            label={formatMessage({ id: 'lastName' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '100%' } }}>
                        <ControlledTextField
                            name='email'
                            label={formatMessage({ id: 'email' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                pattern: { value: emailValidatorRegexp, message: formatMessage({ id: 'patternEmail' }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='password'
                            label={formatMessage({ id: 'password' })}
                            isPassword
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                pattern: { value: passwordRegex, message: formatMessage({ id: 'patternPassword' }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='confirmPassword'
                            label={formatMessage({ id: 'confirmPassword' })}
                            isPassword
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                validate: {
                                    matchPassword: (value: string | boolean | undefined) => {
                                        const passwordValue: string | undefined = getValues('password');
                                        return passwordValue === value ? true : formatMessage({ id: 'matchPassword' });
                                    },
                                },
                                deps: ['password'],
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Card>
            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton onClick={onDismiss} text={formatMessage({ id: 'cancel' })} /> */}
                <PrimaryButton
                    disabled={!formState.isDirty || !formState.isValid || createUserLoading}
                    onClick={handleSubmit(onSubmit)}
                    text={formatMessage({ id: 'save' })}
                />
            </ModalFooter>
        </Stack>
    );
};
