import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField, ControlledTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import { EmailTemplate } from 'pages/PortalSettings/hooks/Email/useEmailTemplateGet';

interface Props {
    control: Control<EmailTemplate>;
}

export const DebtorsStatementEmail: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='debtorsStatementEmail' />}>
            <>
                <ControlledTextField
                    name='debtorsStatementEmailSubject'
                    control={control}
                    label={formatMessage({ id: 'debtorsStatementEmailSubject' })}
                />

                <ControlledRichTextField
                    name='debtorsStatementEmailBody'
                    control={control}
                    label={formatMessage({ id: 'debtorsStatementEmailBody' })}
                />
            </>
        </EmailTemplatePanel>
    );
};
