import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, Dialog, DialogFooter, DialogType, PrimaryButton, useTheme } from '@fluentui/react';

interface Props {
    onAgree: () => void;
}

export const RevokeConnectionButton: FunctionComponent<Props> = ({ onAgree }: Props) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const dialogContentProps = {
        type: DialogType.normal,
        title: formatMessage({ id: 'warning' }),
        subText: formatMessage({ id: 'doYouWishToRevokeAccess' }),
    };

    return (
        <>
            <ActionButton
                styles={{ root: { height: 32 }, icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.red } }}
                text={formatMessage({ id: 'revoke' })}
                iconProps={{ iconName: 'Trash' }}
                onClick={() => setOpen(true)}
            />
            <Dialog hidden={!open} onDismiss={() => setOpen(false)} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    {/* <DefaultButton onClick={() => setOpen(false)} text={formatMessage({ id: 'cancel' })} /> */}
                    <PrimaryButton
                        onClick={() => {
                            setOpen(false);
                            onAgree();
                        }}
                        text={formatMessage({ id: 'ok' })}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};
