import { useMemo } from 'react';
import { ContextualMenuItemType, DirectionalHint, FontIcon, IconButton, mergeStyles, Stack, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { stores } from 'stores';
import { RouterPaths } from '../navigation';
import { ActiveIconColorStyles } from '../constants/tableStylesPeset';

type UserActionPropsType = {
    userName: string;
};

export const UserActions = observer(({ userName }: UserActionPropsType) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();
    const iconClass = mergeStyles({ color: theme.semanticColors.menuIcon });
    const linkIconClass = mergeStyles({ color: '#0000ff' });

    const menuProps = useMemo(() => {
        return {
            alignTargetEdge: true,
            items: [
                {
                    key: 'userName',
                    text: userName,
                    itemType: ContextualMenuItemType.Header,
                },
                {
                    key: 'adminPortal',
                    text: intl.formatMessage({ id: 'adminPortal' }),
                    iconProps: { iconName: 'Admin' },
                    onClick: () => navigate(RouterPaths.admin.rulesets.root),
                },
                {
                    key: 'workflowEditor',
                    onClick: () => {
                        window.open('/workflow', '_blank');
                    },
                    onRenderContent: () => (
                        <Stack
                            horizontal
                            horizontalAlign='space-between'
                            tokens={{ padding: '0 4px' }}
                            styles={{ root: { width: '100%' } }}>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <Text variant='mediumPlus'>
                                    <FontIcon aria-label='Workflow' iconName='Workflow' className={iconClass} />
                                </Text>
                                <Text variant='medium'>{intl.formatMessage({ id: 'workflowEditor' })}</Text>
                            </Stack>
                            <Text variant='mediumPlus'>
                                <FontIcon className={linkIconClass} aria-label='NavigateExternalInline' iconName='NavigateExternalInline' />
                            </Text>
                        </Stack>
                    ),
                },
                { key: 'divider', itemType: ContextualMenuItemType.Divider },
                // {
                //     key: 'changePassword',
                //     text: intl.formatMessage({ id: 'changePassword' }),
                //     iconProps: { iconName: 'AccountManagement' },
                //     onClick: () => navigate('/login/change-password'), // TODO: set real link to reset password page
                // },
                {
                    key: 'logout',
                    text: intl.formatMessage({ id: 'logout' }),
                    iconProps: { iconName: 'SignOut', styles: ActiveIconColorStyles(theme.palette.redDark) },
                    onClick: stores.AuthStore.signOut,
                },
            ],
            directionalHint: DirectionalHint.bottomLeftEdge,
        };
    }, [iconClass, intl, linkIconClass, navigate, theme.palette.redDark, userName]);

    return (
        <IconButton
            onRenderMenuIcon={() => <div />}
            styles={{ root: { height: 48, paddingLeft: 16, paddingRight: 16 } }}
            menuProps={menuProps}
            iconProps={{ iconName: 'Contact' }}
        />
    );
});
