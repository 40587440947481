import { FunctionComponent } from 'react';
import { Card, Pagination } from 'components';
import { useUserManagement } from '../UserManagementPage';

interface Props {
    shownItemsCount: number;
    totalItemsCount: number;
}

export const UserManagementPagination: FunctionComponent<Props> = ({ shownItemsCount, totalItemsCount }) => {
    const { pagination, setPagination } = useUserManagement();

    const onPaginationChange = (pageSize: number, page: number) => {
        setPagination({ pageSize, page });
    };

    return (
        <Card>
            <Pagination
                itemsCount={shownItemsCount}
                total={totalItemsCount}
                page={pagination.page}
                pageSize={pagination.pageSize}
                onChange={onPaginationChange}
            />
        </Card>
    );
};
