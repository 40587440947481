import { FunctionComponent, useState } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack, useTheme, INavLink } from '@fluentui/react';
import { Modal, ModalFooter, VerticalMenu } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import {
    BillingInformationTab,
    DashboardsTab,
    DigitalSignatureTab,
    GeneralTab,
    MailingAddressTab,
    OtherInfoTab,
} from './tabs';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export enum PartnerModalTabs {
    GENERAL,
    DASHBOARDS,
    MAILING_ADDRESS,
    BILLING_INFORMATION,
    OTHER_INFO,
    DIGITAL_SIGNATURE,
}

export const PartnerModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            userNamePart: '',
            firstNamePart: '',
            lastNamePart: '',
            emailPart: '',
            positionPart: '',
            team: '',
            securitylevelPart: '',
            country: '',
            postalCode: '',
            state: '',
            city: '',
            street: '',
            street2: '',
            rate: '',
            nameOfAuditingFirm: '',
            SMSFAuditorNumber: '',
            postNominals: '',
        },
    });
    const { formatMessage } = useIntl();

    const onSubmit = (data: ICreateUserCommand) => {
        console.log(data);
        onDismiss();
    };

    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState<PartnerModalTabs>(PartnerModalTabs.GENERAL);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'general' }),
            url: '',
            key: 'General',
            iconProps: { iconName: 'Settings' },
            links: [
                {
                    name: formatMessage({ id: 'general' }),
                    url: '',
                    key: 'general',
                    iconProps: { iconName: 'ComplianceAudit' },
                    isExpanded: activeTab === PartnerModalTabs.GENERAL,
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.GENERAL);
                    },
                },
                {
                    name: formatMessage({ id: 'dashboards' }),
                    url: '',
                    key: 'dashboards',
                    iconProps: { iconName: 'MapLayers' },
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.DASHBOARDS);
                    },
                },
                {
                    name: formatMessage({ id: 'mailingAddress' }),
                    url: '',
                    key: 'mailingAddress',
                    iconProps: { iconName: 'ColumnOptions' },
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.MAILING_ADDRESS);
                    },
                },
                {
                    name: formatMessage({ id: 'billingInformation' }),
                    url: '',
                    key: 'billingInformation',
                    iconProps: { iconName: 'Calculator' },
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.BILLING_INFORMATION);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({ id: 'otherInformation' }),
            url: '',
            key: 'otherInfo',
            iconProps: { iconName: 'Settings' },
            links: [
                {
                    name: formatMessage({ id: 'otherInformation' }),
                    url: '',
                    key: 'otherInfo',
                    iconProps: { iconName: 'ComplianceAudit' },
                    title: '',
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.OTHER_INFO);
                    },
                },
                {
                    name: formatMessage({ id: 'digitalSignature' }),
                    url: '',
                    key: 'digitalSignature',
                    iconProps: { iconName: 'MapPin' },
                    onClick: () => {
                        setActiveTab(PartnerModalTabs.DIGITAL_SIGNATURE);
                    },
                },
            ],
        },
    ]);

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            background: theme.palette.white,
                        },
                    }}>
                    <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                        <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />

                        <Stack
                            styles={{
                                root: {
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                                    background: theme.palette.neutralLight,
                                    padding: '0 0 16px 16px',
                                    boxSizing: 'border-box',
                                    minHeight: '616px',
                                },
                            }}
                            tokens={{ childrenGap: 16 }}>
                            <Stack.Item styles={{ root: { height: '600px' } }}>
                                {activeTab === PartnerModalTabs.GENERAL ? (
                                    <GeneralTab control={control} />
                                ) : activeTab === PartnerModalTabs.DASHBOARDS ? (
                                    <DashboardsTab />
                                ) : activeTab === PartnerModalTabs.MAILING_ADDRESS ? (
                                    <MailingAddressTab control={control} />
                                ) : activeTab === PartnerModalTabs.BILLING_INFORMATION ? (
                                    <BillingInformationTab control={control} />
                                ) : activeTab === PartnerModalTabs.OTHER_INFO ? (
                                    <OtherInfoTab control={control} />
                                ) : activeTab === PartnerModalTabs.DIGITAL_SIGNATURE ? (
                                    <DigitalSignatureTab />
                                ) : (
                                    ''
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>

                    <ModalFooter horizontalAlign='end'>
                        <PrimaryButton
                            disabled={!formState.isDirty}
                            onClick={handleSubmit(onSubmit)}
                            text={formatMessage({ id: 'save' })}
                        />
                    </ModalFooter>
                </Stack>
            </Stack>
        </Modal>
    );
});
