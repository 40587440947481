import { DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Card, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { InvoiceAddFormMainControls } from './InvoiceAddFormMainControls';
import { InvoiceAddFormExtraAmount } from './InvoiceAddFormExtraAmount';
import { InvoiceItem } from '../hooks/Invoices/useGetInvoices';
import { useCreateInvoice } from '../hooks/Invoices/useCreateInvoice';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export interface AddInvoiceForm {
    amount?: number;
    extraAmounts: Array<{ amount?: number; invoiceParagraph?: string }>;
    invoiceParagraph?: string;
    creationDate?: Date;
    billingEntity?: string;
}

interface Props {
    invoice: InvoiceItem;
    onClose: () => void;
}

export const InvoiceAddForm: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const [editExtraAmount, setEditExtraAmount] = useState(false);

    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const clientId = queryParams?.clientId ? String(queryParams.clientId) : '';

    const { createInvoice, creatingInvoiceLoading } = useCreateInvoice(clientId, props.onClose);

    const { control, getValues } = useForm<AddInvoiceForm>({
        ...DefaultFormSettings,
        defaultValues: { extraAmounts: [] },
    });
    const extraAmountItems = useFieldArray({
        control,
        name: 'extraAmounts',
    });

    const save = () => {
        const values = getValues();
        console.log('time', values.creationDate?.toLocaleDateString());
        createInvoice({
            amount: Number(values.amount),
            billingEntityType: values.billingEntity === 'Fund' ? 2 : 1,
            creationDate: values.creationDate?.toLocaleDateString() || props.invoice.invoiceDate,
            jobId: props.invoice.invoiceJobs[0].jobId,
            paragraph: values.invoiceParagraph === 'DD' ? 1 : 0,
            extraAmounts: values.extraAmounts,
        });
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                {editExtraAmount ? (
                    <InvoiceAddFormExtraAmount extraAmountItems={extraAmountItems} />
                ) : (
                    <InvoiceAddFormMainControls
                        control={control}
                        totalExtraAmounts={extraAmountItems.fields.reduce((prev, curr) => prev + (curr.amount ? +curr.amount : 0), 0)}
                        onEditExtraAmount={() => setEditExtraAmount(true)}
                    />
                )}
            </Card>

            <ModalFooter horizontalAlign='end'>
                {editExtraAmount && <DefaultButton text={formatMessage({ id: 'goBack' })} onClick={() => setEditExtraAmount(false)} />}
                <PrimaryButton
                    disabled={creatingInvoiceLoading}
                    onClick={() => (editExtraAmount ? setEditExtraAmount(false) : save())}
                    onRenderChildren={() =>
                        creatingInvoiceLoading ? <Spinner size={SpinnerSize.small} /> : <>{formatMessage({ id: 'save' })}</>
                    }
                />
            </ModalFooter>
        </Stack>
    );
};
