import { IUserInfo } from '../interfaces';

export const MockedUsers: IUserInfo[] = [
    {
        userId: 463,
        employeeId: null,
        username: "<script>alert('Hello!');</script>",
        password: 'Ask19790',
        confirmPassword: null,
        firstName: "<script>alert('Hello!');</script>",
        lastName: "<script>alert('Hello!');</script>",
        displayName: "<script>alert('Hel",
        isSuperUser: false,
        email: 'testtest@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 464,
        employeeId: null,
        username: "<script>alert('Hello123!');</script>",
        password: 'Ask19790',
        confirmPassword: null,
        firstName: "<script>alert('Hello!');</script>",
        lastName: "<script>alert('Hello!');</script>",
        displayName: "<script>alert('Hello!');</script>",
        isSuperUser: false,
        email: 'test7890@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 465,
        employeeId: null,
        username: "<script>alert('Hello546!');</script>",
        password: 'Ask19790',
        confirmPassword: null,
        firstName: "<script>alert('Hello!');</script>",
        lastName: "<script>alert('Hello!');</script>",
        displayName: "<script>alert('Hello!');</script>",
        isSuperUser: false,
        email: 'a.khaswala@evolvsuper.com.au',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 450,
        employeeId: null,
        username: "<script>alert('Test XSS')</script>",
        password: 'Ask19790',
        confirmPassword: null,
        firstName: "<script>alert('Test XSS')</script>",
        lastName: "<script>alert('Test XSS')</script>",
        displayName: "<script>alert('Test XSS')</script>",
        isSuperUser: false,
        email: 'script@test.com',
        clientCount: 4,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 501,
        employeeId: null,
        username: '07102021testing',
        password: 'empty',
        confirmPassword: null,
        firstName: '07102021testing123',
        lastName: '07102021testing321',
        displayName: '07102021testing123 07102021testing321',
        isSuperUser: false,
        email: '07102021testing@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 496,
        employeeId: null,
        username: '2811-7264',
        password: '1234Qwer',
        confirmPassword: null,
        firstName: '7264',
        lastName: '2811',
        displayName: '2811-7264',
        isSuperUser: true,
        email: 'krl_gdk_89@mail.ru',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 425,
        employeeId: null,
        username: '4444',
        password: 'empty',
        confirmPassword: null,
        firstName: '4444',
        lastName: '4444',
        displayName: '4444 4444',
        isSuperUser: false,
        email: '444',
        clientCount: 19,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Queen Mercury',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9a558664-0517-4d52-ae10-c7ac9db5beae',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 489,
        employeeId: null,
        username: '6809 Test User 100',
        password: 'Audit@2021',
        confirmPassword: null,
        firstName: '6809 Test',
        lastName: 'User 100',
        displayName: '6809 Test User 100',
        isSuperUser: false,
        email: '6809testuser100@email.com',
        clientCount: 12,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL360_DTnocodes',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '07cb13d3-8c96-427e-a06b-cc1e8f7b3bfa',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 486,
        employeeId: null,
        username: '6809-test2',
        password: '1234Qwer',
        confirmPassword: null,
        firstName: 'fn8',
        lastName: 'ln8',
        displayName: '6809-test2',
        isSuperUser: false,
        email: '6809@em2.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 527,
        employeeId: null,
        username: 'Add User',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Add',
        lastName: 'User',
        displayName: 'Add User',
        isSuperUser: false,
        email: 'adduser@email.com',
        clientCount: 25,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'AY BGL360 Test Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'e20c19b0-ad82-4ab8-8cce-f6d4816cd821',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL360_DTnocodes',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '07cb13d3-8c96-427e-a06b-cc1e8f7b3bfa',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'EGOR_WL_BGL360',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9f7e4e58-8a76-42d3-9437-0ce8dd955ebb',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "Olga's test",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '0086e961-d6f0-4a67-b39b-1dd5ba6d17a4',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 390,
        employeeId: null,
        username: 'cpuser2',
        password: 'wsxzaq1234A1',
        confirmPassword: null,
        firstName: 'name',
        lastName: 'name',
        displayName: 'cpuser2',
        isSuperUser: false,
        email: 'aaaa@SSSSSS.com',
        clientCount: 9,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '4094 Class',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c8a106d6-ed8c-4f21-9c03-25f00292e64c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '61c392fb-dd72-4581-9e7b-f62301d0bc6e',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv Pty Ltd',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'af657ce4-5c72-4a61-b299-4fa95b6586dd',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 466,
        employeeId: null,
        username: 'CrossScriptingTestUser',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Cross Scripting',
        lastName: 'Test User',
        displayName: 'Cross Scripting Test User',
        isSuperUser: false,
        email: 'crtu@eb.com',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 359,
        employeeId: null,
        username: 'demo',
        password: 'Rj4$3lL#@4',
        confirmPassword: null,
        firstName: 'Demo',
        lastName: 'Demo',
        displayName: 'Demo',
        isSuperUser: false,
        email: 'Demo@demo.com.au',
        clientCount: 1,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv Pty Ltd',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'af657ce4-5c72-4a61-b299-4fa95b6586dd',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 488,
        employeeId: null,
        username: 'TestingDT',
        password: 'Mis12345',
        confirmPassword: null,
        firstName: 'Dmitry111',
        lastName: 'Testing222',
        displayName: 'Dmitry',
        isSuperUser: false,
        email: 'tornado1988@bk.ru',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 528,
        employeeId: null,
        username: 'DTpswrd',
        password: 'Mis2614955',
        confirmPassword: null,
        firstName: 'Dmitry_passwords',
        lastName: 'Testing',
        displayName: 'dmitry_pswrd',
        isSuperUser: false,
        email: 'd.tsyplenkov1988@yandex.by',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 521,
        employeeId: null,
        username: 'EGSH',
        password: 'empty',
        confirmPassword: null,
        firstName: 'EGOR',
        lastName: 'SHUM',
        displayName: 'EGOR SHUM',
        isSuperUser: false,
        email: 'iiiymujio@mail.ru',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 468,
        employeeId: null,
        username: 'employee_username',
        password: 'empty',
        confirmPassword: null,
        firstName: 'employee_firstname',
        lastName: 'employee_lastname',
        displayName: 'employee_firstname employee_lastname',
        isSuperUser: false,
        email: 'employee_email',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 469,
        employeeId: null,
        username: 'employee_username1',
        password: 'empty',
        confirmPassword: null,
        firstName: 'employee_firstname1',
        lastName: 'employee_lastmane1',
        displayName: 'employee_firstname1 employee_lastmane1',
        isSuperUser: false,
        email: 'employee_email1',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 520,
        employeeId: null,
        username: 'ESH',
        password: 'Rbk3kjofa',
        confirmPassword: null,
        firstName: 'EGOR',
        lastName: 'SHUMI',
        displayName: 'ES',
        isSuperUser: false,
        email: 'iiiymujio@gmail.com',
        clientCount: 1,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 526,
        employeeId: null,
        username: 'EGORI',
        password: 'Rbk3kjofa',
        confirmPassword: null,
        firstName: 'Egor',
        lastName: 'Shumilo',
        displayName: 'ESH',
        isSuperUser: false,
        email: 'testmail@mail.ru',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 424,
        employeeId: null,
        username: 'evolv test 3351',
        password: 'Abc12345',
        confirmPassword: null,
        firstName: 'Evolvtest',
        lastName: 'EvolvTest',
        displayName: 'evolvtest',
        isSuperUser: false,
        email: 'evolvtest@evolvwhite.com.au',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 476,
        employeeId: null,
        username: 'Yurytest1111',
        password: 'empty',
        confirmPassword: null,
        firstName: 'ewrew',
        lastName: 'werwerwer',
        displayName: 'ewrew werwerwer',
        isSuperUser: true,
        email: 'test@test.test',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 352,
        employeeId: null,
        username: 'konstantin',
        password: '123456',
        confirmPassword: null,
        firstName: 'Konstanting',
        lastName: 'Clientuser',
        displayName: 'Konstantin ssstt',
        isSuperUser: false,
        email: 'konstantin.koryakov@softconveyer.com',
        clientCount: 23,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3763 Test hehe',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '820652b7-8c39-445e-8863-7530d5203f43',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3763 Test Manual',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '74793572-3a41-4f2f-bc85-440efdff84d6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3955 Test xxx',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '576a59fe-c74a-438f-96fd-9c558cde87ba',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '4041 Test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '05a750cd-f990-4582-9615-ceb21541b68f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '4094 Class',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c8a106d6-ed8c-4f21-9c03-25f00292e64c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '4094 Test xx',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '42e472c0-4b2b-48c0-996d-2a384ebb4ab9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Allmo$t MUSIC',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '4d8585a2-239f-4b8f-bb9a-6d93e25fb6bf',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Demo Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '46d2488c-fdde-49ac-a643-32f5e17f1997',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '61c392fb-dd72-4581-9e7b-f62301d0bc6e',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv Pty Ltd',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'af657ce4-5c72-4a61-b299-4fa95b6586dd',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ex Battalion',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '33efdcc6-5b0c-4a15-95ef-05d683e039c6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'louise test client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '5f5e6549-0259-40a4-874a-321f96b8b4f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Louise Testing client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '3a2155e4-d8c9-4119-8090-1e23e0ba736b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'OC Dawgs',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'd083455f-5446-4370-af24-f2c654a358fb',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Testing 1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '8566bc74-39d8-4e8d-8018-0c90ce401fe1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Testing 2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'bb94eb55-7f38-4160-ad33-3534dfdca49a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Testing 3',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '00d7857a-59c2-436e-a2a9-c4e57e067cc1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 388,
        employeeId: null,
        username: 'kontestuser',
        password: '$Koresh123',
        confirmPassword: null,
        firstName: 'Test user',
        lastName: 'Testuser',
        displayName: 'kontestuser1',
        isSuperUser: false,
        email: 'kontestuser@evolvsuper.com.au',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 484,
        employeeId: null,
        username: 'lastname_1407test',
        password: 'Abc12345!',
        confirmPassword: null,
        firstName: 'Test_1407',
        lastName: 'lastname_1407',
        displayName: 'lastname_1407test',
        isSuperUser: false,
        email: 'lastname1407@gmail.com',
        clientCount: 6,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 413,
        employeeId: null,
        username: 'MFATest',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'MFA ',
        lastName: 'Test',
        displayName: 'MFA Test',
        isSuperUser: false,
        email: 'basic123@outlook.com.au',
        clientCount: 14,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Demo Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '46d2488c-fdde-49ac-a643-32f5e17f1997',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '61c392fb-dd72-4581-9e7b-f62301d0bc6e',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Evolv Pty Ltd',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'af657ce4-5c72-4a61-b299-4fa95b6586dd',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ns Test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'b81eb21e-182e-4468-958d-8e47b1992ba9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ns Test V2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c4ba08e9-a50f-48c1-8fff-b9624a58330f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ns Test V2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c4ba08e9-a50f-48c1-8fff-b9624a58330f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ns Test V2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c4ba08e9-a50f-48c1-8fff-b9624a58330f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Ns Test V2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'c4ba08e9-a50f-48c1-8fff-b9624a58330f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 392,
        employeeId: null,
        username: 'newuser',
        password: 'Diver234',
        confirmPassword: null,
        firstName: 'new 1',
        lastName: 'user2',
        displayName: 'newuser',
        isSuperUser: false,
        email: 'new@isp.com.au',
        clientCount: 4,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '123231',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '6f46d131-c779-4f11-a204-1c210809069b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Prince Accounting ',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ab62111b-b93f-4361-ad77-d5c0932ddedb',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Radford Partners',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7654c3d3-d94f-479e-9066-8aebb54c6bec',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 493,
        employeeId: null,
        username: 'newuserfor_kg',
        password: 'Mis12345',
        confirmPassword: null,
        firstName: 'newuserfor_kg_edited',
        lastName: 'newuserfor_kgg',
        displayName: 'newuserfor_kg_display',
        isSuperUser: false,
        email: 'newuserfor_kg@mail.com',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 504,
        employeeId: null,
        username: 'Nico Soon Mail',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Nico',
        lastName: 'Soon Mail',
        displayName: 'Nico Soon Mail',
        isSuperUser: true,
        email: 'soon_nico@hotmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 470,
        employeeId: null,
        username: 'refactor4',
        password: 'Testing7411!!',
        confirmPassword: null,
        firstName: 'refactor',
        lastName: 'four',
        displayName: 'refactor4',
        isSuperUser: false,
        email: 'refactor4@gmail.com',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Queen Mercury',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9a558664-0517-4d52-ae10-c7ac9db5beae',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 439,
        employeeId: null,
        username: 'SecurityToken1',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Security',
        lastName: 'Token1',
        displayName: 'SecurityToken1',
        isSuperUser: false,
        email: 'security1@email.com',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '30 March  Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9ed34e13-4e34-4133-a604-046378635568',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3763 Test hehe',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '820652b7-8c39-445e-8863-7530d5203f43',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 353,
        employeeId: null,
        username: 'Some employee',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Some employee',
        lastName: 'Some employee',
        displayName: 'Some employee Some employee',
        isSuperUser: false,
        email: 'some@employee.com',
        clientCount: 1,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Dumfries and Associates',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '5e669d90-4b4c-4276-ae0d-5addf0504951',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 497,
        employeeId: null,
        username: 'Paulo Soon',
        password: 'Password1',
        confirmPassword: null,
        firstName: 'Paulo',
        lastName: 'Soon',
        displayName: 'Soon Paulo',
        isSuperUser: false,
        email: 'paulos@email.com',
        clientCount: 25,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'AY BGL360 Test Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'e20c19b0-ad82-4ab8-8cce-f6d4816cd821',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL360_DTnocodes',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '07cb13d3-8c96-427e-a06b-cc1e8f7b3bfa',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'EGOR_WL_BGL360',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9f7e4e58-8a76-42d3-9437-0ce8dd955ebb',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "Olga's test",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '0086e961-d6f0-4a67-b39b-1dd5ba6d17a4',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 460,
        employeeId: null,
        username: 'tathiratest123',
        password: 'Ask19790',
        confirmPassword: null,
        firstName: 'tathiraTest',
        lastName: 'ak123',
        displayName: 'tathiratest',
        isSuperUser: false,
        email: 't.khaswala@evolvsuper.com.au',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 511,
        employeeId: null,
        username: 'Test Fan1',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Test Fan1',
        lastName: 'Test Fan1',
        displayName: 'Test Fan1',
        isSuperUser: false,
        email: 'testfan1@email.com',
        clientCount: 22,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'AY BGL360 Test Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'e20c19b0-ad82-4ab8-8cce-f6d4816cd821',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 475,
        employeeId: null,
        username: 'Test yuri111',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Test',
        lastName: 'testyuri',
        displayName: 'Test testyuri',
        isSuperUser: true,
        email: 'fordv12qt@mail.mail',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 474,
        employeeId: null,
        username: 'Test User 5171',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Test ',
        lastName: 'User 5171',
        displayName: 'Test User 5171',
        isSuperUser: false,
        email: '5171@email.com',
        clientCount: 21,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Queen Mercury',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9a558664-0517-4d52-ae10-c7ac9db5beae',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 485,
        employeeId: null,
        username: 'Test User User Test',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Test User',
        lastName: 'User Test',
        displayName: 'Test User User Test',
        isSuperUser: false,
        email: 'testuser100@email.com',
        clientCount: 21,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Queen Mercury',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '9a558664-0517-4d52-ae10-c7ac9db5beae',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: false,
    },
    {
        userId: 487,
        employeeId: null,
        username: 'Test User WL',
        password: 'Wsxzaq1!',
        confirmPassword: null,
        firstName: 'Test User WL',
        lastName: 'User Test',
        displayName: 'Test User WL',
        isSuperUser: false,
        email: 'testuser200@email.com',
        clientCount: 3,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 423,
        employeeId: null,
        username: '123456',
        password: 'Yuritest1',
        confirmPassword: null,
        firstName: 'YUritest1',
        lastName: 'Yuritest1',
        displayName: 'Yuritest1',
        isSuperUser: false,
        email: 'test@test.by',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: false,
    },
    {
        userId: 404,
        employeeId: 39,
        username: 'AnotherE',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Another',
        lastName: 'Employee',
        displayName: 'Another Employee',
        isSuperUser: false,
        email: 'lastorgano@yahoo.co.uk',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 405,
        employeeId: 40,
        username: 'louise',
        password: 'empty',
        confirmPassword: null,
        firstName: 'louise',
        lastName: 'C',
        displayName: 'louise C',
        isSuperUser: false,
        email: 'loulouinoz@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 420,
        employeeId: 48,
        username: 'tathira001',
        password: 'empty',
        confirmPassword: null,
        firstName: 'tathira',
        lastName: 'khaswala',
        displayName: 'tathira khaswala',
        isSuperUser: false,
        email: 'evolvtest@outlook.com',
        clientCount: 2,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3763 Test hehe',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '820652b7-8c39-445e-8863-7530d5203f43',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '3763 Test Manual',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '74793572-3a41-4f2f-bc85-440efdff84d6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 481,
        employeeId: 81,
        username: 'pa_testuser',
        password: 'empty',
        confirmPassword: null,
        firstName: 'testing_user1',
        lastName: 'for_access_test2',
        displayName: 'testing_user1 for_access_test2',
        isSuperUser: false,
        email: 'pa.testuser2021@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 499,
        employeeId: 89,
        username: 'joshlambino',
        password: 'empty',
        confirmPassword: null,
        firstName: 'josh',
        lastName: 'lambino',
        displayName: 'josh lambino',
        isSuperUser: false,
        email: 'lambino3103@gmail.com',
        clientCount: 4,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 500,
        employeeId: 90,
        username: '07102021testing',
        password: 'empty',
        confirmPassword: null,
        firstName: '07102021testing123',
        lastName: '07102021testing321',
        displayName: '07102021testing123 07102021testing321',
        isSuperUser: false,
        email: '07102021testing@gmail.com',
        clientCount: 0,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: false,
        isAuditorCPUser: true,
    },
    {
        userId: 505,
        employeeId: 94,
        username: 'Nico Soon Gmail',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Nico',
        lastName: 'Soon Gmail',
        displayName: 'Nico Soon Gmail',
        isSuperUser: false,
        email: 'randomnessss1@gmail.com',
        clientCount: 22,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 1');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '85acb363-e9d8-48f9-b988-3fbda5e5a4b1',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 2');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ae118ffe-9a67-4950-ba56-64532b44498b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Client 3');</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39fcd648-7ab6-4fce-8cf3-eeeae89a4534',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss 1')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('test xss')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: "<script>alert('Test XSS')</script>",
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'ef5d1f32-da2f-45f5-acf8-b8e18a7ccf5d',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: '1',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a3feafa4-7e16-4410-8dd9-fa223080485c',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'AY BGL360 Test Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'e20c19b0-ad82-4ab8-8cce-f6d4816cd821',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL 360 Client v3.2',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super API Client test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '49b1262c-0d78-40ad-9463-c292fc5a9fed',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Class Super PDF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '7c6dda31-6ffd-462b-83c7-d269e859bf86',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Cross script testing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '063362c5-7f6a-436d-973f-0e018be413fc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Edit Client.',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '1e368154-d6b3-e211-8b0d-00155d003518',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Everyrthing',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '87718792-ae9a-4d1a-b4d8-edae8464871b',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'MK',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '12d36c49-aaf8-4da3-b233-47c4bcd0da72',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Other Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'df4884c5-be39-441a-b7f0-b86e70677c19',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Supermate Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '64fdcab8-8160-45aa-9afa-c1e4cb7e708a',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'test',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '905031be-ecd0-40ba-98f0-a5fd3bfe6e70',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'testing_client_13102020',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: 'eea319c6-5f3c-4e8e-9ab6-fd8e088c19cc',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'Yuri BGL SF Client',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '274148f5-a292-4374-b642-47d6b9a710f7',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: true,
    },
    {
        userId: 508,
        employeeId: 97,
        username: '08102021_7826',
        password: 'empty',
        confirmPassword: null,
        firstName: 'Dmi',
        lastName: 'try',
        displayName: 'Dmi try',
        isSuperUser: false,
        email: '08102021testing@gmail.com',
        clientCount: 1,
        showExpander: true,
        isPasswordExpired: false,
        sendGuid: null,
        sendUTCDate: null,
        isError: false,
        errorMessage: null,
        child: [
            {
                userId: 0,
                employeeId: null,
                username: null,
                password: null,
                confirmPassword: null,
                firstName: null,
                lastName: null,
                displayName: 'DTclasstest',
                isSuperUser: false,
                email: null,
                clientCount: 0,
                showExpander: true,
                isPasswordExpired: false,
                sendGuid: null,
                sendUTCDate: null,
                isError: false,
                errorMessage: null,
                child: [],
                clientID: 0,
                b2CObjectID: null,
                isDisabled: false,
                clientGuid: '88683652-1f2b-4f0f-9a83-b31a6647ace6',
                accessToDashboard: false,
                isAuditorCPUser: false,
            },
        ],
        clientID: 0,
        b2CObjectID: null,
        isDisabled: false,
        clientGuid: '00000000-0000-0000-0000-000000000000',
        accessToDashboard: true,
        isAuditorCPUser: true,
    },
].map((u) => ({
    id: u.userId,
    username: u.username ?? undefined,
    accessToDashboard: u.accessToDashboard ?? undefined,
    b2CObjectId: u.b2CObjectID ?? undefined,
    displayName: u.displayName ?? undefined,
    email: u.email ?? undefined,
    firstName: u.firstName ?? undefined,
    employeeId: u.employeeId ?? undefined,
    lastName: u.lastName ?? undefined,
    isSuperUser: u.isSuperUser,
    isDeleted: u.isDisabled,
    clients: u.child.map((c) => ({ clientGuid: c.clientGuid, name: c.displayName })),
}));
