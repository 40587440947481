import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { Card, ControlledDatePicker, ControlledDropdown, ControlledNumberField, ModalFooter } from 'components';
import { INewCreditNoteFormValues } from 'interfaces';
import { mockedBillingEntities } from 'constants/MockedBillingEntities';
import { DefaultFormSettings } from 'constants/forms';

interface Props {
    onOpenToggle: () => void;
    onSubmit: (data: INewCreditNoteFormValues) => void;
}

export const CreditNoteAddNewForm: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, formState } = useForm<INewCreditNoteFormValues>(DefaultFormSettings);

    const fundOptions: IDropdownOption[] = mockedBillingEntities.map((be) => ({ key: be.id, text: be.name }));

    const fieldWidth = 'calc(50% - 16px)';
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                    <Stack.Item styles={{ root: { width: fieldWidth } }}>
                        <ControlledNumberField
                            name='amount'
                            control={control}
                            rules={{ required: formatMessage({ id: 'requiredAmount' }) }}
                            label={formatMessage({ id: 'amount' })}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: fieldWidth } }}>
                        <ControlledDatePicker
                            name='creationDate'
                            control={control}
                            label={formatMessage({ id: 'createdOn' })}
                            rules={{ required: formatMessage({ id: 'requiredCreatedOn' }) }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: fieldWidth } }}>
                        <ControlledDropdown
                            name='billingEntityId'
                            control={control}
                            label={formatMessage({ id: 'fund' })}
                            options={fundOptions}
                            rules={{ required: formatMessage({ id: 'requiredFund' }) }}
                        />
                    </Stack.Item>
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton text={formatMessage({ id: 'close' })} onClick={props.onOpenToggle} /> */}

                <PrimaryButton disabled={!formState.isDirty} text={formatMessage({ id: 'save' })} onClick={handleSubmit(props.onSubmit)} />
            </ModalFooter>
        </Stack>
    );
};
