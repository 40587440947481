import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Card, Pagination } from 'components';
import { useRulesetsStore } from '../RulesetsPage';

export const RulesetsPagination: FunctionComponent = observer(() => {
    const { rulesetsFilters, rulesetsPagination, setFilters, loadRulesets } = useRulesetsStore();

    return (
        <Card>
            <Pagination
                page={rulesetsFilters.page}
                pageSize={rulesetsFilters.pageSize}
                itemsCount={rulesetsPagination.shownItemsCount}
                total={rulesetsPagination.totalItemsCount}
                onChange={(newPageSize: number, newPage: number) => {
                    setFilters({ ...rulesetsFilters, page: newPage, pageSize: newPageSize });
                    loadRulesets();
                }}
            />
        </Card>
    );
});
