export const RouterPathsRulesetIdKey = 'rulesetId';
export const RouterPathsRuleIdKey = 'ruleId';
export const RouterPathsVariableIdKey = 'variableId';
export const RouterPathsClientIdKey = 'clientId';
export const RouterPathsFundIdKey = 'fundId';

export const RouterPaths = {
    invoices: {
        root: '/invoices',
        creditNotes: '/invoices/credit-notes',
    },
    portalSettings: {
        root: '/portal-settings',
        auditReadyConnections: '/portal-settings/audit-ready-connections',
        generalSettings: '/portal-settings/general-settings',
        riskMatrixSettings: '/portal-settings/risk-matrix-settings',
        materialityCalculatorSettings: '/portal-settings/materiality-calculator-settings',
        testingAndMethodologySettings: '/portal-settings/testing-and-methodology-settings',
        emailTemplates: '/portal-settings/email-templates',
    },
    userManagement: '/user-management',
    customization: '/customization',
    admin: {
        root: '/admin',
        rulesets: {
            root: '/admin/rulesets',
            ruleset: {
                root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                rules: {
                    root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                    new: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}/rules/new`,
                    rule: (
                        rulesetId: number | string = ':' + RouterPathsRulesetIdKey,
                        ruleId: number | string = ':' + RouterPathsRuleIdKey
                    ) => `/admin/rulesets/${rulesetId}/rules/${ruleId}`,
                },
                variables: {
                    root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                    new: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}/variables/new`,
                    variable: (
                        rulesetId: number | string = ':' + RouterPathsRulesetIdKey,
                        variableId: number | string = ':' + RouterPathsVariableIdKey
                    ) => `/admin/rulesets/${rulesetId}/variables/${variableId}`,
                },
            },
        },
    },
    login: {
        root: '/login',
        authenticator: '/login/authenticator',
        verifyEmail: '/login/verify-email',
        resetPassword: '/login/reset-password',
        changePassword: '/login/change-password',
        final: '/login/final',
    },
    workflow: {
        root: '/workflow',
        dashboard: '/workflow/dashboard',
        clients: '/workflow/clients',
        funds: '/workflow/funds',
        employees: '/workflow/employees',
        jobs: '/workflow/jobs',
        timesheet: '/workflow/timesheet',
        reports: '/workflow/reports',
    },
    administration: {
        root: '/administration',
        jobs: {
            root: '/administration/jobs',
            funds: {
                root: (clientId: number | string = ':' + RouterPathsClientIdKey) => `/administration/jobs/${clientId}`,
                jobList: {
                    root: (
                        clientId: number | string = ':' + RouterPathsClientIdKey,
                        fundId: number | string = ':' + RouterPathsFundIdKey,
                    ) => `/administration/jobs/${clientId}/${fundId}`,
                }
            },
        },
    },

};
