import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { pick } from 'lodash';
import { DefaultFormSettings } from 'constants/forms';
import { emailValidatorRegexp } from 'constants/validators';
import { IUserInfo } from 'interfaces';
import { Card, ControlledCheckbox, ControlledTextField, ModalFooter } from 'components';
import { IEditUser, useEditUser } from '../hooks/useEditUser';

interface Props {
    user: IUserInfo;
    onDismiss: () => void;
}

export const UserManagementEditUserForm: FunctionComponent<Props> = ({ user, onDismiss }: Props) => {
    const { editUser, isLoading: editUserLoading, isSuccess } = useEditUser();
    const { control, handleSubmit, formState } = useForm<IEditUser>({
        ...DefaultFormSettings,
        defaultValues: { ...pick(user, ['firstName', 'lastName', 'email', 'isSuperUser', 'accessToDashboard']), userId: user.id },
    });
    const { formatMessage } = useIntl();
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };

    useEffect(() => {
        if (isSuccess) {
            onDismiss();
        }
    }, [isSuccess, onDismiss]);

    const onSubmit = (data: IEditUser) => {
        editUser(data);
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='firstName'
                            label={formatMessage({ id: 'firstName' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='lastName'
                            label={formatMessage({ id: 'lastName' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '100%' } }}>
                        <ControlledTextField
                            name='email'
                            label={formatMessage({ id: 'email' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                pattern: { value: emailValidatorRegexp, message: formatMessage({ id: 'patternEmail' }) },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledCheckbox
                            name='accessToDashboard'
                            label={formatMessage({ id: 'accessToDashboard' })}
                            control={control}
                            adjustInputHeight
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledCheckbox
                            name='isSuperUser'
                            label={formatMessage({ id: 'superUser' })}
                            control={control}
                            adjustInputHeight
                        />
                    </Stack.Item>
                </Stack>
            </Card>
            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton onClick={onDismiss} text={formatMessage({ id: 'cancel' })} /> */}
                <PrimaryButton
                    disabled={!formState.isDirty || !formState.isValid || editUserLoading}
                    onClick={handleSubmit(onSubmit)}
                    text={formatMessage({ id: 'save' })}
                />
            </ModalFooter>
        </Stack>
    );
};
