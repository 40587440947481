import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField, ControlledTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import { EmailTemplate } from 'pages/PortalSettings/hooks/Email/useEmailTemplateGet';

interface Props {
    control: Control<EmailTemplate>;
}

export const OutstandingQueriesReportEmail: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='outstandingQueriesReportEmail' />}>
            <>
                <ControlledTextField
                    name='outstandingQueriesReportEmailSubject'
                    control={control}
                    label={formatMessage({ id: 'outstandingQueriesReportEmailSubject' })}
                />

                <ControlledRichTextField
                    name='outstandingQueriesReportEmailBody'
                    control={control}
                    label={formatMessage({ id: 'outstandingQueriesReportEmailBody' })}
                />
            </>
        </EmailTemplatePanel>
    );
};
