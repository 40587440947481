import { FunctionComponent, useState } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack, useTheme, INavLink } from '@fluentui/react';
import { Modal, ModalFooter, VerticalMenu } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import { GeneralContactTab, ContactDeatilsTab } from './tabs';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export enum ContactModalTabs {
    GENERAL_CONTACT,
    CONTACT_DETAILS,
}

export const ContactModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            companyName: '',
            jobTitle: '',
            directPhone: '',
            mobilePhone: '',
            emailContact: '',
            firstNameContact: '',
            lastNameContact: '',
        },
    });
    const { formatMessage } = useIntl();

    const onSubmit = (data: ICreateUserCommand) => {
        console.log(data);
        onDismiss();
    };

    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState<ContactModalTabs>(ContactModalTabs.GENERAL_CONTACT);

    const [links] = useState<INavLink[]>([
        {
            name: 'General',
            url: '',
            key: 'general',
            iconProps: { iconName: 'Settings' },
            onClick: () => {
                setActiveTab(ContactModalTabs.GENERAL_CONTACT);
            },
        },
        {
            name: 'Contact Details',
            url: '',
            key: 'contact',
            iconProps: { iconName: 'ComplianceAudit' },
            onClick: () => {
                setActiveTab(ContactModalTabs.CONTACT_DETAILS);
            },
        },
    ]);

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            background: theme.palette.white,
                        },
                    }}>
                    <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                        <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />

                        <Stack
                            styles={{
                                root: {
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                                    background: theme.palette.neutralLight,
                                    padding: '0 0 16px 16px',
                                    boxSizing: 'border-box',
                                    minHeight: '616px',
                                },
                            }}
                            tokens={{ childrenGap: 16 }}>
                            <Stack.Item styles={{ root: { height: '600px' } }}>
                                {activeTab === ContactModalTabs.GENERAL_CONTACT ? (
                                    <GeneralContactTab control={control} />
                                ) : (
                                    <ContactDeatilsTab control={control} />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>

                    <ModalFooter horizontalAlign='end'>
                        <PrimaryButton disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)} text={formatMessage({ id: 'save' })} />
                    </ModalFooter>
                </Stack>
            </Stack>
        </Modal>
    );
});
