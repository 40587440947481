import * as React from 'react';
import {
    ColorPicker,
    DefaultButton,
    IColor,
    Image,
    Shimmer,
    ShimmerElementType,
    Stack,
    Text,
    Theme,
    ThemeProvider,
    Toggle,
} from '@fluentui/react';
import { CustomizationPalete } from './CustomizationPalete';
import { PreviewPage } from './PreviewPage';
import { Card } from 'components';
import { useIntl } from 'react-intl';
import { Context } from 'utils';
import { useGetCustomizationColors } from './hooks/useGetCustomizationColors';
import { useChangeTheme } from './hooks/useChangeTheme';
import { useUploadLogo } from './hooks/useUploadLogo';

export const CustomizationPage = () => {
    const context = React.useContext(Context);
    const { colors, isLoading: getColorsLoading } = useGetCustomizationColors(
        context?.config?.settingsUrl || '',
        false,
        context?.tenantId || ''
    );
    const { changeTheme, applyThemeLoading, changeThemeLoading } = useChangeTheme(context?.config?.settingsUrl || '');
    const { uploadLogo, uploadLogoLoading } = useUploadLogo(context?.config?.settingsUrl || '');

    const [fileSelected, setFileSelected] = React.useState<File>();
    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        setFileSelected(fileList[0]);
    };

    const [primaryColor, setPrimaryColor] = React.useState<string>('');
    const [textColor, setTextColor] = React.useState<string>('');
    const [colorPalette, setColorPalette] = React.useState<Theme | null>(null);
    const [isDarkTheme, setIsDarkTheme] = React.useState<boolean>(false);
    const { formatMessage } = useIntl();

    React.useEffect(() => {
        if (colors?.data) {
            setPrimaryColor(colors.data.themeViewModel.themePrimary);
            setTextColor(colors.data.themeViewModel.neutralPrimary);
        }
    }, [colors?.data]);

    const updatePrimaryColor = React.useCallback((ev: any, colorObj: IColor) => {
        setPrimaryColor(`#${colorObj.hex}`);
    }, []);

    const updateTextColor = React.useCallback((ev: any, colorObj: IColor) => {
        setTextColor(`#${colorObj.hex}`);
    }, []);

    const setDefaultTheme = () => {
        setTextColor('#121212');
        setPrimaryColor('#bfcc49');
    };

    const darkThemeHandle = () => {
        setIsDarkTheme(!isDarkTheme);
    };

    const approveChangesHandle = () => {
        changeTheme({ themePrimary: primaryColor, neutralPrimary: textColor });
    };

    const uplaodLogoHandler = () => {
        if (fileSelected) {
            const formData = new FormData();
            formData.append('file', fileSelected);
            uploadLogo(formData);
        }
    };

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            {!colors?.data || getColorsLoading || applyThemeLoading || changeThemeLoading || uploadLogoLoading ? (
                <>
                    <Card>
                        <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 64 }]} />
                    </Card>
                    <Card>
                        <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 400, width: '100%' }]} />
                    </Card>
                </>
            ) : (
                <>
                    <Card styles={{ root: { minHeight: 64, boxSizing: 'border-box' } }}>
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <DefaultButton
                                    styles={{ root: { maxWidth: 200, minHeight: 30 } }}
                                    text='Default Theme'
                                    primary
                                    onClick={setDefaultTheme}
                                />
                            </Stack>
                            <Stack>
                                <DefaultButton
                                    styles={{ root: { maxWidth: 200, minHeight: 30 } }}
                                    onClick={approveChangesHandle}
                                    text='Approve'
                                    primary
                                />
                            </Stack>
                        </Stack>
                    </Card>

                    <Card styles={{ root: { overflow: 'auto' } }}>
                        <Stack tokens={{ childrenGap: 24 }} horizontal>
                            <Stack tokens={{ childrenGap: 8 }} styles={{ root: { width: '40%', minWidth: 525 } }}>
                                <Text styles={{ root: { fontWeight: 'bold' } }}>{formatMessage({ id: 'changeColorTheme' })}</Text>
                                <Stack tokens={{ childrenGap: 16 }} verticalAlign='space-between' styles={{ root: { height: '100%' } }}>
                                    <Stack styles={{ root: { border: '1px solid gray', padding: 10 } }} horizontal>
                                        <Stack.Item styles={{ root: { width: '50%' } }}>
                                            <ColorPicker color={primaryColor} onChange={updatePrimaryColor} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <ColorPicker color={textColor} onChange={updateTextColor} />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Text styles={{ root: { fontWeight: 'bold' } }}>{formatMessage({ id: 'changeLogo' })}</Text>
                                        <Stack styles={{ root: { border: '1px solid gray', padding: 10 } }} tokens={{ childrenGap: 4 }}>
                                            <Image src={colors.data.logoImageUrl} styles={{ image: { width: 300 } }} />
                                            <Stack
                                                horizontal
                                                verticalAlign='center'
                                                horizontalAlign='space-between'
                                                tokens={{ childrenGap: 4 }}>
                                                <input
                                                    className='uploadFile'
                                                    type='file'
                                                    onChange={handleImageChange}
                                                    multiple={false}
                                                    accept='image/*'
                                                />
                                                <DefaultButton
                                                    onClick={uplaodLogoHandler}
                                                    styles={{ root: { maxWidth: 200, minHeight: 30 } }}
                                                    text='Upload'
                                                    primary
                                                    disabled={!fileSelected}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <CustomizationPalete
                                        isDarkTheme={isDarkTheme}
                                        setColorPalette={setColorPalette}
                                        primaryColor={primaryColor}
                                        textColor={textColor}
                                    />
                                </Stack>
                            </Stack>
                            {colorPalette && (
                                <Stack tokens={{ childrenGap: 4 }}>
                                    <Stack horizontal>
                                        <Text styles={{ root: { fontWeight: 'bold', marginRight: 16 } }}>
                                            {formatMessage({ id: 'viewChanges' })}
                                        </Text>
                                        <Toggle
                                            defaultChecked={isDarkTheme}
                                            onText={formatMessage({ id: 'useLightTheme' })}
                                            offText={formatMessage({ id: 'useDarkTheme' })}
                                            onChange={darkThemeHandle}
                                        />
                                    </Stack>
                                    <ThemeProvider theme={colorPalette}>
                                        <Stack horizontal wrap tokens={{ childrenGap: 16 }}>
                                            <Stack styles={{ root: { width: 615, border: '1px solid gray', position: 'relative' } }}>
                                                <PreviewPage></PreviewPage>
                                            </Stack>
                                        </Stack>
                                    </ThemeProvider>
                                </Stack>
                            )}
                        </Stack>
                    </Card>
                </>
            )}
        </Stack>
    );
};
