import * as React from 'react';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { IObjectWithKey } from '@fluentui/utilities/src/selection/Selection.types';
import { IInvoice } from 'interfaces';
import { SelectInvoicesDataTable } from './SelectInvoicesDataTable';

interface Props {
    invoices: Array<IInvoice>;
    initialSelectedInvoices?: Array<IInvoice>;
    loading: boolean;
    onSelectInvoicesChange?: (value: Array<IInvoice>) => void;
}

interface State {
    total?: number;
    selectedItemsCount: number;
}

interface IInvoiceWithKey extends IInvoice, IObjectWithKey {}

export class SelectInvoicesDataTableSelection extends React.Component<Props, State> {
    private selection: Selection<IInvoiceWithKey> = new Selection<IInvoiceWithKey>({
        onSelectionChanged: () => {
            if (this.props.onSelectInvoicesChange) {
                this.props.onSelectInvoicesChange(this.selection.getSelection());
            }
            this.countTotal();
        },
    });

    constructor(props: Props) {
        super(props);

        this.state = { selectedItemsCount: 0 };
    }

    public render() {
        return (
            <>
                <MarqueeSelection selection={this.selection as Selection<IObjectWithKey>}>
                    <SelectInvoicesDataTable
                        invoices={this.props.invoices}
                        selection={this.selection}
                        total={this.state.total}
                        jobsSelectedCount={this.state.selectedItemsCount}
                        loading={this.props.loading}
                    />
                </MarqueeSelection>
            </>
        );
    }

    private countTotal() {
        const total: number = this.selection.getSelection().reduce((prev, curr) => prev + (curr as any).total, 0);
        this.setState({ total, selectedItemsCount: this.selection.getSelectedCount() });
    }
}
