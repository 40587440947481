import { FunctionComponent } from 'react';
import { IconButton, useTheme } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { observer } from 'mobx-react';
import { ICreditNote } from 'interfaces';
import { useCreditNotesStore } from '../../CreditNotesPage';

interface Props {
    onViewClick: () => void;
    creditNote: ICreditNote;
    onDeleteClick: () => void;
}

export const CreditNoteTableActions: FunctionComponent<Props> = observer((props: Props) => {
    const theme = useTheme();
    const { deleteCreditNote } = useCreditNotesStore();

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'view',
                text: 'View',
                iconProps: { iconName: 'View' },
                onClick: props.onViewClick,
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete', styles: { root: { color: theme.palette.red } } },
                //onClick: () => deleteCreditNote(props.creditNote.id),
                onClick: props.onDeleteClick,
            },
        ],
    };

    return <IconButton onRenderMenuIcon={() => <div />} menuProps={menuProps} iconProps={{ iconName: 'More' }} />;
});
