import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    options: IComboBoxOption[];
    selectAll?: boolean;
    dropdownWidth?: number;
    dropdownMaxWidth?: number;
    allowFreeform?: boolean;
    autoComplete?: boolean;
    selectedYears?: number[];
}

export function ControlledMultiSelect<FormValues>({
    name,
    control,
    rules,
    label,
    options,
    selectAll,
    dropdownWidth = 200,
    dropdownMaxWidth,
    allowFreeform,
    autoComplete,
    selectedYears,
}: Props<FormValues>) {
    const { formatMessage } = useIntl();
    const [selectedKeys, setSelectedKeys] = useState<any[]>();
    const selectAllOption = {
        key: 'selectAll',
        text: formatMessage({ id: 'selectAll' }),
        itemType: SelectableOptionMenuItemType.SelectAll,
    };
    const selectableOptions = options.filter(
        (option) => (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
    );

    useEffect(() => {
        !selectedKeys?.length && setSelectedKeys(control._formValues[name] as any);
    }, [control._formValues, name, selectedKeys?.length]);

    const onChangeComboBox = (option: IComboBoxOption | undefined, prevValue: any[], onChange: (keys: any[]) => void) => {
        const selected = option?.selected;
        // const currentSelectedOptionKeys = prevValue.filter((key) => key !== 'selectAll');
        const selectAllState = prevValue.length === selectableOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                if (selectAllState) {
                    setSelectedKeys([]);
                    onChange([]);
                } else {
                    setSelectedKeys(['selectAll', ...selectableOptions.map((o) => o.key as string)]);
                    onChange(selectableOptions.map((o) => o.key as string));
                }
            } else {
                const updatedKeys = selected ? [...prevValue, option!.key as string] : prevValue.filter((k) => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
                onChange(updatedKeys.filter((key) => key !== 'selectAll'));
            }
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <ComboBox
                    label={label}
                    options={selectAll ? [selectAllOption, ...options] : options}
                    dropdownWidth={dropdownWidth}
                    dropdownMaxWidth={dropdownMaxWidth}
                    onChange={(_e, option) => onChangeComboBox(option, value as any[], onChange)}
                    selectedKey={selectedKeys}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    multiSelect
                    allowFreeform={allowFreeform}
                    autoComplete={autoComplete ? 'on' : 'off'}
                />
            )}
        />
    );
}
