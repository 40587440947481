import { FunctionComponent, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { BaseDialog, Card, DataTable } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import { allData } from 'pages/Workflow/components/data/Data';

export const ContactsDataTable: FunctionComponent = () => {
    const [showActivetedModal, setShowActivetedModal] = useState<boolean>(false);
    const { formatMessage } = useIntl();

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({ id: 'commands' }),
            minWidth: 100,
            maxWidth: 300,
            fieldName: 'commands',
            onRender: (items) => (
                <ActionButton allowDisabledFocus onClick={() => setShowActivetedModal(true)} styles={{ root: { fontWeight: 500 } }}>
                    {items.active ? 'Deactivate' : 'Activate'}
                </ActionButton>
            ),
        },
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'name',
            onRender: (items) => <Text>{items.name}</Text>,
        },
        {
            key: 'email',
            name: formatMessage({ id: 'email' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'email',
            onRender: (items) => <Text>{items.email}</Text>,
        },
        {
            key: 'phone',
            name: formatMessage({ id: 'directPhone' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'phone',
            onRender: (items) => <Text>{items.phone}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['name', 'email', 'phone']}
                initialColumns={columns}
                items={allData.items}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />
            {showActivetedModal && (
                <BaseDialog
                    title={formatMessage({ id: 'changeActivDialogTitle' })}
                    subText={formatMessage({ id: 'changeActivDialogSubText' })}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivetedModal(false)}
                />
            )}
        </Card>
    );
};
