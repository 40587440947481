import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { Card } from 'components';
import { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { ITheme, getTheme } from '@fluentui/react/lib/Styling';

const theme: ITheme = getTheme();
const { fonts, semanticColors, palette } = theme;

export const ErrorPage: FunctionComponent<FallbackProps> = ({error, resetErrorBoundary}) => {
  return (
    <Stack
      role='alert'
      grow={1}
      horizontalAlign='center'
      verticalAlign='center'
      styles={{ root: { background: palette.neutralLighter } }}>
      <Card styles={{ root: { width: 500 } }}>
        <Stack tokens={{ childrenGap: 18 }}>
          <Text as='h2' styles={{ root: { ...fonts.xxLarge, marginTop: 0 } }}>
            Oh, no! Something went wrong...
          </Text>
          <Text
            as='pre'
            styles={{ root: {
              background: semanticColors.errorBackground,
              padding: '16px',
              whiteSpace: 'pre-wrap',
            } }}>
            {error.message}
          </Text>
          <PrimaryButton text={'Try again'} onClick={resetErrorBoundary} />
        </Stack>
      </Card>
    </Stack>
)};