import { FunctionComponent, useState } from 'react';
import { tableColumnsData, tableItemsData } from 'pages/Workflow/components/data/DashboardData';
import {
	DashboardTableTypeEnum,
	GeneratedDataTable,
	GeneratedFilters,
	ITableFilters,
} from 'pages/Workflow/components/tables';
import {
	mergeStyleSets,
	Stack,
	useTheme,
} from '@fluentui/react';

export interface ITableWidget {
	dashboardTableType: DashboardTableTypeEnum;
}


export const TableWidget: FunctionComponent<ITableWidget> = ({ dashboardTableType }) => {
	const theme = useTheme();

	const initialFilters: ITableFilters = {
		page: 1,
		pageSize: 10,
		searchByName: '',
	};

	const [filters, setFilters] = useState<ITableFilters>(initialFilters);

	const getColumnsTableData = (filters: ITableFilters, dashboardTableType: DashboardTableTypeEnum): typeof tableColumnsData => {
		// !TODO do api magic and return columns data
		return tableColumnsData;
	};
	const columnsData = getColumnsTableData(filters, dashboardTableType);

	const getItemsTableData = (filters: ITableFilters, dashboardTableType: DashboardTableTypeEnum): typeof tableItemsData => {
		// !TODO do api magic and return table data
		return tableItemsData;
	};
	const itemsData = getItemsTableData(filters, dashboardTableType);

	const classNames = mergeStyleSets({
		container: {
			height: '100%',
			boxSizing: 'border-box',
			border: `2px solid ${theme.palette.themePrimary}`,
			borderRadius: 2,
		},
		title: {
			padding: '8px 16px',
			backgroundColor: theme.palette.themePrimary,
		},
	});

	return (
		<Stack className={classNames.container}>
			<Stack className={classNames.title} horizontal horizontalAlign='space-between' verticalAlign='center'>
				<Stack.Item>
					{columnsData.tableName}
				</Stack.Item>
				<Stack horizontal tokens={{ childrenGap: 8 }}>
					<GeneratedFilters searchPlaceholder={columnsData.searchPlaceholder} setFilters={setFilters} isWidget />
				</Stack>
			</Stack>
			<Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
				<GeneratedDataTable setFilters={setFilters} itemsData={itemsData} columnsData={columnsData} />
			</Stack.Item>
		</Stack>
	);
};