import { DashboardContainer, IDashboardConfig, WidgetsEnum } from 'pages/Workflow/DashboardWorkflow/components';
import { DashboardTableTypeEnum } from 'pages/Workflow/components/tables';
import { widgetData as mockedData } from 'pages/Workflow/components/data/DashboardData';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

type WidgetDataType = typeof mockedData;

export const AuditorDashboard = () => {
    const { formatMessage } = useIntl();
    const getWidgetData = () => {
        return { widgetData: mockedData, isLoading: false };
    };
    const { widgetData, isLoading } = getWidgetData();

    const getAuditorDashboardConfig = useCallback(
        (props: WidgetDataType): IDashboardConfig => {
            return {
                id: 'auditor',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedToday',
                            props: {
                                number: props.auditsAwaitingAuditorAllocationCount,
                                title: formatMessage({ id: 'auditedToday' }),
                                // !TODO need to change
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                                withoutBG: true,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedYesterday',
                            props: {
                                number: props.auditsInProgressWithAuditorCount,
                                title: formatMessage({ id: 'auditedYesterday' }),
                                // !TODO need to change
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                                withoutBG: true,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedThisWeek',
                            props: {
                                number: props.auditsAwaitingManagerReviewCount,
                                title: formatMessage({ id: 'auditedThisWeek' }),
                                // !TODO need to change
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                                withoutBG: true,
                            },
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'preARQueriesWaitingToBeReviewed',
                            props: {
                                title: formatMessage({ id: 'preARQueriesWaitingToBeReviewed' }),
                                data: props.preArQueriesWaitingToBeReviewed,
                                // !TODO need to change
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                            },
                        },
                        {
                            type: WidgetsEnum.DOUBLE_COUNT_WIDGET,
                            id: 'preArQueriesToBeReviewed',
                            props: {
                                number: props.arSentThisWeekCount,
                                secondNum: 1,
                            },
                        },

                        {
                            type: WidgetsEnum.BAR_CHART_WIDGET,
                            id: 'postARQueriesWaitingToBeReviewed',
                            props: {
                                title: formatMessage({ id: 'postARQueriesWaitingToBeReviewed' }),
                                data: props.postArQueriesWaitingToBeReviewed,
                                // !TODO need to change
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                dashboardTableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingForResponse,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    const auditorDashboardConfig = useMemo(() => {
        return getAuditorDashboardConfig(widgetData);
    }, [widgetData, getAuditorDashboardConfig]);
    return <DashboardContainer isLoading={isLoading} dashboardConfig={auditorDashboardConfig} />;
};
