import { Card, ControlledDatePicker } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';
import { Control } from 'react-hook-form';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const ContraventionTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionEventReadyForReview'
                        label={formatMessage({ id: 'contraventionEventReadyForReview' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='mgrContraventionEventReviewed'
                        label={formatMessage({ id: 'mgrContraventionEventReviewed' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionSent'
                        label={formatMessage({ id: 'contraventionSent' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='pnrContraventionEventReviewed'
                        label={formatMessage({ id: 'pnrContraventionEventReviewed' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionLodged'
                        label={formatMessage({ id: 'contraventionLodged' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
