import * as React from 'react';
import { FunctionComponent, useMemo, useState } from 'react';
import {
    DialogType,
    IColumn,
    IShimmerElement,
    PrimaryButton,
    SelectionMode,
    Shimmer,
    ShimmerElementType,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { formatIdNumber } from 'utils/formatIdNumber';
import { ICreditNote } from 'interfaces';
import { Card, DataTable } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { useCreditNotesStore } from '../../CreditNotesPage';
import { CreditNoteTableActions } from './CreditNoteTableActions';
import { CreditNoteViewModal } from '../CreditNoteViewModal';
import { CreditNoteAddNewModal } from '../CreditNoteAddNewModal';
import { CreditNoteStatus } from './CreditNoteStatus';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useBoolean } from '@fluentui/react-hooks';
import { DialogMessage } from 'components/DialogMessage';

export const CreditNotesTableData: FunctionComponent = observer(() => {
    const { formatMessage, formatNumber, formatDate } = useIntl();
    const [chosenCreditNote, setChosenCreditNote] = useState<ICreditNote | undefined>(undefined);
    const [openViewCreditNoteModal, setOpenViewCreditNoteModal] = useState(false);
    const { creditNotes, creditNotesLoading, creditNotesPageSize, deleteCreditNoteLoadingIds } = useCreditNotesStore();
    const theme = useTheme();
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);
    const [isDialogHidden, setIsDialogHidden] = React.useState<boolean>(true);

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Record Removing',
        closeButtonAriaLabel: 'Close',
        subText: 'Sure to remove this record?',
    };

    const handleViewCreditNoteClick = (creditNote: ICreditNote) => {
        setChosenCreditNote(creditNote);
        setOpenViewCreditNoteModal(true);
    };
    const shimmerElement: IShimmerElement[] = [{ type: ShimmerElementType.line, height: 32, verticalAlign: 'bottom' }];

    const columns: IColumn[] = [
        {
            key: 'creditNoteNumber',
            name: formatMessage({ id: 'creditNoteNumber' }),
            fieldName: 'id',
            minWidth: 90,
            maxWidth: 150,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? (
                    <Shimmer shimmerElements={shimmerElement} />
                ) : (
                    <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                        {formatIdNumber(item.id)}
                    </Text>
                ),
            isMultiline: true,
        },
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 80,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? (
                    <Shimmer shimmerElements={shimmerElement} />
                ) : (
                    formatNumber(item.amount, { style: 'currency', currency: 'USD' })
                ),
        },
        {
            key: 'status',
            name: formatMessage({ id: 'status' }),
            fieldName: 'status',
            minWidth: 60,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? (
                    <Shimmer shimmerElements={shimmerElement} />
                ) : (
                    <CreditNoteStatus status={item.status} />
                ),
        },
        {
            key: 'fundName',
            name: `${formatMessage({ id: 'associatedWith' })}\n(${formatMessage({ id: 'client' })}/${formatMessage({ id: 'fund' })})`,
            // fieldName: 'billingEntity.name',
            minWidth: 190,
            isMultiline: true,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? <Shimmer shimmerElements={shimmerElement} /> : item.billingEntity.name,
        },
        {
            key: 'creationDate',
            name: formatMessage({ id: 'creationDate' }),
            fieldName: 'creationDate',
            minWidth: 115,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? (
                    <Shimmer shimmerElements={shimmerElement} />
                ) : item.creationDate ? (
                    formatDate(item.creationDate)
                ) : null,
        },
        {
            key: 'actions',
            name: '',
            fieldName: 'actions',
            minWidth: 52,
            maxWidth: 52,
            onRender: (item: ICreditNote) =>
                deleteCreditNoteLoadingIds.includes(item.id) ? (
                    <Shimmer shimmerElements={shimmerElement} />
                ) : (
                    <CreditNoteTableActions
                        onDeleteClick={() => setIsDialogHidden(false)}
                        creditNote={item}
                        onViewClick={() => handleViewCreditNoteClick(item)}
                    />
                ),
        },
    ];

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'add' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleIsAddModalOpen,
            },
        ],
        [toggleIsAddModalOpen, formatMessage]
    );

    return (
        <>
            <Card styles={CardTableContainerStyles}>
                <CommandBar
                    items={[]}
                    farItems={commandBarItems}
                    styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
                />
                <DataTable
                    initialColumns={columns}
                    items={cloneDeep(creditNotes) ?? []}
                    enableShimmer={creditNotesLoading}
                    shimmerLines={creditNotesPageSize}
                    selectionMode={SelectionMode.none}
                    setKey='none'
                    isHeaderVisible
                    containerHeight='calc(100% - 32px - 16px)'
                />
                <Text variant='small'>
                    <FormattedMessage
                        id='youHaveInOutstandingCreditsForSelectedInvoice'
                        values={{ value: formatNumber(0, { style: 'currency', currency: 'USD' }) }}
                    />
                </Text>
            </Card>
            <CreditNoteViewModal creditNote={chosenCreditNote} open={openViewCreditNoteModal} onOpenChange={setOpenViewCreditNoteModal} />
            <CreditNoteAddNewModal open={isAddModalOpen} onOpenToggle={toggleIsAddModalOpen} />
            <DialogMessage
                dialogContentProps={dialogContentProps}
                hidden={isDialogHidden}
                onClick={() => setIsDialogHidden(true)}
                onDismis={() => setIsDialogHidden(true)}
            />
        </>
    );
});
