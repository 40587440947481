import { action, makeObservable, observable } from 'mobx';
import { finalize, timer } from 'rxjs';
import { cloneDeep } from 'lodash';
import { mockedInvoices } from 'constants/MockedInvoices';
import { InvoiceFilterStatusType } from '../../../enums';
import { IInvoice, IUpdateFeesValues } from 'interfaces';
import { AddMultipleJobsStepType } from '../enums';

export class AddMultipleJobsStore {
    public step: AddMultipleJobsStepType = AddMultipleJobsStepType.SelectInvoices;
    public selectedInvoices: IInvoice[] = [];
    public loadingInvoices: boolean = false;
    public invoices: IInvoice[] = [];
    public loadingUpdateFees: boolean = false;
    public loadingGenerateInvoice: boolean = false;
    public updateFeesResult?: IUpdateFeesValues;

    constructor() {
        makeObservable(this, {
            invoices: observable,
            loadingInvoices: observable,
            selectedInvoices: observable,
            step: observable,
            loadingUpdateFees: observable,
            updateFeesResult: observable,
            loadingGenerateInvoice: observable,
            getInvoices: action,
            setNextStep: action,
            setSelectedInvoices: action,
            updateFees: action,
            generateInvoice: action,
        });
    }

    public setNextStep = () => {
        switch (this.step) {
            case AddMultipleJobsStepType.SelectInvoices:
                this.step = AddMultipleJobsStepType.UpdateFees;
                break;
            case AddMultipleJobsStepType.UpdateFees:
                this.step = AddMultipleJobsStepType.GenerateInvoices;
                break;
            case AddMultipleJobsStepType.GenerateInvoices:
            default:
                break;
        }
    };

    public getInvoices = (clientId: string[], status: InvoiceFilterStatusType, fund?: string, year?: number) => {
        this.loadingInvoices = true;

        timer(1000)
            .pipe(finalize(() => (this.loadingInvoices = false)))
            .subscribe(() => {
                this.invoices = mockedInvoices;
                // .filter((i) => clientId.some((id) => i.filterClientId === id))
                // .filter((i) => (fund ? i.filterFund === fund : true))
                // .filter((i) => (year ? i.filterYear === year : true));
            });
    };

    public setSelectedInvoices = (selectedInvoices: IInvoice[]) => {
        this.selectedInvoices = cloneDeep(selectedInvoices);
    };

    public updateFees = (updateFeesResult: IUpdateFeesValues) => {
        this.setNextStep();
        this.loadingUpdateFees = true;
        timer(1000)
            .pipe(finalize(() => (this.loadingUpdateFees = false)))
            .subscribe(() => {
                this.updateFeesResult = updateFeesResult;
            });
    };

    public generateInvoice = () => {
        this.loadingGenerateInvoice = true;
        return timer(1000).pipe(finalize(() => (this.loadingGenerateInvoice = false)));
    };
}
