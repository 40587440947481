import {Outlet, useOutletContext} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {useState} from 'react';
import {IAdministrationListItem} from 'interfaces';

export const JobsLayoutPage = () => {
    const { formatMessage } = useIntl();
    const [selectedClient, setSelectedClient] = useState<IAdministrationListItem | null>(null);
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'clients',
                key: RouterPaths.administration.jobs.root,
                text: formatMessage({ id: 'clients' }),
            }}>
            <Outlet context={[selectedClient, setSelectedClient]} />
        </PageWithBreadcrumb>
    );
};

export function useJobsOutletContext() {
    return useOutletContext<[IAdministrationListItem | null, React.Dispatch<React.SetStateAction<IAdministrationListItem>>]>();
};