import { Card, ControlledDatePicker, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const InvoicingTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='invoiceDate'
                        label={formatMessage({ id: 'invoiceDate' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='invoiceAmount'
                        label={formatMessage({ id: 'invoiceAmount' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='invoiceNumber'
                        label={formatMessage({ id: 'invoiceNumber' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
