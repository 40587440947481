import * as React from 'react';
import { createContext, FunctionComponent, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { INavLink } from '@fluentui/react';
import { Layout, PageWithBreadcrumb } from 'components';
import { InvoicePortalStore } from './stores';
import { RouterPaths } from '../../navigation';

const InvoicePortalStoreContext = createContext<InvoicePortalStore>(new InvoicePortalStore());

export const useInvoicePortalStore = () => {
    return React.useContext(InvoicePortalStoreContext);
};

export const InvoicePortalLayoutPage: FunctionComponent = observer(() => {
    const { formatMessage } = useIntl();
    const { search } = useLocation();
    const [invoicePortalStore] = useState(() => new InvoicePortalStore());
    invoicePortalStore.readClientId(search);
    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'invoices' }),
            url: `/invoices${search ?? ''}`,
            key: 'invoices',
            iconProps: { iconName: 'M365InvoicingLogo' },
        },
        {
            name: formatMessage({ id: 'creditNotes' }),
            url: `/invoices/credit-notes${search ?? ''}`,
            key: 'creditNotes',
            iconProps: { iconName: 'NoteReply' },
        },
    ]);

    return (
        <Layout links={links}>
            <InvoicePortalStoreContext.Provider value={invoicePortalStore}>
                <PageWithBreadcrumb
                    breadcrumb={{
                        id: 'invoices',
                        key: RouterPaths.invoices.root,
                        text: formatMessage({ id: 'invoices' }),
                    }}>
                    <Outlet />
                </PageWithBreadcrumb>
            </InvoicePortalStoreContext.Provider>
        </Layout>
    );
});
