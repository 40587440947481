export const mockedJobs: Array<{ id: number, guid: string; name: string, status: boolean, initialInformation: string, auditQueries: string, documents: string }> = [
  {
    id: 1,
    guid: 'ea880f1b-989b-df11-1111-02bf0a033e0b',
    name: '2020 Audit',
    status: true,
    initialInformation: '',
    auditQueries: '',
    documents: '',
  },
  {
    id: 2,
    guid: 'e8880f1b-989b-df11-1111-02bf0a033e0b',
    name: '2021 Audit',
    status: true,
    initialInformation: '',
    auditQueries: '',
    documents: '',
  },
  {
    id: 3,
    guid: 'e7880f1b-989b-df11-1111-02bf0a033e0b',
    name: '2022 Audit',
    status: false,
    initialInformation: '',
    auditQueries: '',
    documents: '',
  },
];