import { DefaultButton, Dialog, DialogFooter, IDialogContentProps, IModalProps, PrimaryButton } from '@fluentui/react';

type DialogMessageProps = {
    hidden: boolean;
    onDismis: () => void;
    dialogContentProps: IDialogContentProps;
    modalProps?: IModalProps;
    onClick: () => void;
};

export const DialogMessage = (props: DialogMessageProps) => {
    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismis}
            dialogContentProps={props.dialogContentProps}
            modalProps={props.modalProps}>
            <DialogFooter>
                <DefaultButton onClick={props.onDismis} text='Cancel' />
                <PrimaryButton onClick={props.onClick} text='Sure' />
            </DialogFooter>
        </Dialog>
    );
};
