import { FunctionComponent, ReactNode } from 'react';
import { Stack, Text } from '@fluentui/react';
import { Card } from 'components';

interface Props {
    header: ReactNode;
    children: ReactNode;
}

export const EmailTemplatePanel: FunctionComponent<Props> = ({ header, children }: Props) => (
    <Card>
        <Stack tokens={{ childrenGap: 16 }}>
            <Text variant='xLarge'>{header}</Text>
            {children}
        </Stack>
    </Card>
);
