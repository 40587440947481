import axios from 'axios';
import { IAppConfiguration } from 'interfaces';
import { useQuery } from 'react-query';
import { useGetTenantId } from './useGetTenantId';

/** Getting Data for configuration routes - Main hook */

function useConfiguration() {
    const httpClientConfig =
        process.env.NODE_ENV === 'development'
            ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/app/configuration'
            : 'app/configuration';
    const { data: config, isLoading: isConfigLoading } = useQuery(
        ['useConfiguration'],
        () =>
            axios.get<IAppConfiguration>(httpClientConfig, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        { useErrorBoundary: true }
    );

    const { data: tenant, isLoading: isTenantLoading } = useGetTenantId(config?.data.tenantProviderUrl || '');

    return {
        config,
        isConfigLoading,
        tenant,
        isTenantLoading,
    };
}

export { useConfiguration };
