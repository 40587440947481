import { Card, ControlledDatePicker, ControlledDropdown } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const ManagerTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='manager'
                        label={formatMessage({ id: 'manager' })}
                        options={[]}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='mgrPointsRaised'
                        label={formatMessage({ id: 'mgrPointsRaised' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='mgrPointsReviewedByAuditor'
                        label={formatMessage({ id: 'mgrPointsReviewedByAuditor' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='readyForPartnerReview'
                        label={formatMessage({ id: 'readyForPartnerReview' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
