import * as React from 'react';
import { FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, IColumn, SelectionMode, useTheme } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useBoolean } from '@fluentui/react-hooks';
import { maxBy } from 'lodash';
import { MockedApprovers } from 'constants/MockedApprovers';
import { DataTable, Modal } from 'components';
import { AddConnectionApproverForm } from './AddConnectionApproverForm';

export const Approvers: FunctionComponent = () => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);

    const [approvers, setApprovers] = useState<Array<{ id: number; email: string }>>(MockedApprovers);

    const addConnectionApprover = (form: { email: string }) => {
        setApprovers([...approvers, { ...form, id: (maxBy(approvers, (a) => a.id)?.id ?? 0) + 1 }]);
    };

    const columns: IColumn[] = [
        { key: 'email', fieldName: 'email', name: 'Approvers', minWidth: 200 },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 92,
            maxWidth: 92,
            onRender: (item) => (
                <ActionButton
                    styles={{ root: { height: 32 }, icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.red } }}
                    iconProps={{ iconName: 'Delete' }}
                    text={formatMessage({ id: 'remove' })}
                    onClick={() => setApprovers(approvers.filter((a) => a.id !== item.id))}
                />
            ),
        },
    ];

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'addConnectionApprover' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleIsAddModalOpen,
            },
        ],
        [toggleIsAddModalOpen, formatMessage]
    );

    return (
        <>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
            />
            <DataTable initialColumns={columns} items={approvers} selectionMode={SelectionMode.none} />

            <Modal title={formatMessage({ id: 'addConnectionApprover' })} isOpen={isAddModalOpen} onDismiss={toggleIsAddModalOpen}>
                <AddConnectionApproverForm
                    onDismiss={toggleIsAddModalOpen}
                    onSubmit={(form) => {
                        addConnectionApprover(form);
                        toggleIsAddModalOpen();
                    }}
                />
            </Modal>
        </>
    );
};
