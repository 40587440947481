import { FunctionComponent, useState } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack, useTheme, INavLink } from '@fluentui/react';
import { Modal, ModalFooter, VerticalMenu } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import { APIAuthorisationFields, AddressTab, AccountInfoTab, AuditTab, FundsTab, NotesTab, ContactTab } from './tabs';

export enum ClientModalTabs {
    ACCOUNT_INFO,
    ADDRESS,
    AUDIT,
    API_AUTHORISATION,
    NOTES,
    FUNDS,
    CONTACTS,
}

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export const ClientModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { formatMessage } = useIntl();
    const [activeTab, setActiveTab] = useState<ClientModalTabs>(ClientModalTabs.ACCOUNT_INFO);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'general' }),
            url: '',
            key: 'General',
            iconProps: { iconName: 'Settings' },
            links: [
                {
                    name: formatMessage({ id: 'accountInformation' }),
                    url: '',
                    key: 'accountInformation',
                    iconProps: { iconName: 'ComplianceAudit' },
                    onClick: () => {
                        setActiveTab(ClientModalTabs.ACCOUNT_INFO);
                    },
                },
                {
                    name: formatMessage({ id: 'address' }),
                    url: '',
                    key: 'address',
                    iconProps: { iconName: 'MapPin' },
                    onClick: () => {
                        setActiveTab(ClientModalTabs.ADDRESS);
                    },
                },
                {
                    name: formatMessage({ id: 'auditParticulars' }),
                    url: '',
                    key: 'auditParticulars',
                    iconProps: { iconName: 'MapLayers' },
                    onClick: () => {
                        setActiveTab(ClientModalTabs.AUDIT);
                    },
                },
                {
                    name: formatMessage({ id: 'apiAuthorisation' }),
                    url: '',
                    key: 'APIauthorisation',
                    iconProps: { iconName: 'ColumnOptions' },
                    onClick: () => {
                        setActiveTab(ClientModalTabs.API_AUTHORISATION);
                    },
                },
                {
                    name: formatMessage({ id: 'notes' }),
                    url: '',
                    key: 'notes',
                    iconProps: { iconName: 'Calculator' },
                    onClick: () => {
                        setActiveTab(ClientModalTabs.NOTES);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({ id: 'funds' }),
            url: '',
            key: 'funds',
            iconProps: { iconName: 'MapLayers' },
            onClick: () => {
                setActiveTab(ClientModalTabs.FUNDS);
            },
        },
        {
            name: formatMessage({ id: 'contacts' }),
            url: '',
            key: 'contact',
            iconProps: { iconName: 'ComplianceAudit' },
            onClick: () => {
                setActiveTab(ClientModalTabs.CONTACTS);
            },
        },
    ]);

    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            clientName: '',
            mainPhone: '',
            primaryContact: '',
            secondaryContact: '',
            primaryContactPhone: '',
            secondaryContactPhone: '',
            type: '',
            state: '',
            street: '',
            street2: '',
            city: '',
            country: '',
            postCode: '',
            typeSec: '',
            stateSec: '',
            streetSec: '',
            street2Sec: '',
            citySec: '',
            countrySec: '',
            postCodeSec: '',
            accountingSoftware: '',
            turnAroundTime: '',
            unsignedAuditReport: '',
            unsignedARDate: '',
            partner: '',
            clientRiskRating: '',
            aRGeneration: '',
            apiAuthorisationType: '',
            accSoftwareCode1: '',
            accSoftwareCode2: '',
            notes: '',
        },
    });

    const onSubmit = (data: ICreateUserCommand) => {
        console.log(data);
        onDismiss();
    };

    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const theme = useTheme();

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            background: theme.palette.white,
                        },
                    }}>
                    <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                        <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />

                        <Stack
                            styles={{
                                root: {
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                                    background: theme.palette.neutralLight,
                                    padding: '0 0 16px 16px',
                                    boxSizing: 'border-box',
                                    minHeight: '616px',
                                },
                            }}
                            tokens={{ childrenGap: 16 }}>
                            <Stack.Item styles={{ root: { height: '600px' } }}>
                                {activeTab === ClientModalTabs.ACCOUNT_INFO ? (
                                    <AccountInfoTab control={control} />
                                ) : activeTab === ClientModalTabs.ADDRESS ? (
                                    <AddressTab control={control} />
                                ) : activeTab === ClientModalTabs.AUDIT ? (
                                    <AuditTab control={control} />
                                ) : activeTab === ClientModalTabs.API_AUTHORISATION ? (
                                    <APIAuthorisationFields control={control} />
                                ) : activeTab === ClientModalTabs.NOTES ? (
                                    <NotesTab control={control} />
                                ) : activeTab === ClientModalTabs.FUNDS ? (
                                    <FundsTab />
                                ) : activeTab === ClientModalTabs.CONTACTS ? (
                                    <ContactTab />
                                ) : (
                                    ''
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>

                    <ModalFooter horizontalAlign='end'>
                        <PrimaryButton
                            disabled={
                                activeTab !== ClientModalTabs.FUNDS && activeTab !== ClientModalTabs.CONTACTS ? !formState.isDirty : false
                            }
                            onClick={handleSubmit(onSubmit)}
                            text={formatMessage({ id: 'save' })}
                        />
                    </ModalFooter>
                </Stack>
            </Stack>
        </Modal>
    );
});
