import { FunctionComponent, useMemo, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { Card, DataTable, Modal } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import {
    ActualFundsModal,
    AgedBalanceReportModal,
    AssetReportModal,
    DebtorStatementModal,
    DetailedWorkflow,
    InvoiceBreakdownModal,
    InvoicingReconciliationModal,
    OutstandingQueriesModal,
    QuerySummaryModal,
    SummarisedWorkflowModal,
} from 'pages/Workflow/components/reports';

export enum ReportModals {
    ACTUAL_FUNDS,
    AGED_BALANCE_REPORT,
    ASSET_REPORT,
    DEBTOR_STATEMENT,
    DETAILED_WORKFLOW,
    INVOICE_BREAKDOWN,
    INVOICING_RECONCILIATION,
    OUTSTANDING_QUERIES,
    QUERY_SUMMARY,
    SUMMARISED_WORKFLOW,
}

export interface IReport {
    reportName: string;
    key: ReportModals;
}
  
export const reports: IReport[] = [
    { reportName: 'actualFunds', key: ReportModals.ACTUAL_FUNDS },
    { reportName: 'agedBalanceReport', key: ReportModals.AGED_BALANCE_REPORT },
    { reportName: 'assetReport', key: ReportModals.ASSET_REPORT },
    { reportName: 'debtorStatement', key: ReportModals.DEBTOR_STATEMENT },
    { reportName: 'detailedWorkflow', key: ReportModals.DETAILED_WORKFLOW },
    { reportName: 'invoiceBreakdown', key: ReportModals.INVOICE_BREAKDOWN },
    { reportName: 'invoicingReconciliation', key: ReportModals.INVOICING_RECONCILIATION },
    { reportName: 'outstandingQueries', key: ReportModals.OUTSTANDING_QUERIES },
    { reportName: 'querySummary', key: ReportModals.QUERY_SUMMARY },
    { reportName: 'summarisedWorkflow', key: ReportModals.SUMMARISED_WORKFLOW },
];

export const ReportsDataTable: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    const [activeModal, setActiveModal] = useState<ReportModals | null>(null);
    const [columns] = useState<IColumn[]>([
        {
            key: 'reportName',
            name: formatMessage({ id: 'reportName' }),
            minWidth: 150,
            fieldName: 'reportName',
            onRender: (items) => <Text>{formatMessage({ id: items.reportName })}</Text>,
        },
        {
            key: 'url',
            name: formatMessage({ id: 'url' }),
            minWidth: 150,
            maxWidth: 150,
            fieldName: 'url',
            onRender: (items) => (
                <ActionButton styles={{ root: { fontWeight: 500 } }} onClick={() => setActiveModal(items.key)} allowDisabledFocus>
                    {formatMessage({ id: 'openReport' })}
                </ActionButton>
            ),
        },
    ]);

    const selectedModal: IReport | null = useMemo(() => {
        if (activeModal === null) return null;
        return reports.find(report => report.key === activeModal) || null;
    }, [activeModal]);

    const modalProps = useMemo(() => ({
        onDismiss() {
            setActiveModal(null);
        },
    }), []);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={columns}
                items={reports}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            <Modal
                title={selectedModal?.reportName ? formatMessage({ id: selectedModal.reportName }) : ''}
                isOpen={activeModal !== null}
                onDismiss={() => setActiveModal(null)}
            >
                {activeModal === ReportModals.ACTUAL_FUNDS ? (
                    <ActualFundsModal {...modalProps} />
                ) : activeModal === ReportModals.AGED_BALANCE_REPORT ? (
                    <AgedBalanceReportModal {...modalProps} />
                ) : activeModal === ReportModals.ASSET_REPORT ? (
                    <AssetReportModal {...modalProps} />
                ) : activeModal === ReportModals.DEBTOR_STATEMENT ? (
                    <DebtorStatementModal {...modalProps} />
                ) : activeModal === ReportModals.DETAILED_WORKFLOW ? (
                    <DetailedWorkflow {...modalProps} />
                ) : activeModal === ReportModals.INVOICE_BREAKDOWN ? (
                    <InvoiceBreakdownModal {...modalProps} />
                ) : activeModal === ReportModals.INVOICING_RECONCILIATION ? (
                    <InvoicingReconciliationModal {...modalProps} />
                ) : activeModal === ReportModals.OUTSTANDING_QUERIES ? (
                    <OutstandingQueriesModal {...modalProps} />
                ) : activeModal === ReportModals.QUERY_SUMMARY ? (
                    <QuerySummaryModal {...modalProps} />
                ) : activeModal === ReportModals.SUMMARISED_WORKFLOW ? (
                    <SummarisedWorkflowModal {...modalProps} />                
                ) : null}
            </Modal>
        </Card>
    );
};