import { FunctionComponent, useState } from 'react';
import { tableColumnsData, tableItemsData } from 'pages/Workflow/components/data/DashboardData';
import { Card, Modal } from 'components';
import {
	DashboardTableTypeEnum,
	GeneratedDataTable,
	GeneratedFilters,
	ITableFilters,
} from 'pages/Workflow/components/tables';
import { Stack } from '@fluentui/react';

interface Props {
	dashboardTableType: DashboardTableTypeEnum;
	isOpen: boolean;
	onDismiss: () => any;
}


export const GeneratedTableModal: FunctionComponent<Props> = ({ dashboardTableType, isOpen, onDismiss }) => {
	const initialFilters: ITableFilters = {
		page: 1,
		pageSize: 10,
		searchByName: '',
	};

	const [filters, setFilters] = useState<ITableFilters>(initialFilters);

	const getColumnsTableData = (filters: ITableFilters, dashboardTableType: DashboardTableTypeEnum): typeof tableColumnsData => {
		// !TODO do api magic and return columns data
		return tableColumnsData;
	};
	const columnsData = getColumnsTableData(filters, dashboardTableType);

	const getItemsTableData = (filters: ITableFilters, dashboardTableType: DashboardTableTypeEnum): typeof tableItemsData => {
		// !TODO do api magic and return table data
		return tableItemsData;
	};
	const itemsData = getItemsTableData(filters, dashboardTableType);

	return (
		<Modal isOpen={isOpen} onDismiss={onDismiss} title={columnsData.tableName}>
			<Stack tokens={{ childrenGap: 16 }}>
				<Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
					<Stack.Item shrink={0}>
						<Card>
							<Stack horizontal horizontalAlign='space-between'>
								<GeneratedFilters searchPlaceholder={columnsData.searchPlaceholder} setFilters={setFilters} />
							</Stack>
						</Card>
					</Stack.Item>
					<Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
						<GeneratedDataTable
							setFilters={setFilters}
							itemsData={itemsData}
							columnsData={columnsData}
							containerProps={{
								tokens: { childrenGap: 16 },
								styles: { root: { height: '100%' } },
							}}/>
					</Stack.Item>
				</Stack>
			</Stack>
		</Modal>
	);
};