import { Card, ControlledDropdown, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';

type GeneralTabType = {
    control: Control<ICreateUserCommand>;
};

export const GeneralTab = ({ control }: GeneralTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    const positionSelect = [
        { key: 'administrator', text: 'Administrator' },
        { key: 'auditor', text: 'Auditor' },
        { key: 'manager', text: 'Manager' },
        { key: 'partner', text: 'Partner' },
    ];

    const optionsSecurity = [
        { key: 'administrator', text: formatMessage({ id: 'administrator' }) },
        { key: 'auditor', text: formatMessage({ id: 'auditor' }) },
        { key: 'auditorCP', text: formatMessage({ id: 'auditorCP' }) },
        { key: 'superUser', text: formatMessage({ id: 'superUser' }) },
        { key: 'workflow', text: formatMessage({ id: 'workflow' }) },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='userNamePart'
                        label={formatMessage({ id: 'userName' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='emailPart'
                        label={formatMessage({ id: 'email' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='firstNamePart'
                        label={formatMessage({ id: 'firstName' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='positionPart'
                        label={formatMessage({ id: 'position' }) + '*'}
                        control={control}
                        options={positionSelect}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='lastNamePart'
                        label={formatMessage({ id: 'lastName' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown options={[]} name='team' label={formatMessage({ id: 'team' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={{ root: { width: 'calc(100% - 16px)' } }}>
                    <ControlledDropdown
                        options={optionsSecurity}
                        name='securitylevel'
                        label={formatMessage({ id: 'securitylevel' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
