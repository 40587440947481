import { IClientInfo } from '../interfaces/IClientInfo';

export const MockedClients: IClientInfo[] = [
    {
        clientGuid: '63d4cce8-794a-469e-b9c6-99f21a136bd9',
        name: "<script>alert('test xss 1')</script>",
    },
    {
        clientGuid: '552e89c8-fdba-4c48-a852-94905370d8ac',
        name: "<script>alert('test xss')</script>",
    },
    {
        clientGuid: '39b3037b-2c21-4144-af6f-a4873bcdae0f',
        name: 'BGL 360 Client v3.2',
    },
    {
        clientGuid: 'a73a1c87-47a7-484f-9525-73793a281db2',
        name: 'BGL SF Client',
    },
    {
        name: '<A A Tateossian Co>>',
        clientGuid: '9b48b651-95dc-e311-9029-02bf0a033e0b',
    },
    {
        name: '<Aspen Designs Pty Limited>',
        clientGuid: '90e0c5f6-6b73-e411-80bb-00155d00351e',
    },
    {
        name: '<Gunderson Briggs>',
        clientGuid: 'ee23165d-16df-e411-80bc-00155d00351f',
    },
    {
        name: '<Martin Lord & Co>',
        clientGuid: '0a60be0d-ea5f-e511-80bc-00155d00351f',
    },
    {
        name: '<Secombe & Coghill>',
        clientGuid: 'd5ce19d8-49cf-e311-9029-02bf0a033e0b',
    },
    {
        name: '<Stephen Ell Chartered Accountant>',
        clientGuid: 'dffa1f0d-74c3-e311-9029-02bf0a033e0b',
    },
    {
        name: '<Vitnell Partners Pty Ltd>',
        clientGuid: '7924c150-fd2b-e311-8988-02bf0a033e0b',
    },
    {
        name: '542 Partners',
        clientGuid: '78c21d76-94d1-e311-9029-02bf0a033e0b',
    },
    {
        name: 'A Squared Advisers',
        clientGuid: '77788cee-307e-e311-8988-02bf0a033e0b',
    },
    {
        name: 'Aaron Long Pty Ltd',
        clientGuid: '4af5b2cf-e849-e611-80c2-00155d00351f',
    },
    {
        name: 'ABA Accounting Pty Ltd',
        clientGuid: 'b520588c-c1f2-e411-80bc-00155d00351f',
    },
    {
        name: 'Account(able)',
        clientGuid: '6f0fb0a8-bc29-e511-80bc-00155d00351f',
    },
    {
        name: 'account.able advisory Pty Ltd',
        clientGuid: 'c999c2da-09d4-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Accounting IQ Pty Ltd',
        clientGuid: 'c9ed526f-c484-e411-80bc-00155d00351f',
    },
    {
        name: 'Accumen Accountants and Business Advisors',
        clientGuid: '6f5c9594-b82e-e711-80c3-00155d00351f',
    },
    {
        name: 'Adept Super',
        clientGuid: '513c3b07-ddb9-e711-80c3-00155d00351f',
    },
    {
        name: "Aitken O'Grady Chartered Accountants",
        clientGuid: '003cef4d-a155-e211-8b0d-00155d003518',
    },
    {
        name: 'Aitken Signor Chartered Accountants',
        clientGuid: '0f7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'AJW Miles and Son',
        clientGuid: 'b314d6f1-5787-e611-80c2-00155d00351f',
    },
    {
        name: 'AlexTestClient',
        clientGuid: '97f8e566-24fa-e911-a813-000d3ad1cd5b',
    },
    {
        name: 'Allworths Chartered Accountants',
        clientGuid: '7c3f7cca-8835-e511-80bc-00155d00351f',
    },
    {
        name: 'Altitude Business Group',
        clientGuid: '0e7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Archer Gowlandxx',
        clientGuid: 'c0ee51fe-624b-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Armstrong Advisory Pty Ltd',
        clientGuid: 'c9d40b7e-963c-e611-80c2-00155d00351f',
    },
    {
        name: 'Asun Superannuation Fund',
        clientGuid: '1c13c509-88ed-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Audits Pty Ltd',
        clientGuid: '59195f91-502b-e511-80bc-00155d00351f',
    },
    {
        name: "Austin's Chartered Accountant",
        clientGuid: '8b1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Australasian Taxation Services',
        clientGuid: 'e660aa25-fe65-e511-80bc-00155d00351f',
    },
    {
        name: 'Batterham & Associates',
        clientGuid: 'e5dd5009-bb27-e211-b870-02bf0a033e0b',
    },
    {
        name: 'Bell Direct',
        clientGuid: '40c85701-7276-e311-8988-02bf0a033e0b',
    },
    {
        name: 'Bell Partners Qld',
        clientGuid: 'c8940a6e-eab1-e611-80c2-00155d00351f',
    },
    {
        name: 'Bell Potter Super Solutions',
        clientGuid: '4db13fc4-1211-e311-8988-02bf0a033e0b',
    },
    {
        name: 'Berry Actuarial Planning',
        clientGuid: '0d7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'BGL Test & Client Louise (Grey)',
        clientGuid: '81616a08-faac-e511-80be-00155d00351e',
    },
    {
        name: 'BGL Test Client 2',
        clientGuid: '0c78764d-5054-e611-80c2-00155d00351f',
    },
    {
        name: 'BGL360 Testing API Admin flow',
        clientGuid: '0b550c49-5068-e711-80c3-00155d00351f',
    },
    {
        name: 'BGL360 Testing API Black flow',
        clientGuid: 'cf296f0f-5068-e711-80c3-00155d00351f',
    },
    {
        name: 'BGL360 Testing API Grey flow test 1',
        clientGuid: 'c835e300-5168-e711-80c3-00155d00351f',
    },
    {
        name: 'BMR Tax Advisory',
        clientGuid: 'a912e385-bd56-ea11-a811-000d3ad1cd5b',
    },
    {
        name: 'BMR Yensch',
        clientGuid: '901d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Boyce Chartered Accountants',
        clientGuid: '9c18610b-83f5-e111-b870-02bf0a033e0b',
    },
    {
        name: 'C A Hill & Associates',
        clientGuid: 'a8f12989-49ca-e711-80c3-00155d00351f',
    },
    {
        name: 'Capecat Pty Ltd',
        clientGuid: 'a8a6a374-24e3-e011-b198-02bf0a033e0b',
    },
    {
        name: "Champion's Business Growth Advisors",
        clientGuid: '55c6fb89-40b6-e411-80bc-00155d00351f',
    },
    {
        name: 'Charlie Shen & Co',
        clientGuid: '8f8e610d-ad2f-e211-b870-02bf0a033e0b',
    },
    {
        name: 'Chrysanthos Superannuation Fund No.1',
        clientGuid: '2fe6f28a-3d34-e211-9f8a-02bf0a033e0b',
    },
    {
        name: 'Class PDF Clientxxss',
        clientGuid: '56701d86-6769-e711-80c3-00155d00351f',
    },
    {
        name: 'Class Testing API Admin flow',
        clientGuid: 'f45e8798-4c68-e711-80c3-00155d00351f',
    },
    {
        name: 'Class Testing API Black Flowxx',
        clientGuid: 'e4aae9fe-4b68-e711-80c3-00155d00351f',
    },
    {
        name: 'Class Testing API Grey Flow',
        clientGuid: '20e2f7ce-4c68-e711-80c3-00155d00351f',
    },
    {
        name: 'Client Presentation',
        clientGuid: '1bd5ac9e-7fe4-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Cohen Fasciani',
        clientGuid: '1765785c-39e1-e711-80c3-00155d00351f',
    },
    {
        name: 'Colombo - Training',
        clientGuid: 'a506ea5a-6623-e411-80bb-00155d00351e',
    },
    {
        name: 'Colombo - Training Client',
        clientGuid: 'be051970-8efd-e311-80bb-00155d00351e',
    },
    {
        name: 'Compass Professional Advisers',
        clientGuid: 'a4cae91a-aa58-e811-80c4-00155d00351f',
    },
    {
        name: 'Copp, Diment & Co',
        clientGuid: 'c6b0e9cd-acca-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Cotter Accountants',
        clientGuid: 'f87d5e7e-d8b7-e411-80bc-00155d00351f',
    },
    {
        name: 'Crest Financial Pty Ltd',
        clientGuid: '520a9bfd-92a9-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Cross Script',
        clientGuid: '812e6c28-4cfd-ea11-a813-000d3ad1cd5b',
    },
    {
        name: 'Crowe Horwath (Aust) Pty Ltd',
        clientGuid: 'b28ed22e-9f32-e411-80bb-00155d00351e',
    },
    {
        name: 'Crowe Horwath (Aust) Pty Ltd - Albury',
        clientGuid: '3abaf528-2fcf-e711-80c3-00155d00351f',
    },
    {
        name: 'Custom Accounting',
        clientGuid: '27e2d233-04ac-e211-a0a5-00155d3ffa03',
    },
    {
        name: 'D R Gow & Co',
        clientGuid: '961d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Daley & Co Chartered Accountants',
        clientGuid: 'b2cd01d2-4953-e011-b5a0-02bf0a033e0b',
    },
    {
        name: 'Dana Pty Ltd',
        clientGuid: '827a931c-967e-e511-80bc-00155d00351f',
    },
    {
        name: 'Daniel Fleischer',
        clientGuid: 'b9f4c772-2f5c-e111-bac0-02bf0a033e0b',
    },
    {
        name: 'David Costigan',
        clientGuid: '1aa6acb2-1a9d-e511-80be-00155d00351e',
    },
    {
        name: 'David J Gibney Pty Ltd',
        clientGuid: 'fc7df965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Davidson White Accountants',
        clientGuid: '1e018a33-212b-e811-80c3-00155d00351f',
    },
    {
        name: 'Demo Client',
        clientGuid: '1933ef0a-c6cb-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Dennis J Klein',
        clientGuid: 'c41d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Drew Robinson Pty Ltd',
        clientGuid: 'e6d5ca80-90cc-e111-b870-02bf0a033e0b',
    },
    {
        name: 'DT_6360test_client',
        clientGuid: '2d017a8d-daf9-eb11-94ef-0022480ff439',
    },
    {
        name: 'Dueo Pty Ltd',
        clientGuid: '6a4f9cdf-f658-e411-80bb-00155d00351e',
    },
    {
        name: 'E.P. Superannuation Fund',
        clientGuid: '981d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Eagle Financial Pty Ltd',
        clientGuid: 'f1b185e7-f7bf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'EG_CLIENT_CLASS_TEST',
        clientGuid: '7bfade2b-b093-ec11-b400-00224811d1fc',
    },
    {
        name: 'EJC Advisory',
        clientGuid: '95843f00-5d6a-e611-80c2-00155d00351f',
    },
    {
        name: 'Ellice-Flint & Co Chartered Accountants',
        clientGuid: 'f1d203f3-6ff7-df11-b3d1-02bf0a033e0b',
    },
    {
        name: 'Emma Griffin Chartered Accountant',
        clientGuid: '8f04ec97-38d4-e411-80bc-00155d00351f',
    },
    {
        name: 'ESV',
        clientGuid: 'd5c50931-6f9e-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Evolv Pty Ltd',
        clientGuid: '5305707c-c6c0-e111-b870-02bf0a033e0b',
    },
    {
        name: 'G Henderson & Co',
        clientGuid: '207ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Garbuz Superannuation Fund',
        clientGuid: 'fd7df965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Gascoigne Consulting',
        clientGuid: 'fe7df965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'GDA Advisory Pty Ltd',
        clientGuid: 'eb5d2763-7085-e211-a0a5-00155d3ffa03',
    },
    {
        name: 'GHS Super Solutions Pty Ltd',
        clientGuid: '3184e698-e78b-e611-80c2-00155d00351f',
    },
    {
        name: 'Grant Thornton',
        clientGuid: '14e155b6-4f78-e511-80bc-00155d00351f',
    },
    {
        name: 'Grant Thornton Cairns',
        clientGuid: '4b580b14-f7c7-e711-80c3-00155d00351f',
    },
    {
        name: 'GrowUp Group',
        clientGuid: '50eb327e-f825-e611-80c1-00155d00351f',
    },
    {
        name: 'Healy Superannuation Fund',
        clientGuid: 'a01d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Heffron Advisers',
        clientGuid: 'f3f99e4c-a4cf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Heffron SMSF Solutions',
        clientGuid: 'b0d78805-1ab7-e411-80bc-00155d00351f',
    },
    {
        name: 'Henderson Edelstein & Co',
        clientGuid: 'f409c40c-15a4-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Henderson McColl and Co',
        clientGuid: '3dd24127-1d95-e311-9029-02bf0a033e0b',
    },
    {
        name: 'HHB Super Fund',
        clientGuid: '69f59adb-7ffc-e511-80c1-00155d00351f',
    },
    {
        name: 'HLB Mann Judd',
        clientGuid: '7e050562-82ed-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Hooper Financial Management Pty Ltd',
        clientGuid: '8843ede5-045a-e211-8b0d-00155d003518',
    },
    {
        name: 'Howse Pilloni & Co',
        clientGuid: 'ff7df965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Ian Henderson Accountant',
        clientGuid: '1f7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'In 2 Business Pty Ltd',
        clientGuid: '247ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'IPG Advisors',
        clientGuid: '573259e7-4cdc-e511-80bf-00155d00351e',
    },
    {
        name: 'J W Kellert & Co',
        clientGuid: 'a11d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Jacoby Cameron & Co',
        clientGuid: '456899f8-35dd-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Jigsaw Advisory',
        clientGuid: '37d21625-5ae5-e211-8b0d-00155d003518',
    },
    {
        name: 'John L Wickenden',
        clientGuid: 'd4de3fbc-fd23-e311-8988-02bf0a033e0b',
    },
    {
        name: 'Just Super',
        clientGuid: '3a69249e-6c10-e311-8988-02bf0a033e0b',
    },
    {
        name: 'Kathleen Portelli Pty Limited',
        clientGuid: 'd406413f-d7b7-e411-80bc-00155d00351f',
    },
    {
        name: 'Kelly Partners (South West Sydney) Pty Ltd',
        clientGuid: '087ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Kennedy Barnden Chartered Accountants',
        clientGuid: 'fa298c8d-bf5e-e211-8b0d-00155d003518',
    },
    {
        name: 'Kennedy Family Superannuation Fund',
        clientGuid: 'a51d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Kirkby Jones Financial Group',
        clientGuid: 'a61d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'KMS Financial Solutions Pty Ltd',
        clientGuid: 'e1ad3d4c-d60a-e311-bbba-02bf0a033e0b',
    },
    {
        name: 'KMT Partners',
        clientGuid: '8f87a969-20ae-eb11-8236-000d3a6acafc',
    },
    {
        name: 'KMT Partners',
        clientGuid: 'a631e475-7989-e811-a95a-000d3ad24c60',
    },
    {
        name: 'Lazarus Superannuation Fund',
        clientGuid: '1493c058-2103-e011-b3d1-02bf0a033e0b',
    },
    {
        name: 'LBW Partners',
        clientGuid: 'c0f60be7-1680-e411-80bc-00155d00351f',
    },
    {
        name: 'Les Ellis & Associates Pty',
        clientGuid: '89df5219-fe08-e211-b870-02bf0a033e0b',
    },
    {
        name: 'Levy Warren Associates',
        clientGuid: '400a4792-ca56-ea11-a811-000d3ad1cd5b',
    },
    {
        name: 'LNP Audit and Assurance',
        clientGuid: '278f477d-3ebc-e611-80c2-00155d00351f',
    },
    {
        name: 'Logicca Pty Ltd',
        clientGuid: 'ab1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Lonnon & Co',
        clientGuid: '4c830399-8efa-e511-80c1-00155d00351f',
    },
    {
        name: 'Lotus Accountants Pty Ltd',
        clientGuid: 'fd965d9b-bf8c-e511-80bd-00155d00351f',
    },
    {
        name: 'Louise Desktop Test Client',
        clientGuid: 'fa4d6f69-0eae-e511-80be-00155d00351e',
    },
    {
        name: 'Louise Test Class',
        clientGuid: 'b230a172-b6ad-e511-80be-00155d00351e',
    },
    {
        name: 'Luka Group',
        clientGuid: 'ae1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Lumina Chartered Accountants',
        clientGuid: '2362a1f5-f1dc-e411-80bc-00155d00351f',
    },
    {
        name: 'M+S Group',
        clientGuid: 'd64bf59e-74bf-e711-80c3-00155d00351f',
    },
    {
        name: 'Maitland Accountancy Services',
        clientGuid: '037ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Martin Lord & Co',
        clientGuid: '047ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'McKinley Plowman & Associates',
        clientGuid: '047eab3e-3e86-e411-80bc-00155d00351f',
    },
    {
        name: 'McMahon Family Superannuation Fund',
        clientGuid: 'dccdb581-210a-e811-80c3-00155d00351f',
    },
    {
        name: 'MDH Partners',
        clientGuid: '057ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Milanese & Co Accountants',
        clientGuid: 'c6d703e8-f8de-e711-80c3-00155d00351f',
    },
    {
        name: 'Milham & Davis Accountants',
        clientGuid: '5b2fa6aa-df27-e811-80c3-00155d00351f',
    },
    {
        name: 'Morse Accounting Services - Bathurst',
        clientGuid: 'ad1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Munro Spaul',
        clientGuid: 'c6ce3e1c-7c89-e811-a95a-000d3ad24c60',
    },
    {
        name: 'Navacue Accountants',
        clientGuid: 'b1893e93-c667-e511-80bc-00155d00351f',
    },
    {
        name: 'Navwealth Accounting Pty Ltd',
        clientGuid: '62378ea7-476a-e011-bbd8-02bf0a033e0b',
    },
    {
        name: 'New Wealth Solutions',
        clientGuid: 'e3f4c54b-c07a-e411-80bc-00155d00351f',
    },
    {
        name: 'O.S. Financial Planning',
        clientGuid: '65f7a471-b60c-e611-80c1-00155d00351f',
    },
    {
        name: 'Other Client',
        clientGuid: '39982c57-310b-ea11-a811-000d3ad1c2b4',
    },
    {
        name: 'Pitcher Partners Sydney',
        clientGuid: 'f829991f-7c4a-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Platinum Associates',
        clientGuid: 'b21d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'PPF Accounting Services Pty Limited',
        clientGuid: 'af1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Price Williams Whyte Combination',
        clientGuid: '19233720-601e-e211-9f8a-02bf0a033e0b',
    },
    {
        name: 'Prior & Co Pty Ltd',
        clientGuid: '9d0bcf0d-fcea-e511-80c0-00155d00351e',
    },
    {
        name: 'Provincial Accountants',
        clientGuid: 'b61d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Quest Chartered Accountants',
        clientGuid: '02673595-96cd-e711-80c3-00155d00351f',
    },
    {
        name: 'Rapattoni and Company',
        clientGuid: '21aca959-b5a8-e711-80c3-00155d00351f',
    },
    {
        name: 'Revolution Advisors Pty Ltd',
        clientGuid: 'c8acbff5-4b6d-e511-80bc-00155d00351f',
    },
    {
        name: 'Richard Beck & Co Pty Ltd',
        clientGuid: '137ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Roach Morgan-Jones',
        clientGuid: '83683858-0433-e411-80bb-00155d00351e',
    },
    {
        name: 'Robert Nielson Partners',
        clientGuid: 'f7f07502-92d9-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Roman 2',
        clientGuid: 'd96cefa5-aeac-e511-80be-00155d00351e',
    },
    {
        name: 'Roman Test Client ClassSuper',
        clientGuid: 'dd3374be-06b9-e511-80be-00155d00351e',
    },
    {
        name: 'Roman Test Client Supermate',
        clientGuid: '28644b51-fcc8-e511-80be-00155d00351e',
    },
    {
        name: 'Rome King Roman',
        clientGuid: 'c65668dd-5a5c-e911-a96d-000d3ad244fd',
    },
    {
        name: 'Samuel Hoare',
        clientGuid: 'f731b7ce-272b-e711-80c3-00155d00351f',
    },
    {
        name: 'Scott Associates',
        clientGuid: '0376efc9-7724-e711-80c3-00155d00351f',
    },
    {
        name: 'Selby Watson & Co',
        clientGuid: '2bd5e991-ec01-e611-80c1-00155d00351f',
    },
    {
        name: 'Shane Roxby Accountants',
        clientGuid: '0b7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Skeggs Goldstien Accounting Pty Ltd',
        clientGuid: 'd0aaf066-9dd0-e711-80c3-00155d00351f',
    },
    {
        name: 'Smith Coffey (Direct)',
        clientGuid: '7582bae8-6dee-e611-80c2-00155d00351f',
    },
    {
        name: 'SMSF Administration Solutions Pty Ltd',
        clientGuid: '8837535a-f72a-e311-8988-02bf0a033e0b',
    },
    {
        name: 'SMSF Compliance Services',
        clientGuid: '6009b06f-8a6b-e511-80bc-00155d00351f',
    },
    {
        name: 'SMSF Consulting',
        clientGuid: '05a81748-c38c-e511-80bd-00155d00351f',
    },
    {
        name: 'Star & Associates',
        clientGuid: 'ba1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Steve Chadwick Accounting',
        clientGuid: '79023af4-4655-e711-80c3-00155d00351f',
    },
    {
        name: 'Steven Vrettos & Co',
        clientGuid: 'e1779c0d-8fd5-e311-9029-02bf0a033e0b',
    },
    {
        name: 'Sundaram Business Services Limited',
        clientGuid: '3e3b59e9-7e5c-e311-9029-02bf0a033e0b',
    },
    {
        name: 'SuperGuardian',
        clientGuid: '06c2dca4-2814-e211-b870-02bf0a033e0b',
    },
    {
        name: 'SuperGuardian 2P',
        clientGuid: '99bdbbb6-d518-e711-80c3-00155d00351f',
    },
    {
        name: 'Supermate Test Client Louise',
        clientGuid: 'cbc2d858-0cad-e511-80be-00155d00351e',
    },
    {
        name: 'Supervision Superannuation Services',
        clientGuid: '3acd8583-4be8-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Sutherland Reid & Farrar Services Pty Ltd',
        clientGuid: 'c20a5cdd-6fd0-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Symes Accountants',
        clientGuid: '7430652c-bd29-e511-80bc-00155d00351f',
    },
    {
        name: 'Synstrat Superannuation',
        clientGuid: '4dc4451b-06d6-e411-80bc-00155d00351f',
    },
    {
        name: 'Taggart Partners',
        clientGuid: '097ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Test Client 05',
        clientGuid: '6e04389f-a909-ea11-a811-000d3ad1caaa',
    },
    {
        name: 'The Diligent Group Pty Ltd',
        clientGuid: '02c36da5-72ed-e611-80c2-00155d00351f',
    },
    {
        name: 'The Jaques Stanmore Financial Group',
        clientGuid: 'a21d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Tricor ChewandDormers',
        clientGuid: 'a81d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Twomeys Cootamundra',
        clientGuid: '61f9f465-3a6d-e511-80bc-00155d00351f',
    },
    {
        name: 'Twomeys Partners',
        clientGuid: '442e961b-3bbb-e611-80c2-00155d00351f',
    },
    {
        name: 'Twomeys Wagga Wagga',
        clientGuid: '1a589fb8-396d-e511-80bc-00155d00351f',
    },
    {
        name: 'UX Change Client',
        clientGuid: 'fbbde7bf-43a5-ea11-a812-000d3ad1cf4f',
    },
    {
        name: 'Vincents',
        clientGuid: '70a93ca4-3394-e711-80c3-00155d00351f',
    },
    {
        name: 'Vizer Financial Pty Ltd',
        clientGuid: 'a21035d0-d428-e811-80c3-00155d00351f',
    },
    {
        name: 'Volcov Business advisory',
        clientGuid: '7539d928-8393-e311-9029-02bf0a033e0b',
    },
    {
        name: 'W R Glassman & Co',
        clientGuid: '7b18feba-f8af-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'W W Vick & Co.',
        clientGuid: 'bf1d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'WAI Accounting Services Pty Ltd',
        clientGuid: '4879de9f-9959-e611-80c2-00155d00351f',
    },
    {
        name: 'Watermark Retirement Savings Fund',
        clientGuid: 'c01d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Wearne & Co',
        clientGuid: 'c21d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'West Superannuation Fund',
        clientGuid: 'c31d1ffa-969b-df11-a9a9-02bf0a033e0b',
    },
    {
        name: 'Weston Woodley & Robertson Chartered Accountants & Consultants',
        clientGuid: 'da4cb625-aadc-df11-b5a0-02bf0a033e0b',
    },
    {
        name: 'Williamson & Chaseling',
        clientGuid: '04ed8fae-efe0-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Wrights',
        clientGuid: 'd4900652-a12b-e711-80c3-00155d00351f',
    },
    {
        name: 'Wynn & Bennett Chartered Accountants',
        clientGuid: '0a7ef965-febf-e111-b870-02bf0a033e0b',
    },
    {
        name: 'Xpress Super',
        clientGuid: '04b8167c-7325-e311-8988-02bf0a033e0b',
    },
    {
        name: 'YuriBulkTestClient',
        clientGuid: '928a1090-85f9-e911-a813-000d3ad1c2b4',
    },
];
