import { IconButton, IDropdownOption, Stack } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import * as React from 'react';
import { UseFieldArrayReturn, useForm } from 'react-hook-form';
import { ControlledDropdown, ControlledNumberField } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { AddInvoiceForm } from './InvoiceAddForm';

interface Props {
    extraAmountItems: UseFieldArrayReturn<AddInvoiceForm, 'extraAmounts', 'id'>;
}

interface AddExtraAmountForm {
    amount?: number;
    invoiceParagraph?: string;
}

export const InvoiceAddFormExtraAmount: FunctionComponent<Props> = ({ extraAmountItems }: Props) => {
    const { control, handleSubmit } = useForm<AddExtraAmountForm>(DefaultFormSettings);
    const { formatMessage, formatNumber } = useIntl();

    const invoiceParagraphOptions: IDropdownOption[] = [
        { key: 'DD', text: 'DD' },
        { key: 'Standard', text: 'Standard' },
    ];

    const handleAdd = () => {
        handleSubmit(
            (data) => {
                extraAmountItems.append(data);
            },
            (err) => {
                console.log(err);
            }
        )();
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }} wrap>
            <Stack.Item styles={{ root: { width: 'calc((100% - 24px - 32px) / 2)' } }}>
                <ControlledNumberField
                    name='amount'
                    control={control}
                    rules={{ required: 'Amount is required' }}
                    label={formatMessage({ id: 'amount' })}
                />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: 'calc((100% - 24px - 32px) / 2)' } }}>
                <ControlledDropdown
                    name='invoiceParagraph'
                    control={control}
                    rules={{ required: 'Invoice Paragraph is required' }}
                    label={formatMessage({ id: 'invoiceParagraph' })}
                    options={invoiceParagraphOptions}
                />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: '32px' } }} align='end'>
                <IconButton iconProps={{ iconName: 'Add' }} onClick={handleAdd} />
            </Stack.Item>

            {extraAmountItems.fields.length
                ? extraAmountItems.fields.map((item, index) => (
                      <React.Fragment key={`extra-amount-${index}`}>
                          <Stack.Item
                              styles={{ root: { width: 'calc((100% - 24px - 32px) / 2)', boxSizing: 'border-box', paddingLeft: 5 } }}>
                              {formatNumber(item.amount ? +item.amount : 0, { style: 'currency', currency: 'USD' })}
                          </Stack.Item>
                          <Stack.Item
                              key={`extra-amount-invoiceParagraph-${index}`}
                              styles={{ root: { width: 'calc((100% - 24px - 32px) / 2)', boxSizing: 'border-box', paddingLeft: 5 } }}>
                              {item.invoiceParagraph}
                          </Stack.Item>
                          <Stack.Item key={`extra-amount-actions-${index}`} styles={{ root: { width: '32px' } }} align='end'>
                              <IconButton iconProps={{ iconName: 'Remove' }} onClick={() => extraAmountItems.remove(index)} />
                          </Stack.Item>
                      </React.Fragment>
                  ))
                : null}
        </Stack>
    );
};
