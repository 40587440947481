import { FunctionComponent, useMemo, useState } from 'react';
import { IColumn, Icon, SelectionMode, Text, useTheme } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useBoolean } from '@fluentui/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import { Card, DataTable, ItemDeletedStatus, Modal } from 'components';
import { IUserInfo } from 'interfaces';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { UserManagementActions } from './UserManagementActions';
import { UserManagementClients } from './UserManagementClients';
import { useUserManagement } from '../UserManagementPage';
import { UserManagementAddUserForm } from './UserManagementAddUserForm';
import { IUser } from '../hooks/useGetUsersFilter';

interface Props {
    reset: Function;
    isLoading: boolean;
    users: IUser[];
}

export const UserManagementDataTable: FunctionComponent<Props> = ({ reset, isLoading, users }) => {
    const { pagination: { pageSize }, setSort } = useUserManagement();
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);

    const [columns] = useState<IColumn[]>([
        {
            key: 'userName',
            name: formatMessage({ id: 'userName' }),
            minWidth: 100,
            maxWidth: 300,
            fieldName: 'username',
            onRender: (item: IUserInfo) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item.username}
                </Text>
            ),
        },
        {
            key: 'firstName',
            name: formatMessage({ id: 'firstName' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'firstName',
        },
        {
            key: 'lastName',
            name: formatMessage({ id: 'lastName' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'lastName',
        },
        {
            key: 'displayName',
            name: formatMessage({ id: 'displayName' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'displayName',
        },
        {
            key: 'email',
            name: formatMessage({ id: 'emailAddress' }),
            minWidth: 150,
            fieldName: 'email',
        },
        {
            key: 'clients',
            name: formatMessage({ id: 'clients' }),
            minWidth: 50,
            maxWidth: 50,
            className: 'centred',
            onRender: (item) => <UserManagementClients item={item} />,
        },
        {
            key: 'superUser',
            name: formatMessage({ id: 'superUser' }),
            minWidth: 70,
            maxWidth: 70,
            fieldName: 'isSuperUser',
            className: 'centred',
            onRender: (item: IUserInfo) =>
                item.isSuperUser ? <Icon iconName='CheckMark' styles={{ root: { fontSize: 16, color: theme.palette.green } }} /> : null,
        },
        {
            key: 'status',
            name: formatMessage({ id: 'status' }),
            minWidth: 70,
            maxWidth: 150,
            fieldName: 'isDeleted',
            onRender: (item) => <ItemDeletedStatus item={item} />,
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => <UserManagementActions item={item} />,
        },
    ]);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'add' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleIsAddModalOpen,
            },
            {
                key: 'refresh',
                disabled: isLoading,
                text: formatMessage({ id: 'refresh' }),
                iconProps: { iconName: 'Refresh' },
                iconOnly: true,
                onClick: () => { reset() },
            },
        ],
        [isLoading, reset, toggleIsAddModalOpen, formatMessage]
    );

    return (
        <Card styles={CardTableContainerStyles}>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
            />
            <DataTable
                sortableColumnKeys={['userName', 'firstName', 'lastName', 'displayName', 'email']}
                initialColumns={columns}
                items={cloneDeep(users)}
                shimmerLines={pageSize}
                enableShimmer={isLoading}
                setOrder={setSort}
                selectionMode={SelectionMode.none}
                containerHeight='calc(100% - 32px)'
            />

            <Modal isOpen={isAddModalOpen} onDismiss={toggleIsAddModalOpen} title={<FormattedMessage id='addNewUser' />}>
                <UserManagementAddUserForm onDismiss={toggleIsAddModalOpen} />
            </Modal>
        </Card>
    );
};
