import { Card, ControlledDatePicker } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const PreARQueriesTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='preARQueriesSent'
                        label={formatMessage({ id: 'preARQueriesSent' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='preARQueriesResponse'
                        label={formatMessage({ id: 'preARQueriesResponse' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='reviewedOSQueries'
                        label={formatMessage({ id: 'reviewedOSQueries' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
