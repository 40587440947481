import * as React from 'react';
import { FunctionComponent } from 'react';
import {
    CheckboxVisibility,
    DetailsRow,
    DetailsRowCheck,
    IColumn,
    IDetailsFooterProps,
    IRenderFunction,
    SelectionMode,
    Sticky,
    Text,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { IDetailsRowCheckProps } from '@fluentui/react/lib/components/DetailsList/DetailsRowCheck.types';
import { formatIdNumber } from 'utils/formatIdNumber';
import { IInvoice } from 'interfaces';
import { DataTable } from 'components';
import { IDetailsHeaderProps } from '@fluentui/react/dist/react';

interface Props {
    invoices: IInvoice[];
    selection: Selection;
    total?: number;
    jobsSelectedCount: number;
    loading?: boolean;
}

export const SelectInvoicesDataTable: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage, formatNumber } = useIntl();

    const columns: IColumn[] = [
        {
            key: 'invoiceNumber',
            name: formatMessage({ id: 'invoiceNo' }),
            fieldName: 'id',
            minWidth: 70,
            maxWidth: 90,
            onRender: (item?: IInvoice, index?: number) => (
                <Text variant={item && index !== -1 ? 'medium' : 'small'} styles={{ root: { fontWeight: 500 } }}>
                    {item && index !== -1 ? formatIdNumber(item.id) : `${props.jobsSelectedCount} jobs selected`}
                </Text>
            ),
            isMultiline: true,
        },
        {
            key: 'jobName',
            name: formatMessage({ id: 'job' }),
            fieldName: 'jobName',
            minWidth: 50,
            maxWidth: 135,
            isMultiline: true,
        },
        {
            key: 'fundName',
            name: formatMessage({ id: 'fund' }),
            fieldName: 'fundName',
            minWidth: 90,
            maxWidth: 180,
            isMultiline: true,
        },
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 80,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' }) : null,
        },
        {
            key: 'extraAmount',
            name: formatMessage({ id: 'extraAmount' }),
            fieldName: 'extraAmount',
            minWidth: 115,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? formatNumber(item.extraAmount ?? 0, { style: 'currency', currency: 'USD' }) : null,
        },
        {
            key: 'total',
            name: formatMessage({ id: 'total' }),
            fieldName: 'total',
            minWidth: 55,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? (
                    formatNumber(item.total ?? 0, { style: 'currency', currency: 'USD' })
                ) : index === -1 ? (
                    <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                        {formatNumber(props.total ?? 0, { style: 'currency', currency: 'USD' })}
                    </Text>
                ) : null,
        },
    ];

    const items: Array<IInvoice | null> = props.invoices;

    if (props.loading && items.length < 10) {
        for (let i = 0; i < 10 - items.length; i++) items.push(null);
    }

    const tableStyles = {
        root: {
            background: 'red',
            // selectors: {
            //     '.contentWrapper ~ &': {
            //         maxHeight: 320,
            //     },
            // },
        },
    };

    return (
        <DataTable
            containerHeight={320}
            initialColumns={columns}
            enableShimmer={!props.invoices.length}
            items={items}
            selectionMode={SelectionMode.multiple}
            setKey={'multiple'}
            isHeaderVisible={true}
            selection={props.selection}
            selectionPreservedOnEmptyClick
            enterModalSelectionOnTouch
            checkboxVisibility={CheckboxVisibility.always}
            styles={tableStyles}
            onRenderDetailsFooter={(detailsFooterProps?: IDetailsFooterProps) => (
                <DetailsRow
                    {...detailsFooterProps}
                    columns={detailsFooterProps?.columns}
                    item={{}}
                    itemIndex={-1}
                    groupNestingDepth={detailsFooterProps?.groupNestingDepth}
                    selectionMode={SelectionMode.single}
                    selection={detailsFooterProps?.selection}
                    onRenderCheck={(checkProps: IDetailsRowCheckProps) => (
                        <DetailsRowCheck {...checkProps} selected={false} canSelect={false} />
                    )}
                />
            )}
        />
    );
};
