import * as React from 'react';
import {FunctionComponent} from 'react';
import {Pivot, PivotItem, Stack} from '@fluentui/react';
import {RulesetNameForm} from './components/RulesetNameForm';
import {RulesetRulesDataTable} from './Rules/components/RulesetRulesDataTable';
import {Card} from "../../../components";
import { observer } from 'mobx-react';
import {useIntl} from "react-intl";
import {Variables} from "./GlobalVariables/components/Variables";


export const RulesetPage: FunctionComponent = observer(() => {

    const intl = useIntl();


    return (
        <Stack tokens={{childrenGap: 16}} >
        <Stack.Item shrink={0}>
            <RulesetNameForm/>
        </Stack.Item>
        <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
            <Card styles={{root: {marginBottom: 16}}}>
                <Pivot linkSize="large" styles={{link: {width: '50%'}, itemContainer: {overflow: "auto"}}}>
                    <PivotItem headerText={intl.formatMessage({ id: 'rules' })}>
                        <RulesetRulesDataTable/>
                    </PivotItem>
                    <PivotItem headerText={intl.formatMessage({ id: 'variables' })}>
                        <Variables/>
                    </PivotItem>
                </Pivot>
            </Card>
        </Stack.Item>
    </Stack>
    );
});
