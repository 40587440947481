import { FunctionComponent } from 'react';
import { IParamInfo } from 'interfaces';
import { observer } from 'mobx-react';
import { Control } from 'react-hook-form/dist/types/form';
import { FormState } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import * as React from 'react';
import { ControlledMonacoEditor, ControlledTextField } from 'components';
import { useIntl } from 'react-intl';

interface Props {
    control: Control<IParamInfo>;
    onSubmit: () => void;
    formState: FormState<IParamInfo>;
}

export const VariableForm: FunctionComponent<Props> = observer(({ control, onSubmit, formState }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <ControlledTextField
                    name='name'
                    control={control}
                    rules={{ required: formatMessage({ id: 'requiredVariableName' }) }}
                    label={formatMessage({ id: 'variableName' })}
                    styles={{
                        wrapper: { display: 'block', label: { paddingLeft: 0, height: 'auto' } },
                    }}
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledMonacoEditor
                    label={formatMessage({ id: 'variableExpression' })}
                    name='expression'
                    control={control}
                    rules={{ required: formatMessage({ id: 'requiredVariableExpression' }) }}
                    height={80}
                />
            </Stack.Item>
        </Stack>
    );
});
