type Items = {
    id: number;
    active: boolean;
    fund: string;
    clientName: string;
    year: string;
    auditAllocated: string;
    partner: string;
    dateReceived: string;
    dateArSent: string;
    daysIn: string;
    clientId: number;
    fundId: number;
    yearId: number;
    auditAllocatedId: number;
    partnerId: number;
    uid: string;
}

type Data = {
    count: number;
    items:Items[];
    pageNumber: number;
    pageSize: number;
}



export const jobsData: Data = {
    count: 24,
    items: [
        { id: 1, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 1, yearId: 1, active: false, fund: 'Test_Mocked_Fund_1', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 2, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 1, yearId: 1, active: false, fund: 'Test_Mocked_Fund_1', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 3, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 1, yearId: 1, active: false, fund: 'Test_Mocked_Fund_1', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 4, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 2, yearId: 1, active: false, fund: 'Test_Mocked_Fund_2', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 5, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 2, yearId: 1, active: false, fund: 'Test_Mocked_Fund_2', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 6, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 1, fundId: 2, yearId: 1, active: false, fund: 'Test_Mocked_Fund_2', year: '2020', clientName: 'Test_Mocked_Client_1', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 7, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 3, yearId: 1, active: false, fund: 'Test_Mocked_Fund_3', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 8, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 3, yearId: 1, active: false, fund: 'Test_Mocked_Fund_3', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 9, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 3, yearId: 1, active: false, fund: 'Test_Mocked_Fund_3', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 10, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 4, yearId: 1, active: false, fund: 'Test_Mocked_Fund_4', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 11, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 4, yearId: 1, active: false, fund: 'Test_Mocked_Fund_4', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 12, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 1, auditAllocatedId: 1, clientId: 2, fundId: 4, yearId: 1, active: true, fund: 'Test_Mocked_Fund_4', year: '2020', clientName: 'Test_Mocked_Client_2', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_1', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 13, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 5, yearId: 2, active: true, fund: 'Test_Mocked_Fund_5', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 14, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 5, yearId: 2, active: true, fund: 'Test_Mocked_Fund_5', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 15, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 5, yearId: 2, active: true, fund: 'Test_Mocked_Fund_5', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 16, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 6, yearId: 2, active: true, fund: 'Test_Mocked_Fund_6', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 17, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 6, yearId: 2, active: true, fund: 'Test_Mocked_Fund_6', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 18, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 3, fundId: 6, yearId: 2, active: true, fund: 'Test_Mocked_Fund_6', year: '2021', clientName: 'Test_Mocked_Client_3', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 19, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 7, yearId: 2, active: true, fund: 'Test_Mocked_Fund_7', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 20, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 7, yearId: 2, active: true, fund: 'Test_Mocked_Fund_7', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 21, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 7, yearId: 2, active: true, fund: 'Test_Mocked_Fund_7', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 22, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 8, yearId: 2, active: true, fund: 'Test_Mocked_Fund_8', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 23, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 8, yearId: 2, active: true, fund: 'Test_Mocked_Fund_8', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 24, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 8, yearId: 2, active: true, fund: 'Test_Mocked_Fund_8', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
        { id: 25, uid: '49b1262c-0d78-40ad-9463-c292fc5a9fed', partnerId: 2, auditAllocatedId: 1, clientId: 4, fundId: 8, yearId: 2, active: true, fund: 'Test_Mocked_Fund_8', year: '2021', clientName: 'Test_Mocked_Client_4', auditAllocated: 'Test_Mocked_Audit_1', partner: 'Test_Mocked_Partner_2', dateReceived: '', dateArSent: '', daysIn: '' }, 
    ],
    pageNumber:1,
    pageSize: 10,
}