import { IInvoice } from 'interfaces';
import { BillingEntityType, InvoiceStatusType } from '../enums';

export const mockedInvoices: Array<IInvoice> = [
    {
        id: 1,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '430bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 2,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: '410bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 3,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '3f0bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 4,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: 'ccdccbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 5,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: '3d0bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 6,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: 'b7b2eadf-c443-e611-80c2-00155d00351f',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 7,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: '36c93ada-94f9-423b-9bf9-fc9a2ac53240',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: null,
        extraAmounts: [{ amount: 0, paragraph: 'DD' }],
    },
    {
        id: 8,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: 'a0433040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: null,
        extraAmounts: [{ amount: 0, paragraph: 'DD' }],
    },
    {
        id: 9,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: '53897a01-3e1b-e311-8988-02bf0a033e0b',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: null,
        extraAmounts: [{ amount: 0, paragraph: 'DD' }],
    },
    {
        id: 10,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: 'c4dbcbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: null,
        extraAmounts: [
            { amount: 0, paragraph: 'DD' },
            { amount: 0, paragraph: 'DD' },
        ],
    },
    {
        id: 11,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: 'c45a8280-c443-e611-80c2-00155d00351f',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 12,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: 'db7a90b7-5b9c-443b-8680-2c9f2bc4df0f',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 13,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: '9f433040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 14,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: 'c3dbcbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 15,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: 'ac5a8280-c443-e611-80c2-00155d00351f',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 16,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '310bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 17,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '5c408280-c443-e611-80c2-00155d00351f',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 18,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '0b20b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 19,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '0d48f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'Andrew Milliken Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 20,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: 'cdd14fb9-5537-480c-ab9f-f66d2fac1a5f',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 21,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: 'ad463040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 22,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '69decbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 23,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '49caeadf-c443-e611-80c2-00155d00351f',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 24,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: 'f8453040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 25,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: '81beeadf-c443-e611-80c2-00155d00351f',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 26,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: 'a4453040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 27,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '1fbbeadf-c443-e611-80c2-00155d00351f',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 28,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: 'd6443040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 29,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '69fdb460-1f33-4fda-8fb4-98973aa95eed',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 30,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: 'f5403040-b1b9-e111-b870-02bf0a033e0b',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2012 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 31,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: '7cd9cbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 32,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Andrew Milliken Superannuation Fund',
        trusteeName: 'Andrew Ewen Milliken & Pamela Anne Milliken',
        jobGuid: 'f069d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 33,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: 'fe69d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 34,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: '2720b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 35,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: '2748f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'St Bees Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 36,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: 'fd69d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 37,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '2520b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 38,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: '9ecc5ba6-78a2-411d-8fa7-44cda2e19557',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 39,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '88ddcbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 40,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '1716b118-97d0-4aff-bc94-71d5f9f85451',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 41,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: 'cdddcbae-e0b4-e011-8b3b-00155d3ffa06',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2011 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 42,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: '230053f4-66b5-df11-a9a9-02bf0a033e0b',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2009 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 43,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'St Bees Superannuation Fund',
        trusteeName: 'CK St Bees Pty Limited',
        jobGuid: 'e827a63b-4590-4b87-a4dc-328976ce0a15',
        fundGuid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2010 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 44,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '2f48f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'Wrevwood Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 45,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '006ad072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 46,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Wrevwood Superannuation Fund',
        trusteeName: 'Wrevwood Pty Ltd',
        jobGuid: '2f20b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 47,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'S & L Coates Family Superannuation Fund',
        trusteeName: 'Scott Anthony Coates & Lynette Maree Coates',
        jobGuid: '2548f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'S & L Coates Family Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 48,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: 'fc69d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 49,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: '2320b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 50,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Norden Superannuation Fund',
        trusteeName: 'M A Norden Pty Ltd',
        jobGuid: '2348f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'Norden Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 51,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: 'f969d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 52,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: '3b0bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 53,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Judd Superannuation Fund',
        trusteeName: 'Ritsun Pty Ltd',
        jobGuid: '1f20b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 54,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: 'f869d072-0fe5-e211-a0a5-00155d3ffa03',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 55,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: '390bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 56,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: '1d20b441-aa37-e511-80bc-00155d00351f',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 57,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'JR Douglas Superannuation Fund',
        trusteeName: 'Keikys Pty Ltd',
        jobGuid: '1f48f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        jobName: 'JR Douglas Superannuation Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 58,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Fairhaven Retirement Fund',
        trusteeName: 'JJ & MH Jacobs Pty Ltd',
        jobGuid: '3c4f8280-c443-e611-80c2-00155d00351f',
        fundGuid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        jobName: '2016 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 58,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Fairhaven Retirement Fund',
        trusteeName: 'JJ & MH Jacobs Pty Ltd',
        jobGuid: 'f8e82dc0-fe98-e311-9029-02bf0a033e0b',
        fundGuid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        jobName: '2013 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 59,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Fairhaven Retirement Fund',
        trusteeName: 'JJ & MH Jacobs Pty Ltd',
        jobGuid: '1748f7ae-f312-e411-80bb-00155d00351e',
        fundGuid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        jobName: 'Fairhaven Retirement Fund 2014 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 60,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Fairhaven Retirement Fund',
        trusteeName: 'JJ & MH Jacobs Pty Ltd',
        jobGuid: '1520b441-aa37-e511-80bc-00155d00351f',
        fundGuid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        jobName: '2015 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
    {
        id: 61,
        billingEntityType: BillingEntityType.Fund,
        clientName: 'BMR Yensch',
        fundName: 'Fairhaven Retirement Fund',
        trusteeName: 'JJ & MH Jacobs Pty Ltd',
        jobGuid: '330bd7b5-da56-e711-80c3-00155d00351f',
        fundGuid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        jobName: '2017 Audit',
        jobYear: 0,
        jobARSentDate: null,
        jobInvoiceDate: null,
        jobDraftARSentDate: null,
        status: InvoiceStatusType.Open,
        total: 0,
        amount: 0,
        creationDate: '2014-07-29T09:54:41.11',
        paragraph: 'DD',
        extraAmount: 0,
        extraAmounts: null,
    },
];
