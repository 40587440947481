import React, { FunctionComponent, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { INavLink } from '@fluentui/react';
import { Layout } from 'components';
import { RouterPaths } from 'navigation';
import { Menu } from './components/Menu';
import { HelpMenu } from './components/HelpMenu';

export const PrivateLayoutPage: FunctionComponent = () => {
    const location = useLocation();
    const [links] = useState<INavLink[]>([
        {
            name: 'Workflow',
            url: '',
            key: 'workflow',
            iconProps: { iconName: 'PreviewLink' },
            links: [
                {
                    name: 'Dashboard',
                    url: RouterPaths.workflow.dashboard,
                    key: 'workflow-dashboard',
                    iconProps: { iconName: 'PieSingle' },
                },
                {
                    name: 'Jobs',
                    url: RouterPaths.workflow.jobs,
                    key: 'workflow-jobs',
                    iconProps: { iconName: 'KnowledgeArticle' },
                },
                {
                    name: 'Funds',
                    url: RouterPaths.workflow.funds,
                    key: 'workflow-funds',
                    iconProps: { iconName: 'MapLayers' },
                },
                {
                    name: 'Clients',
                    url: RouterPaths.workflow.clients,
                    key: 'workflow-clients',
                    iconProps: { iconName: 'Contact' },
                },
                {
                    name: 'Reports',
                    url: RouterPaths.workflow.reports,
                    key: 'workflow-reports',
                    iconProps: { iconName: 'ClipboardList' },
                },
                {
                    name: 'Employees',
                    url: RouterPaths.workflow.employees,
                    key: 'workflow-employees',
                    iconProps: { iconName: 'RecruitmentManagement' },
                },
                {
                    name: 'Timesheet',
                    url: RouterPaths.workflow.timesheet,
                    key: 'workflow-timesheet',
                    iconProps: { iconName: 'clock' },
                },
            ],
        },
        {
            name: 'Administration',
            url: '',
            key: 'administration',
            iconProps: { iconName: 'AccountManagement' },
            links: [
                {
                    name: 'Jobs',
                    url: RouterPaths.administration.jobs.root,
                    key: 'jobs',
                    iconProps: { iconName: 'Stack' },
                },
            ],
        },
        {
            name: 'User Management',
            url: RouterPaths.userManagement,
            key: 'user-management',
            iconProps: { iconName: 'PlayerSettings' },
        },
        {
            name: 'Customization',
            url: RouterPaths.customization,
            key: 'customization',
            iconProps: { iconName: 'Color' },
        },
        {
            name: 'Portal Settings',
            url: '',
            key: 'portal-settings',
            iconProps: { iconName: 'Settings' },
            links: [
                {
                    name: 'Audit Ready Connections',
                    url: RouterPaths.portalSettings.auditReadyConnections,
                    key: 'audit-ready-connections',
                    iconProps: { iconName: 'ComplianceAudit' },
                },
                {
                    name: 'General Settings',
                    url: RouterPaths.portalSettings.generalSettings,
                    key: 'general-settings',
                    iconProps: { iconName: 'Settings' },
                },
                {
                    name: 'Risk Matrix Settings',
                    url: RouterPaths.portalSettings.riskMatrixSettings,
                    key: 'risk-matrix-settings',
                    iconProps: { iconName: 'ColumnOptions' },
                },
                {
                    name: 'Materiality Calculator Settings',
                    url: RouterPaths.portalSettings.materialityCalculatorSettings,
                    key: 'materiality-calculator-settings',
                    iconProps: { iconName: 'Calculator' },
                },
                {
                    name: 'Testing and Methodology Settings',
                    url: RouterPaths.portalSettings.testingAndMethodologySettings,
                    key: 'testing-and-methodology-settings',
                    iconProps: { iconName: 'DataManagementSettings' },
                },
                {
                    name: 'Email Templates',
                    url: RouterPaths.portalSettings.emailTemplates,
                    key: 'email-templates',
                    iconProps: { iconName: 'MailOptions' },
                },
            ],
        },
    ]);

    return (
        <Layout links={links} headerLeft={location.pathname === '/private' ? <Menu /> : <></>} headerRight={<HelpMenu />}>
            <Outlet />
        </Layout>
    );
};
