import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { ControlledDropdown, ControlledTextField } from 'components';
import { useAdministrationContext } from 'pages/Administration/AdministrationLayoutPage';

interface Props {
    open: boolean;
    toggle: () => void;
    onSubmit: (data: any) => void;
    title?: string;
}

interface IClientForm {
    clientName: string;
    fundName: string;
    jobYear: string;
}

export const AddJobModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { selectedClient, targetFund } = useAdministrationContext();

    const clientName = selectedClient?.name || '';
    const fundName = targetFund?.name || '';
    const mockedYearOptions: IDropdownOption[] = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'].map((year) => ({
        key: year,
        text: year,
    }));

    const { control, handleSubmit, setValue, formState } = useForm<IClientForm>({
        ...DefaultFormSettings,
        defaultValues: {
            clientName,
            fundName,
            jobYear: '',
        },
    });

    useEffect(() => {
        setValue('clientName', selectedClient?.name || '');
        setValue('fundName', targetFund?.name || '');
    }, [selectedClient, targetFund, setValue]);

    return (
        <Modal title={props?.title || formatMessage({ id: 'addJob' })} isOpen={props.open} onDismiss={props.toggle}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card>
                    <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                        <Stack.Item>
                            <ControlledTextField name='clientName' control={control} label={formatMessage({ id: 'clientName' })} disabled />
                        </Stack.Item>
                        <Stack.Item>
                            <ControlledTextField name='fundName' control={control} label={formatMessage({ id: 'fundName' })} disabled />
                        </Stack.Item>
                        <Stack.Item>
                            <ControlledDropdown
                                name='jobYear'
                                control={control}
                                label={formatMessage({ id: 'jobYear' })}
                                options={mockedYearOptions}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>

                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit((data) => props.onSubmit(data))}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
};
