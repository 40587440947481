import { FunctionComponent, useState } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack, useTheme, INavLink } from '@fluentui/react';
import { Modal, ModalFooter, VerticalMenu } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import { AuditParticularsTab, FoundInfoTab, JobsTab } from './tabs';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export enum FundModalTabs {
    FOUND_INFO,
    AUDIT_PARTICULARS,
    JOB,
}

export const FundModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            name: '',
            trustee: '',
            abn: '',
            clientName: '',
            primaryContact: '',
            accountingSoftware: '',
            accSoftwareCode: '',
        },
    });
    const { formatMessage } = useIntl();

    const [activeTab, setActiveTab] = useState<FundModalTabs>(FundModalTabs.FOUND_INFO);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'general' }),
            url: '',
            key: 'General',
            iconProps: { iconName: 'Settings' },
            isExpanded: true,
            links: [
                {
                    name: formatMessage({ id: 'foundInformation' }),
                    url: '',
                    key: 'foundInformation',
                    iconProps: { iconName: 'ComplianceAudit' },
                    onClick: () => {
                        setActiveTab(FundModalTabs.FOUND_INFO);
                    },
                    isExpanded: true,
                },
                {
                    name: formatMessage({ id: 'auditParticulars' }),
                    url: '',
                    key: 'auditParticulars',
                    iconProps: { iconName: 'MapPin' },
                    onClick: () => {
                        setActiveTab(FundModalTabs.AUDIT_PARTICULARS);
                    },
                },
            ],
        },
        {
            name: formatMessage({ id: 'jobs' }),
            url: '',
            key: 'jobs',
            iconProps: { iconName: 'MapLayers' },
            onClick: () => {
                setActiveTab(FundModalTabs.JOB);
            },
        },
    ]);

    const onSubmit = (data: ICreateUserCommand) => {
        console.log(data);
        onDismiss();
    };

    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const theme = useTheme();

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            background: theme.palette.white,
                        },
                    }}>
                    <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                        <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />

                        <Stack
                            styles={{
                                root: {
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                                    background: theme.palette.neutralLight,
                                    padding: '0 0 16px 16px',
                                    boxSizing: 'border-box',
                                    minHeight: '616px',
                                },
                            }}
                            tokens={{ childrenGap: 16 }}>
                            <Stack.Item styles={{ root: { height: '600px' } }}>
                                {activeTab === FundModalTabs.FOUND_INFO ? (
                                    <FoundInfoTab control={control} />
                                ) : activeTab === FundModalTabs.AUDIT_PARTICULARS ? (
                                    <AuditParticularsTab control={control} />
                                ) : activeTab === FundModalTabs.JOB ? (
                                    <JobsTab />
                                ) : (
                                    ''
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>

                    <ModalFooter horizontalAlign='end'>
                        <PrimaryButton
                            disabled={activeTab === FundModalTabs.JOB ? false : !formState.isDirty}
                            onClick={handleSubmit(onSubmit)}
                            text={formatMessage({ id: 'save' })}
                        />
                    </ModalFooter>
                </Stack>
            </Stack>
        </Modal>
    );
});
