import { Card, ControlledDropdown, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const APIAuthorisationFields = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    const optionsApi = [
        { key: 'admin', text: formatMessage({ id: 'admin' }) },
        { key: 'client', text: formatMessage({ id: 'client' }) },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledDropdown
                        options={optionsApi}
                        name='apiAuthorisationType'
                        label={formatMessage({ id: 'apiAuthorisationType' })}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='accSoftwareCode1' label={formatMessage({ id: 'accSoftwareCode1' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='accSoftwareCode2' label={formatMessage({ id: 'accSoftwareCode2' })} control={control} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
