import { action, makeObservable, observable } from 'mobx';
import { finalize, map, Observable, tap } from 'rxjs';
import { ICreateRuleCommand, IParamInfo, IRuleInfo, IRulesetInfo } from 'interfaces';
import { rulesetService } from 'services';
import { BasicStore } from 'stores';

export class RulesetStore extends BasicStore {
    public ruleset?: IRulesetInfo;

    public globalVariable?: IParamInfo;
    public changingGlobalVariable: boolean = false;
    public addNewGlobalVariable: boolean = false;
    public deletingGlobalVariable: boolean = false;

    public rulesetRenaming: boolean = false;
    public rulesetLoading: boolean = false;
    public rulesetDeleting: boolean = false;
    public rulesetRestoring: boolean = false;
    public rulesetRuleDeleting: boolean = false;
    public rulesetRuleRestoring: boolean = false;

    public rule?: IRuleInfo;
    public ruleAdding: boolean = false;
    public ruleEditing: boolean = false;

    private rulesetId: IRulesetInfo['id'] = -1;

    constructor() {
        super();
        makeObservable(this, {
            ruleset: observable,
            globalVariable: observable,
            deletingGlobalVariable: observable,
            addNewGlobalVariable: observable,
            rulesetLoading: observable,
            rulesetDeleting: observable,
            rulesetRestoring: observable,
            rulesetRuleDeleting: observable,
            rulesetRuleRestoring: observable,
            rule: observable,
            ruleAdding: observable,
            ruleEditing: observable,
            setId: action,
            loadRuleset: action,
            loadRulesetObservable: action,
            renameRuleset: action,
            deleteRuleset: action,
            restoreRuleset: action,
            deleteRule: action,
            restoreRule: action,
            setRuleById: action,
            clearRule: action,
            addRule: action,
            editRule: action,
            deleteGlobalVariable: action,
        });
    }

    public setId: (id: IRulesetInfo['id']) => void = (id: IRulesetInfo['id']) => {
        this.rulesetId = id;
    };

    public setRuleById: (id: IRuleInfo['id']) => void = (id: IRuleInfo['id']) => {
        this.rule = this.ruleset?.rules?.find((r) => r.id === id);
    };

    public clearRule: () => void = () => {
        this.rule = undefined;
    };

    public loadRuleset: () => void = () => {
        this.subscriptions.push(this.loadRulesetObservable().subscribe());
    };

    public loadRulesetObservable: () => Observable<IRulesetInfo> = () => {
        this.rulesetLoading = true;
        return rulesetService.getRuleset(this.rulesetId).pipe(
            finalize(() => (this.rulesetLoading = false)),
            tap((ruleset) => (this.ruleset = ruleset))
        );
    };

    public renameRuleset: (name: IRulesetInfo['workflowName']) => Observable<IRulesetInfo> = (name: IRulesetInfo['workflowName']) => {
        this.rulesetRenaming = true;
        return rulesetService.renameRuleset(this.rulesetId, name).pipe(
            tap(() => (this.rulesetRenaming = false)),
            finalize(() => (this.rulesetRenaming = false))
        );
    };

    public deleteRuleset: () => Observable<any> = () => {
        return rulesetService.deleteRuleset(this.rulesetId).pipe(finalize(() => (this.rulesetDeleting = false)));
    };

    public restoreRuleset: () => Observable<any> = () => {
        this.rulesetRestoring = true;
        return rulesetService.restoreRuleset(this.rulesetId).pipe(
            finalize(() => (this.rulesetRestoring = false)),
            tap(() => this.loadRuleset())
        );
    };

    public deleteRule: (id: IRuleInfo['id']) => Observable<any> = (id: IRuleInfo['id']) => {
        this.rulesetRuleDeleting = true;
        return rulesetService.deleteRule(this.rulesetId, id).pipe(finalize(() => (this.rulesetRuleDeleting = false)));
    };

    public restoreRule: (id: IRuleInfo['id'], forceRestore?: boolean) => Observable<any> = (
        id: IRuleInfo['id'],
        forceRestore: boolean = false
    ) => {
        this.rulesetRuleRestoring = true;
        return rulesetService.restoreRule(this.rulesetId, id, forceRestore).pipe(finalize(() => (this.rulesetRuleRestoring = false)));
    };

    public addRule: (rule: ICreateRuleCommand) => Observable<IRuleInfo> = (rule: ICreateRuleCommand) => {
        this.ruleAdding = true;
        return rulesetService.addRule(this.ruleset?.id!, rule).pipe(finalize(() => (this.ruleAdding = false)));
    };

    public editRule: (id: IRuleInfo['id'], rule: ICreateRuleCommand) => Observable<any> = (
        id: IRuleInfo['id'],
        rule: ICreateRuleCommand
    ) => {
        this.ruleAdding = true;
        return rulesetService.editRule(this.ruleset?.id!, id, rule).pipe(finalize(() => (this.ruleAdding = false)));
    };

    public setRulesetVariableById: (id: IParamInfo['id']) => void = (id: IParamInfo['id']) => {
        this.globalVariable = this.ruleset?.globalParams?.find((r) => r.id === id);
    };

    public clearVariable: () => void = () => {
        this.globalVariable = undefined;
    };

    public addGlobalVariable: (variable: IParamInfo) => Observable<IParamInfo> = (variable: IParamInfo) => {
        this.changingGlobalVariable = true;

        return rulesetService.changeGlobalVariable(this.ruleset?.id!, variable).pipe(
            finalize(() => (this.changingGlobalVariable = false)),
            map((res) => res.variable)
        );
    };

    public editGlobalVariable: (id: IRulesetInfo['id'], variable: IParamInfo) => Observable<IParamInfo> = (
        id: IRulesetInfo['id'],
        command: IParamInfo
    ) => {
        this.changingGlobalVariable = true;

        return rulesetService.changeGlobalVariable(this.ruleset?.id!, command).pipe(
            finalize(() => (this.changingGlobalVariable = false)),
            map((res) => res.variable)
        );
    };

    public deleteGlobalVariable: (id: IRulesetInfo['id'], variable: IParamInfo) => Observable<IParamInfo> = (
        id: IRulesetInfo['id'],
        variable: IParamInfo
    ) => {
        this.deletingGlobalVariable = true;
        variable.deleted = true;

        return rulesetService
            .deleteRuleSetVariable(this.ruleset?.id!, variable)
            .pipe(finalize(() => (this.deletingGlobalVariable = false)));
    };
}
