import * as React from 'react';
import {FunctionComponent, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {IColumn, IGroup, SelectionMode, Text, useTheme} from '@fluentui/react';
import {IRuleInfo} from 'interfaces';
import {DataTable} from 'components';
import {useRulesetStore} from '../../RulesetLayoutPage';
import {RulesetRulesDataTableActions} from './RulesetRulesDataTableActions';
import {sortBy} from 'lodash';
import {CommandBar, ICommandBarItemProps} from '@fluentui/react/lib/CommandBar';

export const RulesetRulesDataTable: FunctionComponent = observer(() => {
    const {ruleset, rulesetLoading} = useRulesetStore();
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();

    const [columns] = useState<IColumn[]>([
        {
            key: 'executionOrder',
            fieldName: 'executionOrder',
            minWidth: 110,
            maxWidth: 110,
            name: formatMessage({id: 'executionOrder'}),
            isMultiline: true,
        },

        {
            key: 'name',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 150,
            name: formatMessage({id: 'ruleName'}),
            onRender: (item: IRuleInfo) => (
                <Text variant='medium' styles={{root: {fontWeight: 500}}}>
                    {item.ruleName}
                </Text>
            ),
        },

        {
            key: 'expression',
            fieldName: 'expression',
            minWidth: 250,
            maxWidth: 350,
            name: formatMessage({id: 'expression'}),
            isMultiline: true,
        },
        {
            key: 'onSuccess',
            fieldName: 'onSuccess',
            minWidth: 210,
            name: formatMessage({id: 'onSuccess'}),
            isMultiline: true,
        },
        {
            key: 'onFailed',
            fieldName: 'onFailed',
            minWidth: 210,
            name: formatMessage({id: 'onFailed'}),
            isMultiline: true,
        },
        {
            key: 'actions',
            minWidth: 50,
            maxWidth: 50,
            name: '',
            onRender: (item: IRuleInfo) => <RulesetRulesDataTableActions rule={item}/>,
        },
    ]);
    const rules: IRuleInfo[] = useMemo(() => sortBy(ruleset?.rules ?? [], 'isDeleted'), [ruleset?.rules]);
    const deletedRulesCount: number = useMemo(() => ruleset?.rules?.filter((r) => r.isDeleted)?.length ?? 0, [ruleset?.rules]);
    const groups = useMemo<IGroup[]>(
        () => [
            {
                count: rules.length - deletedRulesCount,
                key: 'active',
                startIndex: 0,
                name: formatMessage({id: 'active'}),
            },
            {
                count: deletedRulesCount,
                key: 'deleted',
                startIndex: rules.length - deletedRulesCount,
                name: formatMessage({id: 'deleted'}),
            },
        ],
        [formatMessage, rules.length, deletedRulesCount]
    );

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({id: 'add'}),
                iconProps: {iconName: 'add'},
                iconOnly: true,
                onClick: () => navigate(RouterPaths.admin.rulesets.ruleset.rules.new(ruleset?.id!)),
            },
        ],
        [formatMessage, navigate, ruleset]
    );

    return (
        <>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{root: {padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}`}}}
            />
            <DataTable
                initialColumns={columns}
                shimmerLines={10}
                items={rules ?? []}
                groups={groups}
                selectionMode={SelectionMode.none}
                enableShimmer={rulesetLoading}
                containerHeight='calc(100% - 32px)'
            />
        </>
    )
});
