import * as React from 'react';
import { Controller, Path } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { RichTextEditor } from './RichTextEditor';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    placeholder?: string;
}

export function ControlledRichTextField<FormValues>({ name, control, rules, label, placeholder }: Props<FormValues>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RichTextEditor
                    label={label}
                    placeholder={placeholder}
                    value={value as string}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                />
            )}
        />
    );
}
