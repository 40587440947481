import { useState } from 'react';
import { useIntl } from 'react-intl';
import { IColumn, SelectionMode } from '@fluentui/react';
import { DataTable } from 'components';
import { RevokeConnectionButton } from './RevokeConnectionButton';

const mockedConnections = [
    {
        connectionId: '1f8b46a5-6951-45d3-8ac0-c2182e2fe6cc',
        firm: 'Audit Ready DEV',
        date: '2021-12-07T13:19:25.5',
    },
    {
        connectionId: 'test',
        firm: 'Firm_2',
        date: '2021-09-28T08:19:49.623',
    },
];

export const Connections = () => {
    const { formatMessage, formatDate } = useIntl();

    const [connections, setConnections] = useState<Array<{ connectionId: string; firm: string; date: string }>>(mockedConnections);

    const columns: IColumn[] = [
        { key: 'firm', fieldName: 'firm', name: 'Accounting firm', minWidth: 100 },
        {
            key: 'date',
            fieldName: 'date',
            name: formatMessage({ id: 'approvedDate' }),
            minWidth: 100,
            onRender: (item) => (item.date ? formatDate(item.date) : null),
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 87,
            maxWidth: 87,
            onRender: (item) => (
                <RevokeConnectionButton onAgree={() => setConnections(connections.filter((a) => a.connectionId !== item.connectionId))} />
            ),
        },
    ];

    return <DataTable initialColumns={columns} items={connections} selectionMode={SelectionMode.none} />;
};
