import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { IDropdownOption } from '@fluentui/react';
import { mockedFunds } from 'constants/MockedFunds';
import { DefaultFormSettings } from 'constants/forms';
import { IInvoicesFilters } from 'interfaces';
import { ControlledDropdown, SearchPanel } from 'components';
import { InvoiceFilterStatusType } from '../../enums';
import { useInvoicesStore } from '../../InvoicesPage';

export const InvoicesTableFilters: FunctionComponent = observer(() => {
    const { invoicesFilters, invoicesPageSize, setInvoicesFilters, loadInvoices, setInvoicesPagination } = useInvoicesStore();
    const { formatMessage } = useIntl();
    const { control, handleSubmit, reset } = useForm<IInvoicesFilters>({ ...DefaultFormSettings, defaultValues: invoicesFilters });

    const statusOptions: IDropdownOption[] = [
        { key: InvoiceFilterStatusType.Open, text: formatMessage({ id: 'invoicesFilters.open' }) },
        { key: InvoiceFilterStatusType.Closed, text: formatMessage({ id: 'invoicesFilters.closed' }) },
        { key: InvoiceFilterStatusType.Created, text: formatMessage({ id: 'invoicesFilters.createdOnly' }) },
        { key: InvoiceFilterStatusType.NotCreated, text: formatMessage({ id: 'invoicesFilters.notCreatedOnly' }) },
        { key: InvoiceFilterStatusType.NotCreatedAndARDraftARSent, text: formatMessage({ id: 'invoicesFilters.notCreatedAndARDraft' }) },
    ];

    const fundsOptions: IDropdownOption[] = [
        { key: '', text: formatMessage({ id: 'all' }) },
        ...mockedFunds.map((f) => ({ key: f.guid, text: f.name })),
    ];

    const yearsOptions: IDropdownOption[] = [
        { key: -1, text: formatMessage({ id: 'all' }) },
        { key: 2018, text: '2018' },
        { key: 2017, text: '2017' },
        { key: 2016, text: '2016' },
    ];

    return (
        <SearchPanel
            onSearch={handleSubmit((data) => {
                setInvoicesFilters(data);
                setInvoicesPagination(invoicesPageSize, 1);
                loadInvoices();
            })}
            onClear={() => {
                reset({ status: '', invoiceNumber: '', fund: '', year: '' });
                setInvoicesPagination(invoicesPageSize, 1);
                loadInvoices();
            }}>
            <ControlledDropdown
                label={formatMessage({ id: 'status' })}
                name='status'
                control={control}
                options={statusOptions}
                dropdownWidth={250}
            />

            {/* {[InvoiceFilterStatusType.Open, InvoiceFilterStatusType.Closed, InvoiceFilterStatusType.Created].includes(watch('status')) ? (
                <ControlledTextField label={formatMessage({ id: 'invoiceNumber' })} name='invoiceNumber' control={control} />
            ) : null} */}

            <ControlledDropdown
                label={formatMessage({ id: 'fund' })}
                name='fund'
                control={control}
                options={fundsOptions}
                dropdownWidth={150}
            />

            <ControlledDropdown
                label={formatMessage({ id: 'year' })}
                name='year'
                control={control}
                options={yearsOptions}
                dropdownWidth={60}
            />
        </SearchPanel>
    );
});
