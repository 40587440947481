import { IconButton, IDropdownOption, mergeStyleSets, Stack, TextField } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledDatePicker, ControlledDropdown, ControlledNumberField } from 'components';
import { AddInvoiceForm } from './InvoiceAddForm';

interface Props {
    control: Control<AddInvoiceForm, Object>;
    onEditExtraAmount: () => void;
    totalExtraAmounts: number;
}

export const InvoiceAddFormMainControls: FunctionComponent<Props> = ({ control, onEditExtraAmount, totalExtraAmounts }: Props) => {
    const { formatMessage, formatNumber } = useIntl();

    const invoiceParagraphOptions: IDropdownOption[] = [
        { key: 'DD', text: 'DD' },
        { key: 'Standard', text: 'Standard' },
    ];
    const billEntityOptions: IDropdownOption[] = [
        { key: 'Client', text: 'Client' },
        { key: 'Fund', text: 'Fund' },
    ];

    const dataPickerContainerStyles = mergeStyleSets({
        root: { width: 'calc(50% - 4px)' },
    });

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }} wrap>
            <Stack.Item styles={{ root: { width: 'calc((100% - 16px) / 2)' } }}>
                <ControlledNumberField
                    name='amount'
                    control={control}
                    rules={{ required: 'Amount is required' }}
                    label={formatMessage({ id: 'amount' })}
                />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: 'calc((100% - 16px) / 2 - 40px)' } }}>
                <TextField
                    label={formatMessage({ id: 'extraAmount' })}
                    disabled
                    value={formatNumber(totalExtraAmounts, { style: 'currency', currency: 'USD' })}
                />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: '32px' } }} align='end'>
                <IconButton iconProps={{ iconName: 'Edit' }} onClick={onEditExtraAmount} />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: '100%' } }}>
                <div className={dataPickerContainerStyles.root}>
                    <ControlledDatePicker
                        control={control}
                        name='creationDate'
                        rules={{ required: 'Creation Date is required' }}
                        label={formatMessage({ id: 'creationDate' })}
                    />
                </div>
            </Stack.Item>

            <Stack.Item styles={{ root: { width: 'calc((100% - 16px) / 2)' } }}>
                <ControlledDropdown
                    name='invoiceParagraph'
                    control={control}
                    rules={{ required: 'Invoice Paragraph is required' }}
                    label={formatMessage({ id: 'invoiceParagraph' })}
                    options={invoiceParagraphOptions}
                />
            </Stack.Item>

            <Stack.Item styles={{ root: { width: 'calc((100% - 16px) / 2)' } }}>
                <ControlledDropdown
                    name='billingEntity'
                    control={control}
                    rules={{ required: 'Billing Entity is required' }}
                    label={formatMessage({ id: 'billingEntity' })}
                    options={billEntityOptions}
                />
            </Stack.Item>
        </Stack>
    );
};
