import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { createTheme, initializeIcons, Theme, ThemeProvider } from '@fluentui/react';
import { useConfiguration } from 'hooks/useConfiguration';
import { generateTheme } from 'utils';
import { Context } from 'utils/context';
import { SignInLoader } from './navigation/SignInLoader';
import { useGetToken } from 'hooks/useGetToken';
import { useGetTheme } from 'hooks/useGetTheme';
import { useIsDarkTheme } from 'hooks/useIsDarkTheme';

initializeIcons();

export const defaultAppTheme: Theme = createTheme({
    palette: {
        themePrimary: '#bfcc49',
        themeLighterAlt: '#fcfdf6',
        themeLighter: '#f4f7de',
        themeLight: '#ebf0c2',
        themeTertiary: '#d8e08a',
        themeSecondary: '#c6d25c',
        themeDarkAlt: '#acb842',
        themeDark: '#919b38',
        themeDarker: '#6b7229',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#ffffff',
    },
});

export const AppTheme: FunctionComponent = (props: PropsWithChildren<{}>) => {
    useGetToken();
    const { config, tenant, isConfigLoading, isTenantLoading } = useConfiguration();
    const { colors, isLoading, error } = useGetTheme(config?.data.settingsUrl || '', true, tenant?.data.GlobalId || '');

    const isUserPreferDarkTheme = useIsDarkTheme();

    const defaultTheme = useMemo(() => {
        return isUserPreferDarkTheme
            ? createTheme({
                  palette: generateTheme({
                      primaryColor: colors?.data.themeViewModel.themePrimary || defaultAppTheme.palette.themePrimary,
                      textColor: '#ffffff',
                      backgroundColor: '#454342',
                      isDark: isUserPreferDarkTheme,
                  }),
              })
            : createTheme({
                  palette: generateTheme({
                      primaryColor: colors?.data.themeViewModel.themePrimary || defaultAppTheme.palette.themePrimary,
                      textColor: colors?.data.themeViewModel.neutralPrimary || defaultAppTheme.palette.neutralPrimary,
                      backgroundColor: '#ffffff',
                      isDark: isUserPreferDarkTheme,
                  }),
              });
    }, [colors, isUserPreferDarkTheme]);

    if (isConfigLoading || isTenantLoading || isLoading) {
        return <SignInLoader />;
    }

    return error?.response?.status !== 401 ? (
        <>
            <Context.Provider
                value={{ config: config?.data || null, tenantId: tenant?.data.GlobalId || '', logo: colors?.data.logoImageUrl || '' }}>
                <ThemeProvider theme={defaultTheme}>{props.children}</ThemeProvider>
            </Context.Provider>
        </>
    ) : (
        <SignInLoader />
    );
};
