import { FunctionComponent } from 'react';
import { Card } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';

export const DigitalSignatureTab: FunctionComponent = () => {
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                Digital Signature
            </Stack>
        </Card>
    );
};
