import { FunctionComponent, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { BaseDialog, Card, DataTable } from 'components';
import { ActionButtonStyles, CardTableContainerStyles } from 'constants/tableStylesPeset';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import { allData } from 'pages/Workflow/components/data/Data';
import { ClientModal, PartnerModal, ContactModal } from 'pages/Workflow/components/modals';

export const ClientsDataTable: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    const [showActivetedModal, setShowActivetedModal] = useState<boolean>(false);
    const [showMainModal, setShowMainModal] = useState<boolean>(false);
    const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false);
    const [showContactModal, setShowContactModal] = useState<boolean>(false);

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({ id: 'commands' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            onRender: (items) => (
                <ActionButton styles={{ root: { fontWeight: 500 } }} onClick={() => setShowActivetedModal(true)} allowDisabledFocus>
                    {items.active ? 'Deactivate' : 'Activate'}
                </ActionButton>
            ),
        },
        {
            key: 'userName',
            name: formatMessage({ id: 'name' }),
            minWidth: 150,
            maxWidth: 600,
            fieldName: 'userName',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowMainModal(true)} allowDisabledFocus>
                    {items.name}
                </ActionButton>
            ),
        },
        {
            key: 'contact',
            name: formatMessage({ id: 'primaryContact' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'contact',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowContactModal(true)} allowDisabledFocus>
                    {items.primaryContact}
                </ActionButton>
            ),
        },
        {
            key: 'mainPhone',
            name: formatMessage({ id: 'mainPhone' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'mainPhone',
            onRender: (items) => <Text>{items.phone}</Text>,
        },
        {
            key: 'state',
            name: formatMessage({ id: 'state' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'state',
            onRender: (items) => <Text>{items.addressState}</Text>,
        },
        {
            key: 'partner',
            name: formatMessage({ id: 'partner' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'partner',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowPartnerModal(true)} allowDisabledFocus>
                    {items.partner}
                </ActionButton>
            ),
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['userName', 'contact', 'mainPhone', 'state', 'partner']}
                initialColumns={columns}
                items={allData.items}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            {showActivetedModal && (
                <BaseDialog
                    title={formatMessage({ id: 'changeActivDialogTitle' })}
                    subText={formatMessage({ id: 'changeActivDialogSubText' })}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivetedModal(false)}
                />
            )}

            <ClientModal isOpen={showMainModal} onDismiss={() => setShowMainModal(false)} title={formatMessage({ id: 'client' })} />

            <ContactModal isOpen={showContactModal} onDismiss={() => setShowContactModal(false)} title={formatMessage({ id: 'contact' })} />

            <PartnerModal isOpen={showPartnerModal} onDismiss={() => setShowPartnerModal(false)} title={formatMessage({ id: 'partner' })} />
        </Card>
    );
};
