import { FunctionComponent } from 'react';
import { IconButton, Stack, Text } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { IUserInfo } from 'interfaces';
import { Card, Modal } from 'components';

interface Props {
    item: IUserInfo;
}
export const UserManagementClients: FunctionComponent<Props> = ({ item }: Props) => {
    const { formatMessage } = useIntl();
    const [show, { toggle: toggleShow }] = useBoolean(false);

    if (!item.clients.length) {
        return null;
    }
    return (
        <>
            <IconButton iconProps={{ iconName: 'RedEye' }} onClick={toggleShow} />
            <Modal title={formatMessage({ id: 'clients' })} isOpen={show} onDismiss={toggleShow}>
                <Stack tokens={{ childrenGap: 16 }}>
                    {item.clients.map((c) => (
                        <Card key={c.clientGuid}>
                            <Text>{c.name ?? null}</Text>{' '}
                        </Card>
                    ))}
                </Stack>
            </Modal>
        </>
    );
};
