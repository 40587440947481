import { Card, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';

type MailingAddressTabType = {
    control: Control<ICreateUserCommand>;
};

export const MailingAddressTab = ({ control }: MailingAddressTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='street' label={formatMessage({ id: 'street' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='state' label={formatMessage({ id: 'state' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='street2' label={formatMessage({ id: 'street2' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='postalCode' label={formatMessage({ id: 'postalCode' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='city' label={formatMessage({ id: 'city' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='country' label={formatMessage({ id: 'country' })} control={control} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
