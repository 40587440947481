import { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams, Outlet } from 'react-router-dom';
import { RouterPathsRuleIdKey } from 'navigation';
import { useIntl } from 'react-intl';
import { PageWithBreadcrumb } from 'components';
import { useRulesetStore } from '../RulesetLayoutPage';

export const RulesetRulesLayoutPage: FunctionComponent = observer(() => {
    const params = useParams();
    const ruleId = params[RouterPathsRuleIdKey];
    const { formatMessage } = useIntl();
    const { setRuleById, clearRule, rulesetLoading, ruleset } = useRulesetStore();

    useEffect(() => {
        if (ruleId && !rulesetLoading && ruleset) {
            setRuleById(+ruleId);
        }
        return () => clearRule();
    }, []);

    useEffect(() => {
        if (ruleId && !rulesetLoading && ruleset) setRuleById(+ruleId);
    }, [rulesetLoading, ruleset]);

    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'rules',
                text: ruleset?.category ?? formatMessage({ id: 'rules' }),
                key: 'rules',
                disabled: true,
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
});
