import { FunctionComponent } from 'react';
import { InvoicesAddMultipleJobsSelectInvoices } from './InvoicesAddMultipleJobsSelectInvoices';
import { InvoicesAddMultipleJobsUpdateFees } from './InvoicesAddMultipleJobsUpdateFees';
import { InvoicesAddMultipleJobsGenerateInvoices } from './InvoicesAddMultipleJobsGenerateInvoices';
import { observer } from 'mobx-react';
import { AddMultipleJobsStepType } from './enums';
import { useAddMultipleJobsStore } from './InvoicesAddMultipleJobsModal';

interface Props {
    onClose: () => void;
}

export const InvoicesAddMultipleJobsStepper: FunctionComponent<Props> = observer((props: Props) => {
    const { step } = useAddMultipleJobsStore();

    switch (step) {
        case AddMultipleJobsStepType.SelectInvoices:
            return <InvoicesAddMultipleJobsSelectInvoices onClose={props.onClose} />;

        case AddMultipleJobsStepType.UpdateFees:
            return <InvoicesAddMultipleJobsUpdateFees onClose={props.onClose} />;

        case AddMultipleJobsStepType.GenerateInvoices:
            return <InvoicesAddMultipleJobsGenerateInvoices onClose={props.onClose} />;

        default:
            return null;
    }
});
