import { FunctionComponent, ReactNode } from 'react';
import { ContentState } from 'draft-js';

interface Props {
    contentState: ContentState;
    entityKey: string;
    children: ReactNode;
}

export const RichTextLink: FunctionComponent<Props> = (props: Props) => {
    const { url, target } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} target={target}>
            {props.children}
        </a>
    );
};
