import { INavLink, INavLinkGroup, mergeStyleSets, Nav, Theme, useTheme } from '@fluentui/react';
import { RouterPaths } from 'navigation';
import { FunctionComponent, MouseEvent, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    links: INavLink[];
    isCollapsed: boolean;
    toggleIsCollapsed: () => void;
}

const iconStyles = { root: { width: 30, transition: 'transform 0.1s linear 0s' } };
const setIconProps: (link: INavLink) => INavLink = (link: INavLink) => ({
    ...link,
    iconProps: { ...link.iconProps, styles: iconStyles },
    // links: link.links?.map(setIconProps),
});

const getNavStyles = (theme: Theme) => ({
    root: {
        boxSizing: 'border-box',
        height: '100%',
        width: 200,
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    groupContent: {
        marginBottom: 0,
    },
    chevronIcon: {
        left: 16,
    },

    chevronButton: {
        width: '100%',
        backgroundColor: 'transparent',

        selectors: {
            '>i': {
                right: 16,
                left: 'auto',
            },
        },
    },
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',

        selectors: {
            '.ms-Nav-chevronButton ~ &': {
                paddingLeft: 0,
            },

            '.ms-Nav-navItem .ms-Nav-navItems &': {
                fontSize: 12,
            },
        },
    },
    linkText: {
        color: theme.semanticColors.bodyText,
    },
});

export const VerticalMenu: FunctionComponent<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const theme = useTheme();

    const navStyles = useMemo(() => getNavStyles(theme), [theme]);

    const currentKey = useMemo(() => {
        switch(pathname) {
            case RouterPaths.portalSettings.auditReadyConnections:
                return { key: 'audit-ready-connections' };
            case RouterPaths.workflow.dashboard:
                return { key: 'workflow-dashboard' };
            default:
                return props.links.find((link) => link.url === pathname);
        }
    }, [pathname, props.links]);

    const navLinkGroups: INavLinkGroup[] = useMemo(
        () => [
            {
                links: props.links.map(setIconProps),
            },
            {
                links: [
                    {
                        key: 'collapse',
                        onClick: props.toggleIsCollapsed,
                        name: '',
                        url: '',
                        iconProps: {
                            iconName: 'DoubleChevronLeftMed',
                            styles: iconStyles,
                            className: 'toggle-icon',
                        },
                    },
                ],
            },
        ],
        [props.links, props.toggleIsCollapsed]
    );

    const onLinkClick = (ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        if (item?.url) {
            navigate({ pathname: item?.url! });
        }
    };

    const classNames = mergeStyleSets({
        collapsed: {
            maxWidth: 'max-content',
            selectors: {
                '.ms-Nav-linkText': { display: 'none' },
                '.toggle-icon': { transform: 'rotate(180deg)' },
            },
        },
    });

    return (
        <Nav
            onLinkClick={onLinkClick}
            selectedKey={currentKey?.key}
            className={props.isCollapsed ? classNames.collapsed : undefined}
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
};
