import { Icon, Link, PrimaryButton, Stack, useTheme } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Card, ControlledTextField } from 'components';
import { DefaultFormSettings } from 'constants/forms';

export const AuthoriseBGL360API = () => {
    const { control, formState } = useForm({ ...DefaultFormSettings, defaultValues: { businessCode: '' } });
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
        <Card>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign='end'>
                    <Stack.Item>
                        <ControlledTextField
                            name='businessCode'
                            control={control}
                            label={formatMessage({ id: 'authoriseBGL360API' })}
                            placeholder={formatMessage({ id: 'businessCode' })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty} text={formatMessage({ id: 'authorise' })} />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link href='https://api.bgl360.com.au/oauth/authorize?response_type=code&client_id=159a8c49-e4c3-4b67-8ec1-ee99784b2d00&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIlNvZnR3YXJlVHlwZSI6IkJHTDM2MCIsImlzcyI6Imh0dHBzOi8vZGV2YXBpLmV2b2x2c3VwZXIuY29tLmF1IiwiYXVkIjoiNDE0ZTE5MjdhMzg4NGY2OGFiYzc5ZjcyODM4MzdmZDEiLCJleHAiOjE2NDcwNDY5MzgsIm5iZiI6MTY0NzAwMzczOH0.d8-ZLZl3jlWrE9OB_hcD3QfhBcU3qwYaSUvkVMCk7yI::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=audit&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response'>
                        Authorise BGL360 API
                    </Link>
                    <Icon iconName='CheckMark' styles={{ root: { color: theme.palette.green } }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link href='https://api.bgl360.com.au/oauth/authorize?response_type=code&client_id=159a8c49-e4c3-4b67-8ec1-ee99784b2d00&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIlNvZnR3YXJlVHlwZSI6IkJHTDM2MCIsImlzcyI6Imh0dHBzOi8vZGV2YXBpLmV2b2x2c3VwZXIuY29tLmF1IiwiYXVkIjoiNDE0ZTE5MjdhMzg4NGY2OGFiYzc5ZjcyODM4MzdmZDEiLCJleHAiOjE2NDcwNDY5MzgsIm5iZiI6MTY0NzAwMzczOH0.d8-ZLZl3jlWrE9OB_hcD3QfhBcU3qwYaSUvkVMCk7yI::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=audit&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response'>
                        Authorise BGL360 API
                    </Link>
                    <Icon iconName='Cancel' styles={{ root: { color: theme.palette.red } }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link href='https://api.bgl360.com.au/oauth/authorize?response_type=code&client_id=159a8c49-e4c3-4b67-8ec1-ee99784b2d00&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIlNvZnR3YXJlVHlwZSI6IkJHTDM2MCIsImlzcyI6Imh0dHBzOi8vZGV2YXBpLmV2b2x2c3VwZXIuY29tLmF1IiwiYXVkIjoiNDE0ZTE5MjdhMzg4NGY2OGFiYzc5ZjcyODM4MzdmZDEiLCJleHAiOjE2NDcwNDY5MzgsIm5iZiI6MTY0NzAwMzczOH0.d8-ZLZl3jlWrE9OB_hcD3QfhBcU3qwYaSUvkVMCk7yI::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=audit&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response'>
                        Authorise BGL360 API
                    </Link>
                </Stack>
            </Stack>
        </Card>
    );
};
