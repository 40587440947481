import { FunctionComponent, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { INavLink } from '@fluentui/react';
import { Layout } from 'components';
import { RouterPaths } from '../../navigation';

export const AdminLayoutPage: FunctionComponent = () => {
    const [links] = useState<INavLink[]>([
        {
            name: 'Rulesets',
            url: RouterPaths.admin.rulesets.root,
            key: 'ruleset',
            iconProps: { iconName: 'WorkFlow' },
        },
    ]);

    return (
        <Layout links={links}>
            <Outlet />
        </Layout>
    );
};
