import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IconButton, MessageBarType, useTheme } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean } from '@fluentui/react-hooks';
import { takeUntil } from 'rxjs';
import { IRulesetInfo } from 'interfaces';
import { RouterPaths } from 'navigation';
import { ActiveIconColorStyles } from 'constants/tableStylesPeset';
import { useIsDestroy } from 'utils';
import { useRulesetsStore } from '../RulesetsPage';
import { RulesetDeleteDialog } from './RulesetDeleteDialog';
import { RulesetRestoreDialog } from './RulesetRestoreDialog';
import { stores } from '../../../../stores';

interface Props {
    ruleset: IRulesetInfo;
}

export const RulesetsDataTableActions: FunctionComponent<Props> = observer(({ ruleset }: Props) => {
    const { deleteRuleset, restoreRuleset, loadRulesets, rulesetDeleting, rulesetRestoring } = useRulesetsStore();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();
    const [hiddenDeleteDialog, { toggle: toggleHiddenDeleteDialog }] = useBoolean(true);
    const [hiddenRestoreDialog, { toggle: toggleHiddenRestoreDialog }] = useBoolean(true);
    const isDestroy = useIsDestroy();

    const onDelete = () => {
        deleteRuleset(ruleset.id)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successRulesetDeleteMessage' }),
                });
                toggleHiddenDeleteDialog();
                loadRulesets();
            });
    };

    const onRestore = () => {
        restoreRuleset(ruleset.id)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successRulesetRestoreMessage' }),
                });
                toggleHiddenRestoreDialog();
                loadRulesets();
            });
    };

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'edit',
                text: formatMessage({ id: 'edit' }),
                iconProps: { iconName: 'Edit' },
                disabled: ruleset.isDeleted,
                onClick: () => navigate(RouterPaths.admin.rulesets.ruleset.root(ruleset.id)),
            },
            {
                key: 'restore',
                text: formatMessage({ id: 'restore' }),
                iconProps: { iconName: 'Redo' },
                disabled: !ruleset.isDeleted,
                onClick: toggleHiddenRestoreDialog,
            },
            {
                key: 'delete',
                text: formatMessage({ id: 'delete' }),
                iconProps: {
                    iconName: 'Delete',
                    styles: ActiveIconColorStyles(theme.palette.red),
                },
                disabled: ruleset.isDeleted,
                onClick: toggleHiddenDeleteDialog,
            },
        ],
    };

    return (
        <>
            <IconButton onRenderMenuIcon={() => <div />} menuProps={menuProps} iconProps={{ iconName: 'More' }} />

            <RulesetDeleteDialog
                hidden={hiddenDeleteDialog}
                toggleHidden={toggleHiddenDeleteDialog}
                onAgree={onDelete}
                yesDisabled={rulesetDeleting}
            />

            <RulesetRestoreDialog
                hidden={hiddenRestoreDialog}
                toggleHidden={toggleHiddenRestoreDialog}
                onAgree={onRestore}
                yesDisabled={rulesetRestoring}
            />
        </>
    );
});
