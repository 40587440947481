import { IContact } from 'interfaces/IContact';

export const mockedContacts: Array<IContact> = [
  {
      contactid: '0c1dcd9e-f906-e411-80bb-00155d00351e',
      contactName: 'Craig Pisani',
      businessPhone: '02 9527 1782',
      email: 'Adam.Brown@542partners.com.au',
      firstName: 'Craig',
      lastName: 'Pisani',
  },
  {
      contactid: '9219a602-fa06-e411-80bb-00155d00351e',
      contactName: 'Stuart Brandman',
      businessPhone: '02 9527 1782',
      email: 'Adam.Brown@542partners.com.au',
      firstName: 'Stuart',
      lastName: 'Brandman',
  },
  {
      contactid: 'a15a285d-d722-e611-80c1-00155d00351f',
      contactName: 'Staff Generic Contact',
      businessPhone: '02 95271782',
      email: 'info@542partners.com.au',
      firstName: 'Staff Generic',
      lastName: 'Contact',
  },
  {
      contactid: '79c21d76-94d1-e311-9029-02bf0a033e0b',
      contactName: 'Adam Brown',
      businessPhone: '02 95271782',
      email: 'Adam.Brown@542partners.com.au',
      firstName: 'Adam',
      lastName: 'Brown',
  }
]