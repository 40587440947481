import { map, Observable, tap, timer } from 'rxjs';
import { chunk, cloneDeep, max } from 'lodash';
import { ICreditNote, ICreditNoteBillingEntity, INewCreditNote, IPaginatedResult } from 'interfaces';
import { mockedCreditNotes } from 'constants/MockedCreditNotes';
import { CreditNoteStatusType } from '../enums';
import { mockedBillingEntities } from '../constants/MockedBillingEntities';
import { BaseService } from './BaseService';
import { ConfigurationService, configurationService } from './ConfigurationService';

class CreditNotesService extends BaseService {
    // TODO: remove after implementing real api
    private allCreditNotes: Array<ICreditNote> = cloneDeep(mockedCreditNotes);

    constructor(configurationService: ConfigurationService) {
        super('creditNoteUrl', configurationService);
    }

    public getAllCreditNotes(clientId: string, page: number, pageSize: number): Observable<IPaginatedResult<ICreditNote>> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(
            map(() => {
                const pages = cloneDeep(chunk(this.allCreditNotes, pageSize));
                if (page > pages.length) {
                    throw new Error('Wrong page');
                }
                return { items: pages[page - 1], totalItemsCount: this.allCreditNotes.length, shownItemsCount: pages[page - 1].length };
            })
        );
        // FUI: example of api
        // return this.httpClient
        //     .get<Array<ICreditNote>>('api/credit-notes', { params: { page, pageSize } })
        //     .pipe(map((response) => response.data));
    }

    public deleteCreditNote(creditNoteId: number): Observable<any> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(
            tap(() => (this.allCreditNotes = this.allCreditNotes.filter((creditNote) => creditNote.id !== creditNoteId)))
        );
        // FUI: example of api
        // return this.httpClient.delete(`api/credit-notes/${creditNoteId}`).pipe(map((response) => response.data));
    }

    public createCreditNote(creditNote: INewCreditNote): Observable<ICreditNote> {
        // TODO: remove after implementing real api
        const newCreditNote = {
            creationDate: creditNote.creationDate,
            amount: creditNote.amount,
            billingEntity: mockedBillingEntities.find((be) => be.id === creditNote.billingEntityId) as ICreditNoteBillingEntity,
            id: (max(this.allCreditNotes.map((cn) => cn.id)) as number) + 1,
            clientId: 0,
            status: CreditNoteStatusType.Open,
        };
        return timer(1000).pipe(
            tap(() => (this.allCreditNotes = [...this.allCreditNotes, newCreditNote])),
            map(() => newCreditNote)
        );
        // FUI: example of api
        // return this.httpClient.post(`api/credit-notes`, creditNote).pipe(map((response) => response.data));
    }
}

export const creditNotesService = new CreditNotesService(configurationService);
