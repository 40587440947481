import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { commonHeaders, errorHandling } from 'utils';

type CustomizationResponse = {
    isDefault: boolean;
    logoImageUrl: string;
    themeViewModel: {
        neutralPrimary: string;
        themePrimary: string;
    };
};

function useGetTheme(url: string, isApplied: boolean, tenantId: string) {
    const refreshToken = localStorage.getItem('refreshToken');
    const location = useLocation();
    const {
        data: colors,
        isLoading,
        error,
    } = useQuery(
        ['getTheme', isApplied],
        () => axios.get<CustomizationResponse>(`${url}/api/1.0/Customisation?isApplied=${isApplied}`, commonHeaders()),
        {
            enabled: !!url && !!tenantId && !!refreshToken && refreshToken !== 'undefined' && !location.search.includes('?Code='),
            onError: (err: AxiosError) => {
                errorHandling('getTheme', err.response?.status || 400);
                // if (err.response?.status == 401) {
                //     return Promise.reject(err);
                // }
            },
            retry: false,
        }
    );

    return { colors, isLoading, error };
}

export { useGetTheme };
