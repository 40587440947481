import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Shimmer, ShimmerElementType, Stack } from '@fluentui/react';
import { InvoicePortalStore } from './stores';
import { InvoicesStore } from './stores/InvoicesStore';
import { InvoicesTableData } from './components/InvoicesTable';
import { useGetInvoices } from './hooks/Invoices/useGetInvoices';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

//TODO: remove this and all redux store
const InvoicesStoreContext = createContext<InvoicesStore>(new InvoicesStore(new InvoicePortalStore()));

export const useInvoicesStore = () => useContext(InvoicesStoreContext);

export const InvoicesPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const clientId = queryParams?.clientId ? String(queryParams.clientId) : '';
    const [filters, setFilters] = useState<{
        fund: string;
        status: string;
        year: string;
    }>({ fund: '', status: '', year: '' });

    useEffect(() => {
        if (!queryParams?.clientId) {
            navigate('/private');
        }
    }, [clientId, navigate, queryParams?.clientId]);

    const [pagination, setPagination] = useState<{ page: number; size: number }>({ page: 1, size: 10 });

    const { invoices, isLoading } = useGetInvoices({
        page: pagination.page,
        size: pagination.size,
        //fundId: filters.fund,
        status: filters?.status,
        year: filters?.year,
        clientId: clientId,
    });

    if (!invoices?.data || isLoading)
        return (
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 93 }]} />
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 602 }]} />
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 64 }]} />
            </Stack>
        );

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <InvoicesTableData
                setFilters={setFilters}
                filters={filters}
                invoices={invoices?.data.items}
                invoicesLoading={isLoading}
                invoicesPageSize={pagination.size}
                setInvoicesPagination={setPagination}
                invoicesPage={pagination.page}
                invoicesTotal={invoices?.data.totalItemsCount}
            />
        </Stack>
    );
};
