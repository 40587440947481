import { AuthStore } from './AuthStore';
import { BreadcrumbsStore } from './BreadcrumbsStore';
import { GlobalNotificationsStore } from './GlobalNotificationsStore';

export class MainStore {
    public AuthStore: AuthStore;
    public BreadcrumbsStore: BreadcrumbsStore;
    public GlobalNotificationsStore: GlobalNotificationsStore;

    constructor() {
        this.AuthStore = new AuthStore();
        this.BreadcrumbsStore = new BreadcrumbsStore();
        this.GlobalNotificationsStore = new GlobalNotificationsStore();
    }
}

const mainStore: MainStore = new MainStore();
export const stores = {
    MainStore: mainStore,
    AuthStore: mainStore.AuthStore,
    BreadcrumbsStore: mainStore.BreadcrumbsStore,
    GlobalNotificationsStore: mainStore.GlobalNotificationsStore,
};
