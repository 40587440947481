import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { DataTable } from 'components';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { IColumn, SelectionMode, Stack, useTheme, Text } from '@fluentui/react';
import { ScopedVariablesActions } from './ScopedVariablesActions';
import { IParamInfo, IRuleInfo } from 'interfaces';
import { ScopedVariableForm } from './ScopedVariableForm';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { useRulesetStore } from '../../../RulesetLayoutPage';
import { VariableModal } from './VariableModal';

export const ScopedVariables: FunctionComponent = observer(() => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, formState, reset } = useForm<IParamInfo>(DefaultFormSettings);
    const { rule } = useRulesetStore();
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);
    const [updatedRule, onUpdate] = useState<IRuleInfo>(rule!);
    const variables: IParamInfo[] = useMemo(() => {
        return updatedRule?.localParams!.filter((x) => !x.deleted);
    }, [updatedRule?.localParams]);
    const [tooltip, setTooltip] = useState<boolean>(true);
    const theme = useTheme();

    useEffect(() => {
        onUpdate(rule!);
    }, [rule, onUpdate]);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'add' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: () => {
                    setTooltip(false);
                    toggleIsAddModalOpen();
                },

                tooltipHostProps: {
                    tooltipProps: {
                        styles: { root: { display: tooltip ? 'block' : 'none' } },
                    },
                },
            },
        ],
        [formatMessage, toggleIsAddModalOpen, tooltip]
    );

    const columns: IColumn[] = [
        {
            key: 'name',
            name: formatMessage({ id: 'variableName' }),
            fieldName: 'name',
            minWidth: 150,
            targetWidthProportion: 1,
        },
        {
            key: 'expression',
            name: formatMessage({ id: 'variableExpression' }),
            fieldName: 'expression',
            minWidth: 150,
            maxWidth: 350,
            targetWidthProportion: 2,
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            targetWidthProportion: 1,
            onRender: (item) => <ScopedVariablesActions setUpdateRule={onUpdate} resetVar={reset} variable={item} />,
        },
    ];

    const onAddVariable = (data: IParamInfo) => {
        if (rule?.localParams) {
            rule.localParams = [...rule?.localParams, data];
        }

        toggleIsAddModalOpen();
        reset();
    };

    return (
        <>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
                onMouseEnter={() => setTooltip(true)}
            />
            {variables?.length ? (
                <DataTable
                    initialColumns={columns}
                    items={variables}
                    selectionMode={SelectionMode.none}
                    containerHeight='calc(100% - 32px)'
                />
            ) : (
                <Stack>
                    <Stack.Item align={'center'} styles={{ root: { margin: 16 } }}>
                        <Text>{'No variables found. Press "+" button to add a new one.'}</Text>
                    </Stack.Item>
                </Stack>
            )}

            {isAddModalOpen && (
                <VariableModal
                    isOpen={isAddModalOpen}
                    title={formatMessage({ id: 'addNewGlobalVariable' })}
                    onDismiss={toggleIsAddModalOpen}
                    onSubmit={handleSubmit(onAddVariable)}
                    btnTitle={formatMessage({ id: 'add' })}>
                    <ScopedVariableForm control={control} onSubmit={handleSubmit(onAddVariable)} formState={formState} />
                </VariableModal>
            )}

            {/* {isAddModalOpen && (
                <Modal isOpen={isAddModalOpen} onDismiss={toggleIsAddModalOpen} title={formatMessage({ id: 'addNewGlobalVariable' })}>
                    <ScopedVariableForm control={control} onSubmit={handleSubmit(onAddVariable)} formState={formState} />
                    <ModalFooter horizontalAlign={'end'}>
                        <PrimaryButton onClick={handleSubmit(onAddVariable)} text={formatMessage({ id: 'add' })} />
                    </ModalFooter>
                </Modal>
            )} */}
        </>
    );
});
