import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { DefaultButton, MessageBarType, PrimaryButton, Shimmer, ShimmerElementType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { takeUntil } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { Card, ControlledTextField } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useIsDestroy } from 'utils';
import { RouterPaths } from 'navigation';
import { stores } from 'stores';
import { useRulesetStore } from '../RulesetLayoutPage';
import { RulesetDeleteDialog } from './RulesetDeleteDialog';
import { RulesetRestoreDialog } from './RulesetRestoreDialog';

export const RulesetNameForm: FunctionComponent = observer(() => {
    const navigate = useNavigate();
    const [isRenaiming, setIsRenaiming] = useState<boolean>(false);
    const [hiddenDeleteDialog, { toggle: toggleHiddenDeleteDialog }] = useBoolean(true);
    const [hiddenRestoreDialog, { toggle: toggleHiddenRestoreDialog }] = useBoolean(true);
    const { ruleset, rulesetLoading, rulesetRenaming, rulesetDeleting, rulesetRestoring } = useRulesetStore();
    const { renameRuleset, deleteRuleset, restoreRuleset, loadRuleset } = useRulesetStore();
    const { formatMessage } = useIntl();
    const { control, setValue, formState, handleSubmit, reset } = useForm<{ name: string }>({
        ...DefaultFormSettings,
        defaultValues: { name: ruleset?.workflowName },
    });
    const isDestroy = useIsDestroy();

    const nameTouched = useMemo(() => {
        return formState.dirtyFields.name;
    }, [formState.dirtyFields.name]);

    useEffect(() => {
        if (ruleset?.workflowName) {
            setValue('name', ruleset?.workflowName);
        }
    }, [ruleset, setValue]);

    const onRename = ({ name }: { name: string }) => {
        setIsRenaiming(true);
        renameRuleset(name)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successRulesetRenameMessage' }),
                });
            }, (error) => {
                const nameExist = error?.response?.data?.code === 125020;
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'error' }),
                    type: MessageBarType.error,
                    description: formatMessage({ id: nameExist ? 'rulesetNameAlreadyExist' : 'somethingWentWrong' }),
                });
            })
            .add(() => setIsRenaiming(false));

        reset({ name });
    };

    const rulesetActionsDisabled = useMemo(() => {
        return rulesetDeleting || rulesetRestoring || rulesetRenaming;
    }, [rulesetDeleting, rulesetRenaming, rulesetRestoring]);

    const onDelete = () =>
        deleteRuleset()
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successRulesetDeleteMessage' }),
                });
                toggleHiddenDeleteDialog();
                navigate(RouterPaths.admin.rulesets.root);
                loadRuleset();
            });

    const onRestore = () => {
        restoreRuleset()
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successRulesetRestoreMessage' }),
                });
                toggleHiddenRestoreDialog();
                loadRuleset();
            });
    };

    return (
        <>
            <Card>
                {rulesetLoading || !ruleset ? (
                    <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 32 }]} />
                ) : (
                    <Stack horizontal horizontalAlign='space-between' tokens={{ childrenGap: 16 }}>
                        <Stack.Item grow={1}>
                            <ControlledTextField
                                name='name'
                                control={control}
                                underlined
                                rules={{ required: formatMessage({ id: 'requiredRulesetName' }) }}
                                disabled={ruleset.isDeleted}
                            />
                        </Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <PrimaryButton
                                disabled={ruleset.isDeleted || !nameTouched || rulesetActionsDisabled || !!formState.errors.name}
                                //text={formatMessage({ id: 'save' })}
                                onClick={handleSubmit(onRename)}
                                onRenderChildren={() =>
                                    isRenaiming ? <Spinner size={SpinnerSize.small} /> : <>{formatMessage({ id: 'save' })}</>
                                }
                            />

                            {ruleset.isDeleted ? (
                                <DefaultButton
                                    disabled={rulesetActionsDisabled}
                                    text={formatMessage({ id: 'restore' })}
                                    onClick={toggleHiddenRestoreDialog}
                                />
                            ) : (
                                <DefaultButton
                                    disabled={rulesetActionsDisabled}
                                    text={formatMessage({ id: 'delete' })}
                                    onClick={toggleHiddenDeleteDialog}
                                />
                            )}
                        </Stack>
                    </Stack>
                )}
            </Card>

            <RulesetDeleteDialog
                hidden={hiddenDeleteDialog}
                toggleHidden={toggleHiddenDeleteDialog}
                onAgree={onDelete}
                yesDisabled={rulesetDeleting}
            />
            <RulesetRestoreDialog
                hidden={hiddenRestoreDialog}
                toggleHidden={toggleHiddenRestoreDialog}
                onAgree={onRestore}
                yesDisabled={rulesetRestoring}
            />
        </>
    );
});
