import axios from 'axios';
import { IAppTenantIdResponse } from 'interfaces/IAppTenantIdResponse';
import { useQuery } from 'react-query';
// import { useNavigate } from 'react-router-dom';

/**
 * Getting Data for tenant-id.
 * One of the main hooks, because tenant-id use in every query in headers */

function useGetTenantId(url: string) {
    // const navigation = useNavigate();

    const { data, isLoading } = useQuery(
        ['getTenantId', url],
        () =>
            axios.get<IAppTenantIdResponse>(`${url}&hostname=${document.domain}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        {
            onSuccess: (res) => {
                localStorage.setItem('tenantId', res.data.GlobalId);
                //TODO
                // res.data.IsWhiteLabel ? navigation('/workflow/dashboard') : navigation('/workflow/jobs');
            },
            useErrorBoundary: true,
            enabled: !!url,
        }
    );

    return { data, isLoading };
}

export { useGetTenantId };
