import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useParams, Outlet } from 'react-router-dom';
import { RouterPaths, RouterPathsRulesetIdKey } from 'navigation';
import { PageWithBreadcrumb } from 'components';
import { RulesetStore } from '../stores/RulesetStore';

const RulesetStoreContext = createContext<RulesetStore>(new RulesetStore());

export const useRulesetStore = () => useContext(RulesetStoreContext);

export const RulesetLayoutPage: FunctionComponent = observer(() => {
    const params = useParams();
    const [store] = useState(new RulesetStore());
    const { formatMessage } = useIntl();

    useEffect(() => {
        const id = params[RouterPathsRulesetIdKey];
        if (!id) return;
        store.setId(+id);
        store.loadRuleset();
        return () => store.onDestroy();
    }, [params, store]);

    const breadcrumbText: string = store.ruleset?.workflowName ?? formatMessage({ id: 'ruleset' });

    return (
        <RulesetStoreContext.Provider value={store}>
            <PageWithBreadcrumb
                breadcrumb={{
                    id: 'ruleset',
                    text: breadcrumbText,
                    key: RouterPaths.admin.rulesets.ruleset.root(store.ruleset?.id),
                }}>
                <Outlet />
            </PageWithBreadcrumb>
        </RulesetStoreContext.Provider>
    );
});
