import { Dropdown, IDropdownOption } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    options: IDropdownOption[];
    multiSelect?: boolean;
    dropdownWidth?: number | 'auto';
    placeholder?: string;
}

export function ControlledDropdown<FormValues>({
    name, control, rules, label, options, multiSelect, dropdownWidth, placeholder,
}: Props<FormValues>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Dropdown
                    label={label}
                    options={options}
                    onChange={(_e, option) => {
                        if (multiSelect) {
                            let newValue = [...(value as string[])];
                            if (newValue.includes(option?.key as string)) {
                                newValue = newValue.filter((v) => v !== option?.key);
                            } else {
                                newValue.push(option?.key as string);
                            }

                            onChange(newValue);
                        } else {
                            onChange(option?.key);
                        }
                    }}
                    selectedKey={multiSelect ? undefined : (value as string)}
                    selectedKeys={multiSelect ? (value as string[]) : undefined}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    multiSelect={multiSelect}
                    dropdownWidth={dropdownWidth}
                    placeholder={placeholder}
                />
            )}
        />
    );
}
