import { Stack } from '@fluentui/react';
import { Card, Pagination } from 'components';
import { useState } from 'react';
import { jobsData } from '../components/data/JobsData';
import { JobsFilters, JobsDataTable } from '../components/tables';

export const JobsWorkflowPage = () => {
    const [, setFilters] = useState<{ pageSize: number; page: number }>({ pageSize: 1, page: 1 });

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters({ pageSize, page });
        // loadData();
    };
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <JobsFilters />
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <JobsDataTable />
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Card>
                    <Pagination
                        itemsCount={5}
                        total={jobsData.items.length}
                        page={jobsData.pageNumber}
                        pageSize={jobsData.pageSize}
                        onChange={onPaginationChange}
                    />
                </Card>
            </Stack.Item>
        </Stack>
    );
};