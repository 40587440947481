import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {ControlledTextField, SearchPanel} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useAdministrationContext} from '../AdministrationLayoutPage';
import {IAdministrationContext} from 'interfaces';

export const AdministrationFilters: FunctionComponent = observer(() => {
    const { isLoading, setLoading, setFilters } = useAdministrationContext();
    const { formatMessage } = useIntl();
    const { control, handleSubmit, reset } = useForm<{search: string}>({
        ...DefaultFormSettings,
        defaultValues: { search: '' },
    });

    const onClear = () => {
        reset({ search: '' });
    };

    return (
        <SearchPanel
            onSearch={handleSubmit((data) => {
                setLoading(true);
                setFilters((prev: IAdministrationContext['filters']) => ({...prev, page: 1, search: data.search}));
                setLoading(false);
            })}
            searchDisabled={isLoading}
            onClear={onClear}>
            <ControlledTextField
                name='search'
                control={control}
                label={formatMessage({ id: 'search' })}
            />
        </SearchPanel>
    );
});