import { Card, ControlledDropdown, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Checkbox, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';
import { Control } from 'react-hook-form';

type AuditTabType = {
    control: Control<ICreateUserCommand>;
};

export const AuditTab = ({ control }: AuditTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    const optionsSoftware = [
        { key: 'supermate', text: formatMessage({ id: 'supermate' }) },
        { key: 'bglSinpleFund', text: formatMessage({ id: 'bglSimpleFund' }) },
        { key: 'bgl360', text: formatMessage({ id: 'bgl360' }) },
        { key: 'other', text: formatMessage({ id: 'other' }) },
        { key: 'classSuperApi', text: formatMessage({ id: 'classSuperApi' }) },
        { key: 'classSuperPDF', text: formatMessage({ id: 'classSuperPDF' }) },
    ];

    const optionsRisk = [
        { key: 'low', text: formatMessage({ id: 'low' }) },
        { key: 'high', text: formatMessage({ id: 'high' }) },
        { key: 'medium', text: formatMessage({ id: 'medium' }) },
    ];
    const optionsGenerator = [
        { key: 'standart', text: formatMessage({ id: 'standart' }) },
        { key: 'asPerUnsignerAr', text: formatMessage({ id: 'asPerUnsignerAr' }) },
        { key: 'noSignerARGener', text: formatMessage({ id: 'noSignerARGener' }) },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={optionsSoftware}
                        name='accountingSoftware'
                        label={formatMessage({ id: 'accountingSoftware' })}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown options={[]} name='partner' label={formatMessage({ id: 'partner' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='turnAroundTime' label={formatMessage({ id: 'turnAroundTime' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='clientRiskRating'
                        options={optionsRisk}
                        label={formatMessage({ id: 'clientRiskRating' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={[
                            { key: 'no', text: formatMessage({ id: 'no' }) },
                            { key: 'yes', text: formatMessage({ id: 'yes' }) },
                        ]}
                        name='unsignedAuditReport'
                        label={formatMessage({ id: 'unsignedAuditReport' })}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={optionsGenerator}
                        name='aRGeneration'
                        label={formatMessage({ id: 'aRGeneration' })}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={[
                            { key: 'no', text: formatMessage({ id: 'no' }) },
                            { key: 'yes', text: formatMessage({ id: 'yes' }) },
                        ]}
                        name='unsignedARDate'
                        label={formatMessage({ id: 'unsignedARDate' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item
                    styles={{
                        root: {
                            width: 'calc(50% - 16px)',
                            display: 'flex',
                            marginTop: 32,
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        },
                    }}>
                    <Checkbox label={formatMessage({ id: 'titleSearchConduct' })} />
                    <Checkbox label={formatMessage({ id: 'ASICSearchConduct' })} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
