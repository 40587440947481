import { FunctionComponent, useMemo } from 'react';
import { IColumn, Icon, SelectionMode, Text, Stack, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Card, DataTable } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { mockedJobs } from 'constants/MockedJobs';
import { useAdministrationContext } from '../AdministrationLayoutPage';

export const JobsDataTable: FunctionComponent = () => {
    const { filters } = useAdministrationContext();
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const columns: IColumn[] = [
        {
            key: 'id',
            name: formatMessage({ id: 'id' }),
            fieldName: 'id',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item.id}
                </Text>
            ),
        },
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            fieldName: 'name',
            minWidth: 150,
        },
        {
            key: 'status',
            name: formatMessage({ id: 'status' }),
            minWidth: 150,
            maxWidth: 150,
            onRender: ({ status }) => status ? (
              <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { width: 32, height: 32 } }}>
                <Icon iconName='Accept'  styles={{ root: { color: theme.palette.green } }}  />
              </Stack>
            ) : null,
        },
        {
          key: 'initialInformation',
          name: '',
          minWidth: 300,
          onRender: (item) => (
            <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
              {formatMessage({ id: 'initialInformationChecklist' })}
            </Text>
          ),
        },
        {
          key: 'auditQueries',
          name: '',
          minWidth: 200,
          onRender: (item) => (
            <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
              {formatMessage({ id: 'auditQueries' })}
            </Text>
          ),
        },
        {
          key: 'documents',
          name: '',
          minWidth: 200,
          onRender: (item) => (
            <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
              {formatMessage({ id: 'documents' })}
            </Text>
          ),
        },
    ];

    const items = useMemo(() => {
      const searchItems = filters?.search
        ? mockedJobs.filter(job => job.name.toLowerCase().includes(filters.search?.toLowerCase() as string))
        : mockedJobs;
      return searchItems.slice((filters.page - 1) * filters.pageSize, filters.page * filters.pageSize);
    }, [filters]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={columns}
                shimmerLines={25}
                items={items}
                selectionMode={SelectionMode.none}
                enableShimmer={false}
                containerHeight='calc(100% - 32px)'
            />
        </Card>
    );
};