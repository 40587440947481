import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { PrimaryButton, Stack } from '@fluentui/react';
import { DefaultFormSettings } from 'constants/forms';
import { QueriesEmails } from './QueriesEmails';
import { AuditDispatchEmails } from './AuditDispatchEmails';
import { SetPasswordEmail } from './SetPasswordEmail';
import { SummarisedWorkflowReportEmail } from './SummarisedWorkflowReportEmail';
import { OutstandingQueriesReportEmail } from './OutstandingQueriesReportEmail';
import { DebtorsStatementEmail } from './DebtorsStatementEmail';
import { DetailedAuditWorkflowReportEmail } from './DetailedAuditWorkflowReportEmail';
import { JobLetterEmail } from './JobLetterEmail';
import { EvolvGreyDispatchEmail } from './EvolvGreyDispatchEmail';
import { MonthlyAgedBalancesReportEmail } from './MonthlyAgedBalancesReportEmail';
import { EmailTemplate } from 'pages/PortalSettings/hooks/Email/useEmailTemplateGet';

type EmailTemplateProps = {
    emailTemplate: EmailTemplate;
    updateEmailTemplate: (a: EmailTemplate) => void;
};

/**
 * THIS COMPONENT IT"S SIMPLE CONTAINER FOR EMAIL TEMPLATES.
 * WE USE useForm hook here and call all part of our page
 * API Data we get from props
 */

export const EmailTemplates = (props: EmailTemplateProps) => {
    const { control, handleSubmit, formState } = useForm<EmailTemplate>({ ...DefaultFormSettings, defaultValues: props.emailTemplate });
    const { formatMessage } = useIntl();

    const onUpdateEmailTemplate = (form: EmailTemplate) => {
        props.updateEmailTemplate(form);
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <QueriesEmails control={control} />

            <AuditDispatchEmails control={control} />

            <SetPasswordEmail control={control} />

            <SummarisedWorkflowReportEmail control={control} />

            <OutstandingQueriesReportEmail control={control} />

            <DebtorsStatementEmail control={control} />

            <DetailedAuditWorkflowReportEmail control={control} />

            <JobLetterEmail control={control} />

            <EvolvGreyDispatchEmail control={control} />

            <MonthlyAgedBalancesReportEmail control={control} />

            <Stack.Item styles={{ root: { marginBottom: '16px', justifyContent: 'flex-end', display: 'flex' } }}>
                <PrimaryButton
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(onUpdateEmailTemplate)}
                    text={formatMessage({ id: 'save' })}
                />
            </Stack.Item>
        </Stack>
    );
};
