import * as React from 'react';
import {
    ColumnActionsMode,
    DetailsListLayoutMode,
    IColumn,
    IShimmeredDetailsListProps,
    mergeStyles,
    ShimmeredDetailsList,
} from '@fluentui/react';
import { ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { IOrderedRequest } from 'interfaces';
import { OrderDirectionType } from 'enums';
import { IDetailsHeaderProps } from '@fluentui/react/lib/components/DetailsList/DetailsHeader';
import { TableFixedHeaderStyles } from '../constants/tableStylesPeset';

interface Props extends IShimmeredDetailsListProps {
    sortableColumnKeys?: string[];
    initialColumns: IColumn[];
    setOrder?: (value: IOrderedRequest) => void;
    afterOrderSet?: () => void;
    containerHeight?: string | number;
    inherit?: boolean;
}

interface State {
    columns: IColumn[];
}

export class DataTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onColumnClick = this.onColumnClick.bind(this);
        this.onRenderDetailsHeader = this.onRenderDetailsHeader.bind(this);
        this.state = {
            columns: props.initialColumns.map((c) => ({
                ...c,
                onColumnClick: props.sortableColumnKeys?.includes(c.key) ? this.onColumnClick : undefined,
                columnActionsMode: props.sortableColumnKeys?.includes(c.key) ? ColumnActionsMode.clickable : ColumnActionsMode.disabled,
            })),
        };
    }

    render() {
        const cellStyles = {
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: 14,
        };

        const styles = mergeStyles({
            selectors: {
                '.ms-DetailsRow-cell': cellStyles,
                '.ms-DetailsRow-cell.centred': {
                    justifyContent: 'center',
                },
                '.ms-DetailsHeader': {
                    padding: 0,
                },
                '.ms-DetailsList-contentWrapper': this.props.inherit
                    ? {
                          overflow: 'inherit',
                      }
                    : {},
            },
        });

        const {
            sortableColumnKeys,
            initialColumns,
            setOrder,
            afterOrderSet,
            layoutMode = DetailsListLayoutMode.justified,
            constrainMode = ConstrainMode.unconstrained,
            detailsListStyles = TableFixedHeaderStyles('100%'),
            containerHeight = '100%',
            ...otherProps
        } = this.props;

        const containerStyles = mergeStyles({ width: 'calc(100% - 12px)', height: containerHeight });

        return (
            <div className={containerStyles}>
                <ShimmeredDetailsList
                    columns={this.state.columns}
                    layoutMode={layoutMode}
                    constrainMode={constrainMode}
                    className={styles}
                    onRenderDetailsHeader={this.onRenderDetailsHeader}
                    detailsListStyles={detailsListStyles}
                    compact
                    {...otherProps}
                />
            </div>
        );
    }

    private onRenderDetailsHeader(headerProps?: IDetailsHeaderProps, defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null) {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    minHeight: 42,
                    height: 'fit-content',
                    display: 'inline-flex',
                    alignItems: 'stretch',

                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                        },
                        '>.ms-DetailsHeader-cell:first-child i': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        '.ms-DetailsHeader-cellTitle, .ms-DetailsHeader-cell': {
                            height: 'unset',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            padding: 0,
                            display: 'inline-flex',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        },
                        '.ms-DetailsHeader-cell > *': { position: 'initial', height: '100%', width: '100%' },
                    },
                },
            },
        });
    }

    private onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn) {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;

                if (this.props.setOrder) {
                    this.props.setOrder({
                        orderDirection: currColumn.isSortedDescending ? OrderDirectionType.DESC : OrderDirectionType.ASC,
                        orderBy: currColumn.fieldName,
                    });
                }
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.setState({ columns: newColumns });
        if (this.props.afterOrderSet) {
            this.props.afterOrderSet();
        }
    }
}
