import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { ControlledTextField } from '../ControlledTextField';
import { ControlledNumberField } from '../ControlledNumberField';
import { ControlledFileInput } from '../ControlledFileInput';

interface Props {
    open: boolean;
    toggle: () => void;
    onSubmit: (data: { src: string; height: number; width: number }) => void;
}

interface IImageForm {
    href?: string;
    imageBlob?: string;
    height: number;
    width: number;
}

export const RichTextImageEditForm: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, watch, formState } = useForm<IImageForm>(DefaultFormSettings);
    const [value, setValue] = useState<Partial<IImageForm>>();

    React.useEffect(() => {
        const subscription = watch((newValue) => setValue(newValue));
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <Modal title={formatMessage({ id: 'image' })} isOpen={props.open} onDismiss={props.toggle}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow={1}>
                                <ControlledTextField name='href' control={control} label={formatMessage({ id: 'url' })} />
                            </Stack.Item>
                            <Text styles={{ root: { lineHeight: '32px' } }}>or</Text>
                            <Stack.Item>
                                <ControlledFileInput
                                    control={control}
                                    name='imageBlob'
                                    label={formatMessage({ id: 'chooseTheImage' })}
                                    accept='image/*'
                                />
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow={1}>
                                <ControlledNumberField name='width' control={control} label={formatMessage({ id: 'width' })} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <ControlledNumberField name='height' control={control} label={formatMessage({ id: 'height' })} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Card>

                {value?.imageBlob ?? value?.href ? (
                    <Card>
                        {value.height && value.width ? (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img
                                src={value?.imageBlob ?? value?.href}
                                style={{ display: 'block', height: value.height, width: value.width, margin: 'auto' }}
                            />
                        ) : (
                            <div
                                style={{
                                    height: 300,
                                    width: 300,
                                    backgroundImage: `url(${value.imageBlob ?? value.href})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    margin: 'auto',
                                }}
                            />
                        )}
                    </Card>
                ) : null}
                <ModalFooter horizontalAlign='end'>
                    {/* <DefaultButton onClick={props.toggle} text={formatMessage({ id: 'cancel' })} /> */}
                    <PrimaryButton
                        onClick={handleSubmit((data) =>
                            props.onSubmit({ src: data.imageBlob ?? data.href ?? '', height: data.height, width: data.width })
                        )}
                        disabled={!formState.isDirty}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
};
