import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

export interface ICreateUser {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    isSuperUser: boolean;
}

function useCreateUser() {
    const context = React.useContext(Context);

    const { mutate: createUser, isLoading, isSuccess } = useMutation((data: ICreateUser) =>
        axios.post(`${context?.config?.userUrl}/api/1.0/User`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
        }
    );

    return { createUser, isLoading, isSuccess };
}

export { useCreateUser };
