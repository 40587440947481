import { FunctionComponent, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { BaseDialog, Card, DataTable } from 'components';
import { ActionButtonStyles, CardTableContainerStyles } from 'constants/tableStylesPeset';
import { jobsData } from 'pages/Workflow/components/data/JobsData';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import { FundModal, PartnerModal, YearModal, ClientModal } from 'pages/Workflow/components/modals';

export const JobsDataTable: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    const [showActivatedModal, setShowActivatedModal] = useState<boolean>(false);
    const [showClientModal, setShowClientModal] = useState<boolean>(false);
    const [showAuditAlocatedModal, setShowAuditAlocatedModal] = useState<boolean>(false);
    const [showFundModal, setShowFundModal] = useState<boolean>(false);
    const [showYearModal, setShowYearModal] = useState<boolean>(false);
    const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false);

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({ id: 'commands' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            onRender: (items) => (
                <ActionButton styles={{ root: { fontWeight: 500 } }} onClick={() => setShowActivatedModal(true)} allowDisabledFocus>
                    {items.active ? 'Deactivate' : 'Activate'}
                </ActionButton>
            ),
        },
        {
            key: 'clientName',
            name: formatMessage({ id: 'client' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'clientName',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowClientModal(true)} allowDisabledFocus>
                    {items.clientName}
                </ActionButton>
            ),
        },
        {
            key: 'fund',
            name: formatMessage({ id: 'fund' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'fund',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowFundModal(true)} allowDisabledFocus>
                    {items.fund}
                </ActionButton>
            ),
        },
        {
            key: 'year',
            name: formatMessage({ id: 'year' }),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'year',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowYearModal(true)} allowDisabledFocus>
                    {items.year}
                </ActionButton>
            ),
        },
        {
            key: 'dateReceived',
            name: formatMessage({ id: 'dateReceived' }),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'dateReceived',
            onRender: (items) => <Text>{items.dateReceived}</Text>,
        },
        {
            key: 'auditAllocated',
            name: formatMessage({ id: 'auditAllocatedTo' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'auditAllocated',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowAuditAlocatedModal(true)} allowDisabledFocus>
                    {items.auditAllocated}
                </ActionButton>
            ),
        },
        {
            key: 'partner',
            name: formatMessage({ id: 'partner' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'partner',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowPartnerModal(true)} allowDisabledFocus>
                    {items.partner}
                </ActionButton>
            ),
        },
        {
            key: 'dateArSent',
            name: formatMessage({ id: 'arSent' }),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'dateArSent',
            onRender: (items) => <Text>{items.dateArSent}</Text>,
        },
        {
            key: 'daysIn',
            name: formatMessage({ id: 'daysIn' }),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'daysIn',
            onRender: (items) => <Text>{items.daysIn}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={[
                    'commands',
                    'clientName',
                    'fund',
                    'year',
                    'dateReceived',
                    'auditAllocated',
                    'partner',
                    'dateArSent',
                    'daysIn',
                ]}
                initialColumns={columns}
                items={jobsData.items}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            {showActivatedModal && (
                <BaseDialog
                    title={formatMessage({ id: 'changeActivDialogTitle' })}
                    subText={formatMessage({ id: 'changeActivDialogSubText' })}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivatedModal(false)}
                />
            )}

            <ClientModal
                isOpen={showClientModal}
                onDismiss={() => setShowClientModal(false)}
                title={formatMessage({ id: 'client' })}
            />

            <FundModal
                isOpen={showFundModal}
                onDismiss={() => setShowFundModal(false)}
                title={formatMessage({ id: 'fund' })}
            />

            <PartnerModal
                isOpen={showPartnerModal}
                onDismiss={() => setShowPartnerModal(false)}
                title={formatMessage({ id: 'partner' })}
            />

            <YearModal
                isOpen={showYearModal}
                onDismiss={() => setShowYearModal(false)}
                title={formatMessage({ id: 'year' })}
            />

            <PartnerModal
                isOpen={showAuditAlocatedModal}
                onDismiss={() => setShowAuditAlocatedModal(false)}
                title={formatMessage({ id: 'auditAllocatedTo' })}
            />
        </Card>
    );
};
