import { MessageBarType } from '@fluentui/react';
import axios from 'axios';
import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { stores } from 'stores';
import { commonHeaders, Context, queryClient } from 'utils';

type CreateInvoiceReq = {
    jobId: number;
    amount: number;
    extraAmounts: {
        amount?: number;
        paragraph?: number;
    }[];
    creationDate: string;
    paragraph: number;
    billingEntityType: number;
};

/** Mutation query for creating new Invoice*/
function useCreateInvoice(clientId: string, onClose: () => void) {
    const context = React.useContext(Context);
    const { formatMessage } = useIntl();

    const { mutate: createInvoice, isLoading: creatingInvoiceLoading } = useMutation(
        (data: CreateInvoiceReq) => axios.post(`${context?.config?.billingUrl}/api/1.0/Invoice/Create/${clientId}`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getInvoices');
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'successInvoceCreating' }),
                });
                onClose();
            },
            onError: () => {
                queryClient.invalidateQueries('getInvoices');
                stores.GlobalNotificationsStore.addNotification({
                    name: formatMessage({ id: 'error' }),
                    type: MessageBarType.error,
                    description: formatMessage({ id: 'somethingWentWrong' }),
                });
            },
        }
    );

    return { createInvoice, creatingInvoiceLoading };
}

export { useCreateInvoice };
