import { FunctionComponent, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { BaseDialog, Card, DataTable } from 'components';
import { ActionButtonStyles, CardTableContainerStyles } from 'constants/tableStylesPeset';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import { allData } from 'pages/Workflow/components/data/Data';
import { ClientModal, FundModal } from 'pages/Workflow/components/modals';

export const FundsDataTable: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    const [showActivetedModal, setShowActivetedModal] = useState<boolean>(false);
    const [showMainModal, setShowMainModal] = useState<boolean>(false);
    const [showClientModal, setShowClientModal] = useState<boolean>(false);

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({ id: 'commands' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            onRender: (items) => (
                <ActionButton styles={{ root: { fontWeight: 500 } }} onClick={() => setShowActivetedModal(true)} allowDisabledFocus>
                    {items.active ? 'Deactivate' : 'Activate'}
                </ActionButton>
            ),
        },
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 150,
            maxWidth: 500,
            fieldName: 'name',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowMainModal(true)} allowDisabledFocus>
                    {items.name}
                </ActionButton>
            ),
        },
        {
            key: 'client',
            name: formatMessage({ id: 'client' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'client',
            onRender: (items) => (
                <ActionButton styles={ActionButtonStyles} onClick={() => setShowClientModal(true)} allowDisabledFocus>
                    {items.primaryContact}
                </ActionButton>
            ),
        },
        {
            key: 'primaryContact',
            name: formatMessage({ id: 'primaryContact' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'primaryContact',
            onRender: (items) => <Text>{items.phone}</Text>,
        },
        {
            key: 'trustee',
            name: formatMessage({ id: 'trustee' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'trustee',
            onRender: (items) => <Text>{items.addressState}</Text>,
        },
        {
            key: 'abn',
            name: formatMessage({ id: 'abn' }),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'abn',
            onRender: (items) => <Text>{items.uid}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['name', 'client', 'primaryContact', 'trustee', 'abn']}
                initialColumns={columns}
                items={allData.items}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            {showActivetedModal && (
                <BaseDialog
                    title={formatMessage({ id: 'changeActivDialogTitle' })}
                    subText={formatMessage({ id: 'changeActivDialogSubText' })}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivetedModal(false)}
                />
            )}

            <ClientModal isOpen={showClientModal} onDismiss={() => setShowClientModal(false)} title={formatMessage({ id: 'client' })} />

            <FundModal isOpen={showMainModal} onDismiss={() => setShowMainModal(false)} title={formatMessage({ id: 'fund' })} />
        </Card>
    );
};
