import { ChartProps, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { Stack, useTheme } from '@fluentui/react';

type PropsChartsWidget = {
	dataNumbers: number[];
	groupedLabels: string[];
	height?: number | string;
};

export const DoghnutChart = ({ dataNumbers, groupedLabels, height = 300  }: PropsChartsWidget) => {
	const theme = useTheme();
	const data = {
		labels: groupedLabels,
		datasets: [
			{
				label: 'My First Dataset',
				data: dataNumbers,
				backgroundColor: [
					theme.palette.themeDarker,
					theme.palette.themeSecondary,
					theme.palette.blackTranslucent40,
					theme.palette.neutralTertiaryAlt,
				],
				hoverOffset: 4,
				borderColor: theme.palette.white,
			},
		],
	};

	const config: ChartProps<'doughnut'> = {
		type: 'doughnut',
		data: data,
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: 'left',
					align: 'start',
					labels: {
						color: theme.palette.black,
					},
				},
			},
		},
	};

	return (
		<Stack styles={{ root: {
			canvas: {
				minHeight: height,
				maxHeight: height,
				width: '100%',
			}
		} }}>
			<Doughnut {...config} />
		</Stack>
	);
};
