import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context, errorHandling, queryClient } from 'utils';

type TitleRes = {
    companyTitle: string;
};

/**Query for get general Title data in Portal Settings*/

function useGetGeneralTitle() {
    const context = React.useContext(Context);

    const { data: generalTitle, isLoading } = useQuery(
        ['getGeneralTitle'],
        () => axios.get<TitleRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/web`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
            onError: (err: AxiosError) => {
                errorHandling('getGeneralTitle', err.response?.status || 400, () => {
                    queryClient.invalidateQueries('getGeneralYear');
                    queryClient.invalidateQueries('getGeneralSettings');
                });
            },
        }
    );

    return { generalTitle, isLoading };
}

export { useGetGeneralTitle };
