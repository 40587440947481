import axios from 'axios';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export type RefreshTokenResponse = {
    accessToken: string;
    refreshToken: string;
};

/**Simple hook for getting token*/

function useGetToken() {
    const httpClientConfig =
        process.env.NODE_ENV === 'development' ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/identity/' : 'identity/';

    const navigate = useNavigate();
    const location = useLocation();

    const { data: tokens, isError } = useQuery(
        ['useGetToken'],
        () =>
            axios.get<RefreshTokenResponse>(`${httpClientConfig}api/1.0/Authorization/GetToken${location.search}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        {
            enabled: location.search.includes('?Code='),
            onSuccess: (newTokens) => {
                localStorage.setItem('refreshToken', newTokens.data.refreshToken);
                localStorage.setItem('accessToken', newTokens.data.accessToken);
                navigate('/');
            },
        }
    );

    return { tokens, isError };
}

export { useGetToken };
