import { FunctionComponent, useState } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack, useTheme, INavLink } from '@fluentui/react';
import { Modal, ModalFooter, VerticalMenu } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import {
    ManagerTab,
    PartnerTab,
    YearGeneralTab,
    AuditProgramTab,
    QueryRatingTab,
    PreARQueriesTab,
    DispatchTab,
    InvoicingTab,
    ContraventionTab,
    TimesheetTab,
} from './tabs';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export enum YearModalTabs {
    GENERAL,
    MANAGER,
    PARTNER,
    AUDIT_PROGRAM,
    QUERY_RATING,
    PRE_AR_QUERIES,
    DISPATCH,
    INVOICING,
    CONTRAVENTION,
    TIMESHEET,
}

export const YearModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            auditJobName: '',
            fundName: '',
            jobYear: '',
            auditAllocatedTo: '',
            dateAuditStarted: '',
            daysIn: '',
            dateReceived: '',
            inherentRiskRating: '',
            targetDateforCompletion: '',
            readyForMGRReview: '',
            manager: '',
            mgrPointsReviewedByAuditor: '',
            mgrPointsRaised: '',
            readyForPartnerReview: '',
            partnerName: '',
            pnrPointsReviewedByAuditor: '',
            pnrPointsRaised: '',
            reviewedByPartner: '',
            accSoftwareFundCode: '',
            accSoftwareBusinessCode: '',
            queryRating: '',
            preARQueriesSent: '',
            reviewedOSQueries: '',
            preARQueriesResponse: '',
            unsignedARSent: '',
            arSent: '',
            postARQueriesSent: '',
            reviewedPostARQueries: '',
            postARQueriesResponse: '',
            pdfWorkpaperCreated: '',
            auditFinalised: '',
            invoiceDate: '',
            invoiceNumber: '',
            invoiceAmount: '',
            contraventionEventReadyForReview: '',
            contraventionSent: '',
            contraventionLodged: '',
            mgrContraventionEventReviewed: '',
            pnrContraventionEventReviewed: '',
        },
    });
    const { formatMessage } = useIntl();

    const onSubmit = (data: ICreateUserCommand) => {
        console.log(data);
        onDismiss();
    };

    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const theme = useTheme();

    const [activeTab, setActiveTab] = useState<YearModalTabs>(YearModalTabs.GENERAL);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'jobWorkflowTab' }),
            url: '',
            key: 'jobWorkflowTab',
            iconProps: { iconName: 'MapLayers' },
            links: [
                {
                    name: formatMessage({ id: 'generalInformation' }),
                    url: '',
                    key: 'generalInformation',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.GENERAL);
                    },
                },
                {
                    name: formatMessage({ id: 'manager' }),
                    url: '',
                    key: 'manager',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.MANAGER);
                    },
                },
                {
                    name: formatMessage({ id: 'partner' }),
                    url: '',
                    key: 'partner',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.PARTNER);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({ id: 'auditProgramTab' }),
            url: '',
            key: 'auditProgramTab',
            iconProps: { iconName: 'MapLayers' },
            onClick: () => {
                setActiveTab(YearModalTabs.AUDIT_PROGRAM);
            },
        },
        {
            name: formatMessage({ id: 'queriesTab' }),
            url: '',
            key: 'queriesTab',
            iconProps: { iconName: 'MapLayers' },
            links: [
                {
                    name: formatMessage({ id: 'queryRating' }),
                    url: '',
                    key: 'queryRating',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.QUERY_RATING);
                    },
                },
                {
                    name: formatMessage({ id: 'preARQueries' }),
                    url: '',
                    key: 'preARQueries',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.PRE_AR_QUERIES);
                    },
                },
            ],
            isExpanded: false,
        },
        {
            name: formatMessage({ id: 'dispatchTab' }),
            url: '',
            key: 'dispatchTab',
            iconProps: { iconName: 'MapLayers' },
            links: [
                {
                    name: formatMessage({ id: 'dispatch' }),
                    url: '',
                    key: 'dispatch',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.DISPATCH);
                    },
                },
                {
                    name: formatMessage({ id: 'invoicing' }),
                    url: '',
                    key: 'invoicing',
                    iconProps: { iconName: 'KnowledgeArticle' },
                    onClick: () => {
                        setActiveTab(YearModalTabs.INVOICING);
                    },
                },
            ],
            isExpanded: false,
        },
        {
            name: formatMessage({ id: 'contravention' }),
            url: '',
            key: 'contravention',
            iconProps: { iconName: 'MapLayers' },
            onClick: () => {
                setActiveTab(YearModalTabs.CONTRAVENTION);
            },
        },
        {
            name: formatMessage({ id: 'timesheet' }),
            url: '',
            key: 'timesheet',
            iconProps: { iconName: 'MapLayers' },
            onClick: () => {
                setActiveTab(YearModalTabs.TIMESHEET);
            },
        },
    ]);

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            background: theme.palette.white,
                        },
                    }}>
                    <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                        <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />

                        <Stack
                            styles={{
                                root: {
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                                    background: theme.palette.neutralLight,
                                    padding: '0 0 16px 16px',
                                    boxSizing: 'border-box',
                                    minHeight: '616px',
                                },
                            }}
                            tokens={{ childrenGap: 16 }}>
                            <Stack.Item styles={{ root: { height: '600px' } }}>
                                {activeTab === YearModalTabs.GENERAL ? (
                                    <YearGeneralTab control={control} />
                                ) : activeTab === YearModalTabs.MANAGER ? (
                                    <ManagerTab control={control} />
                                ) : activeTab === YearModalTabs.PARTNER ? (
                                    <PartnerTab control={control} />
                                ) : activeTab === YearModalTabs.AUDIT_PROGRAM ? (
                                    <AuditProgramTab control={control} />
                                ) : activeTab === YearModalTabs.QUERY_RATING ? (
                                    <QueryRatingTab control={control} />
                                ) : activeTab === YearModalTabs.PRE_AR_QUERIES ? (
                                    <PreARQueriesTab control={control} />
                                ) : activeTab === YearModalTabs.DISPATCH ? (
                                    <DispatchTab control={control} />
                                ) : activeTab === YearModalTabs.INVOICING ? (
                                    <InvoicingTab control={control} />
                                ) : activeTab === YearModalTabs.CONTRAVENTION ? (
                                    <ContraventionTab control={control} />
                                ) : activeTab === YearModalTabs.TIMESHEET ? (
                                    <TimesheetTab />
                                ) : (
                                    ''
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>

                    <ModalFooter horizontalAlign='end'>
                        <PrimaryButton
                            disabled={activeTab === YearModalTabs.TIMESHEET ? false : !formState.isDirty}
                            onClick={handleSubmit(onSubmit)}
                            text={formatMessage({ id: 'save' })}
                        />
                    </ModalFooter>
                </Stack>
            </Stack>
        </Modal>
    );
});
