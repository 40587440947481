import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { CheckboxVisibility, IColumn, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { IObjectWithKey } from '@fluentui/react/dist/react';
import { MockedClients } from 'constants/MockedUserClients';
import { Card, DataTable, ModalFooter, Pagination } from 'components';
import { IUserInfo } from 'interfaces';
import { useEditUserAssignClients } from '../hooks/useEditUserAssignClients';

interface Props {
    user: IUserInfo;
    onDismiss: () => void;
}

export const UserManagementEditClients: FunctionComponent<Props> = ({ user, onDismiss }: Props) => {
    const { editUserAssignClients, isLoading: clientsAssigning } = useEditUserAssignClients();
    const [selectedItemsCount, setSelectedItemsCount] = useState(0);
    const [selection] = useState(
        new Selection({
            items: user.clients.map((c) => ({ ...c, key: c.clientGuid })) as IObjectWithKey[],
            onSelectionChanged: (): void => setSelectedItemsCount(selection.getSelectedCount()),
        })
    );
    const { formatMessage } = useIntl();
    const columns: IColumn[] = [{ key: 'name', fieldName: 'name', minWidth: 100, name: formatMessage({ id: 'name' }) }];

    useEffect(() => {
        user.clients.forEach((c) => selection.setKeySelected(c.clientGuid, true, false));
    }, [selection, user.clients]);

    const onSubmit = () => {
        editUserAssignClients({ userId: user.id, body: selection.getSelection() })
        onDismiss();
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <MarqueeSelection selection={selection}>
                    <Stack styles={{ root: { height: 500, overflowX: 'scroll', overflowY: 'none' } }}>
                        <DataTable
                            initialColumns={columns}
                            selection={selection}
                            items={MockedClients.map((c) => ({ ...c, key: c.clientGuid }))}
                            containerHeight={'100%'}
                            selectionMode={SelectionMode.multiple}
                            setKey={'multiple'}
                            getKey={(i) => i.clientGuid}
                            checkboxVisibility={CheckboxVisibility.always}
                        />
                    </Stack>
                </MarqueeSelection>
                <Text variant='small' styles={{ root: { fontWeight: 500 } }}>
                    <FormattedMessage id='clientsSelected' values={{ value: selectedItemsCount }} />
                </Text>
            </Card>
            <Card>
                <Pagination />
            </Card>
            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton onClick={onDismiss} text={formatMessage({ id: 'cancel' })} /> */}
                <PrimaryButton disabled={clientsAssigning} onClick={onSubmit} text={formatMessage({ id: 'save' })} />
            </ModalFooter>
        </Stack>
    );
};
