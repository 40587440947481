import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Card, Pagination } from 'components';
import { useCreditNotesStore } from '../../CreditNotesPage';

export const CreditNotesTablePagination: FunctionComponent = observer(() => {
    const { creditNotesTotal, creditNotes, creditNotesPage, creditNotesPageSize, setCreditNotesPagination, loadCreditNotes } =
        useCreditNotesStore();

    const handlePaginationChange = (pageSize: number, page: number) => {
        setCreditNotesPagination(pageSize, page);
        loadCreditNotes();
    };

    return (
        <Card>
            <Pagination
                itemsCount={creditNotes?.length ?? 0}
                page={creditNotesPage}
                pageSize={creditNotesPageSize}
                total={creditNotesTotal}
                onChange={handlePaginationChange}
            />
        </Card>
    );
});
