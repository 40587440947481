import { ICreditNote } from '../interfaces';
import { BillingEntityType, CreditNoteStatusType } from '../enums';

export const mockedCreditNotes: ICreditNote[] = [
    {
        id: 1102,
        amount: 22,
        creationDate: '2014-07-29T09:54:41.11',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1637, name: 'S & L Coates Family Superannuation Fund' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1125,
        amount: 22,
        creationDate: '2015-06-02T08:39:46.277',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1632, name: 'JR Douglas Superannuation Fund' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1399,
        amount: 1,
        creationDate: '2018-03-19T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1797,
        amount: 45,
        creationDate: '2022-02-03T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1622, name: 'Andrew Milliken Superannuation Fund' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1798,
        amount: 70,
        creationDate: '2022-02-04T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1802,
        amount: 55,
        creationDate: '2022-02-07T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1622, name: 'Andrew Milliken Superannuation Fund' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1803,
        amount: 38,
        creationDate: '2022-02-07T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1805,
        amount: 20,
        creationDate: '2022-02-07T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1806,
        amount: 30,
        creationDate: '2022-02-07T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1622, name: 'Andrew Milliken Superannuation Fund' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1807,
        amount: 40,
        creationDate: '2022-02-08T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1622, name: 'Andrew Milliken Superannuation Fund' },
        status: CreditNoteStatusType.Open,
    },
    {
        id: 1808,
        amount: 40,
        creationDate: '2022-02-08T00:00:00',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1627, name: 'Fairhaven Retirement Fund' },
        status: CreditNoteStatusType.Open,
    },
    {
        id: 1809,
        amount: 35,
        creationDate: '2022-02-09T02:21:55.39',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Closed,
    },
    {
        id: 1810,
        amount: 10,
        creationDate: '2022-02-09T02:23:06.157',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Fund, id: 1632, name: 'JR Douglas Superannuation Fund' },
        status: CreditNoteStatusType.Open,
    },
    {
        id: 1826,
        amount: 100,
        creationDate: '2022-02-16T13:04:58.513',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Open,
    },
    {
        id: 1827,
        amount: 100,
        creationDate: '2022-02-16T13:07:00.207',
        clientId: 65,
        billingEntity: { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
        status: CreditNoteStatusType.Open,
    },
];
