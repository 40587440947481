import { Card, ControlledDatePicker } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type DispatchTabType = {
    control: Control<ICreateUserCommand>;
};

export const DispatchTab = ({ control }: DispatchTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='unsignedARSent'
                        label={formatMessage({ id: 'unsignedARSent' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='arSent'
                        label={formatMessage({ id: 'arSent' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item
                    styles={{
                        root: {
                            width: '100%',
                            padding: '16px 0',
                            borderTop: `1px solid ${theme.palette.neutralLight}`,
                            borderBottom: `1px solid ${theme.palette.neutralLight}`,
                        },
                    }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDatePicker
                                name='postARQueriesSent'
                                label={formatMessage({ id: 'postARQueriesSent' })}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDatePicker
                                name='postARQueriesResponse'
                                label={formatMessage({ id: 'postARQueriesResponse' })}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDatePicker
                                name='reviewedPostARQueries'
                                label={formatMessage({ id: 'reviewedPostARQueries' })}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='pdfWorkpaperCreated'
                        label={formatMessage({ id: 'pdfWorkpaperCreated' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='auditFinalised'
                        label={formatMessage({ id: 'auditFinalised' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
