import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { VerticalSubmenu } from 'components/VerticalSubmenu';
import { mockedContacts } from 'constants/MockedContacts';
import { ContactTab } from '../ContactTab';

interface Props {
    open: boolean;
    toggle: () => void;
    onSubmit: (data: any) => void;
}

interface IClientForm {
    contact: string;
    firstName: string;
    lastName: string;
    email: string;
    businessPhone: string;
}

export const EditClientModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, watch, setValue, formState } = useForm<IClientForm>({
        ...DefaultFormSettings,
        defaultValues: {
            contact: '',
            firstName: '',
            lastName: '',
            email: '',
            businessPhone: '',
        },
    });

    const [selectedTab, setSelectedTab] = useState<'contact' | 'apiAuthorisation'>('contact');

    const links = [
        {
            name: 'Contact',
            key: 'contact',
            url: '',
        },
        {
            name: 'Api Authorisation',
            key: 'apiAuthorisation',
            url: '',
        },
    ];

    return (
        <Modal title={formatMessage({ id: 'editClient' })} isOpen={props.open} onDismiss={props.toggle}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <VerticalSubmenu setSelected={setSelectedTab} defaultValue={selectedTab} links={links} />
                        <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                            {selectedTab === 'contact' && (
                                <ContactTab control={control} watch={watch} setValue={setValue} contacts={mockedContacts} />
                            )}
                            {selectedTab === 'apiAuthorisation' && (
                                <Stack.Item>
                                    <PrimaryButton
                                        text={formatMessage({ id: 'clickHereToSendAuthorisation' })}
                                        styles={{ root: { width: '100%' } }}
                                    />
                                </Stack.Item>
                            )}
                        </Stack>
                    </Stack>
                </Card>

                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit((data) => props.onSubmit(data))}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
};
