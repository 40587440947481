import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { IUpdateFeesValues } from 'interfaces';
import { Card, ControlledDatePicker, ControlledDropdown, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useAddMultipleJobsStore } from './InvoicesAddMultipleJobsModal';
import { UpdateFeesDataTable } from './UpdateFeesDataTable';

interface Props {
    onClose: () => void;
}

export const InvoicesAddMultipleJobsUpdateFees: FunctionComponent<Props> = observer((props: Props) => {
    const { selectedInvoices, updateFees } = useAddMultipleJobsStore();
    const { formatMessage } = useIntl();
    const { control, handleSubmit } = useForm<IUpdateFeesValues>(DefaultFormSettings);

    const invoiceParagraphOptions: IDropdownOption[] = [
        { key: 'DD', text: 'DD' },
        { key: 'Standard', text: 'Standard' },
    ];

    const selectedInvoicesTotal: number = selectedInvoices.reduce((prev, curr) => prev + (curr.total ?? 0), 0);

    const onSubmit = (data: IUpdateFeesValues) => updateFees(data);

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <ControlledDatePicker
                            name='creationDate'
                            control={control}
                            label={formatMessage({ id: 'creationDate' })}
                            rules={{ required: formatMessage({ id: 'requiredCreationDate' }) }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <ControlledDropdown
                            name='invoiceParagraph'
                            control={control}
                            options={invoiceParagraphOptions}
                            label={formatMessage({ id: 'invoiceParagraph' })}
                            rules={{ required: formatMessage({ id: 'requiredInvoiceParagraph' }) }}
                        />
                    </Stack.Item>
                </Stack>
            </Card>

            <Card>
                <Stack tokens={{ childrenGap: 8 }}>
                    <UpdateFeesDataTable
                        invoices={cloneDeep(selectedInvoices)}
                        total={selectedInvoicesTotal}
                        jobsSelectedCount={selectedInvoices.length}
                    />
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton text={formatMessage({ id: 'close' })} onClick={props.onClose} /> */}

                <PrimaryButton
                    disabled={!selectedInvoices.length}
                    text={formatMessage({ id: 'updateFees' })}
                    onClick={handleSubmit(onSubmit)}
                />
            </ModalFooter>
        </Stack>
    );
});
