import { FunctionComponent } from 'react';
import { Text, useTheme } from '@fluentui/react';
import { InvoiceStatusType } from 'enums';
import { InvoiceItem } from 'pages/InvoicePortal/hooks/Invoices/useGetInvoices';

interface Props {
    invoice: InvoiceItem;
}

export const InvoiceStatus: FunctionComponent<Props> = ({ invoice }: Props) => {
    const theme = useTheme();
    let statusColor: string;
    let statusName: string;

    switch (invoice.status) {
        case InvoiceStatusType.NotCreated:
            statusColor = theme.palette.blue;
            statusName = 'Not Created';
            break;
        case InvoiceStatusType.Closed:
            statusColor = theme.palette.red;
            statusName = 'Closed';
            break;
        case InvoiceStatusType.Open:
            statusColor = theme.palette.green;
            statusName = 'Open';
            break;
        default:
            statusColor = theme.palette.neutralQuaternary;
            statusName = 'Undefined';
            break;
    }

    return (
        <Text
            variant='medium'
            styles={{
                root: {
                    backgroundColor: statusColor,
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: 2,
                },
            }}>
            {statusName}
        </Text>
    );
};
