import { useState, useEffect } from 'react';

export function useIsDarkTheme() {
  const initValue = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isUserPreferDarkTheme, setUserPreferDarkTheme] = useState<boolean>(initValue);

  const handleChange = (event: MediaQueryListEvent) => {
    setUserPreferDarkTheme(event.matches);
  }

  useEffect(() => {
    const colorMedia = window.matchMedia('(prefers-color-scheme: dark)');
    colorMedia.addEventListener('change', handleChange);
    return () => {
      colorMedia.removeEventListener('change', handleChange);
    }
  }, []);

  return isUserPreferDarkTheme;
}