import { Stack, Text, DefaultButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react/lib/Styling';
import { useNavigate } from 'react-router-dom';

const theme = getTheme();
const { fonts } = theme;

export const NotFoundPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const classNames = mergeStyleSets({
    container: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyCotent: 'center',
    },
    title: {
      fontSize: 144,
      marginBottom: 16,
    },
    subtitle: {
      ...fonts.xLarge,
    },
  });
  const onBackClick = () => {
    navigate('/')
  };
  return (
    <Stack styles={{ root: { height: '100%', overflow: 'hidden' } }}>
      <Stack className={classNames.container} tokens={{ childrenGap: 16 }} >
        <Text as='h2' className={classNames.title}>
          404
        </Text>
        <Text className={classNames.subtitle}>
          {formatMessage({ id: 'pageNotFound' })}
        </Text>
        <Text>
          {formatMessage({ id: 'thePageYouAreLookingForDoesntExist' })}
        </Text>
        <DefaultButton onClick={onBackClick} text={formatMessage({ id: 'openHomepage' })} />
      </Stack>
    </Stack>
)};