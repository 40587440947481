import { IStyleFunctionOrObject, ITextFieldStyles, TextField, ITextFieldStyleProps, useTheme } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { IIconProps } from '@fluentui/react/lib/Icon';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    multiline?: boolean;
    placeholder?: string;
    underlined?: boolean;
    borderless?: boolean;
    iconProps?: IIconProps;
    isPassword?: boolean;
    disabled?: boolean;
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
    rows?: number;
}

export function ControlledTextField<FormValues>({
    name,
    control,
    rules,
    label,
    multiline,
    placeholder,
    underlined,
    borderless,
    iconProps,
    isPassword,
    disabled,
    styles,
    rows,
}: Props<FormValues>) {
    const theme = useTheme();
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                <TextField
                    styles={{
                        field: {
                            '-webkit-box-shadow': `inset 0 0 0 50px ${theme.palette.white}`,
                            '-webkit-text-fill-color': theme.palette.black,
                        },
                        ...styles,
                    }}
                    label={label}
                    name={fieldName}
                    onChange={onChange}
                    value={value as string}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    underlined={underlined}
                    borderless={borderless}
                    iconProps={iconProps}
                    type={isPassword ? 'password' : undefined}
                    disabled={disabled}
                />
            )}
        />
    );
}
