import { FunctionComponent } from 'react';
import {
    CountWidget,
    ICountWidget,
    TableWidget,
    ITableWidget,
    DoughnutWidget,
    IDoughnutWidget,
    DoubleCountWidget,
    IDoubleCountWidget,
    BarChartWidget,
    IBarChartWidget,
} from 'pages/Workflow/components/widgets';

export enum WidgetsEnum {
    COUNT_WIDGET,
    TABLE_WIDGET,
    DOUGHNUT_WIDGET,
    BAR_CHART_WIDGET,
    DOUBLE_COUNT_WIDGET,
}

interface _IWidget {
	type: WidgetsEnum;
	id: string;
	size?: number;
}

interface IWidget1 extends _IWidget {
	type: WidgetsEnum.COUNT_WIDGET;
	props: ICountWidget;
}

interface IWidget2 extends _IWidget {
	type: WidgetsEnum.TABLE_WIDGET;
	props: ITableWidget;
}

interface IWidget3 extends _IWidget {
	type: WidgetsEnum.DOUGHNUT_WIDGET;
	props: IDoughnutWidget;
}

interface IWidget4 extends _IWidget {
    type: WidgetsEnum.DOUBLE_COUNT_WIDGET;
    props: IDoubleCountWidget;
}

interface IWidget5 extends _IWidget {
    type: WidgetsEnum.BAR_CHART_WIDGET;
    props: IBarChartWidget;
}

export type Widgets = IWidget1 | IWidget2 | IWidget3 | IWidget4 | IWidget5;

export const Widget: FunctionComponent<Widgets> = ({ type, props }) => {
    switch (type) {
        case WidgetsEnum.COUNT_WIDGET:
            return <CountWidget {...(props as ICountWidget)} />;
        case WidgetsEnum.TABLE_WIDGET:
            return <TableWidget {...(props as ITableWidget)} />;
        case WidgetsEnum.DOUGHNUT_WIDGET:
            return <DoughnutWidget {...(props as IDoughnutWidget)} />;
        case WidgetsEnum.DOUBLE_COUNT_WIDGET:
            return <DoubleCountWidget {...(props as IDoubleCountWidget)} />;
        case WidgetsEnum.BAR_CHART_WIDGET:
            return <BarChartWidget {...(props as IBarChartWidget)} />;
        default:
            return null;
    }
};
