import { Stack } from '@fluentui/react';
import { mockedJobs } from 'constants/MockedJobs';
import { AdministrationFilters } from './components/AdministrationFilters';
import { AdministrationPagination } from './components/AdministrationPagination';
import { JobsDataTable } from './components/JobsDataTable';


export const JobListPage = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <AdministrationFilters />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <JobsDataTable />
            </Stack.Item>
            <Stack.Item shrink={0}>
                <AdministrationPagination
                    pagination={{
                      totalItemsCount: mockedJobs.length,
                      shownItemsCount: 25,
                      items: [],
                    }}
                    load={() => {}}
                />
            </Stack.Item>
        </Stack>
    );
};