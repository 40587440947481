import { Card, Pagination } from 'components';
import { IPaginatedResult } from 'interfaces';
import { useAdministrationContext } from '../AdministrationLayoutPage';

interface IAdministrationPaginationProps {
    pagination: IPaginatedResult<any>;
    load: Function;
}

export const AdministrationPagination = ({ pagination, load }: IAdministrationPaginationProps): JSX.Element => {
    const { filters, setFilters } = useAdministrationContext();
    return (
        <Card>
            <Pagination
                page={filters.page}
                pageSize={filters.pageSize}
                itemsCount={pagination.shownItemsCount}
                total={pagination.totalItemsCount}
                onChange={(newPageSize: number, newPage: number) => {
                    setFilters({ ...filters, page: newPage, pageSize: newPageSize });
                    load();
                }}
            />
        </Card>
    );
};