import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Modal } from 'components';
import { INewCreditNoteFormValues } from 'interfaces';
import { useCreditNotesStore } from '../../CreditNotesPage';
import { CreditNoteAddNewForm } from './CreditNoteAddNewForm';

interface Props {
    open: boolean;
    onOpenToggle: () => void;
}

export const CreditNoteAddNewModal: FunctionComponent<Props> = observer((props: Props) => {
    const { formatMessage } = useIntl();
    const { createCreditNote, loadCreditNotes } = useCreditNotesStore();

    const handleSubmit = (data: INewCreditNoteFormValues) => {
        createCreditNote(data).subscribe(() => {
            loadCreditNotes();
            props.onOpenToggle();
        });
    };

    return (
        <Modal title={formatMessage({ id: 'addCreditNote' })} isOpen={props.open} onDismiss={props.onOpenToggle}>
            <CreditNoteAddNewForm onOpenToggle={props.onOpenToggle} onSubmit={handleSubmit} />
        </Modal>
    );
});
