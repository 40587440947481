import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import { IColumn, SelectionMode, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Card, DataTable, ItemDeletedStatus } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { RulesetsDataTableActions } from './RulesetsDataTableActions';
import { useRulesetsStore } from '../RulesetsPage';
import { RulesetsNew } from './RulesetsNew';
import { cloneDeep } from 'lodash';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useBoolean } from '@fluentui/react-hooks';

export const RulesetsDataTable: FunctionComponent = observer(() => {
    const { rulesetsLoading, rulesets, rulesetsFilters } = useRulesetsStore();
    const { formatMessage } = useIntl();
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);
    const theme = useTheme();

    const columns: IColumn[] = [
        {
            key: 'id',
            name: formatMessage({ id: 'id' }),
            fieldName: 'id',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item.id}
                </Text>
            ),
        },
        {
            key: 'workflowName',
            name: formatMessage({ id: 'rulesetName' }),
            fieldName: 'workflowName',
            minWidth: 150,
        },
        {
            key: 'status',
            name: formatMessage({ id: 'status' }),
            minWidth: 150,
            maxWidth: 150,
            onRender: (item) => <ItemDeletedStatus item={item} />,
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => <RulesetsDataTableActions ruleset={item} />,
        },
    ];

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'add' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleIsAddModalOpen,
            },
        ],
        [toggleIsAddModalOpen, formatMessage]
    );

    return (
        <Card styles={CardTableContainerStyles}>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
            />
            <DataTable
                initialColumns={columns}
                shimmerLines={rulesetsFilters.pageSize}
                items={cloneDeep(rulesets)}
                selectionMode={SelectionMode.none}
                enableShimmer={rulesetsLoading}
                containerHeight='calc(100% - 32px)'
            />

            <RulesetsNew isModalOpen={isAddModalOpen} toggleIsModalOpen={toggleIsAddModalOpen} />
        </Card>
    );
});
