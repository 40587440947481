import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { Card, FieldView, ModalFooter } from 'components';
import { useAddMultipleJobsStore } from './InvoicesAddMultipleJobsModal';
import { cloneDeep } from 'lodash';
import { GenerateInvoicesDataTable } from './GenerateInvoicesDataTable';

interface Props {
    onClose: () => void;
}

export const InvoicesAddMultipleJobsGenerateInvoices: FunctionComponent<Props> = observer((props: Props) => {
    const { selectedInvoices, updateFeesResult, generateInvoice, loadingUpdateFees } = useAddMultipleJobsStore();
    const { formatMessage } = useIntl();

    const handleGenerateInvoices = () => {
        generateInvoice().subscribe(props.onClose);
    };

    const selectedInvoicesTotal: number = (selectedInvoices ?? []).reduce((prev, curr) => prev + (curr.total ?? 0), 0);

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <FieldView
                            label={formatMessage({ id: 'creationDate' })}
                            value={
                                updateFeesResult?.creationDate
                                    ? DateTime.fromJSDate(updateFeesResult?.creationDate).toFormat('dd/MM/yyyy')
                                    : null
                            }
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <FieldView label={formatMessage({ id: 'invoiceParagraph' })} value={updateFeesResult?.invoiceParagraph} />
                    </Stack.Item>
                </Stack>
            </Card>

            <Card>
                <Stack tokens={{ childrenGap: 8 }}>
                    <GenerateInvoicesDataTable
                        invoices={cloneDeep(selectedInvoices)}
                        total={selectedInvoicesTotal}
                        jobsSelectedCount={selectedInvoices.length}
                        loading={loadingUpdateFees}
                    />
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton text={formatMessage({ id: 'close' })} onClick={props.onClose} /> */}

                <PrimaryButton text={formatMessage({ id: 'generateInvoices' })} onClick={handleGenerateInvoices} />
            </ModalFooter>
        </Stack>
    );
});
