import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import { IconButton, Stack, Text, useTheme, Modal as ModalFluentUI } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { IModalProps } from '@fluentui/react/lib/components/Modal/Modal.types';
import { Alignment } from '@fluentui/react/lib/components/Stack/Stack.types';

interface Props extends IModalProps {
    title?: ReactNode;
    onDismiss?: () => any;
    fullWidth?: boolean;
    minHeight?: boolean;
}

export const Modal: FunctionComponent<Props> = ({ title, children, ...props }: Props) => {
    const titleId = useId('modal-title');
    const theme = useTheme();

    return (
        <ModalFluentUI
            {...props}
            titleAriaId={titleId}
            isBlocking={false}
            styles={{
                main: {
                    minWidth: '50vw',
                    maxWidth: '95vw',
                    minHeight: props.minHeight ? '90%' : 'auto',
                    width: props.fullWidth ? '96vw' : 1000,
                    background: theme.palette.neutralLight,
                },
                scrollableContent: { maxHeight: 'calc(100vh - 50px)' },
            }}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack
                    horizontal
                    horizontalAlign='space-between'
                    verticalAlign='center'
                    tokens={{ childrenGap: 8, padding: '8px 16px' }}
                    styles={{ root: { background: theme.palette.white } }}>
                    <Text variant='large' id={titleId}>
                        {title}
                    </Text>

                    <IconButton iconProps={{ iconName: 'ChromeClose' }} onClick={props.onDismiss} />
                </Stack>

                <Stack.Item styles={{ root: { padding: '0 16px 16px' } }}>{children}</Stack.Item>
            </Stack>
        </ModalFluentUI>
    );
};

interface ModalFooterProps {
    horizontalAlign?: Alignment;
    children: ReactNode;
}

export const ModalFooter: FunctionComponent<ModalFooterProps> = ({ horizontalAlign, children }: ModalFooterProps) => {
    const theme = useTheme();
    return (
        <Stack
            horizontal
            horizontalAlign={horizontalAlign}
            tokens={{ childrenGap: 16, padding: '16px' }}
            styles={{ root: { background: theme.palette.white, margin: '0 -16px -16px' } }}>
            {children}
        </Stack>
    );
};
