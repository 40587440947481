import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Card, Pagination } from 'components';
import { useInvoicesStore } from '../../InvoicesPage';

export const InvoicesTablePagination: FunctionComponent = observer(() => {
    const { invoices, invoicesPageSize, invoicesPage, invoicesTotal, setInvoicesPagination, loadInvoices } = useInvoicesStore();

    const onPaginationChange = (pageSize: number, page: number) => {
        setInvoicesPagination(pageSize, page);
        loadInvoices();
    };

    return (
        <Card>
            <Pagination
                page={invoicesPage}
                pageSize={invoicesPageSize}
                total={invoicesTotal}
                itemsCount={invoices.length}
                onChange={onPaginationChange}
            />
        </Card>
    );
});
