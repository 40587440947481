import { FunctionComponent, useEffect, useMemo } from 'react';
import { CommandBar, ICommandBarItemProps, Stack, useTheme } from '@fluentui/react';
import { ControlledDropdown } from 'components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

export interface IDashboardFilter {
	client: string;
	fund: string;
	year: string;
}

export const DashboardFilter: FunctionComponent = () => {
	const { formatMessage } = useIntl();
	const theme = useTheme();
	const { control, watch, formState: { isValidating, isValid, isDirty } } = useForm<IDashboardFilter>({
		mode: 'onChange',
		defaultValues: {
			client: '',
			fund: '',
			year: '',
		},
	});
	const filterData = watch();

	const commandBarItems: ICommandBarItemProps[] = useMemo(
		() => [
			{
				key: 'refresh',
				disabled: false,
				text: formatMessage({ id: 'refresh' }),
				iconProps: { iconName: 'Refresh' },
				iconOnly: true,
				onClick: () => { console.log('RESET'); }, // !TODO refresh data here
			},
		],
		[formatMessage]
	);

	useEffect(() => {
		if (isDirty && isValid && !isValidating) {
			// !TODO fetch filtered data here
		}
	}, [isValid, filterData, isValidating, isDirty]);

	return (
		<Stack
			horizontal
			horizontalAlign='stretch'
			tokens={{ childrenGap: 16 }}
			styles={{
				root: {
					paddingBottom: 16,
					borderBottom: `1px solid ${theme.palette.neutralLight}`,
				}
			}}
		>
			<Stack.Item styles={{ root: { width: '25%', maxWidth: 400, minWidth: 150 } }}>
				<ControlledDropdown
					options={[]}
					name='client'
					placeholder={formatMessage({ id: 'filterByClient' })}
					control={control}
				/>
			</Stack.Item>
			<Stack.Item styles={{ root: { width: '25%', maxWidth: 400, minWidth: 150 } }}>
				<ControlledDropdown
					options={[]}
					name='fund'
					placeholder={formatMessage({ id: 'filterByFund' })}
					control={control}
				/>
			</Stack.Item>
			<Stack.Item styles={{ root: { width: '15%', maxWidth: 200, minWidth: 150 } }}>
				<ControlledDropdown
					options={[]}
					name='year'
					placeholder={formatMessage({ id: 'filterByYear' })}
					control={control}
				/>
			</Stack.Item>
			<Stack.Item grow={1}>
				<CommandBar
					items={[]}
					farItems={commandBarItems}
					styles={{ root: { height: '32px' } }}
				/>
			</Stack.Item>
		</Stack>
	);
};