import { FunctionComponent, ReactNode } from 'react';
import { Card } from './Card';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';

interface Props {
    onSearch?: () => void;
    onClear?: () => void;
    searchDisabled?: boolean;
    clearDisabled?: boolean;
    children: ReactNode;
}

export const SearchPanel: FunctionComponent<Props> = ({ searchDisabled, clearDisabled, onSearch, onClear, children }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Card>
            <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                {children}
                <DefaultButton disabled={clearDisabled} text={formatMessage({ id: 'clear' })} onClick={onClear} />
                <PrimaryButton disabled={searchDisabled} text={formatMessage({ id: 'search' })} onClick={onSearch} />
            </Stack>
        </Card>
    );
};
