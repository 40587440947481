export const mockedFunds: Array<{ guid: string; name: string }> = [
    {
        guid: 'ea880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'Wrevwood Superannuation Fund',
    },
    {
        guid: 'e8880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'St Bees Superannuation Fund',
    },
    {
        guid: 'e7880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'S & L Coates Family Superannuation Fund',
    },
    {
        guid: 'e6880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'Norden Superannuation Fund',
    },
    {
        guid: 'e3880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'Judd Superannuation Fund',
    },
    {
        guid: 'e2880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'JR Douglas Superannuation Fund',
    },
    {
        guid: 'd9880f1b-989b-df11-a9a9-02bf0a033e0b',
        name: 'Andrew Milliken Superannuation Fund',
    },
    {
        guid: '9beb3faa-fe98-e311-9029-02bf0a033e0b',
        name: 'Fairhaven Retirement Fund',
    },
];
