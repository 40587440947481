import axios from 'axios';
import { RefreshTokenResponse } from 'hooks/useGetToken';
import { queryClient } from './queryClient';

export function authHeader() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return { headers: { accessToken: `Bearer ${accessToken}`, 'Access-Control-Allow-Origin': '*' } };
    } else {
        return {};
    }
}

export function commonHeaders(formData?: boolean) {
    const accessToken = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('tenantId');

    if (accessToken) {
        return {
            headers: {
                authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*',
                'x-tenant-id': String(tenantId),
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
            },
        };
    } else {
        return {};
    }
}

export function errorHandling(urlForRefetch: string, errorStatus: number, callback?: () => void) {
    if (errorStatus === 401) {
        const httpClientConfig =
            process.env.NODE_ENV === 'development' ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/identity/' : 'identity/';
        const refreshToken = localStorage.getItem('refreshToken');
        axios
            .get<RefreshTokenResponse>(`${httpClientConfig}api/1.0/Authorization/RefreshToken?refreshToken=${refreshToken}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((res) => {
                localStorage.setItem('refreshToken', res.data.refreshToken);
                localStorage.setItem('accessToken', res.data.accessToken);
            })
            .then(() => queryClient.invalidateQueries(urlForRefetch).then(() => callback && callback()))
            .catch(() => {
                localStorage.clear();
                window.location.replace(`${httpClientConfig}Authentication/SignIn?redirectUrl=` + window.location.href);
            });
    }
}
