import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { Card, ControlledDropdown, ModalFooter, Pagination } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { InvoiceFilterStatusType } from '../../enums';
import { SelectInvoicesDataTableSelection } from './SelectInvoicesDataTableSelection';
import { useAddMultipleJobsStore } from './InvoicesAddMultipleJobsModal';
import { useInvoicePortalStore } from '../../InvoicePortalLayoutPage';

interface Props {
    onClose: () => void;
}

interface FiltersValues {
    clientId: string[];
    status: InvoiceFilterStatusType;
    fund?: string;
    year?: number;
}

export const InvoicesAddMultipleJobsSelectInvoices: FunctionComponent<Props> = observer((props: Props) => {
    const { setNextStep, selectedInvoices, setSelectedInvoices, invoices, getInvoices, loadingInvoices } = useAddMultipleJobsStore();
    const { clientId } = useInvoicePortalStore();
    const { control, getValues, watch } = useForm<FiltersValues>({
        ...DefaultFormSettings,
        defaultValues: { clientId: clientId ? [clientId] : [], fund: '', year: '' as any },
    });
    const { formatMessage } = useIntl();

    useEffect(() => updateInvoices(), [watch('clientId'), watch('status'), watch('fund'), watch('year')]);

    const updateInvoices = () => {
        const filters = getValues();

        if (filters.clientId.length) {
            getInvoices(filters.clientId, filters.status, filters.fund, filters.year);
        }
    };

    const clientsDropdownOptions: IDropdownOption[] = [
        { key: 'client-1', text: 'Client 1' },
        { key: 'client-2', text: 'Client 2' },
        { key: 'client-3', text: 'Client 3' },
        { key: 'client-4', text: 'Client 4' },
        { key: 'client-5', text: 'Client 5' },
        { key: 'client-6', text: 'Client 6' },
    ];

    const statusOptions: IDropdownOption[] = [
        { key: InvoiceFilterStatusType.NotCreated, text: formatMessage({ id: 'invoicesFilters.notCreatedOnly' }) },
        { key: InvoiceFilterStatusType.NotCreatedAndARDraftARSent, text: formatMessage({ id: 'invoicesFilters.notCreatedAndARDraft' }) },
    ];

    const fundsOptions: IDropdownOption[] = [
        { key: '', text: formatMessage({ id: 'all' }) },
        { key: '10082021_6360test1', text: '10082021_6360test1' },
        { key: '12102021_bulktest', text: '12102021_bulktest' },
        { key: '27092021_6380', text: '27092021_6380' },
    ];

    const yearsOptions: IDropdownOption[] = [
        { key: '', text: formatMessage({ id: 'all' }) },
        { key: 2018, text: '2018' },
        { key: 2017, text: '2017' },
        { key: 2016, text: '2016' },
    ];

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <ControlledDropdown
                                name='clientId'
                                control={control}
                                options={clientsDropdownOptions}
                                multiSelect
                                label={formatMessage({ id: 'client' }) + ':'}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <ControlledDropdown
                                name='status'
                                control={control}
                                options={statusOptions}
                                label={formatMessage({ id: 'status' }) + ':'}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <ControlledDropdown
                                name='fund'
                                control={control}
                                options={fundsOptions}
                                label={formatMessage({ id: 'fund' }) + ':'}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <ControlledDropdown
                                name='year'
                                control={control}
                                options={yearsOptions}
                                label={formatMessage({ id: 'year' }) + ':'}
                            />
                        </Stack.Item>
                    </Stack>

                    <SelectInvoicesDataTableSelection
                        invoices={cloneDeep(invoices)}
                        loading={loadingInvoices}
                        onSelectInvoicesChange={setSelectedInvoices}
                    />

                    <Pagination total={10} />
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton text={formatMessage({ id: 'close' })} onClick={props.onClose} /> */}

                <PrimaryButton text={formatMessage({ id: 'next' })} disabled={!selectedInvoices.length} onClick={setNextStep} />
            </ModalFooter>
        </Stack>
    );
});
