import axios from 'axios';
import { mockedInvoices } from 'constants/MockedInvoices';
import { BillingEntityType, InvoiceStatusType } from 'enums';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type Invoice = {
    isSelected: boolean;
    year: number;
};

export interface InvoiceItem {
    id: number;
    billingEntityType: BillingEntityType;
    status: InvoiceStatusType;
    amount: number;
    paragraph: string;
    total: number;
    invoiceJobs: InvoiceJob[];
    extraAmounts: Array<{ amount: number; paragraph: string }> | null;
    clientId: number;
    externalId: null;
    gst: number;
    hasExternalReference: boolean;
    invoiceDate: string;
    isComposite: boolean;
    isCreated: boolean;
    remainingAmount: number;
    totalAmount: number;
}

type InvoiceJob = {
    amount: number;
    clientId: number;
    extraAmount: number;
    fundId: number;
    fundName: string;
    id: number;
    invoiceId: number;
    invoiceNumber: number;
    jobGuid: string;
    jobId: number;
    jobName: string;
    jobYear: number;
};

type Invoices = {
    items: InvoiceItem[];
    shownItemsCount: number;
    totalItemsCount: number;
};

type GetInvoiceProps = {
    page?: number;
    size?: number;
    year?: string;
    jobName?: string;
    fundId?: string;
    clientId?: string;
    status?: string;
};

/**Query for getting Invoices*/
function useGetInvoices({ page = 1, size, year, jobName, fundId, clientId, status }: GetInvoiceProps) {
    const context = React.useContext(Context);

    /** For testing only. Remove after finishing invoicing API */
    //const testClientId = '901d1ffa-969b-df11-a9a9-02bf0a033e0b';

    const { data: invoices, isLoading } = useQuery(
        ['getInvoices', page, size, year, jobName, fundId, clientId, status],
        () =>
            axios.get<Invoices>(
                `${context?.config?.billingUrl}/api/1.0/Invoice/GetAll/${clientId}?${page ? `Page=${page}` : ''}${
                    size ? `&Size=${size}` : ''
                }${year ? `&Year=${year}` : ''}${jobName ? `JobName=${jobName}` : ''}${fundId ? `&FundId=${fundId}` : ''}${
                    status ? `&Status=${status}` : ''
                }
        `,
                commonHeaders()
            ),
        {
            enabled: !!context?.config?.billingUrl && !!context.tenantId && !!clientId,
        }
    );

    return { invoices, mockedInvoices, isLoading };
}

export { useGetInvoices };
