import { IButtonStyles, IDetailsListStyles } from '@fluentui/react';

export const TableFixedHeaderStyles: (height: string | number) => Partial<IDetailsListStyles> = (height: string | number) => ({
    root: {
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height,
                overflowY: 'auto',
                overflowX: 'auto',
                width: 'calc(100% + 12px)',
            },
        },
    },
    headerWrapper: {
        flex: '0 0 auto',
    },
    contentWrapper: {
        flex: '1 1 auto',
        width: '100%',
    },
});

export const CardTableContainerStyles = {
    root: {
        height: '100%',
        boxSizing: 'border-box',
        selectors: { '.ms-Viewport, .ms-DetailsList': { height: '100%' } },
    },
};

export const ActiveIconColorStyles = (color: string) => ({
    root: {
        selectors: { '&:not(.is-disabled), &.ms-Button-icon': { color } },
    },
});

export const ActionButtonStyles: IButtonStyles = {
    root: {
        fontWeight: 500,
        right: 8,
    },
};

export const DashboardSearchStyles: IButtonStyles = {
    root: {
        width: 300,
    },
};