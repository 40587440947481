import { FunctionComponent, useState } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, Link, SelectionMode } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Card, Modal } from 'components';
import { InvoiceItem } from 'pages/InvoicePortal/hooks/Invoices/useGetInvoices';

interface Props {
    item: InvoiceItem;
}

export const InvoicesTableExtraAmountColumn: FunctionComponent<Props> = ({ item }: Props) => {
    const { formatMessage, formatNumber } = useIntl();
    const [openModal, setOpenModal] = useState(false);

    const columns: IColumn[] = [
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 100,
            maxWidth: 300,
            isMultiline: true,
            onRender: (item) => formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' }),
        },
        {
            key: 'paragraphName',
            name: formatMessage({ id: 'invoiceParagraph' }),
            fieldName: 'paragraphName',
            minWidth: 100,
            isMultiline: true,
        },
    ];

    return (
        <>
            <Modal title='Extra Amounts' isOpen={openModal} onDismiss={() => setOpenModal(false)}>
                <Card>
                    <DetailsList
                        items={item.extraAmounts ?? []}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        setKey='none'
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                </Card>
            </Modal>

            {item.extraAmounts?.length ? (
                <Link onClick={() => setOpenModal(true)}>
                    {formatNumber(
                        item.extraAmounts.reduce((prev: number, curr: { amount: number }) => prev + curr.amount, 0),
                        { style: 'currency', currency: 'USD' }
                    )}
                </Link>
            ) : (
                formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' })
            )}
        </>
    );
};
