import { FunctionComponent, useState } from 'react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { Card, DataTable } from 'components';
import { CardTableContainerStyles, ActionButtonStyles } from 'constants/tableStylesPeset';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useIntl } from 'react-intl';
import { allData } from 'pages/Workflow/components/data/Data';
import { YearModal } from 'pages/Workflow/components/modals/YearModal';
import { ClientModal } from 'pages/Workflow/components/modals/ClientModal';
import { FundModal } from 'pages/Workflow/components/modals/FundModal';
import { DateModal } from 'pages/Workflow/components/modals/DateModal';
import { PartnerModal } from 'pages/Workflow/components/modals/PartnerModal';
import { TimesheetTotal } from './TimesheetTotal';

export const TimesheetDataTable: FunctionComponent = () => {
    const [showDateModal, setShowDateModal] = useState<boolean>(false);
    const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
    const [showClientModal, setShowClientModal] = useState<boolean>(false);
    const [showFundModal, setShowFundModal] = useState<boolean>(false);
    const [showYearModal, setShowYearModal] = useState<boolean>(false);

    const { formatMessage } = useIntl();

    const [columns] = useState<IColumn[]>([
        {
            key: 'date',
            name: formatMessage({ id: 'date' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'date',
            onRender: (items) => (
                <ActionButton
                    styles={ActionButtonStyles}
                    onClick={() => setShowDateModal(true)}
                    allowDisabledFocus>
                    {items.date}
                </ActionButton>
            ),
        },
        {
            key: 'employee',
            name: formatMessage({ id: 'employee' }),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'employee',
            onRender: (items) => (
                <ActionButton
                    styles={ActionButtonStyles}
                    onClick={() => setShowEmployeeModal(true)}
                    allowDisabledFocus>
                    {items.name}
                </ActionButton>
            ),
        },
        {
            key: 'client',
            name: formatMessage({ id: 'client' }),
            minWidth: 150,
            maxWidth: 450,
            fieldName: 'client',
            onRender: (items) => (
                <ActionButton
                    styles={ActionButtonStyles}
                    onClick={() => setShowClientModal(true)}
                    allowDisabledFocus>
                    {items.primaryContact}
                </ActionButton>
            ),
        },
        {
            key: 'fund',
            name: formatMessage({ id: 'fund' }),
            minWidth: 150,
            maxWidth: 450,
            fieldName: 'fund',
            onRender: (items) => (
                <ActionButton
                    styles={ActionButtonStyles}
                    onClick={() => setShowFundModal(true)}
                    allowDisabledFocus>
                    {items.phone}
                </ActionButton>
            ),
        },
        {
            key: 'year',
            name: formatMessage({ id: 'year' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'year',
            onRender: (items) => (
                <ActionButton
                    styles={ActionButtonStyles}
                    onClick={() => setShowYearModal(true)}
                    allowDisabledFocus>
                    {items.year}
                </ActionButton>
            ),
        },
        {
            key: 'durationMin',
            name: formatMessage({ id: 'durationMin' }),
            minWidth: 100,
            maxWidth: 200,
            fieldName: 'durationMin',
            onRender: (items) => <Text styles={{ root: { marginLeft: 10 } }}>{items.daysIn}</Text>,
        },
        {
            key: 'rate',
            name: formatMessage({ id: 'rate' }) + '($)',
            minWidth: 100,
            maxWidth: 200,
            fieldName: 'rate',
            onRender: (items) => <Text>{items.addressState}</Text>,
        },
        {
            key: 'cost',
            name: formatMessage({ id: 'cost' }) + '($)',
            minWidth: 100,
            maxWidth: 200,
            fieldName: 'cost',
            onRender: (items) => <Text>{items.arSent}</Text>,
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['date', 'employee', 'client', 'fund', 'year', 'durationMin', 'rate']}
                initialColumns={columns}
                items={allData.items}
                selectionMode={SelectionMode.none}
                containerHeight='calc(100% - 35px)'
            />
            <TimesheetTotal items={allData.items} />

            <DateModal
                isOpen={showDateModal}
                onDismiss={() => setShowDateModal(false)}
                title={formatMessage({ id: 'date' })}
            />

            <PartnerModal
                isOpen={showEmployeeModal}
                onDismiss={() => setShowEmployeeModal(false)}
                title={formatMessage({ id: 'employee' })}
            />

            <ClientModal
                isOpen={showClientModal}
                onDismiss={() => setShowClientModal(false)}
                title={formatMessage({ id: 'client' })}
            />

            <FundModal
                isOpen={showFundModal}
                onDismiss={() => setShowFundModal(false)}
                title={formatMessage({ id: 'fund' })}
            />

            <YearModal
                isOpen={showYearModal}
                onDismiss={() => setShowYearModal(false)}
                title={formatMessage({ id: 'year' })}
            />
        </Card>
    );
};
