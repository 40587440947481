import { mergeStyleSets, useTheme } from '@fluentui/react';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { IStyle } from '@fluentui/merge-styles/lib/IStyle';

interface Props {
    styles?: { root?: IStyle };
    onClick?: MouseEventHandler;
    children: ReactNode;
}

export const Card: FunctionComponent<Props> = ({ children, styles: additionalStyles, onClick }: Props) => {
    const theme = useTheme();

    const styles = mergeStyleSets(
        {
            root: {
                background: theme.palette.white,
                borderRadius: 2,
                boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
                overflow: 'hidden',
                padding: 16,
            },
        },
        additionalStyles
    );

    return <div className={styles.root} onClick={onClick}>{children}</div>;
};
