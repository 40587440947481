import { ITheme, mergeStyleSets, getTheme } from '@fluentui/react/lib/Styling';
import React, { FunctionComponent, ReactNode, cloneElement } from 'react';
import { IAdministrationListItem, IAdministrationList } from 'interfaces';
import { Card } from 'components';
import {
  FocusZoneDirection,
  DefaultButton,
  PrimaryButton,
  FocusZone,
  Stack,
  Icon,
  List,
  Text,
} from '@fluentui/react';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const getButtonByType = (type: 'default' | 'primary') => {
  switch(type) {
    case 'primary':
      return <PrimaryButton />;
    case 'default':
    default:
      return <DefaultButton />;
  }
};

export const AdministrationList: FunctionComponent<IAdministrationList> = ({items, buttonsConfig = [], onCardClick, title})  => {

  const onRenderCell = (item: IAdministrationListItem | undefined): ReactNode => {
    const classNames = mergeStyleSets({
      itemCell: [
        {
          display: 'flex',
          minHeight: 54,
          padding: 10,
          marginBottom: 8,
          background: 'rgba(124, 124, 124, 0.2)',
          borderRadius: 2,
          boxSizing: 'border-box',
          cursor: 'pointer',
          selectors: {
            '&:hover': { background: 'rgba(124, 124, 124, 0.4)' },
          },
        },
      ],
      itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
      },
      itemName: [
        fonts.xLarge,
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ],
      chevron: {
        alignSelf: 'center',
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
      },
    });

    const onButtonClick = (event: React.MouseEvent<HTMLElement>, func: Function): void => {
      event.stopPropagation();
      func(item);
    };
    
    return (
      <Stack
        horizontal
        className={classNames.itemCell}
        tokens={{ childrenGap: 32 }}
        onClick={(event) => onCardClick && onCardClick(event, item?.guid, item)}>
        <Stack className={classNames.itemContent}>
          <Stack.Item className={classNames.itemName}>{item?.name}</Stack.Item>
          <Stack.Item>{item?.guid}</Stack.Item>
        </Stack>

        {buttonsConfig?.length && (
          <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 16 }}>
            {buttonsConfig.map(({text, onClick, type}, idx) => {
              return cloneElement(getButtonByType(type), {
                text,
                onClick: (event: React.MouseEvent<HTMLElement>) => onButtonClick(event, onClick),
                key: `card-${item?.guid}-button-${idx}`
              })
            })}
          </Stack>
        )}

        <Icon className={classNames.chevron} iconName={'ChevronRight'} />
      </Stack>
    );
  };

  return (
    <Stack styles={{ root: { height: '100%' } }}>
      {title && (
        <Text styles={{ root: { marginBottom: 8, ...fonts.xLarge } }}>
          {title}
        </Text>
      )}
      <Card styles={{ root: { height: '100%', overflowY: 'scroll', boxSizing: 'border-box' } }}>
          <Stack tokens={{ childrenGap: 16 }} >
            <FocusZone direction={FocusZoneDirection.vertical}>
              <List items={items} onRenderCell={onRenderCell} />
            </FocusZone>
          </Stack>
      </Card>
    </Stack>
  );
};