import { Card, Pagination } from 'components';
import { allData } from 'pages/Workflow/components/data/Data';
import { useState } from 'react';
import { IconButton, IIconProps, Stack } from '@fluentui/react';
import { TimesheetDataTable } from '../components/tables';

export const TimesheetWorkflowPage = () => {
    const [, setFilters] = useState<{ pageSize: number; page: number }>({ pageSize: 1, page: 1 });

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters({ pageSize, page });
        // loadUsers();
    };

    const pdf: IIconProps = { iconName: 'PDF' };
    const exelDoc: IIconProps = { iconName: 'ExcelDocument' };
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item grow={1}>
                <Stack>
                    <Card styles={{ root: { display: 'flex', justifyContent: 'end' } }}>
                        <IconButton iconProps={exelDoc} aria-label='Emoji' />
                        <IconButton iconProps={pdf} aria-label='Emoji' />
                    </Card>
                </Stack>
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <TimesheetDataTable />
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Card>
                    <Pagination
                        itemsCount={5}
                        total={allData.items.length}
                        page={allData.pageNumber}
                        pageSize={allData.pageSize}
                        onChange={onPaginationChange}
                    />
                </Card>
            </Stack.Item>
        </Stack>
    );
};
