import { FunctionComponent, useEffect } from 'react';
import { IComboBoxOption } from '@fluentui/react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useRulesetsStore } from '../RulesetsPage';
import { ControlledAutoComplete, ControlledCheckbox, ControlledTextField, SearchPanel } from 'components';
import { IRulesetFilters } from 'interfaces';
import { DefaultFormSettings } from 'constants/forms';

export const RulesetsFilters: FunctionComponent = observer(() => {
    const { rulesetsFilters, rulesetsLoading, setFilters, loadRulesets, getCategories, categories, categoriesLoading } = useRulesetsStore();
    const { formatMessage } = useIntl();
    const { control, handleSubmit, reset } = useForm<IRulesetFilters>({
        ...DefaultFormSettings,
        defaultValues: rulesetsFilters,
    });

    const comboBoxOptions: IComboBoxOption[] = categories.map((category) => ({
        id: category.name,
        key: category.name!,
        text: category.name!,
    }));

    useEffect(() => {
        if (!categories.length && !categoriesLoading) {
            getCategories();
        }
    }, [categories.length, categoriesLoading, getCategories]);

    const onClear = () => {
        reset({ category: '', workflowName: '', includeDeleted: false });
        setFilters({ ...rulesetsFilters, category: undefined, workflowName: undefined, includeDeleted: false });
        loadRulesets();
    };

    return (
        <SearchPanel
            onSearch={handleSubmit((data) => {
                setFilters({ ...rulesetsFilters, page: 1, ...data });
                loadRulesets();
            })}
            searchDisabled={rulesetsLoading}
            onClear={onClear}>
            <ControlledAutoComplete
                name='category'
                control={control}
                label={formatMessage({ id: 'category' })}
                options={comboBoxOptions}
                allowFreeform
            />
            <ControlledTextField name='workflowName' control={control} label={formatMessage({ id: 'name' })} />
            <ControlledCheckbox
                name='includeDeleted'
                control={control}
                label={formatMessage({ id: 'includeDeleted' })}
                addHiddenTopLabel
                adjustInputHeight
            />
        </SearchPanel>
    );
});
