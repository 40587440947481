import { FunctionComponent } from 'react';
import { ICreateUserCommand } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card, ControlledTextField, Modal, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export const FundsAddItemModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateUserCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            name: '',
            clientName: '',
            primaryContact: '',
        },
    });
    const { formatMessage } = useIntl();
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };

    const onSubmit = (data: ICreateUserCommand) => {
        console.log('created new Item');
        onDismiss();
    };
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='name'
                                label={formatMessage({ id: 'name' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='clientName'
                                label={formatMessage({ id: 'clientName' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{ root: { width: '100%' } }}>
                            <ControlledTextField
                                name='primaryContact'
                                label={formatMessage({ id: 'primaryContact' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)} text={formatMessage({ id: 'save' })} />
                </ModalFooter>
            </Stack>
        </Modal>
    );
});
