import { DashboardContainer, IDashboardConfig, WidgetsEnum } from 'pages/Workflow/DashboardWorkflow/components';
import { widgetData as mockedData } from 'pages/Workflow/components/data/DashboardData';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DashboardTableTypeEnum } from '../tables';

type WidgetDataType = typeof mockedData;

export const ContraventionDashboard = () => {
    const { formatMessage } = useIntl();
    const getWidgetData = () => {
        return { widgetData: mockedData, isLoading: false };
    };
    const { widgetData, isLoading } = getWidgetData();

    const getContraventionDashboardConfig = useCallback(
        (props: WidgetDataType): IDashboardConfig => {
            return {
                id: 'auditor',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrReadyForLodgement',
                            props: {
                                number: props.auditsAwaitingAuditorAllocationCount,
                                title: formatMessage({ id: 'acrReadyForLodgement' }),
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrLodgedThisMonth',
                            props: {
                                number: props.auditsInProgressWithAuditorCount,
                                title: formatMessage({ id: 'acrLodgedThisMonth' }),
                                isSecondary: true,
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrLodgedThisYear',
                            props: {
                                number: props.auditsAwaitingManagerReviewCount,
                                title: formatMessage({ id: 'acrLodgedThisYear' }),
                                dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
                            },
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                dashboardTableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingForResponse,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    const contraventionDashboardConfig = useMemo(() => {
        return getContraventionDashboardConfig(widgetData);
    }, [widgetData, getContraventionDashboardConfig]);
    return <DashboardContainer isLoading={isLoading} dashboardConfig={contraventionDashboardConfig} />;
};
