import { Card, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const GeneralContactTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='firstNameContact'
                        label={formatMessage({ id: 'firstName' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='lastNameContact'
                        label={formatMessage({ id: 'lastName' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='companyName' label={formatMessage({ id: 'companyName' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='jobTitle' label={formatMessage({ id: 'jobTitle' })} control={control} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
