import { FunctionComponent } from 'react';
import { CreditNoteStatusType } from 'enums';
import { Text, useTheme } from '@fluentui/react';

interface Props {
    status: CreditNoteStatusType;
}

export const CreditNoteStatus: FunctionComponent<Props> = ({ status }: Props) => {
    const theme = useTheme();
    let statusColor: string;
    let statusName: string = CreditNoteStatusType[status];

    switch (status) {
        case CreditNoteStatusType.Open:
            statusColor = theme.palette.green;
            break;
        case CreditNoteStatusType.Closed:
            statusColor = theme.palette.red;
            break;
        default:
            statusColor = theme.palette.neutralQuaternary;
            statusName = 'Undefined';
            break;
    }

    return (
        <Text
            variant='medium'
            styles={{
                root: {
                    backgroundColor: statusColor,
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: 2,
                },
            }}>
            {statusName}
        </Text>
    );
};
