import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { observer } from 'mobx-react';
import { useInvoicePortalStore } from './InvoicePortalLayoutPage';
import { InvoicePortalStore, CreditNotesStore } from './stores';
import { CreditNotesTableData, CreditNotesTablePagination } from './components/CreditNotesTable';

const CreditNotesStoreContext = createContext<CreditNotesStore>(new CreditNotesStore(new InvoicePortalStore()));

export const useCreditNotesStore = () => useContext(CreditNotesStoreContext);

export const CreditNotesPage: FunctionComponent = observer(() => {
    const invoicePortalStore = useInvoicePortalStore();
    const [creditNotesStore] = useState(() => new CreditNotesStore(invoicePortalStore));

    useEffect(() => {
        creditNotesStore.loadCreditNotes();
        return () => creditNotesStore.onDestroy();
    });

    return (
        <CreditNotesStoreContext.Provider value={creditNotesStore}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                    <CreditNotesTableData />
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <CreditNotesTablePagination />
                </Stack.Item>
            </Stack>
        </CreditNotesStoreContext.Provider>
    );
});
