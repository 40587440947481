import * as React from 'react';
import { FunctionComponent } from 'react';
import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsFooterProps,
    SelectionMode,
    Text,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { formatIdNumber } from 'utils/formatIdNumber';
import { IInvoice } from 'interfaces';

interface Props {
    invoices: IInvoice[];
    total?: number;
    jobsSelectedCount: number;
}

export const UpdateFeesDataTable: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage, formatNumber } = useIntl();

    const columns: IColumn[] = [
        { key: 'empty', minWidth: 28, maxWidth: 28, name: '' },
        {
            key: 'invoiceNumber',
            name: formatMessage({ id: 'invoiceNo' }),
            fieldName: 'id',
            minWidth: 70,
            maxWidth: 90,
            onRender: (item?: IInvoice, index?: number) => (
                <Text variant={item && index !== -1 ? 'small' : 'medium'} styles={{ root: { fontWeight: 500 } }}>
                    {item && index !== -1 ? formatIdNumber(item.id) : `${props.jobsSelectedCount} jobs selected`}
                </Text>
            ),
            isMultiline: true,
        },
        {
            key: 'jobName',
            name: formatMessage({ id: 'job' }),
            fieldName: 'jobName',
            minWidth: 50,
            maxWidth: 135,
            isMultiline: true,
        },
        {
            key: 'fundName',
            name: formatMessage({ id: 'fund' }),
            fieldName: 'fundName',
            minWidth: 90,
            maxWidth: 180,
            isMultiline: true,
        },
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 80,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' }) : null,
        },
        {
            key: 'extraAmount',
            name: formatMessage({ id: 'extraAmount' }),
            fieldName: 'extraAmount',
            minWidth: 115,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? formatNumber(item.extraAmount ?? 0, { style: 'currency', currency: 'USD' }) : null,
        },
        {
            key: 'total',
            name: formatMessage({ id: 'total' }),
            fieldName: 'total',
            minWidth: 55,
            maxWidth: 135,
            isMultiline: true,
            onRender: (item: IInvoice, index) =>
                item && index !== -1 ? (
                    formatNumber(item.total ?? 0, { style: 'currency', currency: 'USD' })
                ) : index === -1 ? (
                    <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                        {formatNumber(props.total ?? 0, { style: 'currency', currency: 'USD' })}
                    </Text>
                ) : null,
        },
    ];

    return (
        <DetailsList
            items={props.invoices}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            checkboxVisibility={CheckboxVisibility.hidden}
            onRenderDetailsFooter={(detailsFooterProps?: IDetailsFooterProps) => (
                <DetailsRow
                    {...detailsFooterProps}
                    columns={detailsFooterProps?.columns}
                    item={{}}
                    itemIndex={-1}
                    groupNestingDepth={detailsFooterProps?.groupNestingDepth}
                    selectionMode={SelectionMode.single}
                    selection={detailsFooterProps?.selection}
                />
            )}
        />
    );
};
