import { observable, makeObservable, action } from 'mobx';
import { finalize, Observable, tap } from 'rxjs';
import { cloneDeep, pick } from 'lodash';
import { ICategory, ICreateRulesetCommand, IPaginatedResult, IRulesetFilters, IRulesetInfo } from 'interfaces';
import { rulesetService } from 'services';
import { BasicStore } from 'stores';
import { DefaultPaginationPageSize, RulesetsPaginationStorageKey } from 'constants/paginationSettings';

export class RulesetsStore extends BasicStore {
    public rulesets: Array<IRulesetInfo> = [];
    public rulesetsPagination: {
        totalItemsCount: number;
        shownItemsCount: number;
    } = { totalItemsCount: 0, shownItemsCount: 0 };
    public rulesetsLoading: boolean = false;
    public rulesetsFilters: IRulesetFilters = {};
    public rulesetDeleting: boolean = false;
    public rulesetRestoring: boolean = false;
    public rulesetCreating: boolean = false;
    public categories: ICategory[] = [];
    public categoriesLoading: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            rulesets: observable,
            rulesetsPagination: observable,
            rulesetsLoading: observable,
            rulesetsFilters: observable,
            rulesetDeleting: observable,
            rulesetRestoring: observable,
            rulesetCreating: observable,
            categories: observable,
            categoriesLoading: observable,
            loadRulesets: action,
            loadRulesetsObservable: action,
            setFilters: action,
            deleteRuleset: action,
            restoreRuleset: action,
            createRuleset: action,
            getCategories: action,
            getCategoriesObservable: action,
        });

        const rulesetsFiltersFromStorage = sessionStorage.getItem(RulesetsPaginationStorageKey);
        this.rulesetsFilters = rulesetsFiltersFromStorage
            ? { page: 1, ...JSON.parse(rulesetsFiltersFromStorage) }
            : { page: 1, pageSize: DefaultPaginationPageSize };
    }

    public loadRulesets: () => void = () => {
        this.subscriptions.push(this.loadRulesetsObservable().subscribe());
    };

    public loadRulesetsObservable: () => Observable<IPaginatedResult<IRulesetInfo>> = () => {
        this.rulesetsLoading = true;
        return rulesetService.filterRulesets(this.rulesetsFilters).pipe(
            finalize(() => (this.rulesetsLoading = false)),
            tap({
                next: (rulesetsPaginationResult) => {
                    this.rulesets = rulesetsPaginationResult.items ?? [];
                    this.rulesetsPagination = {
                        totalItemsCount: rulesetsPaginationResult.totalItemsCount,
                        shownItemsCount: rulesetsPaginationResult.shownItemsCount,
                    };
                },
                error: () => {
                    this.rulesets = [];
                    this.rulesetsPagination = {
                        totalItemsCount: 0,
                        shownItemsCount: 0,
                    };
                },
            })
        );
    };

    public setFilters: (filters: IRulesetFilters) => void = (filters: IRulesetFilters) => {
        this.rulesetsFilters = cloneDeep(filters);
        sessionStorage.setItem(RulesetsPaginationStorageKey, JSON.stringify(pick(this.rulesetsFilters, ['pageSize'])));
    };

    public deleteRuleset: (id: IRulesetInfo['id']) => Observable<any> = (id: IRulesetInfo['id']) => {
        this.rulesetDeleting = true;
        return rulesetService.deleteRuleset(id).pipe(finalize(() => (this.rulesetDeleting = false)));
    };

    public restoreRuleset: (id: IRulesetInfo['id']) => Observable<IRulesetInfo> = (id: IRulesetInfo['id']) => {
        this.rulesetRestoring = true;
        return rulesetService.restoreRuleset(id).pipe(finalize(() => (this.rulesetRestoring = false)));
    };

    public createRuleset: (ruleset: ICreateRulesetCommand) => Observable<IRulesetInfo> = (ruleset: ICreateRulesetCommand) => {
        this.rulesetCreating = true;
        return rulesetService.createRuleset(ruleset).pipe(
            tap({
                error: (e) => {
                    this.rulesetCreating = false;
                },
            }),

            finalize(() => (this.rulesetCreating = false))
        );
    };

    public getCategories: () => void = () => {
        this.subscriptions.push(this.getCategoriesObservable().subscribe());
    };

    public getCategoriesObservable: () => Observable<ICategory[]> = () => {
        this.categoriesLoading = true;
        return rulesetService.getAllCategories().pipe(
            finalize(() => (this.categoriesLoading = true)),
            tap((categories) => (this.categories = categories))
        );
    };
}
