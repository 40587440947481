import { IGlobalNotification } from '../interfaces';
import { Label, MessageBar, Stack } from '@fluentui/react';

export const Notification = ({ name, description, type }: IGlobalNotification) => {
    return (
        <MessageBar
            messageBarType={type}
            isMultiline={true}
            styles={{
                root: {
                    width: 'auto',
                    boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
                },
            }}>
            <Stack>
                <Label styles={{ root: { paddingTop: 0 } }}>{name}</Label>
                <Stack.Item>{description}</Stack.Item>
            </Stack>
        </MessageBar>
    );
};
