import { MockedClients } from 'constants/MockedUserClients';
import { IAdministrationListItem, IButtonConfig } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'navigation';
import { useAdministrationContext } from '../AdministrationLayoutPage';
import { useMemo, FunctionComponent } from 'react';
import { AdministrationList } from './AdministrationList';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { EditClientModal } from './modals/EditClientModal';
import { AddFundModal } from './modals/AddFundModal';

export const ClientsList: FunctionComponent = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { filters, setSelectedClient, setTargetClient } = useAdministrationContext();

    const [openAddFundModal, { toggle: toggleOpenAddFundModal }] = useBoolean(false);
    const [openEditClientModal, { toggle: toggleOpenEditClientModal }] = useBoolean(false);

    const buttonsConfig: IButtonConfig[] = [
        {
            text: formatMessage({ id: 'addFund' }),
            type: 'default',
            onClick: (data) => {
                setTargetClient(data);
                toggleOpenAddFundModal();
            },
        },
        {
            text: formatMessage({ id: 'editClient' }),
            type: 'primary',
            onClick: (data) => {
                setTargetClient(data);
                toggleOpenEditClientModal();
            },
        },
    ];

    const onSubmitEditClientModal = (data: any) => {
        console.log(data, 'submit edit client data');
    };

    const onSubmitAddFundModal = (data: any) => {
        console.log(data, 'submit add fund data');
    };

    const onCardClick = (_: React.MouseEvent<HTMLElement>, id: string, data: IAdministrationListItem) => {
        setSelectedClient(data);
        navigate(RouterPaths.administration.jobs.funds.root(id));
    };

    const clients: IAdministrationListItem[] = useMemo(() => {
        const clients = MockedClients.map(({ name, clientGuid }) => ({ name, guid: clientGuid })) as IAdministrationListItem[];
        const searchClients = clients.filter((client) => client.name.includes(filters.search as string));
        return searchClients;
    }, [filters]);

    return (
        <>
            <AdministrationList
                onCardClick={onCardClick}
                items={clients}
                title={formatMessage({ id: 'clients' })}
                buttonsConfig={buttonsConfig}
            />
            <EditClientModal open={openEditClientModal} toggle={toggleOpenEditClientModal} onSubmit={onSubmitEditClientModal} />
            <AddFundModal open={openAddFundModal} toggle={toggleOpenAddFundModal} onSubmit={onSubmitAddFundModal} />
        </>
    );
};
