import { action, makeObservable, observable } from 'mobx';
import { IGlobalNotification } from 'interfaces';

export class GlobalNotificationsStore {
    public notifications: Array<IGlobalNotification> = [];

    constructor() {
        makeObservable(this, {
            notifications: observable,
            addNotification: action,
        });
    }

    public addNotification = (notification: IGlobalNotification) => {
        this.notifications = [...this.notifications, notification];
    };
}
