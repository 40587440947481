import * as React from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {IAdministrationContext, IAdministrationListItem} from 'interfaces';

export const initialContext: IAdministrationContext = {
    filters: {
        page: 1,
        pageSize: 25,
        search: '',
    },
    isLoading: false,
    selectedClient: null,
    selectedFund: null,
    targetClient: null,
    targetFund: null,
    setFilters: () => {},
    setLoading: () => {},
    setSelectedClient: () => {},
    setSelectedFund: () => {},
    setTargetClient: () => {},
    setTargetFund: () => {},
};

const AdministrationContext = React.createContext(initialContext);

export const useAdministrationContext = () => {
    return React.useContext(AdministrationContext);
};

export const AdministrationLayoutPage = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigation = useNavigate();

    const [selectedClient, setSelectedClient] = React.useState<IAdministrationListItem | null>(null);
    const [selectedFund, setSelectedFund] = React.useState<IAdministrationListItem | null>(null);
    const [filters, setFilters] = React.useState<IAdministrationContext['filters']>(initialContext.filters);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [targetClient, setTargetClient] = React.useState<IAdministrationListItem | null>(null);
    const [targetFund, setTargetFund] = React.useState<IAdministrationListItem | null>(null);

    React.useEffect(() => {
        setFilters(initialContext.filters);
        if (location.pathname === '/administration') {
            navigation(RouterPaths.administration.jobs.root);
        }
    }, [location.pathname, navigation]);

    return (
        <AdministrationContext.Provider value={{
            filters,
            isLoading,
            selectedClient,
            selectedFund,
            targetClient,
            targetFund,
            setFilters,
            setLoading,
            setSelectedClient,
            setSelectedFund,
            setTargetClient,
            setTargetFund,
        }}>
            <PageWithBreadcrumb
                breadcrumb={{
                    id: 'administration',
                    key: RouterPaths.administration.root,
                    text: formatMessage({ id: 'administration' }),
                }}>
                <Outlet />
            </PageWithBreadcrumb>
        </AdministrationContext.Provider>
    );
};