import { Card, ControlledDropdown } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type QueryRatingTabType = {
    control: Control<ICreateUserCommand>;
};

export const QueryRatingTab = ({ control }: QueryRatingTabType) => {
    const stackItemStyles = { root: { width: '100%' } };
    const { formatMessage } = useIntl();

    const queryRatingOptions = [
        { key: '1', text: '1' },
        { key: '2', text: '2' },
        { key: '3', text: '3' },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='queryRating'
                        label={formatMessage({ id: 'queryRating' })}
                        options={queryRatingOptions}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                        placeholder={formatMessage({ id: 'selectQueryRating' })}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
