import { Card, ControlledDatePicker, ControlledDropdown } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';
import { Control } from 'react-hook-form';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const PartnerTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='partnerName'
                        label={formatMessage({ id: 'partner' })}
                        options={[]}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='pnrPointsRaised'
                        label={formatMessage({ id: 'pnrPointsRaised' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='pnrPointsReviewedByAuditor'
                        label={formatMessage({ id: 'pnrPointsReviewedByAuditor' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='reviewedByPartner'
                        label={formatMessage({ id: 'reviewedByPartner' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
