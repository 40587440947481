import { Card, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const ContactDeatilsTab = ({ control }: AccountTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='directPhone' label={formatMessage({ id: 'directPhone' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='mobilePhone' label={formatMessage({ id: 'mobilePhone' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledTextField name='emailContact' label={formatMessage({ id: 'email' })} control={control} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
