import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { defaultAppTheme } from 'AppTheme';

type PropsChartsWidget = {
    dataNumbers: number[];
    groupedLabels: string[];
};

export const BarChart = ({ dataNumbers, groupedLabels, ...chartProps }: PropsChartsWidget) => {
    const data = {
        labels: groupedLabels,

        datasets: [
            {
                label: 'awd',
                data: dataNumbers,
                backgroundColor: [
                    defaultAppTheme.palette.themeDarker,
                    defaultAppTheme.palette.themeSecondary,
                    defaultAppTheme.palette.blackTranslucent40,
                    defaultAppTheme.palette.neutralTertiaryAlt,
                ],
                hoverOffset: 4,
            },
        ],
    };

    const config = {
        type: 'bar',
        data: data,
    };

    return (
        <div>
            <Bar {...chartProps} {...config} />
        </div>
    );
};
