import { DashboardContainer, IDashboardConfig, WidgetsEnum } from 'pages/Workflow/DashboardWorkflow/components';
import { widgetData as mockedData } from 'pages/Workflow/components/data/DashboardData';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DashboardTableTypeEnum } from 'pages/Workflow/components/tables';

type WidgetDataType = typeof mockedData;

export const AdministrationDashboard = () => {
    const { formatMessage } = useIntl();
    const getWidgetData = () => {
        return { widgetData: mockedData, isLoading: false };
    };
    const { widgetData, isLoading } = getWidgetData();

    const getAdministrationDashboardConfig = useCallback(
        (props: WidgetDataType): IDashboardConfig => {
            return {
                id: 'administration',
                rows: [
										[
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'auditsAwaitingAuditorAllocation',
												props: {
													number: props.auditsAwaitingAuditorAllocationCount,
													title: formatMessage({ id: 'auditsAwaitingAuditorAllocation' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingAuditorAllocation,
												},
											},
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'auditsInProgressWithAuditor',
												props: {
													number: props.auditsInProgressWithAuditorCount,
													title: formatMessage({ id: 'auditsInProgressWithAuditor' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor,
													isSecondary: true,
												},
											},
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'auditsAwaitingManagerReview',
												props: {
													number: props.auditsAwaitingManagerReviewCount,
													title: formatMessage({ id: 'auditsAwaitingManagerReview' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingManagerReview,
												},
											},
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'auditsAwaitingPartnerReview',
												props: {
													number: props.auditsAwaitingPartnerReviewCount,
													title: formatMessage({ id: 'auditsAwaitingPartnerReview' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingPartnerReview,
													isSecondary: true,
												},
											},
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'postARQueriesReadyForDispatch',
												props: {
													number: props.postArQueriesReadyForDispatchCount,
													title: formatMessage({ id: 'postARQueriesReadyForDispatch' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_PostArQueriesReadyForDispatch,
												},
											},
											{
												type: WidgetsEnum.COUNT_WIDGET,
												id: 'arSentThisWeek',
												props: {
													number: props.arSentThisWeekCount,
													title: formatMessage({ id: 'arSentThisWeek' }),
													dashboardTableType: DashboardTableTypeEnum.Administrator_ArSentThisWeek,
													isSecondary: true,
												},
											},
										],
										// !TODO change tables' ids
										[
											{
												type: WidgetsEnum.TABLE_WIDGET,
												id: '7',
												props: {
													dashboardTableType: DashboardTableTypeEnum.Administrator_AuditsReadyForInitialDispatch,
												}
											},
										],
										[
											{
												type: WidgetsEnum.DOUGHNUT_WIDGET,
												id: 'preARQueriesWaitingToBeReviewed',
												props: {
													title: formatMessage({ id: 'preARQueriesWaitingToBeReviewed' }),
													data: props.preArQueriesWaitingToBeReviewed,
													dashboardTableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingToBeReviewed,
												},
											},
											{
												type: WidgetsEnum.DOUGHNUT_WIDGET,
												id: 'postARQueriesWaitingToBeReviewed',
												props: {
													title: formatMessage({ id: 'postARQueriesWaitingToBeReviewed' }),
													data: props.postArQueriesWaitingToBeReviewed,
													dashboardTableType: DashboardTableTypeEnum.Administrator_PostArQueriesWaitingToBeReviewed,
												},
											},
										],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                dashboardTableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingForResponse,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '9',
                            props: {
                                dashboardTableType: DashboardTableTypeEnum.Administrator_PostArQueriesWaitingForResponse,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    const administrationDashboardConfig = useMemo(() => {
        return getAdministrationDashboardConfig(widgetData);
    }, [widgetData, getAdministrationDashboardConfig]);
    return <DashboardContainer isLoading={isLoading} dashboardConfig={administrationDashboardConfig} />;
};
