import { FunctionComponent } from 'react';
import { IconButton, useTheme } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { InvoiceItem } from 'pages/InvoicePortal/hooks/Invoices/useGetInvoices';

interface Props {
    onAddClick: () => void;
    invoice: InvoiceItem;
    onDeleteClick: () => void;
}

export const InvoiceTableActions: FunctionComponent<Props> = (props: Props) => {
    const theme = useTheme();
    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'download',
                text: 'Download',
                iconProps: { iconName: 'Installation' },
                disabled: !props.invoice.id,
                // TODO: disabled: !props.invoice.isCreated,
            },
            {
                key: 'addInvoice',
                text: 'Add Invoice',
                iconProps: { iconName: 'Add' },
                onClick: props.onAddClick,
                // disabled: props.invoice.isCreated, // open modal for not creates, create duplicate for opened or closed
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete', styles: { root: { color: theme.palette.red } } },
                onClick: props.onDeleteClick,
                disabled: !props.invoice.id,
            },
        ],
    };

    return <IconButton onRenderMenuIcon={() => <div />} menuProps={menuProps} iconProps={{ iconName: 'More' }} />;
};
