import { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { stores } from '../stores';
import { IGlobalNotification } from '../interfaces';
import { Label, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { Subscription, timer } from 'rxjs';
import { useIntl } from 'react-intl';

export const GlobalNotification: FunctionComponent = observer(() => {
    const { notifications } = stores.GlobalNotificationsStore;

    const { formatMessage } = useIntl();

    const [notificationToShow, setNotificationToShow] = useState<IGlobalNotification | null>(null);
    const [timerSubscription, setTimerSubscription] = useState<Subscription | null>(null);

    const clearNotification = () => {
        timerSubscription?.unsubscribe();
        setNotificationToShow(null);
        setTimerSubscription(null);
    };

    useEffect(() => {
        return clearNotification;
    }, []);

    useEffect(() => {
        clearNotification();

        if (notifications.length) {
            const newNotification = notifications[notifications.length - 1];
            setNotificationToShow(newNotification);
            setTimerSubscription(
                timer(newNotification.type === MessageBarType.error ? 15000 : 3000).subscribe(() => setNotificationToShow(null))
            );
        }
    }, [notifications.length]);

    if (!notificationToShow) {
        return null;
    }

    let isUserPreferDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const notificationBackground = (type: MessageBarType) => {
        if (type === MessageBarType.success) {
            if (isUserPreferDarkTheme) {
                return '#60c857';
            }
            return 'rgb(223, 246, 221)';
        } else {
            if (isUserPreferDarkTheme) {
                return '#d3545f';
            }
            return 'rgb(253, 231, 233)';
        }
    };

    return (
        <MessageBar
            messageBarType={notificationToShow.type}
            isMultiline={true}
            onDismiss={clearNotification}
            dismissButtonAriaLabel={formatMessage({ id: 'close' })}
            styles={{
                root: {
                    position: 'absolute',
                    zIndex: 9999999,
                    top: 8,
                    right: 16,
                    width: '50%',
                    boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
                    background: notificationBackground(notificationToShow.type),
                },
            }}>
            <Stack>
                <Label styles={{ root: { paddingTop: 0 } }}>{notificationToShow.name}</Label>
                <Stack.Item>{notificationToShow.description}</Stack.Item>
            </Stack>
        </MessageBar>
    );
});
