import { action, makeObservable, observable } from 'mobx';
import { finalize, tap } from 'rxjs';
import { IInvoice, IInvoicesFilters } from 'interfaces';
import { invoicesService } from 'services';
import { BasicStore } from 'stores';
import { InvoicePortalStore } from './InvoicePortalStore';

export class InvoicesStore extends BasicStore {
    public invoicesPage: number = 1;
    public invoicesPageSize: number = 50;
    public invoicesTotal: number = 0;
    public invoices: IInvoice[] = [];
    public invoicesLoading: boolean = false;
    public invoicesFilters: IInvoicesFilters = { status: '', year: '', fund: '' };

    public deleteInvoiceLoadingIds: number[] = [];

    constructor(private invoicePortalStore: InvoicePortalStore) {
        super();
        makeObservable(this, {
            invoicesPage: observable,
            invoicesPageSize: observable,
            invoicesTotal: observable,
            invoices: observable,
            invoicesLoading: observable,
            deleteInvoiceLoadingIds: observable,
            invoicesFilters: observable,
            loadInvoices: action,
            setInvoicesPagination: action,
            setInvoicesFilters: action,
        });
    }

    public setInvoicesPagination = (pageSize: number, page: number) => {
        this.invoicesPage = page;
        this.invoicesPageSize = pageSize;
    };

    public setInvoicesFilters = (invoicesFilters: IInvoicesFilters) => {
        this.invoicesFilters = invoicesFilters;
    };

    public loadInvoices = () => {
        this.subscriptions.push(this.loadInvoicesObservable().subscribe());
    };

    private loadInvoicesObservable = () => {
        this.invoicesLoading = true;
        return invoicesService
            .getAllInvoices(this.invoicePortalStore.clientId as string, this.invoicesPage, this.invoicesPageSize, this.filters)
            .pipe(
                finalize(() => (this.invoicesLoading = false)),
                tap((result) => {
                    this.invoices = result.items ?? [];
                    this.invoicesTotal = result.totalItemsCount;
                })
            );
    };

    private get filters(): IInvoicesFilters {
        return {
            ...this.invoicesFilters,
            fund: this.invoicesFilters.fund !== '' ? this.invoicesFilters.fund : undefined,
            year: this.invoicesFilters.year !== '' ? this.invoicesFilters.year : undefined,
        };
    }
}
