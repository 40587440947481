import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField, ControlledTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import { EmailTemplate } from 'pages/PortalSettings/hooks/Email/useEmailTemplateGet';

interface Props {
    control: Control<EmailTemplate>;
}

export const QueriesEmails: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='queriesEmails' />}>
            <>
                <ControlledTextField name='queriesEmailSubject' control={control} label={formatMessage({ id: 'queriesEmailsSubject' })} />

                <ControlledRichTextField name='queriesEmailBody1' control={control} label={formatMessage({ id: 'queriesEmailBody1' })} />

                <ControlledRichTextField name='queriesEmailBody2' control={control} label={formatMessage({ id: 'queriesEmailBody2' })} />
            </>
        </EmailTemplatePanel>
    );
};
