import { DatePicker, Text, useTheme } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { DateTime } from 'luxon';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
}

export function ControlledDatePicker<FormValues>({ name, control, rules, label }: Props<FormValues>) {
    const theme = useTheme();

    const onFormatDate = (date?: Date): string =>
        date ? DateTime.fromISO(date.toISOString()).toFormat('dd/MM/yyyy') : DateTime.now().toFormat('dd/MM/yyyy');

    const onParseDateFromString = (newValue: string): Date => DateTime.fromFormat(newValue, 'dd/MM/yyyy').toJSDate();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <>
                    <DatePicker
                        label={label}
                        showMonthPickerAsOverlay={true}
                        onSelectDate={onChange}
                        value={value as Date | undefined}
                        onBlur={onBlur}
                        formatDate={onFormatDate}
                        parseDateFromString={onParseDateFromString}
                    />

                    {error?.message ? (
                        <Text variant='small' styles={{ root: { color: theme.semanticColors.errorText } }}>
                            {error.message}
                        </Text>
                    ) : null}
                </>
            )}
        />
    );
}
