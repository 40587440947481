import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import {
    Callout,
    DefaultButton,
    DirectionalHint,
    IComboBoxOption,
    PrimaryButton,
    Shimmer,
    ShimmerElementType,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Card, ControlledCheckbox, ControlledMultiSelect, ControlledNumberField } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useGeneralYearGet } from '../hooks/General/useGeneralYearGet';
import { useCreateGeneralYear } from '../hooks/General/useGeneralYearCreate';
import { useUpdateGeneralYear } from '../hooks/General/useGeneralYearUpdate';

interface IJobYearsForm {
    years: number[];
}

interface INewYearForm {
    year: number;
    selected: boolean;
}

export const JobsYearsSettings: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    const { generalYears, isLoading } = useGeneralYearGet();
    const { createGeneralYear, createGeneralYearLoading } = useCreateGeneralYear();
    const { updateGeneralYear, updateGeneralYearLoading } = useUpdateGeneralYear();
    const [yearsOptions, setYearsOptions] = useState<IComboBoxOption[]>([]);

    const { control, watch, setValue, handleSubmit, formState } = useForm<IJobYearsForm>({
        ...DefaultFormSettings,
        defaultValues: { years: [] },
    });

    const {
        control: newYearControl,
        handleSubmit: createHandle,
        reset: resetNewYearValue,
        formState: formStateYear,
    } = useForm<INewYearForm>({
        ...DefaultFormSettings,
        defaultValues: { selected: false, year: 0 },
    });

    useEffect(() => {
        if (generalYears?.data) {
            const options = generalYears.data.map((generalYear) => {
                return {
                    text: String(generalYear.year),
                    key: generalYear.year,
                };
            });
            const formYears = generalYears.data.filter((generalYear) => generalYear.isSelected).map((generalYear) => generalYear.year);
            setYearsOptions(options.sort((a, b) => a.key - b.key));
            setValue('years', formYears);
        }
    }, [generalYears?.data, setValue]);

    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const selectedYears = watch('years');

    const createNewYear = (form: INewYearForm) => {
        toggleIsCalloutVisible();
        resetNewYearValue();
        createGeneralYear({ jobYear: { isSelected: form.selected, year: form.year } });
    };

    const saveChanges = (form: IJobYearsForm) => {
        const unCheckedYears = generalYears?.data.filter((i) => !i.isSelected) || [];
        const checkedYears = form.years.map((i) => {
            return { year: i, isSelected: true };
        });
        updateGeneralYear({ jobYears: [...unCheckedYears, ...checkedYears] });
    };

    return (
        <Card styles={{ root: { marginBottom: '16px' } }}>
            {!generalYears || isLoading || createGeneralYearLoading || updateGeneralYearLoading ? (
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 96 }]} />
            ) : (
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item styles={{ root: { width: 200 } }}>
                            <ControlledMultiSelect
                                name='years'
                                control={control}
                                options={yearsOptions}
                                label={formatMessage({ id: 'years' })}
                                selectedYears={selectedYears}
                                selectAll
                            />
                        </Stack.Item>

                        <Stack.Item align='end'>
                            <DefaultButton
                                id={buttonId}
                                iconProps={{ iconName: 'Add' }}
                                text={formatMessage({ id: 'add' })}
                                title={formatMessage({ id: 'addYear' })}
                                onClick={toggleIsCalloutVisible}
                            />
                            {isCalloutVisible && (
                                <Callout
                                    directionalHint={DirectionalHint.rightCenter}
                                    ariaLabelledBy={labelId}
                                    onDismiss={toggleIsCalloutVisible}
                                    target={`#${buttonId}`}
                                    setInitialFocus>
                                    <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
                                        <Stack.Item align='center'>
                                            <TooltipHost content='Is selected' id={'is-selected-tooltip'}>
                                                <ControlledCheckbox name='selected' control={newYearControl} />
                                            </TooltipHost>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <ControlledNumberField name='year' control={newYearControl} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton
                                                disabled={!formStateYear.isDirty}
                                                onClick={createHandle(createNewYear)}
                                                text={formatMessage({ id: 'save' })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Callout>
                            )}
                        </Stack.Item>
                        <Stack.Item align='end'>
                            <PrimaryButton
                                disabled={!formState.isDirty}
                                onClick={handleSubmit(saveChanges)}
                                text={formatMessage({ id: 'save' })}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <Text styles={{ root: { fontWeight: 500 } }}>Selected years: </Text>
                        <Text> {selectedYears.sort((a, b) => a - b).join(', ') ?? null}</Text>
                    </Stack.Item>
                </Stack>
            )}
        </Card>
    );
};
