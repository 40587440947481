import { FunctionComponent } from 'react';
import { CommandBarButton, DirectionalHint, IContextualMenuProps } from '@fluentui/react';
import { useIntl } from 'react-intl';

export const HelpMenu: FunctionComponent = () => {
    const intl = useIntl();

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'helpGuide',
                text: intl.formatMessage({ id: 'helpGuide' }),
                iconProps: { iconName: 'Info' },
            },

            {
                key: 'contactSupport',
                text: intl.formatMessage({ id: 'contactSupport' }),
                iconProps: { iconName: 'Mail' },
            },
        ],
        directionalHint: DirectionalHint.bottomLeftEdge,
    };

    return (
        <CommandBarButton
            styles={{ root: { height: 48, paddingLeft: 10, paddingRight: 10 } }}
            text={intl.formatMessage({ id: 'help' })}
            iconProps={{ iconName: 'Help' }}
            onRenderMenuIcon={() => <div />}
            menuProps={menuProps}
        />
    );
};
