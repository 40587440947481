import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'components';
import { ICreditNote, ICreditNoteMultipleInvoiceFormValues } from 'interfaces';
import { CreditNoteSingleInvoice } from './CreditNoteSingleInvoice';
import { CreditNoteMultipleInvoice } from './CreditNoteMultipleInvoice';

interface Props {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    creditNote?: ICreditNote;
}

export const CreditNoteViewModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const handleMultipleInvoiceSubmit = (data: ICreditNoteMultipleInvoiceFormValues) => {
        props.onOpenChange(false);
    };

    const handleSingleInvoiceSubmit = (data: { creditNoteApplied: number }) => {
        props.onOpenChange(false);
    };

    return (
        <Modal
            title={`${formatMessage({ id: 'creditNoteNumber' })}: ${props.creditNote?.id}`}
            isOpen={props.open}
            onDismiss={() => props.onOpenChange(false)}>
            {/*{props.creditNote?.details?.length ? (*/}
            {/*    <CreditNoteMultipleInvoice {...props} onSubmit={handleMultipleInvoiceSubmit} />*/}
            {/*) : (*/}
            {/*    <CreditNoteSingleInvoice {...props} onSubmit={handleSingleInvoiceSubmit} />*/}
            {/*)}*/}
        </Modal>
    );
};
