import { DashboardFilter } from './DashboardFilter';
import { FunctionComponent } from 'react';
import { Stack } from '@fluentui/react';
import { Widget, Widgets } from './Widget';

export interface IDashboardRow {
	rowIndex: number;
	widgets: Widgets[];
}

export interface IDashboardConfig {
	id: string | number;
	rows: Widgets[][];
}

export interface IDashboardContainer {
	dashboardConfig: IDashboardConfig;
	isLoading: boolean;
}


const DashboardRow: FunctionComponent<IDashboardRow> = ({ widgets, rowIndex }) => {
	if (!widgets.length) return null;
	const gapWidth = 16 * (widgets.length - 1);
	const totalSize = widgets.reduce((acc, widget) => acc + (widget?.size || 1), 0);
	return (
		<Stack tokens={{ childrenGap: 16 }} horizontal>
			{widgets.map(({ size = 1, ...widget }, idx) => {
				const width = `calc((100% - ${gapWidth}px) / ${totalSize} * ${size})`
				return (
					<Stack.Item styles={{ root: { width } }}  key={`dashboard-cell-${idx}-${rowIndex}`}>
						<Widget {...widget} />
					</Stack.Item>
				);
			})}
		</Stack>
	);
};


export const DashboardContainer: FunctionComponent<IDashboardContainer> = ({ dashboardConfig, isLoading = false }) => {
	// !TODO show loader if isLoading
	return (
		<Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
			<DashboardFilter />
			<Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
				{dashboardConfig.rows.map((row, idx) => (
					<DashboardRow widgets={row} rowIndex={idx} key={`dashboard-row-${idx}`} />
				))}
			</Stack>
		</Stack>
	);
};