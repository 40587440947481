import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useEffect } from 'react';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    options: IComboBoxOption[];
    dropdownWidth?: number;
    dropdownMaxWidth?: number;
    allowFreeform?: boolean;
}

export function ControlledAutoComplete<FormValues>({
    name,
    control,
    rules,
    label,
    options: initialOptions,
    dropdownWidth = 200,
    dropdownMaxWidth,
    allowFreeform,
}: Props<FormValues>) {
    const [options, setOptions] = React.useState(initialOptions);

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const onChangeComboBox = React.useCallback(
        (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, value?: string, onChange?: (...event: any[]) => void): void => {
            let key = option?.key;
            if (allowFreeform && !option && value) {
                // If allowFreeform is true, the newly selected option might be something the user typed that
                // doesn't exist in the options list yet. So there's extra work to manually add it.
                setOptions((prevOptions) => [...prevOptions, { key: value, text: value }]);
                key = value;
            }

            if (onChange) onChange(key);
        },
        [allowFreeform]
    );

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <ComboBox
                    label={label}
                    options={options}
                    dropdownWidth={dropdownWidth}
                    dropdownMaxWidth={dropdownMaxWidth}
                    onInputValueChange={onChange}
                    onChange={(ev, option, index, v) => onChangeComboBox(ev, option, v, onChange)}
                    selectedKey={value as string}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    allowFreeform={allowFreeform}
                    autoComplete='on'
                />
            )}
        />
    );
}
