import { Stack } from '@fluentui/react';
import { useState } from 'react';
import { Card, Pagination } from 'components';
import { allData } from '../components/data/Data';
import { FundsDataTable, FundsFilters } from '../components/tables';

export const FundsWorkflowPage = () => {
    const [, setFilters] = useState<{ pageSize: number; page: number }>({ pageSize: 1, page: 1 });

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters({ pageSize, page });
        // loadUsers();
    };
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <FundsFilters />
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <FundsDataTable />
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Card>
                    <Pagination
                        itemsCount={5}
                        total={allData.items.length}
                        page={allData.pageNumber}
                        pageSize={allData.pageSize}
                        onChange={onPaginationChange}
                    />
                </Card>
            </Stack.Item>
        </Stack>
    );
};
