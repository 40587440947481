import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { VerticalSubmenu } from 'components/VerticalSubmenu';
import { mockedContacts } from 'constants/MockedContacts';
import { ContactTab } from '../ContactTab';
import { ControlledTextField } from 'components';

interface Props {
    open: boolean;
    toggle: () => void;
    onSubmit: (data: any) => void;
    title?: string;
}

interface IClientForm {
    contact: string;
    firstName: string;
    lastName: string;
    email: string;
    businessPhone: string;
    fundName: string;
    trusteeName: string;
    abn: string;
}

export const AddFundModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, watch, setValue, formState } = useForm<IClientForm>({
        ...DefaultFormSettings,
        defaultValues: {
            contact: '',
            firstName: '',
            lastName: '',
            email: '',
            businessPhone: '',
            fundName: '',
            trusteeName: '',
            abn: '',
        },
    });

    const [selectedTab, setSelectedTab] = useState<'contact' | 'fund'>('fund');

    const links = [
        {
            name: 'Fund',
            key: 'fund',
            url: '',
        },
        {
            name: 'Contact',
            key: 'contact',
            url: '',
        },
    ];

    return (
        <Modal title={props?.title || formatMessage({ id: 'addFund' })} isOpen={props.open} onDismiss={props.toggle}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <VerticalSubmenu setSelected={setSelectedTab} defaultValue={selectedTab} links={links} />
                        <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                            {selectedTab === 'fund' && (
                                <>
                                    <Stack.Item>
                                        <ControlledTextField name='fundName' control={control} label={formatMessage({ id: 'fundName' })} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <ControlledTextField
                                            name='trusteeName'
                                            control={control}
                                            label={formatMessage({ id: 'trusteeName' })}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <ControlledTextField name='abn' control={control} label={formatMessage({ id: 'abn' })} />
                                    </Stack.Item>
                                </>
                            )}
                            {selectedTab === 'contact' && (
                                <ContactTab control={control} watch={watch} setValue={setValue} contacts={mockedContacts} />
                            )}
                        </Stack>
                    </Stack>
                </Card>

                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit((data) => props.onSubmit(data))}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
};
