import { createContext, FunctionComponent, useContext } from 'react';
import { Modal } from 'components';
import { InvoicesAddMultipleJobsStepper } from './InvoicesAddMultipleJobsStepper';
import { AddMultipleJobsStore } from './stores/AddMultipleJobsStore';

interface Props {
    open: boolean;
    onOpenChange: (value: boolean) => void;
}

const AddMultipleJobsStoreContext = createContext<AddMultipleJobsStore>(new AddMultipleJobsStore());

export const useAddMultipleJobsStore = () => useContext(AddMultipleJobsStoreContext);

export const InvoicesAddMultipleJobsModal: FunctionComponent<Props> = (props: Props) => (
    <Modal title='Multiple Jobs' isOpen={props.open} onDismiss={() => props.onOpenChange(false)}>
        <AddMultipleJobsStoreContext.Provider value={new AddMultipleJobsStore()}>
            <InvoicesAddMultipleJobsStepper onClose={() => props.onOpenChange(false)} />
        </AddMultipleJobsStoreContext.Provider>
    </Modal>
);
