import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { stores } from 'stores';
import { SignIn } from './SignIn';

interface Props {
    children: JSX.Element;
}

export const RequireAuth: FunctionComponent<Props> = observer(({ children }: Props) => {
    if (stores.AuthStore.isAuthorised) {
        return children;
    }

    return (
        <SignIn
            getToken={stores.AuthStore.getToken}
            signIn={stores.AuthStore.signIn}
            isSigningIn={stores.AuthStore.isSigningIn}
            isSigningOut={stores.AuthStore.isSigningOut}
        />
    );
});
