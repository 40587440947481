import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface Props {
    title: string;
    subText: string;
    hidden: boolean;
    onAgree: () => void;
    toggleHidden: () => void;
    noDisabled?: boolean;
    yesDisabled?: boolean;
}
export const BaseDialog: FunctionComponent<Props> = ({ title, subText, hidden, onAgree, toggleHidden, noDisabled, yesDisabled }: Props) => {
    const { formatMessage } = useIntl();
    return (
        <Dialog hidden={hidden} onDismiss={toggleHidden} dialogContentProps={{ type: DialogType.normal, title, subText }}>
            <DialogFooter>
                <DefaultButton disabled={noDisabled} onClick={toggleHidden} text={formatMessage({ id: 'no' })} />
                <PrimaryButton
                    disabled={yesDisabled}
                    onClick={() => {
                        onAgree();
                        // toggleHidden();
                    }}
                    text={formatMessage({ id: 'yes' })}
                />
            </DialogFooter>
        </Dialog>
    );
};
