import {IAdministrationList} from 'interfaces';
import {Stack} from '@fluentui/react';
import {AdministrationList} from './AdministrationList';
import {AdministrationPagination} from './AdministrationPagination';

interface IAdministrationListWithPaginationProps extends IAdministrationList {
    totalItemsCount: number;
}

export const AdministrationListWithPagination = ({
    onCardClick, items, buttonsConfig, totalItemsCount, title,
}: IAdministrationListWithPaginationProps): JSX.Element => {
    return (
        <>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden', height: '50%' } }}>
                <AdministrationList
                    onCardClick={onCardClick}
                    items={items}
                    title={title}
                    buttonsConfig={buttonsConfig}/>
            </Stack.Item>
            <Stack.Item shrink={0}>
                <AdministrationPagination
                    pagination={{
                      totalItemsCount,
                      shownItemsCount: 25,
                      items: [],
                    }}
                    load={() => {}}/>
            </Stack.Item>
        </>
    );
};