import { INavLink, Panel, Stack, useTheme } from '@fluentui/react';
import { Header } from './Header';
import { VerticalMenu } from './VerticalMenu';
import React, { FunctionComponent, ReactNode } from 'react';
import { LayoutBreadcrumb } from './LayoutBreadcrumb';
import { useBoolean } from '@fluentui/react-hooks';
import { Notification } from './Notification';
import { stores } from 'stores';
import { observer } from 'mobx-react';
import { useGetCurrent } from 'hooks/useGetCurrent';

interface Props {
    children: ReactNode;
    links: INavLink[];
    headerLeft?: ReactNode | undefined;
    headerRight?: ReactNode | undefined;
}

export const Layout: FunctionComponent<Props> = observer(({ children, links, headerLeft, headerRight }: Props) => {
    const { notifications } = stores.GlobalNotificationsStore;
    const theme = useTheme();
    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const { data: dataUser } = useGetCurrent();

    return (
        <Stack
            styles={{
                root: {
                    height: '100%',
                    background: theme.palette.white,
                },
            }}>
            <Header openPanel={openPanel} left={headerLeft} right={headerRight} userName={dataUser?.data.displayName || ''} />
            <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                            background: theme.palette.neutralLight,
                            padding: 16,
                            boxSizing: 'border-box',
                        },
                    }}
                    tokens={{ childrenGap: 16 }}>
                    <Stack.Item>
                        <LayoutBreadcrumb />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { height: 'calc(100% - 49px - 16px)' } }}>{children}</Stack.Item>
                </Stack>
            </Stack>
            <Panel
                styles={{ content: { marginTop: 10, overflowY: 'auto' } }}
                headerText='Notification panel'
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel='Close'>
                <Stack tokens={{ childrenGap: 16 }}>
                    {notifications.map((notification, idx) => (
                        <Notification key={idx} description={notification.description} name={notification.name} type={notification.type} />
                    ))}
                </Stack>
            </Panel>
        </Stack>
    );
});
