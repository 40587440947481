import {Outlet, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {useAdministrationContext} from './AdministrationLayoutPage';

export const JobListLayoutPage = () => {
    const { formatMessage } = useIntl();
    const { clientId, fundId } = useParams();
    const { selectedFund } = useAdministrationContext();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'jobs',
                key: RouterPaths.administration.jobs.funds.jobList.root(clientId, fundId),
                text: selectedFund?.name || formatMessage({ id: 'jobs' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};