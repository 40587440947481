import { FunctionComponent } from 'react';
import { Modal } from 'components';
import { InvoiceAddForm } from './InvoiceAddForm';
import { InvoiceItem } from '../hooks/Invoices/useGetInvoices';

interface Props {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    invoice?: InvoiceItem;
}

export const InvoiceAddModal: FunctionComponent<Props> = (props: Props) => {
    return (
        <Modal title='Edit Invoice' isOpen={props.open} onDismiss={() => props.onOpenChange(false)}>
            <InvoiceAddForm invoice={props.invoice!} onClose={() => props.onOpenChange(false)} />
        </Modal>
    );
};
