import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { ChoiceGroup, mergeStyles } from '@fluentui/react';
import { IChoiceGroupOption } from '@fluentui/react/lib/components/ChoiceGroup/ChoiceGroup.types';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    options?: IChoiceGroupOption[];
    disabled?: boolean;
}

export function ControlledChoiceGroup<FormValues>({ name, control, rules, label, options, disabled }: Props<FormValues>) {
    const stylesOptions = options?.map((o) => {
        o.styles = {
            choiceFieldWrapper: { '::after': { display: 'none' } },
        };
        return o;
    });

    const controlRootClass = mergeStyles({
        width: '100%',
        maxWidth: 350,
        '>div >div': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <ChoiceGroup
                    selectedKey={value as number | string}
                    options={stylesOptions}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
                        onChange(option?.value)
                    }
                    label={label}
                    disabled={disabled}
                    className={controlRootClass}
                />
            )}
        />
    );
}
