import { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ExamplePrivatePage, PrivateLayoutPage } from 'pages';
import { RequireAuth } from './RequiredAuth';
import { CreditNotesLayoutPage, CreditNotesPage, InvoicePortalLayoutPage, InvoicesPage } from 'pages/InvoicePortal';
import {
    AuditReadyConnectionsLayoutPage,
    AuditReadyConnectionsPage,
    EmailTemplatesLayoutPage,
    EmailTemplatesPage,
    GeneralSettingsLayoutPage,
    GeneralSettingsPage,
    MaterialityCalculatorSettingsLayoutPage,
    MaterialityCalculatorSettingsPage,
    PortalSettingsLayoutPage,
    RiskMatrixSettingsLayoutPage,
    RiskMatrixSettingsPage,
    TestingAndMethodologySettingsLayoutPage,
    TestingAndMethodologySettingsPage,
} from 'pages/PortalSettings';
import { AdminLayoutPage } from 'pages/Admin';
import {
    RulesetLayoutPage,
    RulesetNewRuleLayoutPage,
    RulesetPage,
    RulesetRuleLayoutPage,
    RulesetRulePage,
    RulesetRulesLayoutPage,
    RulesetsLayoutPage,
    RulesetsPage,
} from 'pages/Admin/Rulesets';
import { UserManagementLayoutPage, UserManagementPage } from 'pages/UserManagement';
import { RouterPaths } from './RouterPaths';
import { CustomizationLayoutPage, CustomizationPage } from 'pages/Customization';
import { NotFoundPage } from 'pages/NotFound';
import {
    JobsLayoutPage,
    JobsPage,
    AdministrationLayoutPage,
    FundsListLayoutPage,
    FundsListPage,
    JobListLayoutPage,
    JobListPage,
} from 'pages/Administration';
import {
    WorkflowLayoutPage,
    DashboardWorkflowPage,
    DashboardWorkflowLayoutPage,
    FundsWorkflowLayoutPage,
    FundsWorkflowPage,
    EmployeesWorkflowLayoutPage,
    EmployeesWorkflowPage,
    ClientsWorkflowLayoutPage,
    ClientsWorkflowPage,
    JobsWorkflowLayoutPage,
    JobsWorkflowPage,
    ReportsWorkflowLayoutPage,
    ReportsWorkflowPage,
    TimesheetWorkflowLayoutPage,
    TimesheetWorkflowPage,
} from 'pages/Workflow';

export const AppRoutes: FunctionComponent = () => (
    <Routes>
        <Route
            path={RouterPaths.invoices.root}
            element={
                <RequireAuth>
                    <InvoicePortalLayoutPage />
                </RequireAuth>
            }>
            <Route path={RouterPaths.invoices.root} element={<InvoicesPage />} />
            <Route path={RouterPaths.invoices.creditNotes} element={<CreditNotesLayoutPage />}>
                <Route path={RouterPaths.invoices.creditNotes} element={<CreditNotesPage />} />
            </Route>
        </Route>

        <Route
            path='/'
            element={
                <RequireAuth>
                    <PrivateLayoutPage />
                </RequireAuth>
            }>
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/' element={<Navigate to='/private' replace />} />
            <Route path='/private' element={<ExamplePrivatePage />} />
            <Route path={RouterPaths.portalSettings.root} element={<PortalSettingsLayoutPage />}>
                <Route path={RouterPaths.portalSettings.auditReadyConnections} element={<AuditReadyConnectionsLayoutPage />}>
                    <Route path={RouterPaths.portalSettings.auditReadyConnections} element={<AuditReadyConnectionsPage />} />
                </Route>

                <Route path={RouterPaths.portalSettings.generalSettings} element={<GeneralSettingsLayoutPage />}>
                    <Route path={RouterPaths.portalSettings.generalSettings} element={<GeneralSettingsPage />} />
                </Route>

                <Route path={RouterPaths.portalSettings.riskMatrixSettings} element={<RiskMatrixSettingsLayoutPage />}>
                    <Route path={RouterPaths.portalSettings.riskMatrixSettings} element={<RiskMatrixSettingsPage />} />
                </Route>

                <Route
                    path={RouterPaths.portalSettings.materialityCalculatorSettings}
                    element={<MaterialityCalculatorSettingsLayoutPage />}>
                    <Route
                        path={RouterPaths.portalSettings.materialityCalculatorSettings}
                        element={<MaterialityCalculatorSettingsPage />}
                    />
                </Route>

                <Route
                    path={RouterPaths.portalSettings.testingAndMethodologySettings}
                    element={<TestingAndMethodologySettingsLayoutPage />}>
                    <Route
                        path={RouterPaths.portalSettings.testingAndMethodologySettings}
                        element={<TestingAndMethodologySettingsPage />}
                    />
                </Route>

                <Route path={RouterPaths.portalSettings.emailTemplates} element={<EmailTemplatesLayoutPage />}>
                    <Route path={RouterPaths.portalSettings.emailTemplates} element={<EmailTemplatesPage />} />
                </Route>
            </Route>
            <Route path={RouterPaths.userManagement} element={<UserManagementLayoutPage />}>
                <Route path={RouterPaths.userManagement} element={<UserManagementPage />} />
            </Route>

            <Route path={RouterPaths.administration.root} element={<AdministrationLayoutPage />}>
                <Route path={RouterPaths.administration.jobs.root} element={<JobsLayoutPage />}>
                    <Route path={RouterPaths.administration.jobs.root} element={<JobsPage />} />
                    <Route path={RouterPaths.administration.jobs.funds.root()} element={<FundsListLayoutPage />}>
                        <Route path={RouterPaths.administration.jobs.funds.root()} element={<FundsListPage />} />
                        <Route path={RouterPaths.administration.jobs.funds.jobList.root()} element={<JobListLayoutPage />}>
                            <Route path={RouterPaths.administration.jobs.funds.jobList.root()} element={<JobListPage />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path={RouterPaths.customization} element={<CustomizationLayoutPage />}>
                <Route path={RouterPaths.customization} element={<CustomizationPage />} />
            </Route>

            <Route path={RouterPaths.workflow.root} element={<WorkflowLayoutPage />}>
                <Route path={RouterPaths.workflow.dashboard} element={<DashboardWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.dashboard} element={<DashboardWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.clients} element={<ClientsWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.clients} element={<ClientsWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.funds} element={<FundsWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.funds} element={<FundsWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.employees} element={<EmployeesWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.employees} element={<EmployeesWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.jobs} element={<JobsWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.jobs} element={<JobsWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.reports} element={<ReportsWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.reports} element={<ReportsWorkflowPage />} />
                </Route>
                <Route path={RouterPaths.workflow.timesheet} element={<TimesheetWorkflowLayoutPage />}>
                    <Route path={RouterPaths.workflow.timesheet} element={<TimesheetWorkflowPage />} />
                </Route>
            </Route>
        </Route>

        <Route
            path={RouterPaths.admin.root}
            element={
                <RequireAuth>
                    <AdminLayoutPage />
                </RequireAuth>
            }>
            <Route path={RouterPaths.admin.rulesets.root} element={<RulesetsLayoutPage />}>
                <Route path={RouterPaths.admin.rulesets.root} element={<RulesetsPage />} />

                <Route path={RouterPaths.admin.rulesets.ruleset.root()} element={<RulesetLayoutPage />}>
                    <Route path={RouterPaths.admin.rulesets.ruleset.root()} element={<RulesetPage />} />

                    <Route path={RouterPaths.admin.rulesets.ruleset.rules.root()} element={<RulesetRulesLayoutPage />}>
                        <Route path={RouterPaths.admin.rulesets.ruleset.rules.new()} element={<RulesetNewRuleLayoutPage />}>
                            <Route path={RouterPaths.admin.rulesets.ruleset.rules.new()} element={<RulesetRulePage />} />
                        </Route>
                        <Route path={RouterPaths.admin.rulesets.ruleset.rules.rule()} element={<RulesetRuleLayoutPage />}>
                            <Route path={RouterPaths.admin.rulesets.ruleset.rules.rule()} element={<RulesetRulePage />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Route>
    </Routes>
);
