import React, { useState } from 'react';
import { DialogType, IColumn, IDropdownOption, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { BillingEntityType } from 'enums';
import { Card, ControlledDropdown, DataTable, Pagination, SearchPanel } from 'components';
import { InvoiceStatus } from './InvoiceStatus';
import { InvoiceTableActions } from './InvoiceTableActions';
import { InvoicesTableExtraAmountColumn } from './InvoicesTableExtraAmountColumn';
import { InvoicesAddMultipleJobsModal } from '../InvoicesAddMultipleJobsModal';
import { CardTableContainerStyles } from '../../../../constants/tableStylesPeset';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { mockedFunds } from 'constants/MockedFunds';
import { DialogMessage } from 'components/DialogMessage';
import { InvoiceItem } from 'pages/InvoicePortal/hooks/Invoices/useGetInvoices';
import { IInvoicesFilters } from 'interfaces';
import { InvoiceAddModal } from '../InvoiceAddModal';

type InvoiceTablePropsType = {
    invoices: InvoiceItem[];

    invoicesLoading: boolean;
    invoicesPageSize: number;
    setInvoicesPagination: React.Dispatch<
        React.SetStateAction<{
            page: number;
            size: number;
        }>
    >;
    invoicesPage: number;
    invoicesTotal: number;
    setFilters: React.Dispatch<
        React.SetStateAction<{
            fund: string;
            status: string;
            year: string;
        }>
    >;
    filters: {
        fund: string;
        status: string;
        year: string;
    };
};

export const InvoicesTableData = observer(
    ({
        invoices,
        invoicesLoading,
        invoicesPage,
        invoicesPageSize,
        invoicesTotal,
        setInvoicesPagination,
        filters,
        setFilters,
    }: InvoiceTablePropsType) => {
        const { formatMessage, formatNumber, formatDate } = useIntl();
        const [chosenInvoice, setChosenInvoice] = useState<InvoiceItem | undefined>(undefined);
        const [openAddInvoiceModal, setOpenAddInvoiceModal] = useState(false);
        const [openAddMultipleJobsModal, setOpenAddMultipleJobsModal] = useState(false);
        const [isDialogHidden, setIsDialogHidden] = React.useState<boolean>(true);

        const dialogContentProps = {
            type: DialogType.normal,
            title: 'Invoice Removing',
            closeButtonAriaLabel: 'Close',
            subText: 'Sure to remove this invoice?',
        };

        const handleAddInvoiceClick = (invoice: InvoiceItem) => {
            setChosenInvoice(invoice);
            setOpenAddInvoiceModal(true);
        };

        const handleOpenChangeInvoiceModal = (value: boolean) => {
            if (!value) {
                setChosenInvoice(undefined);
                setOpenAddInvoiceModal(false);
            }
        };

        const columns: IColumn[] = [
            {
                key: 'invoiceNumber',
                name: formatMessage({ id: 'invoiceNo' }),
                fieldName: 'id',
                minWidth: 95,
                maxWidth: 95,
                onRender: (item: InvoiceItem, idx) => (
                    <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                        {item.id || Number(idx) + 1}
                    </Text>
                ),
                isMultiline: true,
            },
            {
                key: 'jobName',
                name: formatMessage({ id: 'job' }),
                fieldName: 'jobName',
                minWidth: 150,
                maxWidth: 200,
                isMultiline: true,
                onRender: (item: InvoiceItem) => item.invoiceJobs[0]?.jobName,
            },
            {
                key: 'fundName',
                name: formatMessage({ id: 'fund' }),
                fieldName: 'fundName',
                minWidth: 190,
                maxWidth: 500,
                isMultiline: true,
                onRender: (item: InvoiceItem) => item.invoiceJobs[0]?.fundName,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                fieldName: 'amount',
                minWidth: 80,
                maxWidth: 100,
                isMultiline: true,
                onRender: (item: InvoiceItem) => formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' }),
            },
            {
                key: 'extraAmount',
                name: formatMessage({ id: 'extraAmount' }),
                minWidth: 115,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: InvoiceItem) => <InvoicesTableExtraAmountColumn item={item} />,
            },
            {
                key: 'total',
                name: formatMessage({ id: 'total' }),
                fieldName: 'total',
                minWidth: 55,
                maxWidth: 100,
                isMultiline: true,
                onRender: (item: InvoiceItem) => formatNumber(item.total ?? 0, { style: 'currency', currency: 'USD' }),
            },
            {
                key: 'status',
                name: formatMessage({ id: 'status' }),
                fieldName: 'status',
                minWidth: 110,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: InvoiceItem) => <InvoiceStatus invoice={item} />,
            },
            {
                key: 'creationDate',
                name: formatMessage({ id: 'creationDate' }),
                fieldName: 'creationDate',
                minWidth: 115,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: InvoiceItem) => (item.invoiceDate ? formatDate(item.invoiceDate) : null),
            },
            {
                key: 'paragraph',
                name: formatMessage({ id: 'invoiceParagraph' }),
                fieldName: 'paragraph',
                minWidth: 115,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: InvoiceItem) => (item.paragraph ? 'DD' : 'Standart'),
            },
            {
                key: 'billingEntityType',
                name: formatMessage({ id: 'billingEntity' }),
                fieldName: 'billingEntityType',
                minWidth: 85,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: InvoiceItem) => BillingEntityType[item.billingEntityType],
            },
            {
                key: 'actions',
                name: '',
                fieldName: 'actions',
                minWidth: 52,
                maxWidth: 52,
                onRender: (item: InvoiceItem) => (
                    <InvoiceTableActions
                        onDeleteClick={() => setIsDialogHidden(false)}
                        invoice={item}
                        onAddClick={() => handleAddInvoiceClick(item)}
                    />
                ),
            },
        ];

        //INVOICE FILTERS
        const { control, handleSubmit, reset } = useForm<IInvoicesFilters>({
            ...DefaultFormSettings,
            defaultValues: filters,
        });

        const statusOptions: IDropdownOption[] = [
            {
                key: '',
                text: formatMessage({ id: 'all' }),
            },
            { key: '0', text: formatMessage({ id: 'invoicesFilters.notCreatedOnly' }) },
            { key: '10', text: formatMessage({ id: 'invoicesFilters.open' }) },
            { key: '20', text: formatMessage({ id: 'invoicesFilters.closed' }) },
        ];

        const fundsOptions: IDropdownOption[] = [
            { key: '', text: formatMessage({ id: 'all' }) },
            ...mockedFunds.map((f) => ({ key: f.guid, text: f.name })),
        ];

        const yearsOptions: IDropdownOption[] = [
            { key: '', text: formatMessage({ id: 'all' }) },
            { key: 2018, text: '2018' },
            { key: 2017, text: '2017' },
            { key: 2016, text: '2016' },
        ];

        //PAGINATION
        const onPaginationChange = (pageSize: number, page: number) => {
            setInvoicesPagination({ page, size: pageSize });
        };

        return (
            <>
                <Stack.Item shrink={0}>
                    <SearchPanel
                        onSearch={handleSubmit((data) => {
                            setFilters({ fund: data.fund || '', status: data.status || '', year: data.year || '' });
                        })}
                        onClear={() => {
                            reset({ status: '', invoiceNumber: '', fund: '', year: '' });
                            setFilters({ fund: '', status: '', year: '' });
                            setInvoicesPagination({ size: invoicesPageSize, page: 1 });
                        }}>
                        <ControlledDropdown
                            label={formatMessage({ id: 'status' })}
                            name='status'
                            control={control}
                            options={statusOptions}
                            dropdownWidth={250}
                        />

                        {/* {[InvoiceFilterStatusType.Open, InvoiceFilterStatusType.Closed, InvoiceFilterStatusType.Created].includes(
                            watch('status')
                        ) ? (
                            <ControlledTextField label={formatMessage({ id: 'invoiceNumber' })} name='invoiceNumber' control={control} />
                        ) : null} */}

                        <ControlledDropdown
                            label={formatMessage({ id: 'fund' })}
                            name='fund'
                            control={control}
                            options={fundsOptions}
                            dropdownWidth={150}
                        />

                        <ControlledDropdown
                            label={formatMessage({ id: 'year' })}
                            name='year'
                            control={control}
                            options={yearsOptions}
                            dropdownWidth={60}
                        />
                    </SearchPanel>
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                    <Card styles={CardTableContainerStyles}>
                        <Stack horizontal horizontalAlign='end' verticalAlign='center' tokens={{ childrenGap: 16 }}>
                            <PrimaryButton
                                disabled={true}
                                text={formatMessage({ id: 'multipleJobs' })}
                                onClick={() => setOpenAddMultipleJobsModal(true)}
                            />
                            <PrimaryButton disabled={true} text={formatMessage({ id: 'importPayments' })} />
                        </Stack>
                        <DataTable
                            initialColumns={columns}
                            enableShimmer={invoicesLoading}
                            shimmerLines={invoicesPageSize}
                            items={cloneDeep(invoices)}
                            selectionMode={SelectionMode.none}
                            setKey='none'
                            isHeaderVisible
                            containerHeight='calc(100% - 32px)'
                        />
                        <InvoiceAddModal invoice={chosenInvoice} open={openAddInvoiceModal} onOpenChange={handleOpenChangeInvoiceModal} />
                        <InvoicesAddMultipleJobsModal
                            open={openAddMultipleJobsModal}
                            onOpenChange={() => setOpenAddMultipleJobsModal(false)}
                        />
                    </Card>
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <Card>
                        <Pagination
                            page={invoicesPage}
                            pageSize={invoicesPageSize}
                            total={invoicesTotal}
                            itemsCount={invoices.length}
                            onChange={onPaginationChange}
                        />
                    </Card>
                </Stack.Item>
                <DialogMessage
                    dialogContentProps={dialogContentProps}
                    hidden={isDialogHidden}
                    onClick={() => setIsDialogHidden(true)}
                    onDismis={() => setIsDialogHidden(true)}
                />
            </>
        );
    }
);
