import React, { FunctionComponent } from 'react';
import { ProgressIndicator, Stack} from '@fluentui/react';
import { Card } from 'components';

export const SignInLoader: FunctionComponent = () => {
    return (
        <Stack styles={{root: {height: '100%'}}} horizontalAlign='center' verticalAlign='center'>
            <Card
                styles={{
                    root: {
                        maxWidth: 500,
                        width: '100%',
                    },
                }}>
                <Stack horizontal horizontalAlign='center' verticalAlign='center'
                       tokens={{childrenGap: 16}}>
                    <ProgressIndicator label="Loading..." styles={{root: {width: '90%'},itemName: {textAlign: 'center'}}}/>
                </Stack>
            </Card>
        </Stack>
    );
};
