import {Stack} from '@fluentui/react';
import {AdministrationFilters} from './components/AdministrationFilters';
import {FundsList} from './components/FundsList';

export const FundsListPage = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <AdministrationFilters />
            </Stack.Item>
            <FundsList />
        </Stack>
    );
};