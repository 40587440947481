import { FunctionComponent } from 'react';
import { Text, useTheme } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';

interface Props {
    item: { isDeleted: boolean };
}

export const ItemDeletedStatus: FunctionComponent<Props> = ({ item }: Props) => {
    const theme = useTheme();

    return (
        <Text
            variant='medium'
            styles={{
                root: {
                    backgroundColor: item.isDeleted ? theme.palette.red : theme.palette.green,
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: 2,
                },
            }}>
            {item.isDeleted ? <FormattedMessage id='deleted' /> : <FormattedMessage id='active' />}
        </Text>
    );
};
