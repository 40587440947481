import { observable, makeObservable, action } from 'mobx';

export class InvoicePortalStore {
    public clientId: string | undefined;

    constructor() {
        makeObservable(this, {
            clientId: observable,
            readClientId: action,
        });
    }

    public readClientId = (searchString: string) => {
        const queryParams = new URLSearchParams(searchString);
        this.clientId = queryParams.get('clientId')!;
    };
}
