import { Card, ControlledDropdown, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ICreateUserCommand } from 'interfaces';

type AddressTabType = {
    control: Control<ICreateUserCommand>;
};

export const AddressTab = ({ control }: AddressTabType) => {
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };
    const { formatMessage } = useIntl();

    const opionsType = [
        { key: 'other', text: formatMessage({ id: 'other' }) },
        { key: 'physical', text: formatMessage({ id: 'physical' }) },
        { key: 'postal', text: formatMessage({ id: 'postal' }) },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown options={opionsType} name='type' label={formatMessage({ id: 'type' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='state' label={formatMessage({ id: 'state' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='street'
                        label={formatMessage({ id: 'street' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='postCode'
                        label={formatMessage({ id: 'postCode' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='street2' label={formatMessage({ id: 'street2' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='country' label={formatMessage({ id: 'country' })} control={control} />
                </Stack.Item>

                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledTextField
                        name='city'
                        label={formatMessage({ id: 'city' }) + '*'}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
