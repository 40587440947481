import { FunctionComponent, ReactNode } from 'react';
import { Stack, useTheme } from '@fluentui/react';

interface Props {
    hasErrors: boolean;
    children: ReactNode;
}

export const RichTextFieldWrap: FunctionComponent<Props> = ({ hasErrors, children }: Props) => {
    const theme = useTheme();

    return (
        <Stack
            styles={{
                root: {
                    border: `1px solid ${hasErrors ? theme.palette.red : theme.palette.neutralPrimary}`,
                    borderRadius: 2,
                    overflow: 'hidden',

                    selectors: {
                        '> *:first-child': {
                            borderBottom: `1px solid ${hasErrors ? theme.palette.red : theme.palette.neutralPrimary}`,
                        },

                        '> *:last-child': {
                            padding: 8,
                        },
                    },
                },
            }}>
            {children}
        </Stack>
    );
};
