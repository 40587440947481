import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card, ControlledDatePicker, ControlledTextField, Modal, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { ICreateClientsCommands } from 'interfaces';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export const DateModal: FunctionComponent<Props> = observer(({ onDismiss, isOpen, title }: Props) => {
    const { control, handleSubmit, formState } = useForm<ICreateClientsCommands>({
        ...DefaultFormSettings,
        defaultValues: {
            date: '',
            employee: '',
            client: '',
            job: '',
            jobYear: '',
            durationMin: '',
            fundName: '',
        },
    });
    const { formatMessage } = useIntl();
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };

    const onSubmit = (data: ICreateClientsCommands) => {
        onDismiss();
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDatePicker
                                name='date'
                                label={formatMessage({ id: 'date' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField name='jobYear' label={formatMessage({ id: 'jobYear' })} control={control} />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='employee'
                                label={formatMessage({ id: 'employee' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>
                        
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='fundName'
                                label={formatMessage({ id: 'fundName' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='client'
                                label={formatMessage({ id: 'client' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='durationMin'
                                label={formatMessage({ id: 'durationMin' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{ root: { width: '100%' } }}>
                            <ControlledTextField
                                name='job'
                                label={formatMessage({ id: 'job' }) + '*'}
                                control={control}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(onSubmit)}
                    text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
});
