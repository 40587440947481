import { createContext, useContext, useState } from 'react';
import { Stack } from '@fluentui/react';
import { DefaultPaginationPageSize } from 'constants/paginationSettings';
import { UserManagementDataTable, UserManagementFilters } from './components';
import { UserManagementPagination } from './components/UserManagementPagination';
import { useGetUsersFilter } from './hooks/useGetUsersFilter';
import { OrderDirectionType } from 'enums';
import { IOrderedRequest } from 'interfaces';

export interface IUserPagination {
    page: number;
    pageSize: number;
}

export interface IUserFilters {
    onlySuperUsers: boolean;
    includeDeleted: boolean;
    searchString: string;
}

export interface IUserSort {
    orderDirection: OrderDirectionType;
    orderBy: string;
}

export interface IUserManagement {
    pagination: IUserPagination;
    filters: IUserFilters;
    sort: IOrderedRequest | undefined;
    setPagination: Function;
    setFilters: Function;
    setSort: (value: IOrderedRequest) => void;
}

export const initialContext: IUserManagement = {
    pagination: {
        page: 1,
        pageSize: DefaultPaginationPageSize,
    },
    filters: {
        onlySuperUsers: false,
        includeDeleted: false,
        searchString: '',
    },
    sort: {},
    setPagination: () => {},
    setFilters: () => {},
    setSort: () => {},
};

const UserManagementContext = createContext<IUserManagement>(initialContext);

export const useUserManagement = () => useContext(UserManagementContext);

export const UserManagementPage = () => {
    const [pagination, setPagination] = useState<IUserPagination>({
        pageSize: DefaultPaginationPageSize,
        page: 1,
    });
    const [filters, setFilters] = useState<IUserFilters>({
        onlySuperUsers: false,
        includeDeleted: false,
        searchString: '',
    });
    const [sort, setSort] = useState<IOrderedRequest | undefined>();

    const { usersFilterData, isFetching, refetch } = useGetUsersFilter({ ...filters, ...pagination, ...sort });

    return (
        <UserManagementContext.Provider value={{
            pagination,
            filters,
            sort,
            setPagination,
            setFilters,
            setSort,
        }}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item shrink={0}>
                    <UserManagementFilters isLoading={isFetching} />
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                    <UserManagementDataTable
                        reset={refetch}
                        isLoading={isFetching}
                        users={usersFilterData?.data?.items || []}
                    />
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <UserManagementPagination
                        shownItemsCount={usersFilterData?.data?.shownItemsCount || 0}
                        totalItemsCount={usersFilterData?.data?.totalItemsCount || 0}
                    />
                </Stack.Item>
            </Stack>
        </UserManagementContext.Provider>
    );
};
