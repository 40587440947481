import {mockedFunds} from 'constants/MockedFunds';
import {IAdministrationListItem, IButtonConfig} from 'interfaces';
import {useNavigate, useParams} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {useAdministrationContext} from '../AdministrationLayoutPage';
import {useMemo, FunctionComponent} from 'react';
import {AdministrationListWithPagination} from './AdministrationListWithPagination';
import {useIntl} from 'react-intl';
import {useBoolean} from '@fluentui/react-hooks';
import {AddFundModal} from './modals/AddFundModal';
import {AddJobModal} from './modals/AddJobModal';

export const FundsList: FunctionComponent = ()  => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { formatMessage } = useIntl();
  const { filters, setSelectedFund, setTargetFund } = useAdministrationContext();

  const [openAddJobModal, { toggle: toggleOpenAddJobModal }] = useBoolean(false);
  const [openEditFundModal, { toggle: toggleOpenEditFundModal }] = useBoolean(false);

  const buttonsConfig: IButtonConfig[]  = [
      {
          text: formatMessage({ id: 'addJob' }),
          type: 'default',
          onClick: (data) => {
              setTargetFund(data);
              toggleOpenAddJobModal();
          },
      },
      {
          text: formatMessage({ id: 'editFund' }),
          type: 'primary',
          onClick: (data) => {
              setTargetFund(data);
              toggleOpenEditFundModal();
          },
      },
  ];
  const onCardClick = (_: React.MouseEvent<HTMLElement>, id: string, data: IAdministrationListItem) => {
      setSelectedFund(data);
      navigate(RouterPaths.administration.jobs.funds.jobList.root(clientId, id));
  };

  const onSubmitEditFundModal = (data: any) => {
      console.log(data, 'submit edit fund data');
  };  

  const onSubmitAddJobModal = (data: any) => {
    console.log(data, 'submit add job data');
  };  

  const items: IAdministrationListItem[] = useMemo(() => {
      const searchItems = filters?.search
          ? mockedFunds.filter(fund => fund.name.toLowerCase().includes(filters.search?.toLowerCase() as string))
          : mockedFunds;
      return searchItems.slice((filters.page - 1) * filters.pageSize, filters.page * filters.pageSize);
  }, [filters]);

  return (
      <>
          <AdministrationListWithPagination
              onCardClick={onCardClick}
              items={items}
              buttonsConfig={buttonsConfig}
              totalItemsCount={items.length}
              title={formatMessage({ id: 'funds' })}/>
          <AddFundModal
              open={openEditFundModal}
              toggle={toggleOpenEditFundModal}
              onSubmit={onSubmitEditFundModal}
              title={formatMessage({ id: 'editFund' })}/>
          <AddJobModal
              open={openAddJobModal}
              toggle={toggleOpenAddJobModal}
              onSubmit={onSubmitAddJobModal}/>
      </>
  );
};