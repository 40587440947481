import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    addHiddenTopLabel?: boolean;
    adjustInputHeight?: boolean;
    extraValue?: boolean;
}

export function ControlledCheckbox<FormValues>({
    name,
    control,
    rules,
    label,
    addHiddenTopLabel,
    adjustInputHeight,
    extraValue,
}: Props<FormValues>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <Checkbox
                    label={label}
                    checked={!!value}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => onChange(!!checked)}
                    styles={
                        addHiddenTopLabel || adjustInputHeight
                            ? {
                                  root: {
                                      marginTop: addHiddenTopLabel ? 29 : undefined,
                                      height: adjustInputHeight ? 32 : undefined,
                                      alignItems: adjustInputHeight ? 'center' : undefined,
                                  },
                              }
                            : undefined
                    }
                />
            )}
        />
    );
}
