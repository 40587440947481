import { FunctionComponent, useEffect } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IDropdownOption, Stack } from '@fluentui/react';
import { ControlledTextField } from 'components/controls/ControlledTextField';
import { ControlledDropdown } from 'components';
import { IContact } from 'interfaces';

interface Props {
    control: Control<any>;
    watch: UseFormWatch<any>;
    setValue: UseFormSetValue<any>;
    contacts: IContact[];
}

export const ContactTab: FunctionComponent<Props> = ({ control, watch, setValue, contacts }: Props) => {
    const { formatMessage } = useIntl();

    const contactOptions: IDropdownOption[] = contacts.map((contact) => ({ key: contact.contactid, text: contact.contactName }));
    const currentContactId = watch('contact');

    useEffect(() => {
        const currentContact: IContact | undefined = contacts.find((c) => c.contactid === currentContactId);
        if (currentContact) {
            setValue('firstName', currentContact.firstName);
            setValue('lastName', currentContact.lastName);
            setValue('email', currentContact.email);
            setValue('businessPhone', currentContact.businessPhone);
        }
    }, [currentContactId, setValue, contacts]);

    return (
        <>
            <Stack.Item>
                <ControlledDropdown name='contact' control={control} label={formatMessage({ id: 'contact' })} options={contactOptions} />
            </Stack.Item>
            <Stack.Item>
                <hr style={{ width: '100%' }} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='firstName' control={control} label={formatMessage({ id: 'firstName' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='lastName' control={control} label={formatMessage({ id: 'lastName' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='email' control={control} label={formatMessage({ id: 'email' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='businessPhone'
                    control={control}
                    label={formatMessage({ id: 'businessPhone' })}
                    disabled={true}
                />
            </Stack.Item>
        </>
    );
};
