type ItemsType = {
    id: number;
    name: string;
    active: boolean;
    primaryContact: string;
    phone: string;
    addressState: string;
    partner: string;
    email: string;
    partnerId: number;
    primaryContactId: number;
    uid: string;
    fund: string;
    daysIn: string;
    year: string;
    date: string;
    arSent: string;
};

type DataType = {
    count: number;
    items: ItemsType[];
    pageNumber: number;
    pageSize: number;
};

export const allData: DataType = {
    count: 24,
    items: [
        {
            id: 1,
            uid: '49b1262c-0d78-40ad',
            partnerId: 1,
            primaryContactId: 1,
            name: 'Maksim',
            active: false,
            primaryContact: 'PC_car',
            phone: '+444 623 236',
            addressState: '7586',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 2,
            uid: '49b1262c-0d78-40ad',
            partnerId: 2,
            primaryContactId: 2,
            name: 'Gyrab',
            active: false,
            primaryContact: 'PC_test',
            phone: '+444 938 835',
            addressState: '7238',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 3,
            uid: '49b1262c-0d78-40ad',
            partnerId: 3,
            primaryContactId: 3,
            name: 'Malhaz',
            active: false,
            primaryContact: 'PC_contact',
            phone: '+444 252 352',
            addressState: '3568',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 4,
            uid: '49b1262c-0d78-40ad',
            partnerId: 4,
            primaryContactId: 4,
            name: 'David',
            active: false,
            primaryContact: 'PC_vivi',
            phone: '+444 174 356',
            addressState: '1634',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 5,
            uid: '49b1262c-0d78-40ad',
            partnerId: 5,
            primaryContactId: 5,
            name: 'Omar',
            active: false,
            primaryContact: 'PC_new',
            phone: '+444 667 532',
            addressState: '3633',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 6,
            uid: '49b1262c-0d78-40ad',
            partnerId: 6,
            primaryContactId: 6,
            name: 'Dima',
            active: false,
            primaryContact: 'PC_deBug',
            phone: '+444 367 638',
            addressState: '3875',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 7,
            uid: '49b1262c-0d78-40ad',
            partnerId: 7,
            primaryContactId: 7,
            name: 'Mark',
            active: false,
            primaryContact: 'PC_ssd',
            phone: '+444 174 286',
            addressState: '3948',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 8,
            uid: '49b1262c-0d78-40ad',
            partnerId: 8,
            primaryContactId: 8,
            name: 'John',
            active: false,
            primaryContact: 'PC_zxc',
            phone: '+444 135 635',
            addressState: '3857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 9,
            uid: '49b1262c-0d78-40ad',
            partnerId: 9,
            primaryContactId: 9,
            name: 'Evil',
            active: false,
            primaryContact: 'PC_q13',
            phone: '+444 124 927',
            addressState: '8574',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 10,
            uid: '49b1262c-0d78-40ad',
            partnerId: 10,
            primaryContactId: 81,
            name: 'Tran',
            active: false,
            primaryContact: 'PC_fhr',
            phone: '+444 925 252',
            addressState: '3857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 11,
            uid: '49b1262c-0d78-40ad',
            partnerId: 11,
            primaryContactId: 82,
            name: 'Linda',
            active: false,
            primaryContact: 'PC_href',
            phone: '+444 847 285',
            addressState: '4867',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 12,
            uid: '49b1262c-0d78-40ad',
            partnerId: 12,
            primaryContactId: 83,
            name: 'Thor',
            active: false,
            primaryContact: 'PC_bind',
            phone: '+444 263 945',
            addressState: '1857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 13,
            uid: '49b1262c-0d78-40ad',
            partnerId: 13,
            primaryContactId: 84,
            name: 'Whill',
            active: false,
            primaryContact: 'PC_slice',
            phone: '+444 168 463',
            addressState: '3957',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 14,
            uid: '49b1262c-0d78-40ad',
            partnerId: 14,
            primaryContactId: 85,
            name: 'OGS',
            active: false,
            primaryContact: 'PC_UI',
            phone: '+444 141 521',
            addressState: '4975',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 15,
            uid: '49b1262c-0d78-40ad',
            partnerId: 15,
            primaryContactId: 86,
            name: 'WRL',
            active: false,
            primaryContact: 'PC_jol',
            phone: '+444 384 525',
            addressState: '1957',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 16,
            uid: '49b1262c-0d78-40ad',
            partnerId: 16,
            primaryContactId: 87,
            name: 'Kolya',
            active: false,
            primaryContact: 'PC_dfg',
            phone: '+444 163 738',
            addressState: '8475',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 17,
            uid: '49b1262c-0d78-40ad',
            partnerId: 17,
            primaryContactId: 88,
            name: 'Schott',
            active: false,
            primaryContact: 'PC_drg',
            phone: '+444 734 263',
            addressState: '1846',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 18,
            uid: '49b1262c-0d78-40ad',
            partnerId: 18,
            primaryContactId: 89,
            name: 'Dava',
            active: false,
            primaryContact: 'PC_pdf',
            phone: '+444 263 623',
            addressState: '1856',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 19,
            uid: '49b1262c-0d78-40ad',
            partnerId: 19,
            primaryContactId: 90,
            name: 'Makar',
            active: false,
            primaryContact: 'PC_tsg',
            phone: '+444 632 253',
            addressState: '4857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 20,
            uid: '49b1262c-0d78-40ad',
            partnerId: 20,
            primaryContactId: 91,
            name: 'Oliver',
            active: false,
            primaryContact: 'PC_elem',
            phone: '+444 734 262',
            addressState: '3857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
        {
            id: 21,
            uid: '49b1262c-0d78-40ad',
            partnerId: 21,
            primaryContactId: 92,
            name: 'Banjamin',
            active: false,
            primaryContact: 'PC_rush',
            phone: '+444 623 253',
            addressState: '4857',
            partner: 'InovGroup',
            email: 'test@mail.com',
            arSent: '4124',
            date: '10-10-2010',
            daysIn: '23',
            fund: 'fund',
            year: '1990',
        },
    ],
    pageNumber: 1,
    pageSize: 10,
};
