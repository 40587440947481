import { Card, ControlledTextField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { ICreateUserCommand } from 'interfaces';

type AccountTabType = {
    control: Control<ICreateUserCommand>;
};

export const AuditParticularsTab = ({ control }: AccountTabType) => {
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledTextField
                        name='accountingSoftware'
                        label={formatMessage({ id: 'accountingSoftware' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledTextField
                        name='accSoftwareCode'
                        label={formatMessage({ id: 'accSoftwareCode1' })}
                        control={control}
                        rules={{
                            required: formatMessage({ id: 'requiredField' }),
                            minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
