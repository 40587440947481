import { observable, makeObservable, action } from 'mobx';
import { finalize, switchMap, tap } from 'rxjs';
import { ICreditNote, INewCreditNoteFormValues } from 'interfaces';
import { creditNotesService } from 'services';
import { BasicStore } from 'stores';
import { InvoicePortalStore } from './InvoicePortalStore';

export class CreditNotesStore extends BasicStore {
    public creditNotesPage: number = 1;
    public creditNotesPageSize: number = 50;
    public creditNotesTotal: number = 0;
    public creditNotes: ICreditNote[] = [];
    public creditNotesLoading: boolean = false;

    public createCreditNoteLoading: boolean = false;

    public deleteCreditNoteLoadingIds: number[] = [];

    constructor(private invoicePortalStore: InvoicePortalStore) {
        super();
        makeObservable(this, {
            creditNotesPage: observable,
            creditNotesPageSize: observable,
            creditNotesTotal: observable,
            creditNotes: observable,
            creditNotesLoading: observable,
            createCreditNoteLoading: observable,
            deleteCreditNoteLoadingIds: observable,
            loadCreditNotes: action,
            setCreditNotesPagination: action,
            createCreditNote: action,
            deleteCreditNote: action,
        });
    }

    public setCreditNotesPagination = (pageSize: number, page: number) => {
        this.creditNotesPage = page;
        this.creditNotesPageSize = pageSize;
    };

    public loadCreditNotes = () => {
        this.subscriptions.push(this.loadCreditNotesObservable().subscribe());
    };

    public createCreditNote = (creditNote: INewCreditNoteFormValues) => {
        this.createCreditNoteLoading = true;
        return creditNotesService
            .createCreditNote({ ...creditNote, creationDate: creditNote.creationDate.toISOString() })
            .pipe(finalize(() => (this.createCreditNoteLoading = false)));
    };

    public deleteCreditNote = (creditNoteId: number) => {
        this.deleteCreditNoteLoadingIds = [...this.deleteCreditNoteLoadingIds, creditNoteId];
        this.subscriptions.push(
            creditNotesService
                .deleteCreditNote(creditNoteId)
                .pipe(
                    finalize(() => (this.deleteCreditNoteLoadingIds = this.deleteCreditNoteLoadingIds.filter((id) => id !== creditNoteId))),
                    switchMap(() => this.loadCreditNotesObservable())
                )
                .subscribe()
        );
    };

    private loadCreditNotesObservable = () => {
        this.creditNotesLoading = true;
        return creditNotesService
            .getAllCreditNotes(this.invoicePortalStore.clientId as string, this.creditNotesPage, this.creditNotesPageSize)
            .pipe(
                finalize(() => (this.creditNotesLoading = false)),
                tap((result) => {
                    this.creditNotes = result.items ?? [];
                    this.creditNotesTotal = result.totalItemsCount;
                })
            );
    };
}
