import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Card, ControlledTextField, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';

interface Props {
    onDismiss: () => void;
    onSubmit: (form: { email: string }) => void;
}

export const AddConnectionApproverForm: FunctionComponent<Props> = ({ onDismiss, onSubmit }: Props) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, formState } = useForm<{ email: string }>({
        ...DefaultFormSettings,
        defaultValues: {
            email: '',
        },
    });

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <ControlledTextField label={formatMessage({ id: 'approverEmail' })} name='email' control={control} />
            </Card>
            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton text={formatMessage({ id: 'cancel' })} onClick={onDismiss} /> */}
                {/* <PrimaryButton text={formatMessage({ id: 'save' })} onClick={handleSubmit(onSubmit)} /> */}

                <PrimaryButton disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)} text={formatMessage({ id: 'save' })} />
            </ModalFooter>
        </Stack>
    );
};
