import React, { FunctionComponent, ReactNode } from 'react';
import { Stack } from '@fluentui/react';
import { Logo } from './Logo';
import { UserActions } from './UserActions';

interface Props {
    openPanel: () => void;
    left?: ReactNode | undefined;
    right?: ReactNode | undefined;
    userName: string;
}

export const Header: FunctionComponent<Props> = ({ left, right, openPanel, userName }: Props) => {
    return (
        <Stack horizontal horizontalAlign='space-between'>
            <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                <Logo size='small' currentLogo={true} linkToMain={true} />
                {left ?? null}
            </Stack>
            <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                {/* <CommandBarButton onClick={openPanel} styles={{ root: { height: 48 } }} iconProps={{ iconName: 'Ringer' }} /> */}
                <UserActions userName={userName} />
                {right ?? null}
            </Stack>
        </Stack>
    );
};
