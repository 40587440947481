import { INavLink, INavLinkGroup, Nav } from '@fluentui/react';
import { FunctionComponent, MouseEvent, useMemo, useState } from 'react';

interface Props {
    links: INavLink[];
    setSelected: Function;
    defaultValue?: string;
}

const navStyles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        width: 200,
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    groupContent: {
        marginBottom: 0,
    },
    chevronIcon: {
        left: 16,
    },

    chevronButton: {
        width: '100%',
        backgroundColor: 'transparent',

        selectors: {
            '>i': {
                right: 16,
                left: 'auto',
            },
        },
    },
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',

        selectors: {
            '.ms-Nav-chevronButton ~ &': {
                paddingLeft: 0,
            },

            '.ms-Nav-navItem .ms-Nav-navItems &': {
                fontSize: 12,
            },
        },
    },
};

export const VerticalSubmenu: FunctionComponent<Props> = (props: Props) => {
    const [selectedKey, setSelectedKey] = useState<string | undefined>(props.defaultValue || (props?.links?.length ? props.links[0].key : undefined));

    const navLinkGroups: INavLinkGroup[] = useMemo(
        () => [
            {
                links: props.links,
            },
        ],
        [props.links]
    );

    const onLinkClick = (ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setSelectedKey(item?.key);
        props.setSelected(item?.key);
    };

    return (
        <Nav
            onLinkClick={onLinkClick}
            selectedKey={selectedKey}
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
};