import { ControlledCheckbox, ControlledTextField, SearchPanel } from 'components';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DefaultFormSettings } from 'constants/forms';
import { IUserFilters, useUserManagement } from '../UserManagementPage';
import { pick } from 'lodash';

interface Props {
    isLoading: boolean;
}

export const UserManagementFilters: FunctionComponent<Props> = ({ isLoading }) => {
    const { filters, setFilters } = useUserManagement();
    const { control, setValue, handleSubmit, reset } = useForm<IUserFilters>({
        ...DefaultFormSettings,
        defaultValues: pick(filters, ['includeDeleted', 'searchString', 'onlySuperUsers']),
    });
    const { formatMessage } = useIntl();

    useEffect(() => {
        setValue('includeDeleted', filters.includeDeleted);
        setValue('searchString', filters.searchString);
        setValue('onlySuperUsers', filters.onlySuperUsers);
    }, [filters, setValue]);

    const onSearch = handleSubmit((data) => {
        setFilters(data);
    });

    const onClear = () => {
        reset({ searchString: '', onlySuperUsers: false, includeDeleted: false });
        onSearch();
    };

    return (
        <SearchPanel searchDisabled={isLoading} clearDisabled={isLoading} onSearch={onSearch} onClear={onClear}>
            <ControlledTextField name='searchString' control={control} label={formatMessage({ id: 'searchByUserNameOrEmail' })} />
            <ControlledCheckbox
                name='onlySuperUsers'
                control={control}
                label={formatMessage({ id: 'onlySuperUser' })}
                addHiddenTopLabel
                adjustInputHeight
            />
            <ControlledCheckbox
                name='includeDeleted'
                control={control}
                label={formatMessage({ id: 'includeDeleted' })}
                addHiddenTopLabel
                adjustInputHeight
            />
        </SearchPanel>
    );
};
