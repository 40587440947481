import { TextField } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    borderless?: boolean;
    underlined?: boolean;
    prefix?: string;
    suffix?: string;
    disabled?: boolean;
}

export function ControlledNumberField<FormValues>({ name, control, rules, ...otherProps }: Props<FormValues>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                <TextField
                    name={fieldName}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => onChange(newValue)}
                    value={String(value)}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    type='number'
                    {...otherProps}
                />
            )}
        />
    );
}
