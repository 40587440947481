import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { MessageBarType, ResponsiveMode } from '@fluentui/react';
import { takeUntil } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { ICreateRulesetCommand } from 'interfaces';
import { RouterPaths } from 'navigation';
import { Modal } from 'components';
import { stores } from 'stores';
import { useIsDestroy } from 'utils';
import { RulesetNewForm } from './RulesetNewForm';
import { useRulesetsStore } from '../RulesetsPage';

interface Props {
    isModalOpen: boolean;
    toggleIsModalOpen: () => void;
}

export const RulesetsNew: FunctionComponent<Props> = observer(({ isModalOpen, toggleIsModalOpen }: Props) => {
    const { formatMessage } = useIntl();
    const { createRuleset } = useRulesetsStore();
    const navigate = useNavigate();
    const isDestroy = useIsDestroy();

    const getStyles = () => {
        return {
            main: [
                {
                    selectors: {
                        ['']: {
                            // Apply at root
                            maxWidth: '450px',
                        },
                    },
                },
            ],
        };
    };

    const onSubmit = (data: ICreateRulesetCommand) => {
        createRuleset(data)
            .pipe(takeUntil(isDestroy))
            .subscribe(
                (ruleset) => {
                    stores.GlobalNotificationsStore.addNotification({
                        name: formatMessage({ id: 'success' }),
                        type: MessageBarType.success,
                        description: formatMessage({ id: 'successRulesetCreateMessage' }),
                    });
                    navigate(RouterPaths.admin.rulesets.ruleset.root(ruleset.id));
                },
                (error) => {
                    const nameExist = error?.response?.data?.code === 125020;
                    stores.GlobalNotificationsStore.addNotification({
                        name: formatMessage({ id: 'error' }),
                        type: MessageBarType.error,
                        description: formatMessage({ id: nameExist ? 'rulesetNameAlreadyExist' : 'somethingWentWrong' }),
                    });
                }
            );
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={toggleIsModalOpen}
            styles={{ scrollableContent: { width: '300px' } }}
            title={<FormattedMessage id='addNewRuleset' />}>
            <RulesetNewForm onDismiss={toggleIsModalOpen} onSubmit={onSubmit} />
        </Modal>
    );
});
