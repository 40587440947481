import { ICreditNoteBillingEntity } from 'interfaces';
import { BillingEntityType } from 'enums';

export const mockedBillingEntities: ICreditNoteBillingEntity[] = [
    { entityType: BillingEntityType.Client, id: 65, name: 'Client' },
    { entityType: BillingEntityType.Fund, id: 1622, name: 'Andrew Milliken Superannuation Fund' },
    { entityType: BillingEntityType.Fund, id: 1627, name: 'Fairhaven Retirement Fund' },
    { entityType: BillingEntityType.Fund, id: 1632, name: 'JR Douglas Superannuation Fund' },
    { entityType: BillingEntityType.Fund, id: 1637, name: 'S & L Coates Family Superannuation Fund' },
];
