import React, { FunctionComponent } from 'react';
import { mergeStyleSets, Stack, useTheme } from '@fluentui/react';
import { Card } from 'components';

// !TODO add click handler on widget to open modals with tables
export interface IDoubleCountWidget {
    number: number;
    secondNum: number;
}

export const DoubleCountWidget: FunctionComponent<IDoubleCountWidget> = ({ number = 0, secondNum }) => {
    const theme = useTheme();

    const wrapperStyles = {
        root: {
            height: '100%',
            display: 'flex',
            padding: 0,
            justifyContent: 'space-between;',
            color: '#fff;',
        },
    };

    const cardStyles = {
        root: {
            backgroundColor: theme.palette.themePrimary,
            width: '48%',
            height: '100%',
            boxSizing: 'border-box',
            transition: 'all 0.2s linear',
            '&:hover': {
                opacity: 0.8,
                transform: 'scale(1.02)',
            },
        },
    };

    const cardStylesLeft = {
        root: {
            backgroundColor: theme.palette.blackTranslucent40,
            width: '48%',
            height: '100%',
            boxSizing: 'border-box',
            transition: 'all 0.2s linear',
            '&:hover': {
                opacity: 0.8,
                transform: 'scale(1.02)',
            },
        },
    };

    const classNames = mergeStyleSets({
        container: {
            height: '100%',
            textAlign: 'center',
            cursor: 'pointer',
        },
        title: [theme.fonts.xLarge],
        count: [
            theme.fonts.xxLarge,
            {
                paddingTop: 8,
            },
        ],
    });

    return (
        <Card styles={wrapperStyles}>
            <Card styles={cardStylesLeft}>
                <Stack horizontalAlign='center' className={classNames.container}>
                    <Stack.Item className={classNames.title}>Pre-AR Queries To Be Reviewed</Stack.Item>
                    <Stack.Item className={classNames.count}>{number}</Stack.Item>
                </Stack>
            </Card>
            <Card styles={cardStyles}>
                <Stack horizontalAlign='center' className={classNames.container}>
                    <Stack.Item className={classNames.title}>Post-AR Queries To Be Reviewed</Stack.Item>
                    <Stack.Item className={classNames.count}>{secondNum}</Stack.Item>
                </Stack>
            </Card>
        </Card>
    );
};
