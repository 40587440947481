import { IPivotItemProps, Pivot, PivotItem } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { DashboardTabsEnum } from 'pages/Workflow/DashboardWorkflow/DashboardWorkflowPage';

interface Props {
	selectedKey: string;
	setSelectedKey: Function;
}

export interface IDashboardPivotItemProps extends IPivotItemProps {
	itemKey: DashboardTabsEnum
}

export const DashboardTabsMenu: FunctionComponent<Props> = ({ selectedKey, setSelectedKey }) => {
	const { formatMessage } = useIntl();
	const handleLinkClick = (item?: PivotItem) => {
		if (item) {
			setSelectedKey(item.props.itemKey!);
		}
	};

	const tabs: IDashboardPivotItemProps[] = [
		{
			headerText: formatMessage({ id: 'administrator' }),
			itemKey: DashboardTabsEnum.ADMINISTRATOR,
		},
		{
			headerText: formatMessage({ id: 'auditor' }),
			itemKey: DashboardTabsEnum.AUDITOR,
		},
		{
			headerText: formatMessage({ id: 'contravention' }),
			itemKey: DashboardTabsEnum.CONTRAVENTION,
		},
		{
			headerText: formatMessage({ id: 'manager' }),
			itemKey: DashboardTabsEnum.MANAGER,
		},
		{
			headerText: formatMessage({ id: 'partner' }),
			itemKey: DashboardTabsEnum.PARTNER,
		},
	];

	return (
		<Pivot
			selectedKey={selectedKey}
			onLinkClick={handleLinkClick}
			headersOnly={true}
			styles={{ root: { height: 48 }, link: { height: 48 } }}>
			{tabs.map((tab, idx) => (
				<PivotItem key={`tab-${tab.itemKey}-${idx}`} {...tab} />
			))}
		</Pivot>
	);
};