export const MockedApprovers: Array<{ id: number; email: string }> = [
  {
    id: 378,
    email: 'a.butolin@hqsoftwarelab.com',
  },
  {
    id: 397,
    email: 'tkhaswala@evolvsuper.com.au',
  },
  {
    id: 420,
    email: 'd.shepelev@hqsoftwarelab.com',
  },
  {
    id: 421,
    email: 'd.tsyplenkov@hqsoftwarelab.com',
  },
  {
    id: 450,
    email: 'e.shumilo@evolvsuper.com.au',
  },
];
