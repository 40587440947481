import { FunctionComponent, ReactNode } from 'react';
import { IShimmerElement, Shimmer, ShimmerElementType, Stack, Text, useTheme } from '@fluentui/react';
import * as React from 'react';

interface Props {
    horizontal?: boolean;
    width?: number | string;
    label: string | ReactNode;
    value: string | ReactNode;
    enableShimmer?: boolean;
}

export const FieldView: FunctionComponent<Props> = ({ horizontal = false, width, label, value, enableShimmer = false }: Props) => {
    const theme = useTheme();
    const shimmerElement: IShimmerElement[] = [{ type: ShimmerElementType.line, height: 10, verticalAlign: 'bottom' }];

    return (
        <Stack
            horizontal={horizontal}
            verticalAlign={horizontal ? 'center' : undefined}
            styles={{ root: { width: width } }}
            tokens={{ childrenGap: 8 }}>
            <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                {label}
                {horizontal ? ':' : null}
            </Text>
            {enableShimmer ? (
                <Shimmer shimmerElements={shimmerElement} />
            ) : (
                <Text variant='smallPlus' styles={{ root: { color: theme.palette.neutralSecondary } }}>
                    {value}
                </Text>
            )}
        </Stack>
    );
};
