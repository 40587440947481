import { FunctionComponent, useEffect } from 'react';
import { IParamInfo, IRuleInfo } from 'interfaces';
import { observer } from 'mobx-react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { ActiveIconColorStyles } from 'constants/tableStylesPeset';
import { useIntl } from 'react-intl';
import { IconButton, useTheme } from '@fluentui/react';

import { useBoolean } from '@fluentui/react-hooks';
import { BaseDialog } from 'components';
import { ScopedVariableForm } from './ScopedVariableForm';
import * as React from 'react';
import { useForm, UseFormReset } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { useRulesetStore } from '../../../RulesetLayoutPage';
import { VariableModal } from './VariableModal';

interface Props {
    variable: IParamInfo;
    resetVar: UseFormReset<IParamInfo>;
    setUpdateRule: React.Dispatch<React.SetStateAction<IRuleInfo>>;
}

export const ScopedVariablesActions: FunctionComponent<Props> = observer(({ variable }: Props) => {
    const { formatMessage } = useIntl();
    const { rule } = useRulesetStore();
    const [isDeleteModalOpen, { toggle: toggleIsDeleteModalOpen }] = useBoolean(true);
    const [isEditModalOpen, { toggle: toggleIsEditModalOpen }] = useBoolean(false);
    const { control, handleSubmit, formState, setValue, clearErrors } = useForm<IParamInfo>({
        ...DefaultFormSettings,
        defaultValues: {
            name: variable.name || '',
            expression: variable.expression || '',
        },
    });

    const theme = useTheme();
    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'edit',
                text: formatMessage({ id: 'edit' }),
                iconProps: { iconName: 'Edit' },
                onClick: toggleIsEditModalOpen,
            },
            {
                key: 'delete',
                text: formatMessage({ id: 'delete' }),
                iconProps: {
                    iconName: 'Delete',
                    styles: ActiveIconColorStyles(theme.palette.red),
                },
                onClick: toggleIsDeleteModalOpen,
            },
        ],
    };

    const onDelete = (deleted: IParamInfo) => {
        console.log('deleted', deleted);
        //variable.deleted = true;
        if (rule?.localParams) {
            const ruleWithoutUpdate = rule.localParams.filter((item) => item.id !== deleted.id);
            //const newVariable = { ...variable, deleted: true };
            const newVariable = { ...deleted, deleted: true };
            rule.localParams = [...ruleWithoutUpdate, newVariable];
        }
        clearErrors('expression');
        clearErrors('name');
        toggleIsDeleteModalOpen();
    };

    const onEditVariable = (updated: IParamInfo) => {
        // let variableToUpdate = updatedRule.localParams?.filter((x) => x.id == updatedRule.id || x.name == updated.name);
        // variableToUpdate![0] = updated;
        if (rule?.localParams) {
            const ruleWithoutUpdate = rule.localParams.filter((item) => item.id !== updated.id);
            rule.localParams = [...ruleWithoutUpdate, updated];
        }
        clearErrors('expression');
        clearErrors('name');
        //resetVar();
        //reset();
        toggleIsEditModalOpen();
    };

    useEffect(() => {
        if (variable) {
            setValue('id', variable.id);
            setValue('name', variable.name);
            setValue('expression', variable.expression);
            setValue('deleted', variable.deleted);
        }
    }, [variable, setValue]);

    return (
        <>
            <IconButton onRenderMenuIcon={() => <div />} menuProps={menuProps} iconProps={{ iconName: 'More' }} />

            <BaseDialog
                title={formatMessage({ id: 'deleteVariableDialogTitle' })}
                subText={formatMessage({ id: 'deleteVariableDialogSubText' })}
                toggleHidden={toggleIsDeleteModalOpen}
                hidden={isDeleteModalOpen}
                onAgree={handleSubmit(onDelete)}
            />

            {isEditModalOpen && (
                <VariableModal
                    isOpen={isEditModalOpen}
                    disabled={!formState.isDirty}
                    title={formatMessage({ id: 'addNewGlobalVariable' })}
                    onDismiss={toggleIsEditModalOpen}
                    onSubmit={handleSubmit(onEditVariable)}
                    btnTitle={formatMessage({ id: 'save' })}>
                    <ScopedVariableForm control={control} onSubmit={handleSubmit(onEditVariable)} formState={formState} />
                </VariableModal>
            )}

            {/* <Modal isOpen={isEditModalOpen} onDismiss={toggleIsEditModalOpen} title={formatMessage({ id: 'addNewGlobalVariable' })}>
                <ScopedVariableForm control={control} onSubmit={handleSubmit(onEditVariable)} formState={formState} />
                <ModalFooter horizontalAlign={'end'}>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit(onEditVariable)}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Modal> */}
        </>
    );
});
