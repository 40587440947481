import * as React from 'react';
import { ChangeEvent, useRef } from 'react';
import { Controller, Path } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { PrimaryButton } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    iconProps?: IIconProps;
    accept?: string;
}

export function ControlledFileInput<FormValues>({ name, control, rules, label, iconProps, accept }: Props<FormValues>) {
    const ref = useRef<HTMLInputElement>(null);

    const handleChangeFileInput = (ev: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const reader = new FileReader();
        reader.onload = () => {
            onChange(reader.result);
        };
        const { files } = ev.target;
        if (files?.length) {
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                <>
                    <PrimaryButton iconProps={iconProps} width='100%' text={label} onClick={() => ref?.current?.click()} />
                    <input
                        onBlur={onBlur}
                        name={fieldName}
                        ref={ref}
                        style={{ display: 'none' }}
                        type='file'
                        accept={accept}
                        onChange={(ev: ChangeEvent<HTMLInputElement>) => handleChangeFileInput(ev, onChange)}
                    />
                </>
            )}
        />
    );
}
