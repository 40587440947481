import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';
import { useEffect } from 'react';

export const WorkflowLayoutPage = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigation = useNavigate();
  
    useEffect(() => {
      if (location.pathname === '/workflow') {
          navigation(RouterPaths.workflow.dashboard);
      }
  }, [location.pathname, navigation]);

    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'workflow',
                key: RouterPaths.workflow.root,
                text: formatMessage({ id: 'workflow' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};