import * as React from 'react';
import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { observer } from 'mobx-react';
import { RulesetsStore } from '../stores/RulesetsStore';
import { RulesetsFilters } from './components/RulesetsFilters';
import { RulesetsDataTable } from './components/RulesetsDataTable';
import { RulesetsPagination } from './components/RulesetsPagination';

const RulesetsStoreContext = createContext<RulesetsStore>(new RulesetsStore());

export const useRulesetsStore = () => useContext(RulesetsStoreContext);

export const RulesetsPage: FunctionComponent = observer(() => {
    const [store] = useState(new RulesetsStore());

    useEffect(() => {
        store.loadRulesets();
        return () => store.onDestroy();
    }, [store]);

    return (
        <RulesetsStoreContext.Provider value={store}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item shrink={0}>
                    <RulesetsFilters />
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                    <RulesetsDataTable />
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <RulesetsPagination />
                </Stack.Item>
            </Stack>
        </RulesetsStoreContext.Provider>
    );
});
